import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { imports } from '../../../../imports'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../globals'
import {
  DownloadFileBlob,
  Moment,
  Pagination,
  formatPrice,
  formatPriceAutoDecimal,
  productImage,
  productKey,
} from '../../../../helpers'
import { MatDialog } from '@angular/material/dialog'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { ProductStockService } from '../../../../services'
import { MatPaginator } from '@angular/material/paginator'
import { ModalDownloadFileComponent } from '../../../../components/modal-download-file/modal-download-file'
import { ModalSelectProductMultipleComponent } from '../../../../components/modal-select-product-multiple/modal-select-product-multiple'
import { CheckboxAllTableComponent } from '../../../../components/checkbox-table/checkbox-all-table.component'
import { CheckboxTableComponent } from '../../../../components/checkbox-table/checkbox-table.component'

@Component({
  selector: 'app-you-may-like-color',
  standalone: true,
  imports: [...imports, MatPaginator, CheckboxAllTableComponent, CheckboxTableComponent],
  templateUrl: './you-may-like-color.html',
  styleUrls: ['./you-may-like-color.scss'],
})
export class YouMayLikeColorComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment
  readonly formatPrice = formatPrice

  readonly pagination = new Pagination({
    sortName: 'code',
  })

  @Input() productId: any
  @Input() isEdit = false
  @Input() product_may_like_colors: any[] = []
  @Output() onClose = new EventEmitter()
  @Output() onConfirm = new EventEmitter()

  itemChecked = new Set<any>()
  listProduct: any[] = []

  get pageList() {
    return this.listProduct.filter((d: any, i: number) => {
      return i >= this.pagination.indexData.min && i <= this.pagination.indexData.max
    })
  }

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public productStockService: ProductStockService
  ) {}

  ngOnInit(): void {
    this.listProduct = [...this.product_may_like_colors]
    this.pagination.setFromResponse({
      page: 1,
      total_record: this.listProduct.length,
    })
  }

  addProduct() {
    const dialogRef = this.dialog.open(ModalSelectProductMultipleComponent, {
      data: {
        except_product_ids: [this.productId],
        product_list: [...this.listProduct],
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log('result', result)

        this.listProduct = result
        this.pagination.setFromResponse({
          page: 1,
          total_record: this.listProduct.length,
        })
        this.updatePage()
      }
    })
  }

  updatePage() {
    this.pagination.setFromResponse({
      page: this.pagination.page,
      total_record: this.listProduct.length,
    })

    if (this.pagination.data.pageIndex > 0 && this.pageList.length == 0) {
      this.pagination.data.pageIndex--
      this.updatePage()
    }
  }

  deleteItem(keys: any[] = [...this.itemChecked.values()]) {
    for (const key of keys) {
      const p = this.listProduct.find((p: any) => p.key == key)
      if (p) {
        this.listProduct.splice(this.listProduct.indexOf(p), 1)
        this.itemChecked.delete(key)
      }
    }
    this.updatePage()
  }

  confirm() {
    const products = [...this.listProduct]
    this.onConfirm.emit(products)
    this.onClose.emit()
  }
}
