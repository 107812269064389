<div class="page-main-content">
  <div class="top-content-wrapper align-items-center flex-wrap mb-3 gap-3">
    <div class="top-content-left gap-4">
      <div class="title-wrapper mb-0">สินค้า</div>
      <app-search
        [control]="filterTableProduct.search"
        placeholder="ค้นหา"
        (onEnter)="paginationProduct.reset();getProductList();itemChecked.clear()"></app-search>
    </div>
    <div class="top-content-right">
      <!-- สินค้าทั่วไป -->
      @if (tab == 0) { @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT],
      [ACTION_ACCESS.DELETE])) {
      <button
        matRipple
        class="btn btn-outline-danger"
        [disabled]="itemCheckedData.size < 1"
        (click)="confirmDelete()">
        <span class="icon material-symbols-outlined fill">delete</span>
        ลบข้อมูล @if (itemCheckedData.size > 0) { ({{ itemCheckedData.size }}) }
      </button>
      } @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT], [ACTION_ACCESS.EXPORT])) {
      <button
        matRipple
        (click)="openModalDownloadFile('ดาวน์โหลดไฟล์สินค้า', { message: 'คุณต้องการดาวน์โหลดไฟล์สินค้าที่เลือกใช่หรือไม่' })"
        class="btn btn-outline-secondary">
        <span class="icon material-symbols-outlined fill">download_2</span>
        ส่งออกไฟล์
      </button>
      }
      <!-- ~ -->
      @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT], [ACTION_ACCESS.IMPORT])) {
      <button
        matRipple
        (click)="openModalUploadFile('นำเข้าสินค้า')"
        class="btn btn-outline-secondary">
        <span class="icon material-symbols-outlined fill">upload_2</span>
        นำเข้าสินค้า
      </button>
      }
      <!-- ~ -->
      @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT], [ACTION_ACCESS.CREATE])) {
      <button matRipple [routerLink]="['/product/create']" class="btn btn-primary">
        <span class="icon material-symbols-outlined fill">add_circle</span>
        เพิ่มสินค้า
      </button>
      } }

      <!-- สินค้าลดราคา -->
      @if (tab == 1) {
      <button
        matRipple
        class="btn btn-outline-danger"
        [disabled]="itemCheckedDataDiscount.size < 1"
        (click)="confirmDelete()">
        <span class="icon material-symbols-outlined fill">delete</span>
        ลบข้อมูล @if (itemCheckedDataDiscount.size > 0) { ({{ itemCheckedDataDiscount.size }}) }
      </button>

      <button matRipple [routerLink]="['/product/discount/create']" class="btn btn-primary">
        <span class="icon material-symbols-outlined fill">add_circle</span>
        เพิ่มสินค้า
      </button>
      }
    </div>
  </div>

  <div class="tabs-wrapper">
    <mat-tab-group
      animationDuration="0ms"
      [disableRipple]="true"
      [selectedIndex]="tab"
      (selectedIndexChange)="changeTab($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="icon material-symbols-outlined fill">shopping_basket</span>
          <span>สินค้าทั่วไป</span>
        </ng-template>
        <div class="tabs-content-wrapper">
          <div class="top-content-wrapper align-items-center flex-wrap mb-3">
            <div class="top-content-left">
              <div class="title-blue">รายการสินค้า ({{ paginationProduct.data.length }})</div>
            </div>
            <div class="top-content-right">
              <app-select-sort
                [control]="sortControl"
                [datas]="list.sort"
                fieldValue="value"
                placeholder="เลือก"
                (selectionChange)="onSort()">
                <ng-template #triggerRef let-option> {{ option.text }}</ng-template>
                <ng-template #optionRef let-option> {{ option.text }}</ng-template>
              </app-select-sort>
            </div>
          </div>

          <div class="table-responsive table-main-tabs-wrapper">
            <table class="table table-fixed-column table-checkbox">
              <colgroup>
                <col width="70" />
                <col width="110" />
                <col width="200" />
                <col width="310" />
                <col width="120" />
                <col width="100" />
                <col width="100" />
                <col width="100" />
                <col width="100" />
                <col width="100" />
                <col width="100" />
                <col width="100" />
                <col width="100" />
                <col width="100" />
                <col width="180" />
              </colgroup>
              <thead>
                <tr class="tr-product">
                  <th rowspan="2">
                    <app-checkbox-all-table
                      [checkedValue]="itemChecked"
                      [datas]="productList"
                      fieldValue="id"
                      (onChange)="setItemCheckedData($event, productList)"></app-checkbox-all-table>
                  </th>
                  <th rowspan="2">รูปสินค้า</th>

                  <th rowspan="2">รหัสสินค้า</th>
                  <th rowspan="2">ชื่อสินค้า</th>
                  <th rowspan="2" class="text-end">ราคาขาย (บาท)</th>
                  <th
                    colspan="2"
                    class="text-center bg--brand-blue-100--f color--brand-blue-700--f">
                    ออนไลน์
                  </th>
                  <th colspan="2" class="text-center bg--orange-100--f color--orange-700--f">
                    Shopee
                  </th>
                  <th colspan="2" class="text-center bg--blue-100--f color--blue-700--f">Lazada</th>
                  <th rowspan="2" class="text-center">สต๊อกรวม</th>
                  <th rowspan="2" class="text-center">จองรวม</th>
                  <th rowspan="2" class="text-center">คงเหลือรวม</th>

                  <th rowspan="2" class="fixed box-shadow-left th-fixed">จัดการ</th>
                </tr>
                <tr class="tr-product tr-sub">
                  <th class="text-center bg--brand-blue-50--f color--brand-blue-700--f">สต๊อก</th>
                  <th class="text-center bg--brand-blue-50--f color--brand-blue-700--f">
                    <div class="d-flex justify-content-center align-items-center gap-1">
                      <div>จอง</div>
                      <span
                        matTooltipClass="tooltip-black"
                        matTooltip="สถานะจองคือสินค้าที่อยู่ในออเดอร์ที่ยังไม่ปิดรายการ เมื่อออเดอร์ถูกปิดรายการจึงจะตัดสต๊อก"
                        matTooltipPosition="above"
                        class="icon-16 icon-brand-blue-700 material-symbols-outlined fill cursor-pointer"
                        >info</span
                      >
                    </div>
                  </th>

                  <th class="text-center bg--orange-50--f color--orange-700--f">สต๊อก</th>
                  <th class="text-center bg--orange-50--f color--orange-700--f">
                    <div class="d-flex justify-content-center align-items-center gap-1">
                      <div>จอง</div>
                      <span
                        matTooltipClass="tooltip-black"
                        matTooltip="สถานะจองคือสินค้าที่อยู่ในออเดอร์ที่ยังไม่ปิดรายการ เมื่อออเดอร์ถูกปิดรายการจึงจะตัดสต๊อก"
                        matTooltipPosition="above"
                        class="icon-16 icon-orange-700 material-symbols-outlined fill cursor-pointer"
                        >info</span
                      >
                    </div>
                  </th>

                  <th class="text-center bg--blue-50--f color--blue-700--f">สต๊อก</th>
                  <th class="text-center bg--blue-50--f color--blue-700--f">
                    <div class="d-flex justify-content-center align-items-center gap-1">
                      <div>จอง</div>
                      <span
                        matTooltipClass="tooltip-black"
                        matTooltip="สถานะจองคือสินค้าที่อยู่ในออเดอร์ที่ยังไม่ปิดรายการ เมื่อออเดอร์ถูกปิดรายการจึงจะตัดสต๊อก"
                        matTooltipPosition="above"
                        class="icon-16 icon-blue-700 material-symbols-outlined fill cursor-pointer"
                        >info</span
                      >
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                @for (item of productList; track $index) {
                <tr class="tr-product">
                  <td class="td-checkbox">
                    <app-checkbox-table
                      [checkedValue]="itemChecked"
                      [value]="item.id"
                      (onChange)="setItemCheckedData($event, [item])"></app-checkbox-table>
                  </td>
                  <td>
                    <img
                      width="90"
                      height="90"
                      alt="Product"
                      class="img-product"
                      [src]="getCover(item)?.file.url || '/assets/images/default-product.svg'" />
                  </td>
                  <td class="px-0 py-3" colspan="12">
                    <table class="w-100">
                      <colgroup>
                        <col width="200" />
                        <col width="310" />
                        <col width="120" />
                        <col width="100" />
                        <col width="100" />
                        <col width="100" />
                        <col width="100" />
                        <col width="100" />
                        <col width="100" />
                        <col width="100" />
                        <col width="100" />
                        <col width="100" />
                      </colgroup>
                      <tbody>
                        <tr class="tr-sub-product">
                          <td>{{ item.code }}</td>
                          <td>{{ item.name }}</td>
                          <td class="text-end">
                            {{ item.isShowStock ? formatPrice(item.selling_price) : '' }}
                          </td>

                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(getStock(item,
                            channelId.online)?.stock) : '' }}
                          </td>
                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(getStock(item,
                            channelId.online)?.book) : '' }}
                          </td>

                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(getStock(item,
                            channelId.shopee)?.stock) : '' }}
                          </td>
                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(getStock(item,
                            channelId.shopee)?.book) : '' }}
                          </td>

                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(getStock(item,
                            channelId.lazada)?.stock) : '' }}
                          </td>
                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(getStock(item,
                            channelId.lazada)?.book) : '' }}
                          </td>

                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(item.stock) : '' }}
                          </td>
                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(item.book) : '' }}
                          </td>
                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(item.remain) : '' }}
                          </td>
                        </tr>
                        @for (itemD of item.details; track $index) {
                        <tr class="tr-sub-product">
                          <td>{{ itemD.code }}</td>
                          <td>
                            <div class="d-flex flex-column">
                              <div>{{ itemD.name }}</div>
                              <div class="color-size-text">{{ itemD.color }}, {{ itemD.size }}</div>
                            </div>
                          </td>
                          <td class="text-end">{{ formatPrice(itemD.selling_price) }}</td>

                          <td class="text-center">
                            {{ formatPriceAutoDecimal(getStock(itemD, channelId.online)?.stock) }}
                          </td>
                          <td class="text-center">
                            {{ formatPriceAutoDecimal(getStock(itemD, channelId.online)?.book) }}
                          </td>

                          <td class="text-center">
                            {{ formatPriceAutoDecimal(getStock(itemD, channelId.shopee)?.stock) }}
                          </td>
                          <td class="text-center">
                            {{ formatPriceAutoDecimal(getStock(itemD, channelId.shopee)?.book) }}
                          </td>

                          <td class="text-center">
                            {{ formatPriceAutoDecimal(getStock(itemD, channelId.lazada)?.stock) }}
                          </td>
                          <td class="text-center">
                            {{ formatPriceAutoDecimal(getStock(itemD, channelId.lazada)?.book) }}
                          </td>

                          <td class="text-center">{{ formatPriceAutoDecimal(itemD.stock) }}</td>
                          <td class="text-center">{{ formatPriceAutoDecimal(itemD.book) }}</td>
                          <td class="text-center">{{ formatPriceAutoDecimal(itemD.remain) }}</td>
                        </tr>
                        }
                      </tbody>
                    </table>
                  </td>
                  <td class="fixed box-shadow-left">
                    <div class="d-flex justify-content-start align-items-center">
                      @if (item.action.view) {
                      <button
                        matRipple
                        matTooltip="ดู"
                        matTooltipPosition="above"
                        class="btn btn-outline-secondary btn-icon me-2"
                        [routerLink]="['/product/view/' + item.id]">
                        <span class="icon material-symbols-outlined fill">visibility</span>
                      </button>
                      }
                      <!-- ~ -->
                      @if (item.action_stock.add) {
                      <button
                        matRipple
                        (click)="openModalAddStock('เพิ่มสต๊อก', item)"
                        class="btn btn-outline-secondary h-36px">
                        <span class="icon material-symbols-outlined fill">add_circle</span>
                        เพิ่มสต๊อก
                      </button>
                      }
                    </div>
                  </td>
                </tr>
                }
              </tbody>
            </table>

            @if(!productList.length) {
            <div class="data-not-found-wrapper">ไม่พบสินค้า กรุณาเพิ่มสินค้า</div>
            }
          </div>

          <!-- Pagination -->
          @if (productList.length) {
          <div class="pagination-wrapper d-flex flex-wrap gap-2">
            <mat-paginator
              [pageIndex]="paginationProduct.data.pageIndex"
              [length]="paginationProduct.data.length"
              [pageSize]="paginationProduct.data.pageSize"
              [pageSizeOptions]="paginationProduct.data.pageSizeOptions"
              (page)="paginationProduct.setFromPaginator($event);getProductList()"
              aria-label="Select page">
            </mat-paginator>
            <div class="d-flex align-items-center gap-2 mat-mdc-paginator">
              <span>ไปที่หน้า</span>
              <div class="font-12" [style]="{ 'min-width': '70px', 'max-width': '70px' }">
                @if (!rerender) {
                <app-input-number
                  [control]="pageNumber"
                  placeholder="0"
                  [min]="1"
                  [max]="paginationProduct.totalPage || 1"
                  [remove_bottom]="true"
                  (onEnter)="onChangePage()"
                  (onblur)="onChangePage(true)">
                </app-input-number>
                }
              </div>
              / {{ formatPriceAutoDecimal(paginationProduct.totalPage) }}
            </div>
          </div>
          }
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <span class="icon material-symbols-outlined fill">percent</span>
          <span>สินค้าลดราคา</span>
        </ng-template>
        <div class="tabs-content-wrapper">
          <app-tab-products-discount
            (setItemCheckedDiscount)="setItemCheckedDiscount($event)"></app-tab-products-discount>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
