<div class="page-content pb-0">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">หน้ารวมสินค้า</div>
  </div>

  <div class="title-wrapper">หน้ารวมสินค้า</div>

  <div class="tabs-wrapper">
    <mat-tab-group
      animationDuration="0ms"
      [disableRipple]="true"
      [selectedIndex]="tab"
      (selectedIndexChange)="changeTab($event)">
      @for (item of tabList.controls; track item; let i = $index) {
      <mat-tab [label]="item.value.name || ''">
        <div class="tabs-content-wrapper">
          @if (isView) {
          <div class="form-label color--neutral-500 mb-3">
            วันที่อัปเดต: 02-04-2024, 19:00 อัปเดตโดย: กัญญา วงศ์วิไล
          </div>
          <div class="mb-3">
            <app-status [status]="3"> เผยแพร่แล้ว </app-status>
          </div>
          } @for (content of item.controls['content'].controls; track content; let j = $index) {
          <div class="title-blue mt-3">คอนเทนต์ที่ {{j + 1}}/4</div>
          <div class="box">
            @if (isEdit) {
            <app-radio-layout
              [oneImage]="true"
              [control]="content.controls['type']"
              (onChange)="content.controls['type'].setValue($event)" />
            }

            <div class="title-blue mt-2">
              ภาพส่วนที่ {{j + 1}} ({{ content.controls['type'].value == 'one' ? 'แนวนอน' :
              'แนวตั้ง' }})
            </div>
            @if (isEdit) {
            <div class="text-center font-14 color--neutral-500 mb-1">
              @if (content.controls['type'].value == 'one') { (ขนาดรูปภาพ : 1328px x 810px / ไม่เกิน
              5 Mb) } @else { (ขนาดรูปภาพ : 620px x 1015px / ไม่เกิน 5 Mb) } | (ขนาดวีดิโอ : 1920px
              x 1080px / ไม่เกิน 30 Mb)
            </div>
            }

            <div class="d-flex justify-content-center">
              <div
                [style]="content.controls['type'].value == 'one' ? 'width: 655px; height: 400px' : 'width: 310px; height: 500px'">
                <app-upload-file-flexible
                  [type]="isEdit ? 'edit' : 'view'"
                  [maxImageSize]="5"
                  [maxVideoSize]="30"
                  (upload)="content.controls['file'].setValue($event)" />
              </div>
            </div>
            <div class="d-flex justify-content-center mt-2">
              <div
                [style]="content.controls['type'].value == 'one' ? 'width: 655px' : 'width: 310px'">
                <label class="form-label {{ isView && 'color--neutral-500'}}">เพิ่มลิงก์</label>
                <div>
                  @if (isEdit) {
                  <app-select-add-link
                    [controlLinkType]="content.controls['link']"
                    [controlLinkName]="content.controls['link_name']" />
                  } @else { เสื้อผ้า }
                </div>
              </div>
            </div>

            <div class="title-section mb-3 mt-4 pt-2 d-flex justify-content-between">
              รายการสินค้า ({{content.controls['product_list'].value?.length}}/4)
              <!-- ~~ -->
              @if (isEdit) {
              <!-- ~~ -->
              @if (content.controls['product_list'].value?.length) {
              <button
                class="btn btn-primary w-fit"
                (click)="addProduct(content.controls['product_list'])"
                [disabled]="content.controls['product_list'].value?.length == 4">
                <span class="icon material-symbols-outlined fill"> add_circle </span>
                เลือกสินค้า
              </button>
              } }
            </div>

            <div class="table-responsive-detail">
              <table class="table table-fixed-column">
                <thead>
                  <tr>
                    <th width="50"></th>
                    <th width="90"></th>
                    <th width="320">รหัสสินค้า</th>
                    <th width="350">ชื่อสินค้า</th>
                    <th width="130" class="text-end">ราคา (บาท)</th>
                    <th width="130" class="text-center">สต๊อกคงเหลือ</th>
                    @if (isEdit) {
                    <th width="100" class="fixed box-shadow-left"></th>
                    }
                  </tr>
                </thead>
                <tbody
                  id="all"
                  cdkDropList
                  (cdkDropListDropped)="dropTable($event, content.controls['product_list'].value)"
                  class="drag-list mt-3 w-100"
                  cdkDropListGroup>
                  @for (product of content.controls['product_list'].value; track product; let k =
                  $index;) {
                  <tr class="drag-box mb-2" cdkDrag cdkDragHandle>
                    <td width="50">
                      <div class="pre-box">
                        <button class="btn btn-transparent-outline me-1">
                          <span class="icon icon-dark-grey material-symbols-outlined fill">
                            drag_indicator
                          </span>
                        </button>
                      </div>
                    </td>
                    <td width="90">
                      <img src="/assets/images/icons/img-product-test.svg" />
                    </td>
                    <td width="320">{{ product?.['code'] }}</td>
                    <td width="350">
                      <div>
                        <div>{{product?.['name']}}</div>
                        <div class="product-detail">
                          <div
                            class="tag-color"
                            [ngStyle]="{'background-color': product?.['color']}"></div>
                          <div>{{product?.['color_name']}}, {{product?.['size']}}</div>
                        </div>
                      </div>
                    </td>
                    <td width="130" class="text-end">{{ product?.['price'] }}</td>
                    <td width="130" class="text-center">{{ product?.['qty'] }}</td>
                    @if (isEdit) {
                    <td width="100" class="fixed box-shadow-left">
                      <div class="w-100 d-flex justify-content-center">
                        <button
                          matRipple
                          class="btn btn-outline-danger"
                          (click)="deleteProduct(content.controls['product_list'], product?.['id'])">
                          <span class="icon material-symbols-outlined fill">delete</span>
                        </button>
                      </div>
                    </td>
                    }
                  </tr>
                  }
                </tbody>
              </table>

              @if(!content.controls['product_list'].value?.length) {
              <!-- ~~ -->

              <div class="data-not-found-wrapper flex-column align-items-center">
                <div>ไม่พบสินค้า กรุณาเลือกสินค้า</div>
                @if (isEdit) {
                <button
                  class="btn btn-primary me-1 mt-4 w-fit"
                  (click)="addProduct(content.controls['product_list'])">
                  <span class="icon material-symbols-outlined fill"> add_circle </span>
                  เลือกสินค้า
                </button>
                }
              </div>
              }
            </div>
          </div>
          } @if (isEdit) {
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-outline-secondary w-fit mt-3"
              (click)="addContent(item.controls['content'])"
              [disabled]="item.controls['content'].controls.length == 4">
              <span class="icon material-symbols-outlined fill"> add_circle </span>
              เพิ่มชุดคอนเทนต์
            </button>
          </div>
          }
        </div>
      </mat-tab>
      }
    </mat-tab-group>
  </div>

  <div class="page-footer-btn-tab">
    @if (isView) {
    <div class="d-flex justify-content-end">
      <button
        matRipple
        class="btn btn-outline-secondary min-w-120px"
        [routerLink]="['/setting/cms/product-list/edit']">
        แก้ไข
      </button>
    </div>
    } @else if(isEdit) {
    <div class="d-flex flex-wrap justify-content-between">
      <div>
        <button matRipple class="btn btn-outline-danger min-w-135px">ล้างข้อมูล</button>
      </div>

      <div class="d-flex flex-wrap gap-2">
        <button matRipple class="btn btn-outline-secondary min-w-135px" (click)="openPreview()">
          ดูตัวอย่าง
        </button>
        <button matRipple class="btn btn-outline-secondary min-w-135px" (click)="onSave()">
          บันทึกร่าง
        </button>
        <button matRipple class="btn btn-primary min-w-135px">เผยแพร่ทันที</button>
      </div>
    </div>
    }
  </div>
</div>
