import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class CMSProductListService {
  constructor(private service: Service) {}
  private pathCMSProductList = '/v1/cms/product-list'

  getProductList(): Observable<any> {
    return this.service.get(`${this.pathCMSProductList}`)
  }

  getProductListById(id: any): Observable<any> {
    return this.service.get(`${this.pathCMSProductList}/${id}`)
  }

  updateProductList(payload: any): Observable<any> {
    return this.service.put(`${this.pathCMSProductList}`, payload)
  }
}
