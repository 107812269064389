import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'

@Component({
  selector: 'app-shipping-manage',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
  ],
  templateUrl: './shipping-manage.html',
  styleUrls: ['./shipping-manage.scss'],
})
export class ShippingManageComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false
  dataManage: any
  id: any = 1


  form = new FormGroup({
    shipping_normal: new FormControl('', [Validators.required, Validators.max(1000000000)]),
    conditions: new FormControl(1),

    min_order: new FormControl(''),
    min_purchase: new FormControl(''),
    shipping_cost: new FormControl(''),
  })

  conditionsList = [
    {id: 1, name: 'ไม่มีกำหนด'},
    {id: 2, name: 'ยอดคำสั่งซื้อขั้นต่ำ'},
    {id: 3, name: 'จำนวนสินค้าที่ซื้อขั้นต่ำ'},
    {id: 4, name: 'ยอดคำสั่งซื้อและจำนวนสินค้าที่ซื้อขั้นต่ำ'},
  ]

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    this.profile.getProfile()
    this.initData()
  }

  initData() {
    // const apis = [this.caratService.getCarat(this.id)]

    // this.loading.start()
    // forkJoin(apis).subscribe(([resCarat]: any) => {
    //   if (resCarat) {
    //     if (!resCarat.is_error) {
    //       this.dataManage = resCarat.data

    //       this.form.reset({
    //         carat: resCarat.data.carat || 1,
    //         total_order_amount: resCarat.data.total_order_amount,
    //         order_start_date: resCarat.data.order_start_date,
    //         order_end_date: resCarat.data.order_end_date,
    //         value_amount: resCarat.data.value_amount,
    //         // value_start_date: resCarat.data.value_start_date,
    //         // value_end_date: resCarat.data.value_end_date,
    //       })
    //       this.discounts.clear()
    //       const discounts = (this.dataManage.discounts || []).sort((a: any, b: any) =>
    //         a.sort > b.sort ? 1 : -1
    //       )
    //       for (const discount of discounts) {
    //         this.addCaratDiscount(discount)
    //       }
    //     } else {
    //       this.customSnackBar.fail(resCarat.message)
    //     }
    //   }

    //   this.loading.stop()
    // })
  }

  resetValidate() {
    this.form.controls['min_order'].clearValidators();
    this.form.controls['min_order'].updateValueAndValidity();
    this.form.controls['min_purchase'].clearValidators();
    this.form.controls['min_purchase'].updateValueAndValidity();
    this.form.controls['shipping_cost'].clearValidators();
    this.form.controls['shipping_cost'].updateValueAndValidity();
  }

  onSave() {
    if (this.form.value.conditions == 2) {
      this.form.controls['min_order'].setValidators([Validators.required, Validators.max(1000000)])
      this.form.controls['shipping_cost'].setValidators([Validators.required, Validators.max(1000000)])
    }

    if (this.form.value.conditions == 3) {
      this.form.controls['min_purchase'].setValidators([Validators.required, Validators.max(1000000)])
      this.form.controls['shipping_cost'].setValidators([Validators.required, Validators.max(1000000)])
    }

    if (this.form.value.conditions == 4) {
      this.form.controls['min_order'].setValidators([Validators.required, Validators.max(1000000)])
      this.form.controls['min_purchase'].setValidators([Validators.required, Validators.max(1000000)])
      this.form.controls['shipping_cost'].setValidators([Validators.required, Validators.max(1000000)])
    }

    this.form.updateValueAndValidity()

    console.log('this.form', this.form);
    
    ValidateForm(this.form)
    if (!this.form.valid) {
      return
    }

  }

}
