import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class CMSMainService {
  constructor(private service: Service) {}
  private pathCMSMain = '/v1/cms/main'

  getCmsMain(): Observable<any> {
    return this.service.get(`${this.pathCMSMain}`)
  }

  updateCmsMain(payload: any): Observable<any> {
    return this.service.put(`${this.pathCMSMain}`, payload)
  }
}
