import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { CommonModule } from '@angular/common'
import { imports } from '../../imports'
import { MatRadioModule } from '@angular/material/radio'

@Component({
  selector: 'app-radio-layout',
  standalone: true,
  imports: [
    imports,
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    MatSuffix,
    MatRadioModule,
  ],
  templateUrl: './radio-layout.html',
  styleUrls: ['./radio-layout.scss'],
})
export class RadioLayoutComponent implements OnInit {
  @Input() oneImage: boolean = false
  @Input() control: FormControl | AbstractControl = new FormControl('')
  @Output() onChange = new EventEmitter()

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  list: any[] = []
  ngOnInit(): void {
    this.list = [
      {
        img: '/assets/images/icons/img-layout-image-left.svg',
        value: 'left',
        text: 'จัดภาพชิดซ้าย',
        show: this.oneImage,
      },
      {
        img: '/assets/images/icons/img-layout-image-right.svg',
        value: 'right',
        text: 'จัดภาพชิดขวา',
        show: this.oneImage,
      },
      {
        img: '/assets/images/icons/img-layout-image-two.svg',
        value: 'two',
        text: '2 ภาพ',
        show: !this.oneImage,
      },
      {
        img: '/assets/images/icons/img-layout-image-one.svg',
        value: 'one',
        text: '1 ภาพ',
        show: true,
      },
    ]
    const initialValue = this.formControl.value
    if (initialValue) {
      this.onChange.emit(initialValue)
    }
  }

  selectOption(value: any) {
    this.formControl.setValue(value)
    this.onChange.emit(value)
  }
}
