export const channelId = {
  online: 0,
  none: 1,
  line: 2,
  instragram: 3,
  facebook: 4,
  lazada: 5,
  shopee: 6,
  website: 7,
} as const

export type EnumChannelId = (typeof channelId)[keyof typeof channelId]

export const channelName = {
  [channelId.online]: 'ออนไลน์',
  [channelId.none]: 'ไม่ระบุ',
  [channelId.line]: 'Line',
  [channelId.instragram]: 'Instagram',
  [channelId.facebook]: 'Facebook',
  [channelId.lazada]: 'Lazada',
  [channelId.shopee]: 'shopee',
  [channelId.website]: 'เว็บไซต์',
} as const

export const channelIcon = {
  [channelId.online]: '',
  [channelId.none]: '',
  [channelId.line]: 'line',
  [channelId.instragram]: 'ig',
  [channelId.facebook]: 'fb',
  [channelId.lazada]: 'lazada',
  [channelId.shopee]: 'shopee',
  [channelId.website]: 'website',
} as const

export type EnumChannelIcon = (typeof channelIcon)[keyof typeof channelIcon]
