<div class="page-content pb-0">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">สิทธิประโยชน์</div>
  </div>

  <div class="title-wrapper">สิทธิประโยชน์</div>

  <div class="page-content-inner d-flex flex-column pb-0">
    <div class="tab-box">
      <div class="box-left">
        <div class="box-top d-flex justify-content-between">
          <div class="d-flex align-items-center">ระดับสมาชิก</div>
        </div>
        <div class="box-body">
          @for (item of tierList; track $index) {
          <div
            matRipple
            class="box d-flex justify-content-between align-items-center manage {{ $index != tierList.length && 'mb-3' }}"
            [class.active]="tierId == item.id"
            (click)="setSelection(item.id)">
            <div class="box-name d-flex justify-content-between align-items-center">
              <div class="text-truncate">{{item.name}}</div>
            </div>
          </div>
          }
        </div>
      </div>
      <div class="box-right">
        @for (item of tierList; track $index) {
        <!-- ~~ -->
        @if(tierId == item.id) {
        <div class="box-body right min-hight">
          @if (!!dataManage) {
          <div class="form-label color--neutral-500">
            วันที่อัปเดต: {{ Moment(dataManage?.updated_at).format('DD-MM-YYYY, HH:mm') }}
            อัปเดตโดย: {{ dataManage?.updated_by?.full_name || '-' }}
          </div>
          <div class="mb-3">
            <app-status [status]="dataManage?.is_public ? 'public' : 'not_public'">
              {{dataManage?.is_public ? 'เผยแพร่แล้ว' : 'ยังไม่เผยแพร่'}}
            </app-status>
          </div>
          }
          <div class="tabs-modal-wrapper">
            <mat-tab-group
              animationDuration="0ms"
              [selectedIndex]="tab"
              (selectedIndexChange)="changeTab($event)">
              <mat-tab label="ภาษาไทย">
                <div class="tabs-content-wrapper pt-3 px-2">
                  <label class="label-view">เนื้อหาคอนเทนต์</label>
                  @if (isEdit) {
                  <app-text-editor
                    [control]="form.controls['detail_th']"
                    [toolbarImg]="true"
                    [toolbarControl]="[
                    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
                    ['bold', 'italic', 'underline'],
                    ['bullet_list', 'ordered_list'],
                    ['align_left', 'align_center', 'align_right'],
                  ]"></app-text-editor>
                  } @else {
                  <div class="box-view">
                    @if (form.value.detail_th) {
                    <div
                      [innerHtml]="sanitizer.bypassSecurityTrustHtml(form.value.detail_th)"></div>
                    } @else { {{ form.value['detail_th'] || '-' }} }
                  </div>
                  }
                </div>
              </mat-tab>
              <mat-tab label="ภาษาอังกฤษ">
                <div class="tabs-content-wrapper pt-3 px-2">
                  <label class="label-view">เนื้อหาคอนเทนต์</label>
                  @if (isEdit) {
                  <app-text-editor
                    [control]="form.controls['detail_en']"
                    [toolbarImg]="true"
                    [toolbarControl]="[
                    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
                    ['bold', 'italic', 'underline'],
                    ['bullet_list', 'ordered_list'],
                    ['align_left', 'align_center', 'align_right'],
                  ]"></app-text-editor>
                  } @else {
                  <div class="box-view">
                    @if (form.value.detail_en) {
                    <div
                      [innerHtml]="sanitizer.bypassSecurityTrustHtml(form.value.detail_en)"></div>
                    } @else { {{ form.value['detail_en'] || '-' }} }
                  </div>
                  }
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
        }
        <!-- ~~ -->
        }
      </div>
    </div>

    <div class="page-footer-btn">
      @if (isView) {
      @if (profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.UPDATE])) {
      <div class="d-flex flex-wrap justify-content-end">
        <button
          matRipple
          class="btn btn-outline-secondary min-w-165px"
          [routerLink]="['/setting/cms/tier/edit']"
          [queryParams]="{ id: tierId }">
          แก้ไข
        </button>
      </div>
      }
      } @else if(isEdit) {
      <div class="footer-btn d-flex flex-wrap justify-content-between">
        <div class="footer-btn-left d-flex flex-wrap">
          <button matRipple class="btn btn-outline-secondary min-w-165px" (click)="onCancel()">
            ยกเลิก
          </button>
        </div>

        <div class="footer-btn-right d-flex flex-wrap">
          <button matRipple class="btn btn-outline-secondary min-w-165px" (click)="openPreview()">
            ดูตัวอย่าง
          </button>
          @if (profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.UPDATE]) ||
          profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.CREATE])) {
          <button
            matRipple
            class="btn btn-outline-secondary min-w-165px"
            (click)="onSave('private')">
            บันทึกร่าง
          </button>
          <button matRipple class="btn btn-primary min-w-165px" (click)="onSave('public')">
            เผยแพร่ทันที
          </button>
          }
        </div>
      </div>
      }
    </div>
  </div>
</div>
