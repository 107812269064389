<div class="page-content pb-0">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">แบรนด์ของเรา</div>
  </div>

  <div class="title-wrapper">แบรนด์ของเรา</div>

  <div class="page-content-inner d-flex flex-column pb-0">
    <div class="row">
      <div class="col-12 col-sm-4">
        <div class="title-blue mb-3">ภาพแบรนด์</div>
        @if (isEdit) {
        <div class="font-14 color--neutral-500 mb-1">
          (ขนาดรูปภาพ : 720px x 830px / ไม่เกิน 5 Mb)
        </div>
        }
        <div class="my-2" [style]="'height: 500px'">
          <app-upload-file-flexible
            [type]="isEdit ? 'edit' : 'view'"
            [imgOnly]="true"
            [maxImageSize]="5"
            [maxVideoSize]="30"
            [isRequired]="form.controls['file'].touched && form.controls['file'].invalid"
            [logoDefault]="dataManage?.file_main_banner_file?.url"
            (upload)="form.controls['file'].setValue($event); isErrorFile = false" />
        </div>
      </div>
      <div class="col-12 col-sm-8">
        <div class="tabs-modal-wrapper box">
          @if (isView && dataManage) {
            <div class="form-label color--neutral-500">
              วันที่อัปเดต: {{ Moment(dataManage?.updated_at).format('DD-MM-YYYY, HH:mm') }} อัปเดตโดย: {{ dataManage?.updated_by?.full_name || '-' }}
            </div>
            <div class="mb-3">
              <app-status [status]="dataManage?.is_public ? 'public' : 'not_public'">
                {{dataManage?.is_public ? 'เผยแพร่แล้ว' : 'ยังไม่เผยแพร่'}}
              </app-status>
            </div>
            }
          <mat-tab-group
            animationDuration="0ms"
            [selectedIndex]="tab"
            (selectedIndexChange)="changeTab($event)">
            <mat-tab label="ภาษาไทย">
              <div class="tabs-content-wrapper">
                <label class="label-view">เนื้อหาคอนเทนต์</label>
                @if (isEdit) {
                <app-text-editor
                  [control]="form.controls['detail_th']"
                  [toolbarImg]="true"
                  [toolbarControl]="[
                [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
                ['bold', 'italic', 'underline'],
                ['bullet_list', 'ordered_list'],
                ['align_left', 'align_center', 'align_right'],
              ]"></app-text-editor>
                } @else {
                <div class="box-view">
                  @if (form.value.detail_th) {
                    <div [innerHtml]="sanitizer.bypassSecurityTrustHtml(form.value.detail_th)"></div>
                    } @else { - }
                </div>
                }
              </div>
            </mat-tab>
            <mat-tab label="ภาษาอังกฤษ">
              <div class="tabs-content-wrapper">
                <label class="label-view">เนื้อหาคอนเทนต์</label>
                @if (isEdit) {
                <app-text-editor
                  [control]="form.controls['detail_en']"
                  [toolbarImg]="true"
                  [toolbarControl]="[
                  [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
                  ['bold', 'italic', 'underline'],
                  ['bullet_list', 'ordered_list'],
                  ['align_left', 'align_center', 'align_right'],
                ]"></app-text-editor>
                } @else {
                <div class="box-view">
                  @if (form.value.detail_en) {
                  <div [innerHtml]="sanitizer.bypassSecurityTrustHtml(form.value.detail_en)"></div>
                  } @else { - }
                </div>
                }
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>

    <div class="page-footer-btn">
      @if (isView) {
      <div class="d-flex justify-content-end">
        <button
          matRipple
          class="btn btn-outline-secondary min-w-120px"
          [routerLink]="['/setting/cms/about-us/edit']">
          แก้ไข
        </button>
      </div>
      } @else if(isEdit) {
      <div class="d-flex flex-wrap justify-content-end gap-2">
        <button matRipple class="btn btn-outline-secondary min-w-135px" (click)="openPreview()">
          ดูตัวอย่าง
        </button>
        <button matRipple class="btn btn-outline-secondary min-w-135px" (click)="onSave('private')">
          บันทึกร่าง
        </button>
        <button matRipple class="btn btn-primary min-w-135px" (click)="onSave('public')">
          เผยแพร่ทันที
        </button>
      </div>
      }
    </div>
  </div>
</div>
