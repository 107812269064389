import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class CMSTierService {
  constructor(private service: Service) {}
  private pathCMSTier = '/v1/cms/tier'

  addCmsTier(payload: any): Observable<any> {
    return this.service.put(`${this.pathCMSTier}`, payload)
  }

  getCmsTier(): Observable<any> {
    return this.service.get(`${this.pathCMSTier}`)
  }
}
