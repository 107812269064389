import { Component, Inject, Injectable } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { FormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatTabsModule } from '@angular/material/tabs'
import { MatIconModule } from '@angular/material/icon'
import { MatRipple } from '@angular/material/core'
import { TopMenuComponent } from '../../../../components/preview/top-menu/top-menu'
import { FileService, ProductService } from '../../../../services'
import { Loading } from '../../../../globals'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { CardProductComponent } from '../../../../components/preview/card-product/card-product'
import { FooterComponent } from '../../../../components/preview/footer/footer'
import { groupProducts, ProductData } from '../../../../helpers/preview/product'

@Component({
  selector: 'app-modal-preview',
  templateUrl: './modal-preview.html',
  styleUrls: ['./modal-preview.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatTabsModule,
    MatIconModule,
    MatRipple,
    TopMenuComponent,
    CardProductComponent,
    FooterComponent,
  ],
})
export class ModalPreviewProductListComponent {
  categoryTemp = [
    { name: 'All' },
    { name: 'Dresses' },
    { name: 'Denim' },
    { name: 'Shirt' },
    { name: 'Skirts' },
    { name: 'Short' },
    { name: 'Top' },
    { name: 'Trousers' },
  ]
  contents: any[] = []

  constructor(
    public dialogRef: MatDialogRef<ModalPreviewProductListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fileService: FileService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public productService: ProductService
  ) {}

  ngOnInit(): void {
    console.log(this.data)
    this.setData()
  }

  getFile(id: any, call: Function) {
    this.loading.start()
    this.fileService.getFile(id).subscribe(res => {
      if (!res.is_error) {
        call(res)
      } else {
        this.customSnackBar.fail()
      }
      this.loading.stop()
    })
  }

  setData() {
    this.contents = (this.data.formValue.contents || []).map((content: any) => {
      content.layout_type =
        content.layout_type == 'one' ? 'bottom' : content.layout_type == 'right' ? 'right' : 'left'

      content.file = null
      this.getFile(content.file_id, (res: any) => {
        content.file = res.data
      })

      const products = groupProducts(
        (content.cms_pania_all_product_lists || []).map((d: any, i: number) => {
          return {
            seq: i + 1,
            ...d,
          }
        })
      )

      content.products = products.map((product: any) => {
        return new ProductData(product)
      })

      return content
    })

    // console.log(this.contents)
  }

  onClose(): void {
    this.dialogRef.close()
  }
}
