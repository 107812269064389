<div class="table-responsive mt-3">
  <div class="wrapper">
    <button class="btn-add-column btn btn-transparent-outline" (click)="addColumn()">
      <span class="icon color--neutral-500 material-symbols-outlined fill"> add_circle </span>
    </button>

    <table class="table-size" [cellPadding]="0" [cellPadding]="0">
      <thead>
        <tr>
          <th class="th" [width]="120">
            <div class="d-flex align-items-center justify-content-between">
              <!-- <input placeholder="Size" disabled /> -->
              <input
                placeholder="ระบุชื่อ"
                [value]="head_name.value"
                (input)="head_name.setValue($any($event.target).value)"
                class="input-size-name"
                [class.border-danger]="head_name.touched && head_name.invalid" />
            </div>
          </th>

          @for (c of size_ys.controls; track c) {
          <th class="th" [width]="120">
            <div class="d-flex align-items-center justify-content-between">
              <input
                placeholder="ระบุชื่อ"
                [value]="c.controls.name.value"
                (input)="c.controls.name.setValue($any($event.target).value)"
                class="input-size-name"
                [class.border-danger]="c.controls.name.touched && c.controls.name.invalid" />
              <button class="btn btn-transparent-outline" (click)="deleteColumn($index)">
                <span class="icon color--neutral-500 material-symbols-outlined fill"> close </span>
              </button>
            </div>
          </th>
          }
        </tr>
      </thead>
      <tbody>
        @for (f of size_xs.controls; track f) {
        <tr>
          <td class="th">
            <div class="d-flex align-items-center justify-content-between">
              <input
                placeholder="ระบุชื่อ"
                [value]="f.controls.name.value"
                (input)="f.controls.name.setValue($any($event.target).value)"
                class="input-size-name"
                [class.border-danger]="f.controls.name.touched && f.controls.name.invalid" />
              <button class="btn btn-transparent-outline" (click)="deleteRow($index)">
                <span class="icon color--neutral-500 material-symbols-outlined fill"> close </span>
              </button>
            </div>
          </td>
          @for (s of f.controls.size_values.controls; track s) {
          <td class="td">
            <div class="d-flex align-items-center justify-content-between">
              <app-input-number
                [control]="s.controls.value"
                placeholder=""
                [inputDefault]="true"
                inputDefaultClass="input-size {{ s.controls.value.touched && s.controls.value.invalid ? 'border-danger' : '' }}"></app-input-number>
            </div>
          </td>
          }
        </tr>
        }
      </tbody>
    </table>

    <button class="btn-add-row btn btn-transparent-outline" (click)="addRow()">
      <span class="icon color--neutral-500 material-symbols-outlined fill"> add_circle </span>
    </button>
  </div>
</div>
