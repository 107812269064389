<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">ดูตัวอย่าง</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">
    <div class="page-top-content">
      <div class="box-content">
        <app-top-menu theme="black" [lang]="data.lang"></app-top-menu>
      </div>
    </div>

    <div class="my-tier-wrapper mt-6">
      <div class="label-section mb-5">{{ data.lang == 'th' ? 'ระดับของฉัน' : 'My Tier'}}</div>

      <div
        class="d-flex flex-column align-items-center gap-3 md:px-4 lg:px-0 mb-5 position-relative box-loading">
        <b class="text-2xl-bold">Member</b>
        <div
          class="d-flex justify-content-center align-items-center flex-wrap row-gap-2 column-gap-3">
          <div class="text-xl-regular">{{ data.lang == 'th' ? 'ยอดคำสั่งซื้อทั้งหมด' : 'Total Amount of Order'}}</div>
          <div class="d-flex align-items-center gap-3">
            <div class="text-2xl-bold color--Primary-Ruby">10,000.00</div>
            <div class="text-lg-regular">THB</div>
          </div>
        </div>

        <div class="tier-card mt-2">
          <div class="flex-1 py-3 mt-2">
            <div class="slider-progress">
              <div class="line-progress" [style]="{ width: 20 + '%' }">
                <div class="dot-progress">
                  <div class="tooltip-progess">10,000.00</div>

                  <img src="/assets/icons/icon-slider-dot.svg" width="12" height="12" />
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between gap-2">
            <b class="text-sm-bold">Member</b>
            <b class="text-sm-bold">Sliver</b>
          </div>
          <div class="d-flex justify-content-between gap-2 mt-1">
            <div class="text-sm-regular">0</div>
            <div class="text-sm-regular">250,000</div>
          </div>
        </div>

        <div class="text-base-regular pt-2">
          {{ data.lang == 'th' ? 'สะสมยอดคำสั่งซื้อเพิ่มอีก' : 'Accumulate an additional'}}
          <b class="text-base-bold"> 240,000 THB </b>
          {{ data.lang == 'th' ? 'เพื่อปลดล็อกสิทธิประโยชน์พิเศษมากมายจากระดับ Silver' : 'in orders to unlock numerous exclusive benefits from Silver'}}
          
        </div>

        <div class="mt-4">
          <div
            [innerHtml]="
          sanitizer.bypassSecurityTrustHtml(data.formValue['detail_' + data.lang] || '')
          "></div>
        </div>

        <div class="text-base-regular text-underline-offset-2 cursor-pointer mt-4">
          {{ data.lang == 'th' ? 'ดูระดับสมาชิกเพิ่มเติม' : 'See Other tiers'}}
        </div>
      </div>
    </div>

    <app-footer [lang]="data.lang"></app-footer>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-primary btn-md" matRipple (click)="onClose()">กลับ</button>
  </div>
</mat-dialog-actions>
