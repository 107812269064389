<div class="page-content pb-0">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">แคมเปญ</div>
  </div>

  <div class="title-wrapper">แคมเปญ</div>

  <div class="page-content-inner d-flex flex-column pb-0">
    <div class="section-container">
      @if (dataManage) {
      <div class="form-label color--neutral-500">
        วันที่อัปเดต: {{ Moment(dataManage?.updated_at).format('DD-MM-YYYY, HH:mm') }}
        <br class="d-flex d-sm-none mt-1 mt-sm-0" />
        อัปเดตโดย: {{ dataManage?.updated_by?.full_name || '-' }}
      </div>
      <div class="mb-3">
        <app-status [status]="dataManage?.is_public ? 'public' : 'not_public'">
          {{dataManage?.is_public ? 'เผยแพร่แล้ว' : 'ยังไม่เผยแพร่'}}
        </app-status>
      </div>
      }

      <div class="section-wrapper">
        <div class="title-blue mb-3">ภาพแบนเนอร์หลัก</div>

        <div class="row">
          <div class="col-12 mb-4">
            <label class="form-label"
              >ภาพแบนเนอร์ @if (isEdit) { <span class="color--red-600">*</span>}
            </label>

            <div class="section-upload-container">
              @if (isEdit) {
              <div class="remark mt-0 mb-2 my-sm-2">
                (ขนาดรูปภาพ : 1440px x 800px / ไม่เกิน 5 Mb) | (ขนาดวีดิโอ : 1920px x 1080px /
                ไม่เกิน 30 Mb)
              </div>
              }
              <div class="upload-file-wrapper">
                <app-upload-file-flexible
                  [type]="isEdit ? 'edit' : 'view'"
                  [maxImageSize]="5"
                  [maxVideoSize]="30"
                  [reset]="reset"
                  [isRequired]="form.controls['file_main_banner_id'].touched && form.controls['file_main_banner_id'].invalid"
                  [logoDefault]="form.controls['file_main_banner_default'].value"
                  (upload)="form.controls['file_main_banner_id'].setValue($event)" />
              </div>

              <label class="form-label mt-2" [ngClass]="{ 'form-label-view': isView }"
                >เพิ่มลิงก์</label
              >
              @if (isEdit) {
              <app-select-add-link
                [trigger_name]="form.value.product_name || form.value.product_category_name || form.value.link_path"
                [controlLinkType]="form.controls['cms_link_list_id']"
                [product_id]="form.controls['product_id'].value"
                [product_detail_id]="form.controls['product_detail_id'].value"
                [product_category_id]="form.controls['product_category_id'].value"
                [link_path]="form.controls['link_path'].value"
                (onProductId)="form.controls['product_id'].setValue($event)"
                (onProductDetailId)="form.controls['product_detail_id'].setValue($event)"
                (onCategoryId)="form.controls['product_category_id'].setValue($event)"
                (onLinkPath)="form.controls['link_path'].setValue($event)" />
              } @else {
              <div>
                {{ linkTextView(form.controls['cms_link_list_id'].value, form.value.product_name,
                form.value.product_category_name, form.value.link_path) || '-' }}
              </div>
              }
            </div>
          </div>

          <div class="col-12 mb-4">
            <div class="title-blue mb-3">ภาพแบนเนอร์ย่อย</div>

            <label class="form-label">ภาพแบนเนอร์</label>

            <div class="section-upload-container">
              @if (isEdit) {
              <div class="remark mt-0 mb-2 my-sm-2">
                (ขนาดรูปภาพ : 1440px x 220px / ไม่เกิน 5 Mb)
              </div>
              }
              <div class="upload-image-wrapper mb-4">
                <app-upload-file-flexible
                  [imgOnly]="true"
                  [type]="isEdit ? 'edit' : 'view'"
                  [maxImageSize]="5"
                  [maxVideoSize]="30"
                  [reset]="reset"
                  [isRequired]="form.controls['file_sub_banner_one_id'].touched && form.controls['file_sub_banner_one_id'].invalid"
                  [logoDefault]="form.controls['file_sub_banner_one_default'].value"
                  (upload)="form.controls['file_sub_banner_one_id'].setValue($event)" />
              </div>
            </div>
          </div>

          <div class="col-12 mb-4">
            <app-section-product
              [isEdit]="isEdit"
              [isView]="isView"
              [reset]="reset"
              [form]="form"
              [sectionIndex]="0"
              [showButtonDelete]="false" />
          </div>
        </div>
      </div>
    </div>

    <div class="page-footer-btn">
      @if (isView) { @if (profile.permissions.checkAccess([APP_PERMISSION.CMS],
      [ACTION_ACCESS.UPDATE])) {
      <div class="d-flex flex-wrap justify-content-end">
        <button
          matRipple
          class="btn btn-outline-secondary min-w-165px"
          [routerLink]="['/setting/cms/campaign/edit']">
          แก้ไข
        </button>
      </div>
      } } @else if(isEdit) {
      <div class="footer-btn d-flex flex-wrap justify-content-between">
        <div class="footer-btn-left d-flex flex-wrap">
          <button matRipple class="btn btn-outline-danger min-w-165px" (click)="onReset()">
            ล้างข้อมูล
          </button>
          <button matRipple class="btn btn-outline-secondary min-w-165px" (click)="onCancel()">
            ยกเลิก
          </button>
        </div>

        <div class="footer-btn-right d-flex flex-wrap">
          <button matRipple class="btn btn-outline-secondary min-w-165px" (click)="openPreview()">
            ดูตัวอย่าง
          </button>
          @if (profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.UPDATE]) ||
          profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.CREATE])) {
          <button matRipple (click)="onSave(false)" class="btn btn-outline-secondary min-w-165px">
            บันทึกร่าง
          </button>
          <button matRipple (click)="onSave(true)" class="btn btn-primary min-w-165px">
            เผยแพร่ทันที
          </button>
          }
        </div>
      </div>
      }
    </div>
  </div>
</div>
