import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  productImage,
  formatPrice,
  Ascending,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { MatTabsModule } from '@angular/material/tabs'
import { RadioLayoutComponent } from '../../../components/radio-layout/radio-layout'
import { UploadFileFlexibleComponent } from '../../../components/upload-file-flexible/upload-file-flexible'
import { SelectComponent } from '../../../components/select/select'
import { ModalSelectProductSingleComponent } from '../../../components/modal-select-product-single/modal-select-product-single'
import { SelectAddLinkComponent } from '../../../components/select-add-link/select-add-link'
import { ModalDeleteComponent } from '../../../components/modal-delete/modal-delete'
import { ModalSelectProductMultipleComponent } from '../../../components/modal-select-product-multiple/modal-select-product-multiple'
import { StatusComponent } from '../../../components/status/status'
import { CMSMenuService } from '../../../services/cms-menu.service'
import { LINK_LIST_ID, linkTextView } from '../../../helpers/link'
import { CMSProductListService } from '../../../services/cms-product-list.service'
import { ModalPreviewProductListComponent } from './modal-preview/modal-preview'
@Component({
  selector: 'app-cms-product-list',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    MatTabsModule,
    RadioLayoutComponent,
    UploadFileFlexibleComponent,
    SelectComponent,
    CdkDrag,
    CdkDropList,
    ModalSelectProductSingleComponent,
    SelectAddLinkComponent,
    StatusComponent,
  ],
  templateUrl: './cms-product-list.html',
  styleUrls: ['./cms-product-list.scss'],
})
export class CMSProductListComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly linkTextView = linkTextView
  readonly formatPrice = formatPrice
  readonly Moment = Moment

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false

  tab = 0

  content = new FormGroup({
    data: new FormControl<any>(null),
    layout_type: new FormControl('left'),
    file_id: new FormControl(),
    file_url: new FormControl(),
    cms_link_list_id: new FormControl(),
    product_id: new FormControl(),
    product_detail_id: new FormControl(),
    product_category_id: new FormControl(),
    link_path: new FormControl(),
    product_name: new FormControl(),
    product_category_name: new FormControl(),
    cms_pania_all_product_lists: new FormControl<any[]>([]),
  })
  category = new FormGroup({
    data: new FormControl(),
    category_id: new FormControl(null),
    category_name: new FormControl(''),
    contents: new FormArray<typeof this.content>([]),
  })
  tabList = new FormArray<typeof this.category>([])

  get productIds() {
    return (this.content.value.cms_pania_all_product_lists || []).reduce(
      (productIds: any[], item: any) => {
        if (!productIds.includes(item.product_id)) {
          productIds.push(item.product_id)
        }

        return productIds
      },
      []
    )
  }

  constructor(
    public profile: Profile,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public route: ActivatedRoute,
    public router: Router,
    public cmsMenuService: CMSMenuService,
    public cmsProductListService: CMSProductListService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      if (this.router.url.includes('view')) {
        this.isView = true
      }
      if (this.router.url.includes('edit')) {
        this.isEdit = true
      }
      this.initData(params.tab)
    })
  }

  initData(tab = 0) {
    const apis = [
      this.cmsMenuService.getCategoryList(),
      this.cmsProductListService.getProductList(),
    ]

    this.loading.start()
    forkJoin(apis).subscribe(([resCategoryList, resProductList]: any) => {
      if (!resCategoryList.is_error) {
        this.tabList.clear()
        for (const category of resCategoryList.data || []) {
          const contents = new FormArray<typeof this.content>([])

          const productCategory = resProductList.data.find((d: any) => d.category_id == category.id)
          if (productCategory) {
            for (const content of productCategory.contents) {
              content.cms_pania_all_product_lists = content.cms_pania_all_product_lists.reduce(
                (produtcs: any[], item: any) => {
                  produtcs.push({
                    ...item,
                    ...(item.product_detail_id ? item.product_detail : item.product),
                    image: productImage(item.product, item.product_detail),
                  })

                  return produtcs
                },
                []
              )
              this.addContent(contents, content)
            }
          } else {
            this.addContent(contents)
            this.addContent(contents)
          }

          this.tabList.push(
            new FormGroup({
              data: new FormControl(category),
              category_id: new FormControl(category.id, [Validators.required]),
              category_name: new FormControl(category.name_th),
              contents,
            })
          )

          setTimeout(() => {
            if (tab) {
              this.tab = tab
            }
          })
        }
      } else {
        this.customSnackBar.fail()
      }

      this.loading.stop()
    })
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
  }

  dropTable(event: CdkDragDrop<any[]>, lits: any) {
    moveItemInArray(lits, event.previousIndex, event.currentIndex)
  }

  addContent(contents: FormArray<typeof this.content>, value: any = {}) {
    contents.push(
      new FormGroup({
        data: new FormControl(value),
        layout_type: new FormControl(
          value.layout_type == 3 ? 'one' : value.layout_type == 2 ? 'right' : 'left'
        ),
        file_id: new FormControl(value.file_id, [Validators.required]),
        file_url: new FormControl(value.file?.url),
        cms_link_list_id: new FormControl(value.cms_link_list_id),
        product_id: new FormControl(value.product_id),
        product_detail_id: new FormControl(value.product_detail_id),
        product_category_id: new FormControl(value.product_category_id),
        link_path: new FormControl(value.link_path),
        product_name: new FormControl(value.product?.name),
        product_category_name: new FormControl(value?.product_category?.name),
        cms_pania_all_product_lists: new FormControl<any[]>(
          Ascending(value.cms_pania_all_product_lists || [], 'seq'),
          [
            (control: AbstractControl) => {
              if (this.productCount(control) < 4) {
                return {
                  minLength: true,
                }
              }

              return null
            },
          ]
        ),
      })
    )
  }

  productCount(productControl: FormControl | AbstractControl) {
    return (productControl.value || []).reduce((products: any[], value: any) => {
      if (!products.includes(value.product_id)) {
        products.push(value.product_id)
      }
      return products
    }, []).length
  }

  deleteContent(contents: FormArray, index: number) {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบคอนเทนต์นี้ใช่หรือไม่`,
      },
    })
    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        contents.removeAt(index)
      }
    })
    return dialogRefDelete
  }

  deleteProduct(cms_pania_all_product_lists: FormControl, index: number) {
    const values = cms_pania_all_product_lists.value || []
    values.splice(index, 1)
    cms_pania_all_product_lists.reset(values)
  }

  addProduct(cms_pania_all_product_lists: FormControl) {
    const dialogRefProduct = this.dialog.open(ModalSelectProductMultipleComponent, {
      data: {
        showStock: true,
        max: 4,
        product_list: cms_pania_all_product_lists.value || [],
      },
    })
    dialogRefProduct.afterClosed().subscribe(result => {
      if (result) {
        console.log('add product', result)
        cms_pania_all_product_lists.reset(result)
      }
    })
    return dialogRefProduct
  }

  onReset() {
    const f = this.tabList.controls[this.tab]
    if (f) {
      // f.reset()
      f.controls.contents.clear()
      this.addContent(f.controls.contents)
      this.addContent(f.controls.contents)
    }
    // this.initData()
  }

  onCancel() {
    const dialogRefConfirm = this.dialog.open(ModalLeaveComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/setting/cms/product-list/view'])
      }
    })
  }

  openPreview() {
    let payload = this.getPayload(false)
    if (!payload) return

    this.dialog.open(ModalPreviewProductListComponent, {
      width: '1200px',
      data: {
        formValue: this.tabList.controls[this.tab].getRawValue(),
      },
    })
  }

  onEdit() {
    this.router.navigate(['/setting/cms/product-list/edit'], {
      queryParams: {
        tab: this.tab,
      },
    })
  }

  getPayload(is_public: boolean) {
    let nvalid = false

    let payload = this.tabList.controls.map((formTab, i) => {
      let formValue = formTab.getRawValue()
      let tabValue: any = formValue.data

      if (this.tab == i) {
        ValidateArrayForm(formTab.controls.contents)
        if (formTab.controls.contents.invalid) {
          nvalid = true
        }

        tabValue = formValue
      }

      if (tabValue?.contents) {
        return {
          category_id: tabValue.category_id,
          contents: tabValue.contents.map((c: any, i: number) => {
            return {
              id: c.data?.id || null,
              seq: i + 1,
              layout_type: c.layout_type == 'one' ? 3 : c.layout_type == 'right' ? 2 : 1,
              is_public,
              file_id: c.file_id,
              cms_link_list_id: c.cms_link_list_id ?? null,
              product_id: c.product_id || null,
              product_detail_id: c.product_detail_id || null,
              product_category_id: c.product_category_id || null,
              link_path: c.link_path || null,
              product_name: c.product_name || null,
              product_category_name: c.product_category_name || null,
              is_page_campaign: c.cms_link_list_id == LINK_LIST_ID.CAMPAIGN,
              is_page_collection: c.cms_link_list_id == LINK_LIST_ID.COLLECTION,
              cms_pania_all_product_lists: (c.cms_pania_all_product_lists || []).map(
                (p: any, ci: number) => {
                  return {
                    seq: ci + 1,
                    product_id: p.product_id,
                    product_detail_id: p.product_detail_id || null,
                  }
                }
              ),
            }
          }),
        }
      } else {
        return null
      }
    })

    payload = payload.filter(d => d)

    if (nvalid) return null
    return payload
  }

  onSave(is_public: boolean) {
    let payload = this.getPayload(is_public)
    if (!payload) return

    // console.log(payload)
    // return

    this.loading.start()
    this.cmsProductListService.updateProductList(payload).subscribe(res => {
      if (!res.is_error) {
        this.customSnackBar.success(is_public ? 'เผยแพร่สำเร็จ' : 'บันทึกร่างสำเร็จ')
        this.router.navigate(['/setting/cms/product-list/view'])
      } else {
        this.customSnackBar.failSave(is_public ? 'เผยแพร่ไม่สำเร็จ' : 'บันทึกร่างไม่สำเร็จ')
      }
      this.loading.stop()
    })
  }
}
