<div class="top-content-wrapper align-items-center flex-wrap mb-3">
  <div class="top-content-left">
    <div class="title-blue">สินค้าลดราคา ({{ paginationProduct.data.length }})</div>
  </div>
  <div class="top-content-right">
    <app-select-sort
      [control]="sortControl"
      [datas]="list.sort"
      fieldValue="value"
      placeholder="เลือก"
      (selectionChange)="onSort()">
      <ng-template #triggerRef let-option> {{ option.text }}</ng-template>
      <ng-template #optionRef let-option> {{ option.text }}</ng-template>
    </app-select-sort>
  </div>
</div>

<div class="table-responsive table-main-tabs-wrapper">
  <table class="table table-fixed-column">
    <thead>
      <tr>
        <th width="70">
          <app-checkbox-all-table
            [checkedValue]="itemChecked"
            [datas]="productList"
            fieldValue="id"
            (onChange)="setItemCheckedData($event, productList)"></app-checkbox-all-table>
        </th>
        <th width="310">ชื่อแคมเปญ</th>
        <th width="200">วันและเวลาเริ่มต้น</th>
        <th width="200">วันและเวลาสิ้นสุด</th>
        <th width="200">จำนวนสินค้า</th>
        <th width="150">อัปเดตโดย</th>
        <th width="120" class="fixed box-shadow-left text-center">จัดการ</th>
      </tr>
    </thead>

    <tbody>
      @for (item of productList; track $index) {
      <tr>
        <td>
          <app-checkbox-table
            [checkedValue]="itemChecked"
            [value]="item.id"
            (onChange)="setItemCheckedData($event, [item])"></app-checkbox-table>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td class="fixed box-shadow-left">
          <div class="action-button-wrapper justify-content-center">
            <!-- @if (item.action.view) {
            <button
              matRipple
              matTooltip="ดู"
              matTooltipPosition="above"
              class="btn btn-outline-secondary btn-icon"
              [routerLink]="['/supplier/view/' + item.id]">
              <span class="icon material-symbols-outlined fill">visibility</span>
            </button>
            } @if (item.action.edit) {
            <button
              matRipple
              matTooltip="แก้ไข"
              matTooltipPosition="above"
              class="btn btn-outline-secondary btn-icon"
              [routerLink]="['/supplier/edit/' + item.id]">
              <span class="icon material-symbols-outlined fill">edit</span>
            </button>
            } -->
          </div>
        </td>
      </tr>
      }
    </tbody>
  </table>

  @if(!productList.length) {
  <div class="data-not-found-wrapper">ไม่พบสินค้า กรุณาเพิ่มสินค้า</div>
  }

  <!-- Pagination -->
  @if (productList.length) {
  <div class="pagination-wrapper">
    <mat-paginator
      [pageIndex]="paginationProduct.data.pageIndex"
      [length]="paginationProduct.data.length"
      [pageSize]="paginationProduct.data.pageSize"
      [pageSizeOptions]="paginationProduct.data.pageSizeOptions"
      aria-label="Select page"
      (page)="paginationProduct.setFromPaginator($event); getDiscountProductList()">
    </mat-paginator>
  </div>
  }
</div>
