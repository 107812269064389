<div class="page-main-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link color--brand-blue--f" (click)="toList()">
      สินค้า
    </div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">สินค้า Flash Sales</div>
    <div class="breadcrumb-separator">/</div>
    @if(isCreate) {
    <div class="breadcrumb-text">เพิ่มสินค้า Flash Sales</div>
    } @else if(isView) {
    <div class="breadcrumb-text">รายละเอียดสินค้า Flash Sales</div>
    } @else if(isEdit) {
    <div class="breadcrumb-text">แก้ไขสินค้า Flash Sales</div>
    }
  </div>

  @if(isView) {
  <div class="title-wrapper">รายละเอียดสินค้า Flash Sales</div>
  } @else {
  <div class="title-wrapper">
    {{ isCreate ? 'เพิ่มสินค้า Flash Sales' : 'แก้ไขสินค้า Flash Sales' }}
  </div>
  }

  <div class="page-content-inner">
    <div class="title-blue">รายละเอียดแคมเปญ</div>
    <div class="row mt-3">
      <div class="col-12 col-md-12">
        <label class="form-label" [class.color--neutral-500]="isView">
          ชื่อแคมเปญ @if(!isView) {<span class="text-danger">*</span>}
        </label>

        @if(!isView) {
        <app-input [control]="form.controls['name']">
          @if (form.controls['name'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          }
        </app-input>
        } @else {
        <div>{{ dataManage?.name }}</div>
        }
      </div>

      <div class="col-12 col-md-3 mt-3">
        <label class="form-label" [class.color--neutral-500]="isView">
          วันที่เริ่มต้น @if(!isView) {<span class="text-danger">*</span>}
        </label>
        @if(!isView) {
        <app-date-picker
          [control]="form.controls['date_start']"
          placeholder="วว/ดด/ปปปป"
          [max]="form.controls['date_end'].value">
          @if (form.controls['date_start'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          }
        </app-date-picker>
        } @else {
        <div>{{ Moment(dataManage?.start_datetime).format('DD/MM/YYYY') }}</div>
        }
      </div>

      <div class="col-12 col-md-3 mt-3">
        <label class="form-label" [class.color--neutral-500]="isView">
          เวลาเริ่มต้น @if(!isView) {<span class="text-danger">*</span>}
        </label>

        @if(!isView) {
        <app-time-picker [control]="form.controls['time_start']">
          @if (form.controls['time_start'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          }
        </app-time-picker>
        } @else {
        <div>{{ Moment(dataManage?.start_datetime).format('HH:mm') }}</div>
        }
      </div>

      <div class="col-12 col-md-3 mt-3">
        <label class="form-label" [class.color--neutral-500]="isView">
          วันที่สิ้นสุด @if(!isView) {<span class="text-danger">*</span>}
        </label>

        @if(!isView) {
        <app-date-picker
          [control]="form.controls['date_end']"
          placeholder="วว/ดด/ปปปป"
          [min]="form.controls['date_start'].value"
          (onChange)="form.controls['time_end'].setErrors({error_end : false}); form.controls['time_end'].updateValueAndValidity();">
          @if (form.controls['date_end'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @if (form.controls['date_end'].hasError('error_end')) {
          <ng-container error>วันเวลาที่สิ้นสุดต้องมากกว่าวันเวลาที่เริ่มต้น</ng-container>
          }
        </app-date-picker>

        } @else {
        <div>{{ Moment(dataManage?.end_datetime).format('DD/MM/YYYY') }}</div>
        }
      </div>

      <div class="col-12 col-md-3 mt-3">
        <label class="form-label" [class.color--neutral-500]="isView">
          เวลาสิ้นสุด @if(!isView) {<span class="text-danger">*</span>}
        </label>
        @if(!isView) {
        <app-time-picker
          [control]="form.controls['time_end']"
          (onChange)="form.controls['date_end'].setErrors({error_end : false}); form.controls['date_end'].updateValueAndValidity();">
          @if (form.controls['time_end'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @if (form.controls['time_end'].hasError('error_end')) {
          <ng-container error>วันเวลาที่สิ้นสุดต้องมากกว่าวันเวลาที่เริ่มต้น</ng-container>
          }
        </app-time-picker>

        } @else {
        <div>{{ Moment(dataManage?.end_datetime).format('HH:mm') }}</div>
        }
      </div>
    </div>

    <div class="title-blue mt-3">ภาพแสดงหน้าเว็บ</div>
    <label class="form-label mt-3" [class.color--neutral-500]="isView">
      ภาพแบนเนอร์ @if(!isView) {<span class="text-danger">*</span>}
    </label>
    @if (!isView) {
    <div class="text-center font-14 color--neutral-500 mb-1">
      (ขนาดรูปภาพ : 1440px x 480px / ไม่เกิน 5 Mb) | (ขนาดวีดิโอ : 1920px x 1080px / ไม่เกิน 30 Mb)
    </div>
    }
    <div class="upload-file-wrapper">
      <app-upload-file-flexible
        [type]="!isView ? 'edit' : 'view'"
        [maxImageSize]="5"
        [maxVideoSize]="30"
        [isRequired]="form.controls['file_id'].touched && form.controls['file_id'].invalid"
        [logoDefault]="dataManage?.file?.url"
        (upload)="form.controls['file_id'].setValue($event)" />
    </div>

    <div class="d-flex justify-content-between align-items-center mt-4 mb-2">
      <div class="title-blue">
        รายการสินค้าแสดงหน้าหลัก ({{product_main.value.length}}/4) 
        @if (!isView) {
        <span class="text-danger">*</span>
        }
      </div>
      <div>
        @if (!isView && product_main.value.length) {
        <button
          class="btn btn-primary w-fit"
          (click)="openProductMain()"
          [disabled]="product_main.value.length == 4">
          <span class="icon material-symbols-outlined fill"> add_circle </span>
          เลือกสินค้า
        </button>
        }
      </div>
    </div>
    <div class="table-responsive-detail mt-3">
      <table class="table table-detail table-fixed-column">
        <thead>
          <tr>
            @if (!isView) {
            <th width="50"></th>
            }
            <th width="90">สินค้า</th>
            <th width="240"></th>
            <th width="130" class="text-end">ราคาปกติ (บาท)</th>
            <th width="140" class="text-end">ราคาสมาชิก (บาท)</th>
            <th width="160">เปอร์เซ็นต์ที่ลด (%)</th>
            <th width="180" class="text-end">ราคาส่วนลด (บาท)</th>
            <th width="180" class="text-end">ราคาปกติที่ลดแล้ว (บาท)</th>
            @if (!isView) {
            <th width="100" class="fixed box-shadow-left"></th>
            }
          </tr>
        </thead>
        <tbody
          id="all"
          cdkDropList
          (cdkDropListDropped)="dropTable($event)"
          class="drag-list mt-3 w-100"
          cdkDropListGroup>
          @for (item of product_main.controls; track item) {
          <tr class="mb-2" cdkDrag cdkDragHandle>
            @if (!isView) {
            <td width="50">
              <div class="pre-box">
                <button class="btn btn-transparent-outline me-1">
                  <span class="icon icon-dark-grey material-symbols-outlined fill">
                    drag_indicator
                  </span>
                </button>
              </div>
            </td>
            }
            <td width="90">
              <img
                class="img-table"
                src="{{ item.value?.file?.url || '/assets/images/icons/img-default-table.svg' }}" />
            </td>
            <td width="240">{{ item.controls['name'].value }}</td>

            <td width="130" class="text-end">
              {{ formatPrice(item.controls['selling_price'].value) }}
            </td>
            <td width="140" class="text-end">
              {{ formatPrice(item.controls['member_price'].value) }}
            </td>
            <td width="160" class="text-center">
              @if (!isView) {
              <app-input-number
                unit="%"
                placeholder="0"
                [min]="0"
                [control]="item.controls['discount_campaign_percent']"
                [disabled]="service.companyId == COMPANY_ID.BHB"
                (input)="changePercent(item, $event)">
                @if (item.controls['discount_campaign_percent'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (item.controls['discount_campaign_percent'].hasError('max')) {
                <ng-container error>กรอกจำนวนไม่เกิน 99%</ng-container>
                }
              </app-input-number>
              } @else { {{ item.value.discount_campaign_percent || 0 }} }
            </td>
            <td width="180" class="text-end">
              @if (!isView) {
              <app-input-number
                unit="บาท"
                placeholder="0.00"
                [min]="0"
                [control]="item.controls['discount_campaign_price']"
                [disabled]="service.companyId == COMPANY_ID.PANIA"
                (input)="changeDiscount(item, $event)">
              </app-input-number>
              } @else { {{ formatPrice(item.value.discount_campaign_price) }} }
            </td>
            <td width="180" class="text-end">
              <div class="text-danger">{{ formatPrice(item.value.discount_normal_price) }}</div>
            </td>
            @if (!isView) {
            <td width="100" class="fixed box-shadow-left">
              <div class="w-100 d-flex justify-content-center">
                <button
                  matRipple
                  class="btn btn-outline-danger"
                  (click)="product_main.removeAt($index)">
                  <span class="icon material-symbols-outlined fill">delete</span>
                </button>
              </div>
            </td>
            }
          </tr>
          }
        </tbody>
      </table>

      @if(!product_main.value.length) {
      <div class="data-not-found-wrapper flex-column align-items-center">
        <div>ไม่พบสินค้า กรุณาเลือกสินค้า</div>
        @if (!isView) {
        <button class="btn btn-primary me-1 mt-4 w-fit" (click)="openProductMain()">
          <span class="icon material-symbols-outlined fill"> add_circle </span>
          เลือกสินค้า
        </button>
        }
      </div>
      } @if (form.controls['product_main'].hasError('minLength')) {
      <div class="mt-1 font-14 text-danger">กรุณาเพิ่มสินค้าให้ครบ 4 รายการ</div>
      }
    </div>

    <div class="title-blue mt-4">
      รายการสินค้าในแคมเปญ @if (!isView) {
      <span class="text-danger">*</span>
      }
    </div>
    @if (!isView) {
    <div class="box d-flex gap-3 flex-wrap align-items-center justify-content-between">
      <div class="d-flex gap-3 flex-wrap align-items-center">
        <div><b>แก้ไขข้อมูลเป็นข้อชุด</b></div>
        <div c>
          <label class="label-view">เปอร์เซ็นต์ที่ลด (%)</label>
          <app-input-number [control]="percent_all">
            @if (percent_all.hasError('max')) {
            <ng-container error>กรอกจำนวนไม่เกิน 99%</ng-container>
            } @else if (percent_all.hasError('min')) {
            <ng-container error>กรอกจำนวนมากกว่าหรือเท่ากับ 1%</ng-container>
            }
          </app-input-number>
        </div>
        <button class="btn btn-outline-secondary w-fit mt-2" (click)="changePercentAll()">
          อัปเดตทั้งหมด
        </button>
      </div>
      <div>
        <button
          class="btn btn-outline-danger btn-md"
          matRipple
          (click)="deleteProduct()"
          [disabled]="!itemCheckedData.size">
          <span class="icon material-symbols-outlined fill">delete</span>
          ลบข้อมูล @if(itemCheckedData.size) { ({{ itemCheckedData.size }}) }
        </button>
      </div>
    </div>
    }

    <div class="table-responsive-detail mt-3">
      <table class="table table-detail table-fixed-column">
        <thead>
          <tr>
            @if (!isView) {
            <th width="50">
              <app-checkbox-all-table
                [checkedValue]="itemChecked"
                [datas]="product_list.value"
                fieldValue="id"
                (onChange)="setItemCheckedData($event, product_list.value)"></app-checkbox-all-table>
            </th>
            }
            <th width="90">สินค้า</th>
            <th width="240"></th>
            <th width="130" class="text-end">ราคาปกติ (บาท)</th>
            <th width="140" class="text-end">ราคาสมาชิก (บาท)</th>
            <th width="160" class="text-center">เปอร์เซ็นต์ที่ลด (%)</th>
            <th width="180" class="text-end">ราคาส่วนลด (บาท)</th>
            <th width="180" class="text-end">ราคาปกติที่ลดแล้ว (บาท)</th>
            @if (!isView) {
            <th width="100" class="fixed box-shadow-left"></th>
            }
          </tr>
        </thead>
        <tbody id="all" class="mt-3 w-100">
          @for (item of pageList; track item) {
          <tr class="mb-2">
            @if (!isView) {
            <td width="50">
              <app-checkbox-table
                [checkedValue]="itemChecked"
                [value]="item.value['id']"
                (onChange)="setItemCheckedData($event, [item])"></app-checkbox-table>
            </td>
            }
            <td width="90">
              <img
                class="img-table"
                src="{{ item.value?.file?.url || '/assets/images/icons/img-default-table.svg' }}" />
            </td>
            <td width="240">{{ item.controls['name'].value }}</td>

            <td width="130" class="text-end">
              {{ formatPrice(item.controls['selling_price'].value) }}
            </td>
            <td width="140" class="text-end">
              {{ formatPrice(item.controls['member_price'].value) }}
            </td>
            <td width="160" class="text-center">
              @if (!isView) {
              <app-input-number
                unit="%"
                placeholder="0"
                [min]="0"
                [control]="item.controls['discount_campaign_percent']"
                [disabled]="service.companyId == COMPANY_ID.BHB"
                (input)="changePercent(item, $event)">
                @if (item.controls['discount_campaign_percent'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (item.controls['discount_campaign_percent'].hasError('max')) {
                <ng-container error>กรอกจำนวนไม่เกิน 99%</ng-container>
                }
              </app-input-number>
              } @else { {{ item.value.discount_campaign_percent }} }
            </td>
            <td width="180" class="text-end">
              @if (!isView) {
              <app-input-number
                unit="บาท"
                placeholder="0.00"
                [min]="0"
                [control]="item.controls['discount_campaign_price']"
                [disabled]="service.companyId == COMPANY_ID.PANIA"
                (input)="changeDiscount(item, $event)">
              </app-input-number>
              } @else { {{ formatPrice(item.value.discount_campaign_price) }} }
            </td>
            <td width="180" class="text-end">
              <div class="text-danger">{{ formatPrice(item.value.discount_normal_price) }}</div>
            </td>
            @if (!isView) {
            <td width="100" class="fixed box-shadow-left">
              <div class="w-100 d-flex justify-content-center">
                <button matRipple class="btn btn-outline-danger" (click)="deleteProduct($index)">
                  <span class="icon material-symbols-outlined fill">delete</span>
                </button>
              </div>
            </td>
            }
          </tr>
          }
        </tbody>
      </table>

      @if(!product_list.value.length) {
      <div class="data-not-found-wrapper flex-column align-items-center">
        <div>ไม่พบสินค้า กรุณาเลือกสินค้า</div>
        @if (!isView) {
        <button class="btn btn-primary me-1 mt-4 w-fit" (click)="openProduct()">
          <span class="icon material-symbols-outlined fill"> add_circle </span>
          เลือกสินค้า
        </button>
        }
      </div>
      } @if (form.controls['products'].hasError('minLength')) {
      <div class="mt-1 font-14 text-danger">กรุณาเพิ่มสินค้าในแคมเปญ</div>
      }
    </div>

    @if (product_list.value.length) {
    <div class="pagination-wrapper d-flex flex-wrap gap-2">
      <mat-paginator
        [pageIndex]="paginationProduct.data.pageIndex"
        [length]="paginationProduct.data.length"
        [pageSize]="paginationProduct.data.pageSize"
        [pageSizeOptions]="paginationProduct.data.pageSizeOptions"
        (page)="paginationProduct.setFromPaginator($event);"
        aria-label="Select page">
      </mat-paginator>
    </div>
    } @if (!isView && product_list.value.length) {
    <button class="btn btn-primary w-fit mt-2" (click)="openProduct()">
      <span class="icon material-symbols-outlined fill"> add_circle </span>
      เลือกสินค้า
    </button>
    }

    <div class="footer-button-wrapper d-flex flex-wrap justify-content-end gap-2 mt-3">
      <button
        matRipple
        class="btn btn-outline-secondary min-w-135px"
        (click)="!isEdit ? toList() : onCancel()">
        ยกเลิก
      </button>
      @if(isView) {
      <button
        matRipple
        class="btn btn-primary min-w-135px"
        [routerLink]="'/product/flash-sales/edit/'+ dataManage?.id">
        แก้ไข
      </button>
      } @else {
      <button matRipple class="btn btn-primary min-w-135px" (click)="onSave()">บันทึก</button>
      }
    </div>
  </div>
</div>
