<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">เลือกสินค้า</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">
    <div class="row">
      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
        <app-input-search
          [control]="formSearch.controls['search_name']"
          placeholder="ค้นหา"
          (onEnter)="onSearch()" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <label class="form-label mt-2">หมวดหมู่</label>
        <app-select
          [control]="formSearch.controls['category']"
          [datas]="list.category"
          fieldValue="id"
          placeholder="เลือก"
          (selectionChange)="changeCatecory()">
          <ng-template #optionRef let-option> {{ option.name }} </ng-template>
        </app-select>
      </div>
      <div class="col-12 col-sm-6">
        <label class="form-label mt-2">หมวดหมู่ย่อย</label>
        <app-select
          [control]="formSearch.controls['sub_category']"
          [datas]="list.sub_category"
          fieldValue="id"
          placeholder="เลือก"
          (selectionChange)="changeSubCatecory()">
          <ng-template #optionRef let-option> {{ option.name }} </ng-template>
        </app-select>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-fixed-column">
        <thead>
          <tr>
            <th width="50"></th>
            <th width="90"></th>
            <th width="200">รหัสสินค้า</th>
            <th width="300">ชื่อสินค้า</th>
            <th width="150" class="text-end">ราคา (บาท)</th>
            <th width="100"></th>
          </tr>
        </thead>
        <tbody>
          @for (item of listProduct; track $index) {
          <tr>
            <td>
              <div class="radio-button-wrapper">
                <mat-radio-button
                  [value]="item.key"
                  (change)="changeProduct(item.key, item)"></mat-radio-button>
              </div>
            </td>
            <td>
              <img
                class="img-table"
                src="{{ item.image?.file?.url || '/assets/images/icons/img-default-table.svg' }}" />
            </td>
            <td>{{ item.code }}</td>
            <td>
              <div>
                <div>{{ item.name }}</div>
                <div class="product-detail">
                  <div
                    class="tag-color"
                    [ngStyle]="{'background-color': item.product_color?.color_code || '#000000'}"></div>
                  <div>{{item.color}} , {{item.size}}</div>
                </div>
              </div>
            </td>
            <td class="text-end">{{ formatPrice(item.selling_price) }}</td>
            <td></td>
          </tr>
          }
        </tbody>
      </table>

      @if(!listProduct.length) {
      <div class="data-not-found-wrapper flex-column align-items-center">
        <div>ไม่พบสินค้า</div>
      </div>
      }
    </div>
    <!-- % -->
    @if (listProduct.length) {
    <div class="pagination-wrapper d-flex flex-wrap gap-2">
      <mat-paginator
        [pageIndex]="pagination.data.pageIndex"
        [length]="pagination.data.length"
        [pageSize]="pagination.data.pageSize"
        [pageSizeOptions]="pagination.data.pageSizeOptions"
        (page)="pagination.setFromPaginator($event);getProductList()"
        aria-label="Select page">
      </mat-paginator>
      <div class="d-flex align-items-center gap-2 mat-mdc-paginator">
        <span>ไปที่หน้า</span>
        <div class="font-12" [style]="{ 'min-width': '70px', 'max-width': '70px' }">
          @if (!rerender) {
          <app-input-number
            [control]="pageNumber"
            placeholder="0"
            [min]="1"
            [max]="pagination.totalPage || 1"
            [remove_bottom]="true"
            (onEnter)="onChangePage()"
            (onblur)="onChangePage(true)">
          </app-input-number>
          }
        </div>
        / {{ formatPriceAutoDecimal(pagination.totalPage) }}
      </div>
    </div>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-outline-secondary btn-md" matRipple (click)="onClose()">ยกเลิก</button>
    <button class="btn btn-primary btn-md" matRipple (click)="onConfirm()">ยืนยัน</button>
  </div>
</mat-dialog-actions>
