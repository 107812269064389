import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class CMSFaqsService {
  constructor(private service: Service) {}
  private pathCMSFaqs = '/v1/cms/faq'

  getCmsFaqsQuery(payload: any): Observable<any> {
    return this.service.post(`${this.pathCMSFaqs}/query`, payload)
  }

  getCmsFaqsById(id: any): Observable<any> {
    return this.service.get(`${this.pathCMSFaqs}/${id}`)
  }

  createCmsFaqs(payload: any): Observable<any> {
    return this.service.post(`${this.pathCMSFaqs}`, payload)
  }

  updateCmsFaqs(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathCMSFaqs}/${id}`, payload)
  }

  deleteCmsFaqsById(id: any): Observable<any> {
    return this.service.delete(`${this.pathCMSFaqs}/${id}`)
  }

}
