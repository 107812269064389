import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { Loading } from '../../../globals'
import { imports } from '../../../imports'
import { MatPaginatorModule } from '@angular/material/paginator'
import { ProductService } from '../../../services'
import { UploadFileFlexibleComponent } from '../../../components/upload-file-flexible/upload-file-flexible'
import { FormControl, FormGroup } from '@angular/forms'
import { InputNumberComponent } from '../../../components/input-number/input-number'

@Component({
  selector: 'app-modal-model',
  templateUrl: './modal-model.html',
  styleUrls: ['./modal-model.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    MatPaginatorModule,
    UploadFileFlexibleComponent,
    InputNumberComponent,
  ],
})
export class ModalModelComponent {
  dataManage: any
  form = new FormGroup({
    file_model: new FormControl(),
    model_size: new FormControl(''),
    tall: new FormControl(null),
    model_unit: new FormControl(1),
    weight: new FormControl(null),
    shape: new FormControl(''),
  })

  constructor(
    public dialogRef: MatDialogRef<ModalModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public productService: ProductService
  ) {}

  ngOnInit(): void {
    this.form.reset({
      file_model: this.data.formValue.file_model,
      model_size: this.data.formValue.model_size,
      model_unit: this.data.formValue.model_unit || 1,
      tall: this.data.formValue.tall,
      weight: this.data.formValue.weight,
      shape: this.data.formValue.shape,
    })
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onConfirm(): void {
    const value = this.form.getRawValue()
    this.dialogRef.close({
      ...value,
    })
  }
}
