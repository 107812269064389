import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  formatPrice,
  Pagination,
  formatPriceAutoDecimal,
} from '../../helpers'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../services'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../globals'
import { forkJoin } from 'rxjs'
import { MatRadioModule } from '@angular/material/radio'
import { MatRipple } from '@angular/material/core'
import { MatIconModule } from '@angular/material/icon'
import { MatTabsModule } from '@angular/material/tabs'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { imports } from '../../imports'
import { InputComponent } from '../input/input'
import { TextareaComponent } from '../text-area/text-area'
import { SelectComponent } from '../select/select'
import { InputSearchComponent } from '../input-search/input-search'
import { MatPaginator } from '@angular/material/paginator'
import { InputNumberComponent } from '../input-number/input-number'

@Component({
  selector: 'app-modal-select-product-single',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    MatRadioModule,
    SelectComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatIconModule,
    MatRipple,
    InputSearchComponent,
    InputNumberComponent,
    MatPaginator,
  ],
  templateUrl: './modal-select-product-single.html',
  styleUrls: ['./modal-select-product-single.scss'],
})
export class ModalSelectProductSingleComponent implements OnInit {
  // @Input() form: any = new FormGroup('')
  // @Input() sectionIndex: number = 0
  // @Input() showButtonDelete: boolean = false
  // @Output() deleteSection = new EventEmitter()
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly pagination = new Pagination({
    sortName: 'code',
  })

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false
  rerender = false
  pageNumber = new FormControl(1)

  formSearch = new FormGroup({
    search_name: new FormControl(),
    category: new FormControl(),
    sub_category: new FormControl(),
  })
  form = new FormGroup({
    key: new FormControl(),
    value: new FormControl(),
  })

  listProduct: any[] = [
    {
      key: `1,2`,
      product_id: 1,
      product_detail_id: 2,
      img: '/assets/images/icons/img-product-test.svg',
      code: 'A070424001_GREEN',
      name: 'CELINE_RTW SWEATER BLACK',
      color: '#0A0A0A',
      color_name: 'black',
      size: 's',
      price: 1000,
      qty: 10,
    },
    {
      key: `3,4`,
      product_id: 3,
      product_detail_id: 4,
      img: '/assets/images/icons/img-product-test.svg',
      code: 'A070424001_GREEN',
      name: 'CELINE_RTW SWEATER GREEN',
      color: '#16A34A',
      color_name: 'green',
      size: 's',
      price: 2599,
      qty: 10,
    },
  ]

  constructor(
    public dialogRef: MatDialogRef<ModalSelectProductSingleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading
  ) {}

  ngOnInit(): void {
    this.initData()
  }

  initData() {}

  log(log: any) {
    console.log('log', log)
  }

  onClose(): void {
    this.dialogRef.close(false)
  }

  onConfirm(): void {
    const formValue = this.form.getRawValue()
    this.dialogRef.close(formValue.value)
  }

  changeProduct(key: any, value: any) {
    this.form.setValue({
      key,
      value,
    })
  }

  onChangePage(reset = false) {
    if (this.pageNumber.value) {
      const page = this.pageNumber.value - 1
      if (this.pagination.data.pageIndex != page) {
        this.pagination.data.pageIndex = page
        // this.getProductList()
      }
    } else if (reset) {
      this.pageNumber.setValue(this.pagination.data.pageIndex + 1)
      this.rerender = true
      setTimeout(() => {
        this.rerender = false
      })
    }
  }
}
