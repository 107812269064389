import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { UploadFileFlexibleComponent } from '../../../components/upload-file-flexible/upload-file-flexible'
import { SelectAddLinkComponent } from '../../../components/select-add-link/select-add-link'
import { ModalPreviewComponent } from '../../../components/modal-preview/modal-preview'
import { MatMenuModule } from '@angular/material/menu'
import { StatusComponent } from '../../../components/status/status'
import { DomSanitizer } from '@angular/platform-browser'
import { CMSPolicyService } from '../../../services/cms-policy.service'

@Component({
  selector: 'app-cms-policy',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    UploadFileFlexibleComponent,
    SelectAddLinkComponent,
    MatMenuModule,
    StatusComponent
  ],
  templateUrl: './cms-policy.html',
  styleUrls: ['./cms-policy.scss'],
})
export class CMSPolicyComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment

  isEdit: boolean = false
  isView: boolean = false
  dataManage: any
  selectionIndex: number = 0
  tab: any
  is_public: boolean = false

  policyList = new FormArray([
    new FormGroup({
      name: new FormControl('นโยบายความเป็นส่วนตัว'),
      active: new FormControl(true),
      detail_th: new FormControl(''),
      detail_en: new FormControl(''),
    }),
    new FormGroup({
      name: new FormControl('ข้อกำหนดและเงื่อนไข'),
      active: new FormControl(false),
      detail_th: new FormControl(''),
      detail_en: new FormControl(''),
    }),
  ])

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public CMSPolicyService: CMSPolicyService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    this.profile.getProfile()
    this.initData()
  }

  initData() {
    const apis = [
      this.CMSPolicyService.getCmsPolicy()
    ]

    this.loading.start()
    forkJoin(apis).subscribe(([resAboutus]: any) => {
      if (resAboutus) {
        if (!resAboutus.is_error) {
          this.dataManage = resAboutus.data

          if (!this.dataManage) {
            this.router.navigate(['/setting/cms/policy/edit'])
          }
          if (this.dataManage?.length > 0) {
            this.policyList.controls.map((item: any, i: any) => {
              item.controls['detail_th'].setValue(resAboutus.data[i]?.detail_th || '');
              item.controls['detail_en'].setValue(resAboutus.data[i]?.detail_en || '');
              item.controls['active'].setValue(i == 0 ? true : false);

              this.is_public = resAboutus.data[i]?.is_public
            })
            this.policyList.updateValueAndValidity()            
          }          
        } else {
          this.customSnackBar.fail(resAboutus.message)
        }
      }
      this.loading.stop()
    })
  }

  changeTab($tabIndex: any) {
    console.log('', $tabIndex);

    this.tab = $tabIndex
  }

  setSelection(index: any) {    
    if (this.selectionIndex != index) this.tab = 0
    this.selectionIndex = index
    this.policyList.controls.map((item: any, i: any) => {
      if (i == index) {
        item.controls['active'].setValue(true);
      } else {
        item.controls['active'].setValue(false);
      }
    })
    this.policyList.updateValueAndValidity()
  }


  openPreview() {
    const dialogRefPreview = this.dialog.open(ModalPreviewComponent, {
      data: {},
    })
    dialogRefPreview.afterClosed().subscribe(result => {
      if (result) { }
    })
    return dialogRefPreview
  }

  onSave(type: any) {
    let payload = this.policyList.controls.map((item: any, i: number) => {
      return {
        "id": this.dataManage?.[i]?.id || undefined,
        "is_privacy_policy": i == 1 ? true : false,
        "detail_th": item.value.detail_th,
        "detail_en": item.value.detail_en,
        "is_public": type == 'public' ? true : false,
      }
    })
    // return console.log(payload)

    this.loading.start()
    this.CMSPolicyService.addCmsPolicy(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success(type == 'public' ? 'เผยแพร่สำเร็จ' : 'บันทึกร่างสำเร็จ')
          this.router.navigate(['/setting/cms/policy/view'])
        } else {
          this.customSnackBar.failSave(res.message)
        }
      }
      this.loading.stop()
    })

  }
}
