<div class="page-content pb-0">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">หน้าหลัก</div>
  </div>

  <div class="title-wrapper">หน้าหลัก</div>

  <div class="page-content-inner d-flex flex-column pb-0">
    <div class="w-100 d-flex justify-content-end">
      <button matRipple class="btn btn-primary" (click)="openModalManageHome()">
        <span class="icon material-symbols-outlined fill">settings</span>
        <span> จัดการเมนูหน้าหลัก </span>
      </button>
    </div>

    <div class="section-container">
      <div class="title-section mb-3">แบนเนอร์หลัก</div>

      <div class="section-wrapper">
        <div class="row">
          <div class="col-12 mb-4">
            <label class="form-label"> สีตัวอักษรคอนเทนต์ </label>

            <div [style]="{ 'margin-left': '-10px' }">
              <mat-radio-group [formControl]="form.controls.is_content_white">
                @if (isEdit || form.controls.is_content_white.value === true) {
                <mat-radio-button [value]="true" class="me-2">สีขาว</mat-radio-button>
                }
                <!-- ~ -->
                @if (isEdit || form.controls.is_content_white.value === false) {
                <mat-radio-button [value]="false">สีดำ</mat-radio-button>
                }
              </mat-radio-group>
            </div>
          </div>

          <div class="col-12 mb-4">
            <label class="form-label"> หัวข้อคอนเทนต์ (ภาษาไทย) </label>
            @if (isEdit) {
            <app-input [control]="form.controls['content_th']" remainingText="100"> </app-input>
            } @else {
            <div>{{ form.controls['content_th'].value || '-' }}</div>
            }
          </div>

          <div class="col-12 mb-4">
            <label class="form-label"> หัวข้อคอนเทนต์ (ภาษาอังกฤษ) </label>
            @if (isEdit) {
            <app-input [control]="form.controls['content_en']" remainingText="100"> </app-input>
            } @else {
            <div>{{ form.controls['content_en'].value || '-' }}</div>
            }
          </div>

          <div class="col-12 mb-4">
            <label class="form-label"> คำอธิบายหัวข้อคอนเทนต์ (ภาษาไทย) </label>
            @if (isEdit) {
            <app-input [control]="form.controls['content_description_th']" remainingText="100">
            </app-input>
            } @else {
            <div>{{ form.controls['content_description_th'].value || '-' }}</div>
            }
          </div>

          <div class="col-12 mb-4">
            <label class="form-label">คำอธิบายหัวข้อคอนเทนต์ (ภาษาอังกฤษ)</label>
            @if (isEdit) {
            <app-input [control]="form.controls['content_description_en']" remainingText="100">
            </app-input>
            } @else {
            <div>{{ form.controls['content_description_en'].value || '-' }}</div>
            }
          </div>

          <div class="col-12 mb-4">
            <label class="form-label">ภาพแบนเนอร์</label>

            <div class="section-upload-container">
              @if (isEdit) {
              <div class="remark my-2">
                (ขนาดรูปภาพ : 1440px x 1024px / ไม่เกิน 5 Mb) | (ขนาดวีดิโอ : 1920px x 1080px /
                ไม่เกิน 30 Mb)
              </div>
              }
              <div class="upload-file-wrapper mb-1">
                <app-upload-file-flexible
                  [type]="isEdit ? 'edit' : 'view'"
                  [maxImageSize]="5"
                  [maxVideoSize]="30"
                  [logoDefault]="dataManage?.file_banner_id?.url"
                  (upload)="form.controls['file_banner_id'].setValue($event)" />
              </div>

              <label class="form-label mt-2">เพิ่มลิงก์</label>
              @if (isEdit) {
              <app-select-add-link
                [controlLinkType]="form.controls['cms_link_list_id']"
                [product_id]="form.controls['product_id'].value"
                [product_detail_id]="form.controls['product_detail_id'].value"
                [product_category_id]="form.controls['product_category_id'].value"
                [link_path]="form.controls['link_path'].value"
                (onProductId)="form.controls['product_id'].setValue($event)"
                (onProductDetailId)="form.controls['product_detail_id'].setValue($event)"
                (onCategoryId)="form.controls['product_category_id'].setValue($event)"
                (onLinkPath)="form.controls['link_path'].setValue($event)" />
              } @else {
              <div>{{ linkTextView(form.controls['cms_link_list_id'].value) || '-' }}</div>
              }
            </div>
          </div>
        </div>

        <div class="title-section mb-3">แบนเนอร์หลัก</div>

        <div
          id="all"
          cdkDropList
          [cdkDropListData]="cms_pania_link_menus.controls"
          (cdkDropListDropped)="drop($event)"
          class="drag-list mt-3 w-100">
          @for (item of cms_pania_link_menus.controls; track item; let i = $index) {
          <div class="d-flex drag-box mb-2">
            @if (isEdit) {
            <div class="pre-box">
              <label class="form-label">&nbsp;</label>

              <button
                class="btn btn-transparent-outline me-1"
                matRipple
                cdkDragHandle
                cdkDrag
                [cdkDragData]="item">
                <span class="icon icon-dark-grey material-symbols-outlined fill">
                  drag_indicator
                </span>
              </button>
            </div>
            }

            <div class="w-100">
              <div class="row">
                <div class="col-6">
                  <label class="form-label">ชื่อลิงก์</label>
                  @if (isEdit) {
                  <app-input [control]="item.controls['link_name']" remainingText="100">
                  </app-input>
                  } @else {
                  <div>{{ item.controls['link_name'].value || '-' }}</div>
                  }
                </div>

                <div class="col-6">
                  <label class="form-label">เพิ่มลิงก์</label>
                  @if (isEdit) {
                  <app-select-add-link
                    [controlLinkType]="item.controls['cms_link_list_id']"
                    [product_id]="item.controls['product_id'].value"
                    [product_detail_id]="item.controls['product_detail_id'].value"
                    [product_category_id]="item.controls['product_category_id'].value"
                    [link_path]="item.controls['link_path'].value"
                    (onProductId)="item.controls['product_id'].setValue($event)"
                    (onProductDetailId)="item.controls['product_detail_id'].setValue($event)"
                    (onCategoryId)="item.controls['product_category_id'].setValue($event)"
                    (onLinkPath)="item.controls['link_path'].setValue($event)" />
                  } @else {
                  <div>{{ linkTextView(form.controls['cms_link_list_id'].value) || '-' }}</div>
                  }
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>

      @for (item of sectionProductArr.controls; track $index) {
      <app-section-product
        [isEdit]="isEdit"
        [isView]="isView"
        [form]="item"
        [sectionIndex]="$index"
        (deleteSection)="deleteSection($event)"
        [showButtonDelete]="sectionProductArr.value.length > 1" />
      }
      <!-- ~ -->
      @if (isEdit) {
      <div class="w-100 d-flex justify-content-center">
        <button
          matRipple
          class="btn btn-outline-secondary me-1 mt-4 w-fit"
          (click)="addSection()"
          [disabled]="sectionProductArr.value.length == 3">
          <span class="icon material-symbols-outlined fill"> add_circle </span>
          เพิ่มชุดคอนเทนต์
        </button>
      </div>
      }
    </div>

    <div class="page-footer-btn">
      @if (isView) {
      <div class="d-flex flex-wrap justify-content-end">
        <button
          matRipple
          class="btn btn-outline-secondary min-w-120px"
          [routerLink]="['/setting/cms/home/edit']">
          แก้ไข
        </button>
      </div>
      } @else if(isEdit) {
      <div class="d-flex flex-wrap justify-content-between">
        <div>
          <button matRipple class="btn btn-outline-danger min-w-135px">ล้างข้อมูล</button>
        </div>

        <div class="d-flex flex-wrap gap-2">
          <button matRipple class="btn btn-outline-secondary min-w-135px" (click)="openPreview()">
            ดูตัวอย่าง
          </button>
          <button matRipple class="btn btn-outline-secondary min-w-135px" (click)="onSave(false)">
            บันทึกร่าง
          </button>
          <button matRipple class="btn btn-primary min-w-135px" (click)="onSave(true)">
            เผยแพร่ทันที
          </button>
        </div>
      </div>
      }
    </div>
  </div>
</div>
