import { Component, Inject, Injectable } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { FormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatTabsModule } from '@angular/material/tabs'
import { MatIconModule } from '@angular/material/icon'
import { MatRipple } from '@angular/material/core'
import { TopMenuComponent } from '../../../../components/preview/top-menu/top-menu'
import { FileService, ProductService } from '../../../../services'
import { Loading } from '../../../../globals'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { CardProductComponent } from '../../../../components/preview/card-product/card-product'
import { FooterComponent } from '../../../../components/preview/footer/footer'
import { groupProducts, ProductData } from '../../../../helpers/preview/product'

@Component({
  selector: 'app-modal-preview',
  templateUrl: './modal-preview.html',
  styleUrls: ['./modal-preview.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatTabsModule,
    MatIconModule,
    MatRipple,
    TopMenuComponent,
    CardProductComponent,
    FooterComponent,
  ],
})
export class ModalPreviewCampaignComponent {
  file_main_banner: any
  products: any[] = []
  file_sub_banner_one: any
  cms_pania_campaign_product_lists: any[] = []
  file_sub_banner_two: any
  cms_pania_campaign_products: any[] = []

  constructor(
    public dialogRef: MatDialogRef<ModalPreviewCampaignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fileService: FileService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public productService: ProductService
  ) {}

  ngOnInit(): void {
    console.log(this.data)

    this.getFile(this.data.formValue.file_main_banner_id, (res: any) => {
      this.file_main_banner = res.data
    })

    this.getFile(this.data.formValue.file_sub_banner_one_id, (res: any) => {
      this.file_sub_banner_one = res.data
    })

    this.getProducts()

    this.getFile(this.data.formValue.file_sub_banner_two_id, (res: any) => {
      this.file_sub_banner_two = res.data
    })

    this.cms_pania_campaign_products = this.data.formValue.cms_pania_campaign_products.map(
      (d: any) => {
        this.getFile(d.file_id, (res: any) => {
          d.file = res.data
        })
        return d
      }
    )
  }

  getFile(id: any, call: Function) {
    this.loading.start()
    this.fileService.getFile(id).subscribe(res => {
      if (!res.is_error) {
        call(res)
      } else {
        this.customSnackBar.fail()
      }
      this.loading.stop()
    })
  }

  getProducts() {
    const products = groupProducts(
      (this.data.formValue.cms_pania_campaign_product_lists || []).map((d: any, i: number) => {
        return {
          seq: i + 1,
          ...d,
        }
      })
    )

    this.cms_pania_campaign_product_lists = products.map((product: any) => {
      return new ProductData(product)
    })
  }

  onClose(): void {
    this.dialogRef.close()
  }
}
