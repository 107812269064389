<div class="page-content pb-0">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">หน้ารวมสินค้า</div>
  </div>

  <div class="title-wrapper">หน้ารวมสินค้า</div>

  <div class="tabs-wrapper">
    <mat-tab-group
      animationDuration="0ms"
      [disableRipple]="true"
      [selectedIndex]="tab"
      (selectedIndexChange)="changeTab($event)">
      @for (item of tabList.controls; track item; let i = $index) {
      <mat-tab [label]="item.value.category_name || ''">
        <div class="tabs-content-wrapper">
          @if (item.value.contents?.[0]?.data) {
          <div class="form-label color--neutral-500 mb-3">
            วันที่อัปเดต: {{ item.value.contents?.[0]?.data.updated_at ?
            Moment(item.value.contents?.[0]?.data.updated_at).format('DD-MM-YYYY, HH:mm') : '-' }}
            อัปเดตโดย: {{ item.value.contents?.[0]?.data.updated_by?.full_name || '-' }}
          </div>
          <div class="mb-3">
            <app-status
              [status]="item.value.contents?.[0]?.data.is_public ? 'public' : 'not_public'">
              {{ item.value.contents?.[0]?.data.is_public ? 'เผยแพร่แล้ว' : 'ยังไม่เผยแพร่' }}
            </app-status>
          </div>
          }
          <!-- ~ -->
          @for (content of item.controls['contents'].controls; track content; let j = $index) {
          <div class="title-blue mt-3">คอนเทนต์ที่ {{j + 1}}/4</div>
          <div class="box">
            <!-- ~ -->
            <div class="d-flex align-items-end justify-content-between mb-1">
              <span> รูปแบบการจัดวางเลย์เอ้าท์ </span>

              @if (j > 1) {
              <button
                matRipple
                class="btn btn-outline-danger"
                (click)="deleteContent(item.controls['contents'], j)">
                ลบ
                <span class="icon material-symbols-outlined fill">delete</span>
              </button>
              }
            </div>

            @if (isEdit) {
            <app-radio-layout
              [oneImage]="true"
              [control]="content.controls['layout_type']"
              (onChange)="content.controls['layout_type'].setValue($event)" />
            }

            <div class="title-blue mt-2">
              ภาพส่วนที่ {{j + 1}} ({{ content.controls['layout_type'].value == 'one' ? 'แนวนอน' :
              'แนวตั้ง' }})@if (isEdit) { <span class="ms-1 color--red-600">*</span>}
            </div>
            @if (isEdit) {
            <div class="text-center font-14 color--neutral-500 mb-1">
              @if (content.controls['layout_type'].value == 'one') { (ขนาดรูปภาพ : 1328px x 810px /
              ไม่เกิน 5 Mb) } @else { (ขนาดรูปภาพ : 620px x 1015px / ไม่เกิน 5 Mb) } | (ขนาดวีดิโอ :
              1920px x 1080px / ไม่เกิน 30 Mb)
            </div>
            }

            <div class="d-flex justify-content-center">
              <div class="upload-file-wrapper layout-{{ content.controls['layout_type'].value }}">
                <app-upload-file-flexible
                  [type]="isEdit ? 'edit' : 'view'"
                  [maxImageSize]="5"
                  [maxVideoSize]="30"
                  [isRequired]="content.controls['file_id'].touched && content.controls['file_id'].invalid"
                  [logoDefault]="content.controls['file_url'].value"
                  (upload)="content.controls['file_id'].setValue($event)" />
              </div>
            </div>
            <div class="d-flex justify-content-center mt-4">
              <div
                [style]="content.controls['layout_type'].value == 'one' ? 'width: 655px' : 'width: 310px'">
                <label class="form-label {{ isView && 'color--neutral-500'}}">เพิ่มลิงก์</label>
                <div>
                  @if (isEdit) {
                  <app-select-add-link
                    [trigger_name]="content.value.product_name || content.value.product_category_name || content.value.link_path"
                    [controlLinkType]="content.controls['cms_link_list_id']"
                    [product_id]="content.controls['product_id'].value"
                    [product_detail_id]="content.controls['product_detail_id'].value"
                    [product_category_id]="content.controls['product_category_id'].value"
                    [link_path]="content.controls['link_path'].value"
                    (onProductId)="content.controls['product_id'].setValue($event)"
                    (onProductDetailId)="content.controls['product_detail_id'].setValue($event)"
                    (onCategoryId)="content.controls['product_category_id'].setValue($event)"
                    (onLinkPath)="content.controls['link_path'].setValue($event)" />
                  } @else {
                  <div>
                    {{ linkTextView(content.controls['cms_link_list_id'].value,
                    content.value.product_name, content.value.product_category_name,
                    content.value.link_path) || '-' }}
                  </div>
                  }
                </div>
              </div>
            </div>

            <div class="title-section mb-3 mt-4 pt-2 d-flex justify-content-between">
              <div class="title-blue mt-2">
                รายการสินค้า ({{productCount(content.controls['cms_pania_all_product_lists'])}}/4)
                <span class="text-danger">*</span>
              </div>
              <!-- ~~ -->
              @if (isEdit && productCount(content.controls['cms_pania_all_product_lists'])) {
              <!-- ~~ -->
              <button
                class="btn btn-primary w-fit"
                [disabled]="productCount(content.controls['cms_pania_all_product_lists']) > 3"
                (click)="addProduct(content.controls['cms_pania_all_product_lists'])">
                <span class="icon material-symbols-outlined fill"> add_circle </span>
                เลือกสินค้า
              </button>
              }
            </div>

            <div class="table-responsive-detail">
              <table class="table table-fixed-column">
                <thead>
                  <tr>
                    <th width="50"></th>
                    <th width="90"></th>
                    <th width="180">รหัสสินค้า</th>
                    <th width="350">ชื่อสินค้า</th>
                    <th width="120" class="text-end">ราคา (บาท)</th>
                    <th width="120" class="text-center">สต๊อกคงเหลือ</th>
                    @if (isEdit) {
                    <th width="100" class="fixed box-shadow-left"></th>
                    }
                  </tr>
                </thead>
                <tbody
                  id="all"
                  cdkDropList
                  (cdkDropListDropped)="dropTable($event, content.controls['cms_pania_all_product_lists'].value)"
                  [cdkDropListDisabled]="isView"
                  class="drag-list mt-3 w-100"
                  cdkDropListGroup>
                  @for (product of content.controls['cms_pania_all_product_lists'].value; track
                  product; let k = $index;) {
                  <tr class="drag-box mb-2" cdkDrag cdkDragHandle>
                    <td width="50">
                      <div class="pre-box">
                        <button class="btn btn-transparent-outline me-1">
                          <span class="icon icon-dark-grey material-symbols-outlined fill">
                            drag_indicator
                          </span>
                        </button>
                      </div>
                    </td>
                    <td width="90">
                      <img
                        class="img-table"
                        src="{{ product.image?.file?.url || '/assets/images/icons/img-default-table.svg' }}" />
                    </td>
                    <td width="320">{{ product['code'] }}</td>
                    <td width="350">
                      <div>
                        <div>{{product['name']}}</div>
                        <div class="product-detail">
                          <div
                            class="tag-color"
                            [ngStyle]="{'background-color': product.product_detail?.product_color?.color_code || '#000000' }"></div>
                          <div>{{ product.color }}, {{ product.size }}</div>
                        </div>
                      </div>
                    </td>
                    <td width="130" class="text-end">{{ formatPrice(product.selling_price) }}</td>
                    <td width="130" class="text-center">{{ product.remain }}</td>
                    @if (isEdit) {
                    <td width="100" class="fixed box-shadow-left">
                      <div class="w-100 d-flex justify-content-center">
                        <button
                          matRipple
                          class="btn btn-outline-danger"
                          (click)="deleteProduct(content.controls['cms_pania_all_product_lists'], k)">
                          <span class="icon material-symbols-outlined fill">delete</span>
                        </button>
                      </div>
                    </td>
                    }
                  </tr>
                  }
                </tbody>
              </table>

              @if(!content.controls['cms_pania_all_product_lists'].value?.length) {
              <!-- ~~ -->

              <div class="data-not-found-wrapper flex-column align-items-center">
                <div>ไม่พบสินค้า กรุณาเลือกสินค้า</div>
                @if (isEdit) {
                <button
                  class="btn btn-primary me-1 mt-4 w-fit"
                  (click)="addProduct(content.controls['cms_pania_all_product_lists'])">
                  <span class="icon material-symbols-outlined fill"> add_circle </span>
                  เลือกสินค้า
                </button>
                }
              </div>
              }
            </div>
            @if (content.controls['cms_pania_all_product_lists'].touched &&
            content.controls['cms_pania_all_product_lists'].invalid) {
            <div class="mt-1 font-14 text-danger">กรุณาเพิ่มสินค้าให้ครบ 4 รายการ</div>
            }
          </div>
          } @if (isEdit) {
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-outline-secondary w-fit mt-3"
              (click)="addContent(item.controls['contents'])"
              [disabled]="item.controls['contents'].controls.length == 4">
              <span class="icon material-symbols-outlined fill"> add_circle </span>
              เพิ่มชุดคอนเทนต์
            </button>
          </div>
          }
        </div>
      </mat-tab>
      }
    </mat-tab-group>
  </div>

  <div class="page-footer-btn-tab">
    @if (isView) {
    <div class="d-flex justify-content-end">
      @if (profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.UPDATE])) {
      <button matRipple class="btn btn-outline-secondary min-w-120px" (click)="onEdit()">
        แก้ไข
      </button>
      }
    </div>
    } @else if(isEdit) {
    <div class="d-flex flex-wrap justify-content-between gap-2">
      <div class="d-flex flex-wrap gap-2">
        <button matRipple class="btn btn-outline-danger min-w-135px" (click)="onReset()">
          ล้างข้อมูล
        </button>
        <button matRipple class="btn btn-outline-secondary min-w-135px" (click)="onCancel()">
          ยกเลิก
        </button>
      </div>

      <div class="d-flex flex-wrap gap-2">
        <button matRipple class="btn btn-outline-secondary min-w-135px" (click)="openPreview()">
          ดูตัวอย่าง
        </button>
        @if (profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.UPDATE]) ||
        profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.CREATE])) {
        <button matRipple class="btn btn-outline-secondary min-w-135px" (click)="onSave(false)">
          บันทึกร่าง
        </button>
        <button matRipple class="btn btn-primary min-w-135px" (click)="onSave(true)">
          เผยแพร่ทันที
        </button>
        }
      </div>
    </div>
    }
  </div>
</div>
