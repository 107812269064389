<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">ส่วนลดที่ใช้ได้</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">
    @for (item of data.discountCodeList; track item; let i = $index) {
    <div class="box d-flex">
      <div class="w-75">
        <b class="font-20">{{ item.name }}</b>
        <div class="font-14 mt-2">{{ item.description || '-' }}</div>
        <div class="font-14">ใช้ได้ถึง {{ endFormat(item) }}</div>
      </div>
      <div class="line-use"></div>
      <div class="w-25 box-use d-flex justify-content-center align-items-center">
        @if (promotionCode != item.code) {
        <button
          class="btn btn-outline-secondary-bg-tran px-4"
          matRipple
          (click)="promotionCode = item.code">
          ใช้
        </button>
        } @else {
        <span class="icon font-40 material-symbols-outlined fill color--brand-blue-800">
          check_circle
        </span>
        }
      </div>
    </div>
    } @if (!data.discountCodeList.length) {
    <div class="d-flex flex-column justify-content-center align-items-center gap-3 my-2">
      <img src="/assets/images/icons/icon-promotion-not-found.svg" width="100" />
      <span>ไม่มีส่วนลดที่ใช้ได้</span>
    </div>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-outline-secondary btn-md" matRipple (click)="onClose()">ยกเลิก</button>
    <button
      class="btn btn-primary btn-md"
      matRipple
      (click)="onConfirm()"
      [disabled]="!data.discountCodeList.length || !promotionCode">
      บันทึก
    </button>
  </div>
</mat-dialog-actions>
