import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatSelect, MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { CommonModule } from '@angular/common'
import { SanitizeHtmlPipe } from '../../pipes/inner-html'
import { ModalSelectProductSingleComponent } from '../modal-select-product-single/modal-select-product-single'
import { ModalSelectCategoryProductComponent } from '../modal-select-category-product/modal-select-category-product'
import { ModalAddURLComponent } from '../modal-add-url/modal-add-url'
import { Loading, Profile } from '../../globals'
import { Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { CustomSnackBar } from '../snackbar/snackbar'
import { SelectComponent } from '../select/select'
import { EnumLinkListId, LINK_LIST_ID } from '../../helpers/link'

@Component({
  selector: 'app-select-add-link',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    MatSuffix,
    MatSelectModule,
    MatInputModule,
    SanitizeHtmlPipe,
    SelectComponent,
  ],
  templateUrl: './select-add-link.html',
  styleUrls: ['./select-add-link.scss'],
})
export class SelectAddLinkComponent implements OnInit {
  @Input() controlLinkType!: FormControl | AbstractControl
  @Input() controlLinkName: FormControl | AbstractControl = new FormControl('')

  @Input() product_id = null
  @Input() product_detail_id = null
  @Input() product_category_id = null
  @Input() link_path = null

  @Output() onProductId = new EventEmitter()
  @Output() onProductDetailId = new EventEmitter()
  @Output() onCategoryId = new EventEmitter()
  @Output() onLinkPath = new EventEmitter()

  readonly cms_link_list_id = new FormControl()

  listAddLink: any[] = [
    {
      id: LINK_LIST_ID.none,
      name: 'ไม่ระบุ',
    },
    {
      id: LINK_LIST_ID.PRODUCT_DETAIL,
      name: 'หน้ารายละเอียดสินค้า',
    },
    {
      id: LINK_LIST_ID.CATEGORY,
      name: 'หมวดหมู่สินค้า',
    },
    {
      id: LINK_LIST_ID.URL,
      name: 'กำหนด URL',
    },
    {
      id: LINK_LIST_ID.CAMPAIGN,
      name: 'แคมเปญ',
    },
    {
      id: LINK_LIST_ID.COLLECTION,
      name: 'คอลเลคชัน',
    },
  ]

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading
  ) {}

  ngOnInit(): void {}

  onClickAddLink(e: any) {
    if (!e.isUserInput) {
      return
    }

    setTimeout(() => {
      switch (this.cms_link_list_id.value) {
        case LINK_LIST_ID.none:
          this.setValue({})
          return
        case LINK_LIST_ID.PRODUCT_DETAIL:
          return this.openModalSelectProductSingle()
        case LINK_LIST_ID.CATEGORY:
          return this.openModalSelectCategoryProduct()
        case LINK_LIST_ID.URL:
          return this.openModalAddURLComponent()
        case LINK_LIST_ID.CAMPAIGN:
          this.setValue({})
          return
        case LINK_LIST_ID.COLLECTION:
          this.setValue({})
          return
        default:
          this.setValue({})
          return null
      }
    }, 10)
  }

  setValue(value: any) {
    this.controlLinkType.setValue(this.cms_link_list_id.value)
    this.onProductId.emit(value.product_id || null)
    this.onProductDetailId.emit(value.product_detail_id || null)
    this.onCategoryId.emit(value.product_category_id || null)
    this.onLinkPath.emit(value.link_path || null)
  }

  openModalSelectProductSingle() {
    const dialogRef = this.dialog.open(ModalSelectProductSingleComponent, {
      data: {
        product_id: this.product_id,
        product_detail_id: this.product_detail_id,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.setValue({
          product_id: result.product_id,
          product_detail_id: result.product_detail_id,
        })
      } else {
        this.cms_link_list_id.setValue(this.controlLinkType.value, {
          emitEvent: false,
        })
      }
    })
  }

  openModalSelectCategoryProduct() {
    const dialogRef = this.dialog.open(ModalSelectCategoryProductComponent, {
      data: {
        product_category_id: this.product_category_id,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.setValue({
          product_category_id: result.product_category_id,
        })
      } else {
        this.cms_link_list_id.setValue(this.controlLinkType.value, {
          emitEvent: false,
        })
      }
    })
  }

  openModalAddURLComponent() {
    const dialogRef = this.dialog.open(ModalAddURLComponent, {
      data: {
        link_path: this.link_path,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.setValue({
          link_path: result.link_path,
        })
      } else {
        this.cms_link_list_id.setValue(this.controlLinkType.value, {
          emitEvent: false,
        })
      }
    })
  }
}
