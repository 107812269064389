import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  productImage,
  Ascending,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { UploadFileFlexibleComponent } from '../../../components/upload-file-flexible/upload-file-flexible'
import { SelectAddLinkComponent } from '../../../components/select-add-link/select-add-link'
import { ModalPreviewComponent } from '../../../components/modal-preview/modal-preview'
import { SectionProductComponent } from './section-product/section-product'
import { CMSCampaignService } from '../../../services/cms-campaign.service'
import { StatusComponent } from '../../../components/status/status'
import { LINK_LIST_ID, linkTextView } from '../../../helpers/link'
import { ModalPreviewCampaignComponent } from './modal-preview/modal-preview'
@Component({
  selector: 'app-cms-campaign',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    UploadFileFlexibleComponent,
    SelectAddLinkComponent,
    SectionProductComponent,
    StatusComponent,
  ],
  templateUrl: './cms-campaign.html',
  styleUrls: ['./cms-campaign.scss'],
})
export class CMSCampaignComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment
  readonly linkTextView = linkTextView

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false
  dataManage: any
  reset: any

  form = new FormGroup({
    file_main_banner_id: new FormControl(null, [Validators.required]),
    file_main_banner_default: new FormControl(),
    file_sub_banner_one_id: new FormControl(null, [Validators.required]),
    file_sub_banner_one_default: new FormControl(),
    file_sub_banner_two_id: new FormControl(null, [Validators.required]),
    file_sub_banner_two_default: new FormControl(),
    is_two_image: new FormControl(),
    cms_link_list_id: new FormControl(),
    product_id: new FormControl(),
    product_detail_id: new FormControl(),
    product_category_id: new FormControl(),
    product_name: new FormControl(),
    product_category_name: new FormControl(),
    link_path: new FormControl(),
    cms_pania_campaign_products: new FormArray<FormGroup>([]),
    value_cms_pania_campaign_products: new FormControl([]),
    cms_pania_campaign_product_lists: new FormControl(
      [],
      [
        (control: AbstractControl) => {
          if (this.productCount(control) < 4) {
            return {
              minLength: true,
            }
          }

          return null
        },
      ]
    ),
  })

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public CMSCampaignService: CMSCampaignService
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    this.initData()
  }

  initData() {
    const apis = [this.CMSCampaignService.getCampaign()]

    this.loading.start()
    forkJoin(apis).subscribe(([resCampaign]: any) => {
      if (resCampaign) {
        if (!resCampaign.is_error) {
          this.dataManage = resCampaign.data

          let cms_pania_campaign_product_lists: any = []
          if (this.dataManage.cms_pania_campaign_product_lists?.length) {
            Ascending(this.dataManage.cms_pania_campaign_product_lists || [], 'seq')
            cms_pania_campaign_product_lists =
              this.dataManage.cms_pania_campaign_product_lists.reduce(
                (produtcs: any[], item: any) => {
                  produtcs.push({
                    ...item,
                    ...(item.product_detail_id ? item.product_detail : item.product),
                    image: productImage(item.product, item.product_detail),
                  })

                  return produtcs
                },
                []
              )
          }

          if (this.dataManage.id) {
            this.form.reset({
              file_main_banner_id: this.dataManage.file_main_banner_id,
              file_main_banner_default: this.dataManage.file_main_banner_file?.url,
              file_sub_banner_one_id: this.dataManage.file_sub_banner_one_id,
              file_sub_banner_one_default: this.dataManage.file_sub_banner_one_file?.url,
              file_sub_banner_two_id: this.dataManage.file_sub_banner_two_id,
              file_sub_banner_two_default: this.dataManage.file_sub_banner_two_file?.url,
              is_two_image: this.dataManage.is_two_image ? 'two' : 'one',
              cms_link_list_id: this.dataManage.cms_link_list_id,
              product_id: this.dataManage.product_id,
              product_detail_id: this.dataManage.product_detail_id,
              product_category_id: this.dataManage.product_category_id,
              link_path: this.dataManage.link_path,
              product_name: this.dataManage.product?.name,
              product_category_name: this.dataManage.product_category?.name_th,
              value_cms_pania_campaign_products: this.dataManage.cms_pania_campaign_products,
              cms_pania_campaign_product_lists,
            })
          } else {
            this.setDefault()
          }
        } else {
          this.customSnackBar.fail(resCampaign.message)
        }
      }
      this.loading.stop()
    })
  }

  setDefault() {
    this.form.reset({
      is_two_image: 'two',
    })
  }

  productCount(productControl: FormControl | AbstractControl) {
    return (productControl.value || []).reduce((products: any[], value: any) => {
      if (!products.includes(value.product_id)) {
        products.push(value.product_id)
      }
      return products
    }, []).length
  }

  onReset() {
    this.setDefault()
    this.reset = crypto.randomUUID()
    // this.initData()
  }

  onCancel() {
    const dialogRefConfirm = this.dialog.open(ModalLeaveComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/setting/cms/campaign/view'])
      }
    })
  }

  openPreview() {
    if (!this.checkValidate()) return

    this.dialog.open(ModalPreviewCampaignComponent, {
      width: '1200px',
      data: {
        // dataManage: this.dataManage,
        formValue: this.form.getRawValue(),
      },
    })
  }

  checkValidate() {
    ValidateForm(this.form)
    ValidateArrayForm(this.form.controls.cms_pania_campaign_products)
    if (!this.form.valid) return
    if (!this.form.controls.cms_pania_campaign_products.valid) return

    return true
  }

  onSave(is_public: boolean) {
    if (!this.checkValidate()) return

    const formValue = this.form.getRawValue()
    // console.log(formValue)
    const payload = {
      id: this.dataManage?.id || null,
      is_public: is_public,
      file_main_banner_id: formValue.file_main_banner_id,
      file_sub_banner_one_id: formValue.file_sub_banner_one_id,
      file_sub_banner_two_id: formValue.file_sub_banner_two_id,
      cms_link_list_id: formValue.cms_link_list_id ?? null,
      product_id: formValue.product_id || null,
      product_detail_id: formValue.product_detail_id || null,
      product_category_id: formValue.product_category_id || null,
      link_path: formValue.link_path || null,
      is_two_image: formValue.is_two_image == 'one' ? false : true,
      cms_pania_campaign_products: (formValue.cms_pania_campaign_products || []).map((d: any) => {
        return {
          file_id: d.file_id,
          cms_link_list_id: d.cms_link_list_id ?? null,
          product_id: d.product_id || null,
          product_detail_id: d.product_detail_id || null,
          product_category_id: d.product_category_id || null,
          link_path: d.link_path || null,
          is_page_campaign: d.cms_link_list_id == LINK_LIST_ID.CAMPAIGN,
          is_page_collection: d.cms_link_list_id == LINK_LIST_ID.COLLECTION,
        }
      }),
      cms_pania_campaign_product_lists: (formValue.cms_pania_campaign_product_lists || []).map(
        (p: any, seq) => {
          return {
            seq: seq + 1,
            product_id: p.product_id,
            product_detail_id: p.product_detail_id,
          }
        }
      ),
    }
    // console.log(payload)
    // return

    this.loading.start()
    this.CMSCampaignService.updateCampaign(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success(is_public ? 'เผยแพร่สำเร็จ' : 'บันทึกร่างสำเร็จ')
          this.router.navigate(['/setting/cms/campaign/view'])
        } else {
          this.customSnackBar.failSave(is_public ? 'เผยแพร่ไม่สำเร็จ' : 'บันทึกร่างไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }
}
