<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">ดูตัวอย่าง</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>


<mat-dialog-content>
  <div class="modal-content py-2">
    perview
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-primary btn-md" matRipple (click)="onClose()">กลับ</button>
  </div>
</mat-dialog-actions>
