import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  formatPrice,
  Pagination,
  formatPriceAutoDecimal,
} from '../../helpers'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../services'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../globals'
import { forkJoin } from 'rxjs'
import { MatRadioModule } from '@angular/material/radio'
import { MatRipple } from '@angular/material/core'
import { MatIconModule } from '@angular/material/icon'
import { MatTabsModule } from '@angular/material/tabs'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { imports } from '../../imports'
import { InputComponent } from '../input/input'
import { TextareaComponent } from '../text-area/text-area'
import { SelectComponent } from '../select/select'
import { InputSearchComponent } from '../input-search/input-search'
import { MatPaginator } from '@angular/material/paginator'
import { InputNumberComponent } from '../input-number/input-number'

@Component({
  selector: 'app-modal-select-category-product',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    MatRadioModule,
    SelectComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatIconModule,
    MatRipple,
    InputSearchComponent,
    InputNumberComponent,
    MatPaginator,
  ],
  templateUrl: './modal-select-category-product.html',
  styleUrls: ['./modal-select-category-product.scss'],
})
export class ModalSelectCategoryProductComponent implements OnInit {
  // @Input() form: any = new FormGroup('')
  // @Input() sectionIndex: number = 0
  // @Input() showButtonDelete: boolean = false
  // @Output() deleteSection = new EventEmitter()
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly pagination = new Pagination({
    sortName: 'code',
  })

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false
  rerender = false
  pageNumber = new FormControl(1)

  formSearch = new FormGroup({
    search_name: new FormControl(),
  })
  form = new FormGroup({
    category_id: new FormControl(),
    category_name: new FormControl(),
  })

  listCategory: any[] = [
    {
      id: 1,
      name: 'กระเป๋า',
      qty: 5,
    },
    {
      id: 2,
      name: 'กางเกง',
      qty: 10,
    },
    {
      id: 3,
      name: 'ชุดเดรส',
      qty: 20,
    },
    {
      id: 4,
      name: 'รองเท้า',
      qty: 15,
    },
    {
      id: 5,
      name: 'เสื้อผ้า',
      qty: 10,
    },
  ]

  constructor(
    public dialogRef: MatDialogRef<ModalSelectCategoryProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading
  ) {}

  ngOnInit(): void {
    this.initData()
  }

  initData() {}

  log(log: any) {
    console.log('log', log)
  }

  onClose(): void {
    this.dialogRef.close(false)
  }

  onConfirm(): void {
    this.dialogRef.close(this.form.get('category_name')?.value)
  }

  changeCategory(event: any) {
    this.form.controls['category_name'].setValue(event.value)
  }
}
