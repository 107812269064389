<div class="page-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">ขนาดสินค้า</div>
  </div>

  <div class="title-wrapper">ขนาดสินค้า</div>

  <div class="tabs-wrapper">
    <mat-tab-group
      animationDuration="0ms"
      [disableRipple]="true"
      [selectedIndex]="tab"
      (selectedIndexChange)="changeTab($event)">
      <mat-tab label="คู่มือขนาดสินค้า">
        <div class="tabs-content-wrapper">
          <div class="d-flex justify-content-end">
            <button
              class="btn btn-primary w-fit mb-3"
              [routerLink]="'/setting/cms/product-details/manual/create'"
              [queryParams]="{tab: tab}">
              <span class="icon material-symbols-outlined fill"> add_circle </span>
              เพิ่มคู่มือขนาดสินค้า
            </button>
          </div>
          <div class="table-responsive">
            <table class="table table-fixed-column table-detail">
              <thead>
                <tr>
                  <th width="300">ชื่อคู่มือ</th>
                  <th width="140">จำนวนสินค้า</th>
                  <th width="220">อัปเดตโดย</th>
                  <th width="330">วันที่อัปเดต</th>
                  <th width="120" class="fixed box-shadow-left">จัดการ</th>
                </tr>
              </thead>
              <tbody class="mt-3 w-100">
                @for (product of []; track product; let k = $index;) {
                <tr class="drag-box mb-2">
                  <td width="300"></td>
                  <td width="140"></td>
                  <td width="220"></td>
                  <td width="330"></td>
                  <td width="130"></td>
                  @if (isEdit) {
                  <td width="120" class="fixed box-shadow-left">
                    <div class="w-100 d-flex justify-content-center">
                      <button matRipple class="btn btn-outline-danger" (click)="confirmDelete()">
                        <span class="icon material-symbols-outlined fill">delete</span>
                      </button>
                    </div>
                  </td>
                  }
                </tr>
                }
              </tbody>
            </table>

            @if(true) {
            <div class="data-not-found-wrapper">ไม่พบข้อมูล กรุณาเพิ่มคู่มือขนาดสินค้า</div>
            }
          </div>
        </div>
      </mat-tab>

      <mat-tab label="การวัดขนาดสินค้า">
        <div class="tabs-content-wrapper">
          <div class="d-flex justify-content-end">
            <button
              class="btn btn-primary w-fit mb-3"
              [routerLink]="'/setting/cms/product-details/measure/create'"
              [queryParams]="{tab: tab}">
              <span class="icon material-symbols-outlined fill"> add_circle </span>
              เพิ่มการวัดขนาดสินค้า
            </button>
          </div>
          <div class="table-responsive">
            <table class="table table-fixed-column table-detail">
              <thead>
                <tr>
                  <th width="300">ชื่อการวัดขนาดสินค้า</th>
                  <th width="140">จำนวนสินค้า</th>
                  <th width="220">อัปเดตโดย</th>
                  <th width="330">วันที่อัปเดต</th>
                  <th width="120" class="fixed box-shadow-left">จัดการ</th>
                </tr>
              </thead>
              <tbody class="mt-3 w-100">
                @for (product of []; track product; let k = $index;) {
                <tr class="drag-box mb-2">
                  <td width="300"></td>
                  <td width="140"></td>
                  <td width="220"></td>
                  <td width="330"></td>
                  <td width="130"></td>
                  @if (isEdit) {
                  <td width="120" class="fixed box-shadow-left">
                    <div class="w-100 d-flex justify-content-center">
                      <button matRipple class="btn btn-outline-danger" (click)="confirmDelete()">
                        <span class="icon material-symbols-outlined fill">delete</span>
                      </button>
                    </div>
                  </td>
                  }
                </tr>
                }
              </tbody>
            </table>

            @if(true) {
            <div class="data-not-found-wrapper">ไม่พบข้อมูล กรุณาเพิ่มการวัดขนาดสินค้า</div>
            }
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
