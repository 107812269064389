import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class CMSCampaignService {
  constructor(private service: Service) {}
  private pathCMSCampaign = '/v1/cms/campaign'

  addCmsCampaign(payload: any): Observable<any> {
    return this.service.put(`${this.pathCMSCampaign}`, payload)
  }

  getCmsCampaign(): Observable<any> {
    return this.service.get(`${this.pathCMSCampaign}`)
  }
}
