<div class="page-main-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link color--brand-blue--f" (click)="toList()">
      สินค้าลดราคา
    </div>
    <div class="breadcrumb-separator">/</div>
    @if(isCreate) {
    <div class="breadcrumb-text">เพิ่มสินค้าลดราคา</div>
    } @else if(isView) {
    <div class="breadcrumb-text">ข้อมูลสินค้าลดราคา</div>
    } @else if(isEdit) {
    <div class="breadcrumb-text breadcrumb-link color--brand-blue--f" (click)="toView()">
      ข้อมูลสินค้าลดราคา
    </div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">แก้ไขสินค้าลดราคา</div>
    }
  </div>

  @if(isView) {
  <div class="title-wrapper">ข้อมูลสินค้าลดราคา</div>
  } @else {
  <div class="title-wrapper">{{ isCreate ? 'เพิ่มสินค้าลดราคา' : 'แก้ไขสินค้าลดราคา' }}</div>
  }

  <div class="page-content-inner mb-3">
    
  </div>
</div>
