import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { UploadFileFlexibleComponent } from '../../../components/upload-file-flexible/upload-file-flexible'
import { SelectAddLinkComponent } from '../../../components/select-add-link/select-add-link'
import { ModalPreviewComponent } from '../../../components/modal-preview/modal-preview'
import { MatMenuModule } from '@angular/material/menu'
import { StatusComponent } from '../../../components/status/status'
import { DomSanitizer } from '@angular/platform-browser'
import { CMSTierService } from '../../../services/cms-tier.service'
import { CurrencyService } from '../../../services/currency.service'

@Component({
  selector: 'app-currency',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    UploadFileFlexibleComponent,
    SelectAddLinkComponent,
    MatMenuModule,
    StatusComponent,
  ],
  templateUrl: './currency.html',
  styleUrls: ['./currency.scss'],
})
export class CurrencyComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment

  currencyList: any[] = []

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public CurrencyService: CurrencyService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.initData()
  }

  initData() {
    this.loading.start()
    let payload = {
      filter: {
        start_date: Moment().format('YYYY-MM-DD') + 'T00:00:00Z',
        end_date: Moment().format('YYYY-MM-DD') + 'T00:00:00Z',
      },
    }

    this.CurrencyService.getCurrencyRateQuery(payload).subscribe(res => {
      if (res) {
        if (!res.is_error) {
          this.currencyList = res.data
        }
      }
      this.loading.stop()
    })
  }
}
