import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { MatMenu, MatMenuModule } from '@angular/material/menu'
import Image from 'ngx-editor/lib/commands/Image'
import { MatRadioModule } from '@angular/material/radio'
import { UploadFileFlexibleComponent } from '../../../components/upload-file-flexible/upload-file-flexible'
import { StatusComponent } from '../../../components/status/status'
import { ModalDeleteComponent } from '../../../components/modal-delete/modal-delete'
import { ModalPreviewComponent } from '../../../components/modal-preview/modal-preview'
import { CMSCollectionService } from '../../../services/cms-collection.service'
import { query } from '@angular/animations'

@Component({
  selector: 'app-cms-collection',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    MatMenuModule,
    MatRadioModule,
    UploadFileFlexibleComponent,
    StatusComponent,
  ],
  templateUrl: './cms-collection.html',
  styleUrls: ['./cms-collection.scss'],
})
export class CMSCollectionComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS

  isEdit: boolean = false
  isView: boolean = false

  dataManage: any
  collectionId: any
  tab = 0

  isFileError = [false]
  file_list = new FormArray([
    new FormGroup({
      is_vertical: new FormControl(true),
      file: new FormControl(null),
      file_id: new FormControl('', [Validators.required]),
    })
  ])

  form = new FormGroup({
    content_th: new FormControl(''),
    content_en: new FormControl(''),
    description_th: new FormControl(''),
    description_en: new FormControl(''),
  })

  collectionList = [
    {
      id: 'create',
      name: 'คอลเลกชัน 1',
    }
  ]

  constructor(
    public profile: Profile,
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public CMSCollectionService: CMSCollectionService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      if (params.id) {
        this.collectionId = params.id
      }
    })
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    if (this.collectionId && this.collectionId != 'create') {
      this.getCmsCollectionById(this.collectionId)
    }
    this.profile.getProfile() // add for permission
    this.initData()
  }

  initData() {
    this.loading.start()
    this.CMSCollectionService.getCmsCollection().subscribe((res) => {
      if (res) {
        let data = res?.data?.length > 0 ? res?.data : []
        if (!res.is_error) {
          if (data.length == 0) {
            this.router.navigate(['/setting/cms/collection/edit'], { queryParams: { id: 'create' } })
          } else {
            this.dataManage = data
            this.collectionList = []
            data?.map((item: any, index: number) => {
              this.collectionList.push({
                ...item,
                id: item.id,
                name: `คอลเลกชัน ${index + 1}`,
              })
            })

            console.log('this.collectionId', this.collectionId);
            if (!this.collectionId || this.collectionId != 'create') {
              this.collectionId = data?.[0]?.id
            }

            if (this.collectionId && this.collectionId != 'create') {
              if (data?.length > 0) {
                this.getCmsCollectionById(this.collectionId)
              }
            } else if (this.collectionId == 'create') {
              console.log('this.collectionList', this.collectionList);

              let data = {
                id: 'create',
                name: `คอลเลกชัน ${this.collectionList.length + 1}`,
              }
              this.collectionList.push(data)
            }
          }
        }
      } else {
        this.customSnackBar.fail(res.message)
      }

      this.loading.stop()
    })
  }

  getCmsCollectionById(id: any) {
    this.loading.start()
    this.CMSCollectionService.getCmsCollectionById(id).subscribe((res) => {
      if (res) {
        if (!res.is_error) {
          let data = res?.data
          this.file_list.clear()
          if (data.cms_pania_collection_images.length > 0) {
            data.cms_pania_collection_images.map((item: any) => {
              this.file_list.push(
                new FormGroup({
                  is_vertical: new FormControl(item.is_vertical),
                  file: new FormControl(item.file.url),
                  file_id: new FormControl(item.file.id),
                })
              )
            })
          }
          this.form.patchValue({
            content_th: data.content_name_th,
            content_en: data.content_name_en,
            description_th: data.content_detail_th,
            description_en: data.content_detail_en,
          })

        } else {
          this.customSnackBar.fail(res.message)
        }
      }
      this.loading.stop()
    })
  }

  resetCollection() {
    this.file_list.clear()
    this.file_list.push(new FormGroup({
      is_vertical: new FormControl(true),
      file: new FormControl(null),
      file_id: new FormControl(''),
    }))
    this.form.reset()
  }

  addCollection() {
    // let data = {
    //   id: 'create',
    //   name: `คอลเลกชัน ${this.collectionList.length + 1}`,
    // }
    // this.collectionList.push(data)
    this.collectionId = 'create'
    this.router.navigate([`/setting/cms/collection/edit`], { queryParams: { id: this.collectionId } })
  }

  setSelection(id: any) {
    if (id == 'create') {
      this.resetCollection()
    }
    this.collectionId = id
    this.router.navigate([`/setting/cms/collection/${this.isView ? 'view' : 'edit'}`],
      { queryParams: { id: this.collectionId } })
    if (this.collectionId && this.collectionId != 'create') {
      this.getCmsCollectionById(this.collectionId)
    }
  }

  addFiles(index: any) {
    let data = new FormGroup({
      is_vertical: new FormControl(true),
      file: new FormControl(null),
      file_id: new FormControl('', [Validators.required]),
    })
    this.file_list.push(data);
  }

  confirmDelete(id: any, index: any) {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบคอลเลกชันนี้ใช่หรือไม่`,
      },
    })

    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        this.deleteCollection(id, index)
      }
    })

    return dialogRefDelete
  }

  openPreview() {
    const dialogRefPreview = this.dialog.open(ModalPreviewComponent, {
      data: {},
    })

    dialogRefPreview.afterClosed().subscribe(result => {
      if (result) { }
    })

    return dialogRefPreview
  }

  onSave(type: any) {
    ValidateArrayForm(this.file_list)
    if (!this.file_list.valid) {
      return
    }

    let files = []
    files = this.file_list.value.map((item: any) => {
      return {
        file_id: item.file_id,
        is_vertical: item.is_vertical,
      }
    })

    let payload = {
      "id": this.collectionId != 'create' ? this.collectionId : undefined,
      // "collection_lists": this.form.value.collection_lists,
      "content_name_th": this.form.value.content_th,
      "content_name_en": this.form.value.content_en,
      "content_detail_th": this.form.value.description_th,
      "content_detail_en": this.form.value.description_th,
      "is_public": type == 'public' ? true : false,
      "cms_pania_collection_images": files
    }
    // return console.log(payload)

    this.loading.start()
    this.CMSCollectionService.updateCmsCollection(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success(type == 'public' ? 'เผยแพร่สำเร็จ' : 'บันทึกร่างสำเร็จ')
          this.router.navigate(['/setting/cms/collection/view'])
        } else {
          if (res.errors[0]?.field) {
            setErrorForm(this.form, res.errors)
          } else {
            this.customSnackBar.failSave(res.message)
          }
        }
      }
      this.loading.stop()
    })
  }

  deleteCollection(id: any, index: any) {
    if (id == 'create') {
      this.collectionList.splice(index, 1)
      this.collectionId = this.collectionList?.[0]?.id || null
      this.router.navigate([`/setting/cms/collection/view`])
      this.customSnackBar.success('ลบข้อมูลสำเร็จ')
    } else {
      this.loading.start()
      this.CMSCollectionService.deleteCmsCollection(id).subscribe((res: any) => {
        if (res) {
          if (!res.is_error) {
            this.collectionList.splice(index, 1)
            this.collectionId = this.collectionList?.[0]?.id || 'create'
            this.customSnackBar.success('ลบข้อมูลสำเร็จ')
            this.router.navigate([`/setting/cms/collection/view`])
            this.initData()
          } else {
            this.customSnackBar.fail(res.message || 'ลบข้อมูลไม่สำเร็จ')
          }
        }
        this.loading.stop()
      })
    }
  }
}
