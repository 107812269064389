<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">วาง URL ที่กำหนดเองไว้ที่นี่</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <label class="form-label mt-2">ลิงก์</label>
    <app-input [control]="form.controls['url']" placeholder="https://"> </app-input>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-outline-secondary btn-md" matRipple (click)="onClose()">ยกเลิก</button>
    <button class="btn btn-primary btn-md" matRipple (click)="onConfirm()">ยืนยัน</button>
  </div>
</mat-dialog-actions>
