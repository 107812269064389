import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
} from '../../../../helpers'
import { UploadImageProfileComponent } from '../../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../../services'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../../components/date-picker/date-picker'
import { UploadFileFlexibleComponent } from '../../../../components/upload-file-flexible/upload-file-flexible'
import { RadioLayoutComponent } from '../../../../components/radio-layout/radio-layout'
import { MatRadioModule } from '@angular/material/radio'
import { SelectComponent } from '../../../../components/select/select'
import { TextareaComponent } from '../../../../components/text-area/text-area'
import { InputComponent } from '../../../../components/input/input'
import { imports } from '../../../../imports'
import { ModalDeleteComponent } from '../../../../components/modal-delete/modal-delete'
import { ModalSelectProductSingleComponent } from '../../../../components/modal-select-product-single/modal-select-product-single'
import { SelectAddLinkComponent } from '../../../../components/select-add-link/select-add-link'
import { ModalSelectProductMultipleComponent } from '../../../../components/modal-select-product-multiple/modal-select-product-multiple'

@Component({
  selector: 'app-section-product',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    UploadFileFlexibleComponent,
    RadioLayoutComponent,
    MatRadioModule,
    SelectComponent,
    CdkDrag,
    CdkDropList,
    SelectAddLinkComponent,
  ],
  templateUrl: './section-product.html',
  styleUrls: ['./section-product.scss'],
})
export class SectionProductComponent implements OnInit {
  @Input() form: any = new FormGroup('')
  @Input() sectionIndex: number = 0
  @Input() showButtonDelete: boolean = false
  @Output() deleteSection = new EventEmitter()
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false
  dataManage: any
  id: any = 1

  layoutImage: any

  listProduct: any[] = [
    // {
    //   img: '/assets/images/icons/img-product-test.svg',
    //   code: 'A070424001_GREEN',
    //   name: 'CELINE_RTW SWEATER GREEN',
    //   price: 1,
    //   qty: 10,
    // },
    // {
    //   img: '/assets/images/icons/img-product-test.svg',
    //   code: 'A070424001_GREEN',
    //   name: 'CELINE_RTW SWEATER GREEN',
    //   price: 2,
    //   qty: 10,
    // },
  ]

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public caratService: CaratService
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    this.initData()
    // console.log('form',this.form)
  }

  initData() {
    const apis = [this.caratService.getCarat(this.id)]

    // this.loading.start()
    // forkJoin(apis).subscribe(([resCarat]: any) => {
    //   if (resCarat) {
    //     if (!resCarat.is_error) {
    //       this.dataManage = resCarat.data

    //       this.form.reset({
    //         carat: resCarat.data.carat || 1,
    //         total_order_amount: resCarat.data.total_order_amount,
    //         order_start_date: resCarat.data.order_start_date,
    //         order_end_date: resCarat.data.order_end_date,
    //         value_amount: resCarat.data.value_amount,
    //         // value_start_date: resCarat.data.value_start_date,
    //         // value_end_date: resCarat.data.value_end_date,
    //       })
    //       this.discounts.clear()
    //       const discounts = (this.dataManage.discounts || []).sort((a: any, b: any) =>
    //         a.sort > b.sort ? 1 : -1
    //       )
    //       for (const discount of discounts) {
    //         this.addCaratDiscount(discount)
    //       }
    //     } else {
    //       this.customSnackBar.fail(resCarat.message)
    //     }
    //   }

    //   this.loading.stop()
    // })
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      )
    }
    // this.checkValidate()
    this.form.markAllAsTouched()
  }

  dropTable(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.form.controls['product_list'].value,
      event.previousIndex,
      event.currentIndex
    )
  }

  log(log: any) {
    console.log('log', log)
  }

  removeSection(index: number) {
    const dialogRefConfirm = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบคอนเทนต์นี้ใช่หรือไม่`,
      },
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.deleteSection.emit(index)
      }
    })
  }

  addProduct(controlProduct?: any) {
    const dialogRef = this.dialog.open(ModalSelectProductMultipleComponent, {
      data: {
        max: 4,
        product_list: this.form.controls['product_list']?.value,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        controlProduct?.setValue(result)
        // console.log('form',this.form)
      }
    })
  }

  confirmDelete(controlProduct: FormControl, id: any) {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบข้อมูลสินค้านี้ใช่หรือไม่`,
      },
    })
    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        let filter = controlProduct.value.filter((e: any) => {
          return e.id != id
        })
        controlProduct?.setValue(filter)
      }
    })
    return dialogRefDelete
  }
}
