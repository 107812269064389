<div class="page-content pb-0">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">test</div>
  </div>

  <div class="title-wrapper">test</div>

  <div class="page-content-inner d-flex flex-column pb-0">
    <button mat-button (click)="addRow()">Add row</button>
    <button mat-button (click)="addColumn()">Add Column</button>

    <table mat-table [dataSource]="dataSource">
      <!-- All columns -->
      <ng-container *ngFor="let col of displayedFields;let i=index" [matColumnDef]="col">
        <th mat-header-cell *matHeaderCellDef>
          <!-- <span
          class="w-100"
            [style.display]="columnSelect!=i?'inline':'none'"
            (click)="selectColumn(i,columnName)"
            >{{displayedHead[i]}}
          </span> -->
          <!-- <input
            #columnName
            [style.display]="columnSelect==i?'inline':'none'"
            [ngModel]="displayedHead[i]"
            (blur)="changeColumnName(i,columnName.value)" /> -->

          <input
            #columnName
            [ngModel]="displayedHead[i]"
            (blur)="changeColumnName(i,columnName.value)" />

          <button arrow-div mat-button (click)="deleteColumn(i)">x</button>
        </th>
        <td mat-cell *matCellDef="let element">
          <input arrow-div [formControl]="element.get(col)" />
          <button arrow-div mat-button *ngIf="i == 0" (click)="deleteRow(i)">x</button>
        </td>
      </ng-container>
      <!---column delete-->
      <!-- <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element;let i=index;">
          <button arrow-div mat-button (click)="delete(i)">delete</button>
        </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div>
    {{myformArray.value|json}}
    </div>
  </div>
</div>
