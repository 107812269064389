<div class="page-content pb-0">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">หน้าหลัก</div>
  </div>

  <div class="title-wrapper">หน้าหลัก</div>

  <div class="page-content-inner d-flex flex-column pb-0">
    <div class="w-100 d-flex justify-content-between flex-wrap mb-4 mb-sm-0">
      <div class="d-flex flex-column">
        @if (dataManage) {
        <div class="form-label color--neutral-500">
          วันที่อัปเดต: {{ Moment(dataManage?.updated_at).format('DD-MM-YYYY, HH:mm') }}
          <br class="d-flex d-sm-none mt-1 mt-sm-0" />
          อัปเดตโดย: {{ dataManage?.updated_by?.full_name || '-' }}
        </div>
        <div class="mb-3">
          <app-status [status]="dataManage?.is_public ? 'public' : 'not_public'">
            {{dataManage?.is_public ? 'เผยแพร่แล้ว' : 'ยังไม่เผยแพร่'}}
          </app-status>
        </div>
        }
      </div>
      <button matRipple class="btn btn-primary" (click)="openModalManageHome()">
        <span class="icon material-symbols-outlined fill">settings</span>
        <span> จัดการเมนูหน้าหลัก </span>
      </button>
    </div>

    <div class="section-container">
      <div class="title-section mb-3">แบนเนอร์หลัก</div>

      <div class="section-wrapper">
        <div class="row">
          <div class="col-12 mb-4">
            <div class="row">
              @if (isEdit) {
              <div class="col-12 mb-4">
                <label class="form-label"> การจัดวางเลย์เอาท์ </label>
                <app-radio-layout
                  [control]="form.controls['is_two_image']"
                  [oneImage]="false"
                  (onChangeValue)="setLayout($event, [])" />
              </div>
              }

              <div class="col-12 mb-4">
                <label class="form-label">
                  แนบรูป/วิดีโอ @if (isEdit) {<span class="color--red-600">*</span>}</label
                >

                <div class="section-upload-container">
                  @if (isEdit) {
                  <div class="remark mt-0 mb-2 my-sm-2">
                    @if (form.value['is_two_image'] == 'one') { (ขนาดรูปภาพ : 1440px x 1024px /
                    ไม่เกิน 5 Mb) } @else { (ขนาดรูปภาพ : 720px x 765px / ไม่เกิน 5 Mb) } |
                    (ขนาดวีดิโอ : 1920px x 1080px / ไม่เกิน 30 Mb)
                  </div>
                  }
                  <div class="box-uploads">
                    @for (item of this.form.controls['cms_bhb_main_details'].controls; track item) {
                    <div class="flex-1">
                      <div class="upload-file-wrapper">
                        <app-upload-file-flexible
                          [type]="isEdit ? 'edit' : 'view'"
                          [maxImageSize]="5"
                          [maxVideoSize]="30"
                          [reset]="reset"
                          [isRequired]="item.controls['file_id'].touched && item.controls['file_id'].invalid"
                          [logoDefault]="item.controls['file_url'].value"
                          (upload)="item.controls['file_id'].setValue($event)" />
                      </div>

                      <label class="form-label mt-2" [ngClass]="{ 'form-label-view': isView }"
                        >เพิ่มลิงก์</label
                      >
                      @if (isEdit) {
                      <app-select-add-link
                        [trigger_name]="item.value.product_name || item.value.product_category_name || item.value.link_path"
                        [controlLinkType]="item.controls['cms_link_list_id']"
                        [product_id]="item.controls['product_id'].value"
                        [product_detail_id]="item.controls['product_detail_id'].value"
                        [product_category_id]="item.controls['product_category_id'].value"
                        [link_path]="item.controls['link_path'].value"
                        (onProductId)="item.controls['product_id'].setValue($event)"
                        (onProductDetailId)="item.controls['product_detail_id'].setValue($event)"
                        (onCategoryId)="item.controls['product_category_id'].setValue($event)"
                        (onLinkPath)="item.controls['link_path'].setValue($event)" />
                      } @else {
                      <div>
                        {{ linkTextView(item.controls['cms_link_list_id'].value,
                        item.value.product_name, item.value.product_category_name,
                        item.value.link_path) || '-' }}
                      </div>
                      }
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="title-section mt-4 mb-3">สินค้าขายดีประจำเดือน</div>
      <div class="section-wrapper">
        <label class="form-label"> ภาพแบนเนอร์ </label>
        <div class="py-2 section-upload-container">
          <div class="remark mt-0 mb-2 my-sm-2">
            (ขนาดรูปภาพ : 1440px x 480px / ไม่เกิน 5 Mb) | (ขนาดวีดิโอ : 1920px x 1080px / ไม่เกิน
            30 Mb)
          </div>
          <div class="upload-file-wrapper-best-seller">
            <app-upload-file-flexible
              [type]="!isView ? 'edit' : 'view'"
              [maxImageSize]="5"
              [maxVideoSize]="30"
              [isRequired]="form.controls['file_banner_id'].touched && form.controls['file_banner_id'].invalid"
              [logoDefault]="form.controls['file_banner_url'].value"
              (upload)="form.controls['file_banner_id'].setValue($event)" />
          </div>
        </div>

        <div class="title-section product-list mb-3 pt-2 d-flex justify-content-between">
          <div>รายการสินค้า ({{ productIds.length }}/10)</div>
          <!-- ~~ -->
          @if (isEdit && productIds.length) {
          <button
            class="btn btn-primary w-fit"
            [disabled]="productIds.length > 3"
            (click)="addProductFirst()">
            <span class="icon material-symbols-outlined fill"> add_circle </span>
            เลือกสินค้า
          </button>
          }
        </div>
        <div class="table-responsive-detail">
          <table class="table table-detail table-fixed-column">
            <thead>
              <tr>
                @if (isEdit) {
                <th width="50"></th>
                }
                <th width="90"></th>
                <th width="320">รหัสสินค้า</th>
                <th width="350">ชื่อสินค้า</th>
                <th width="120" class="text-end">ราคา (บาท)</th>
                <th width="120" class="text-center">สต๊อกคงเหลือ</th>
                @if (isEdit) {
                <th width="100" class="fixed box-shadow-left"></th>
                }
              </tr>
            </thead>
            <tbody
              id="all"
              cdkDropList
              (cdkDropListDropped)="isEdit ? dropTableProductFirst($event) : ''"
              class="drag-list mt-3 w-100"
              cdkDropListGroup>
              @for (item of products_first; track item) {
              <tr class="drag-box mb-2" cdkDrag cdkDragHandle>
                @if (isEdit) {
                <td width="50">
                  <div class="pre-box">
                    <button class="btn btn-transparent-outline me-1">
                      <span class="icon icon-dark-grey material-symbols-outlined fill">
                        drag_indicator
                      </span>
                    </button>
                  </div>
                </td>
                }
                <td width="90">
                  <img
                    class="img-table"
                    src="{{ item.image?.file?.url || '/assets/images/icons/img-default-table.svg' }}" />
                </td>
                <td width="320">{{ item.code }}</td>
                <td width="350">
                  <div>
                    <div>{{item['name']}}</div>
                    <div class="product-detail">
                      <div
                        class="tag-color"
                        [ngStyle]="{'background-color': item.product_color?.color_code || '#000000' }"></div>
                      <div>{{ item.color }}, {{ item.size }}</div>
                    </div>
                  </div>
                </td>
                <td width="130" class="text-end">{{ formatPrice(item.selling_price) }}</td>
                <td width="130" class="text-center">{{ item.remain }}</td>
                @if (isEdit) {
                <td width="100" class="fixed box-shadow-left">
                  <div class="w-100 d-flex justify-content-center">
                    <button
                      matRipple
                      class="btn btn-outline-danger"
                      (click)="confirmDeleteFirst($index)">
                      <span class="icon material-symbols-outlined fill">delete</span>
                    </button>
                  </div>
                </td>
                }
              </tr>
              }
            </tbody>
          </table>

          @if(!products_first.length) {
          <div class="data-not-found-wrapper flex-column align-items-center">
            <div>ไม่พบสินค้า @if(isEdit) { กรุณาเลือกสินค้า }</div>
            @if (isEdit) {
            <button class="btn btn-primary me-1 mt-4 w-fit" (click)="addProductFirst()">
              <span class="icon material-symbols-outlined fill"> add_circle </span>
              เลือกสินค้า
            </button>
            }
          </div>
          }
        </div>
      </div>

      <div class="title-section mt-4 mb-3">แบนเนอร์ย่อย</div>
      <div class="section-wrapper">
        <label class="form-label"> ภาพแบนเนอร์ </label>
        <div class="py-2 section-upload-container">
          <div class="remark mt-0 mb-2 my-sm-2">
            (ขนาดรูปภาพ : 1440px x 320px / ไม่เกิน 5 Mb) | (ขนาดวีดิโอ : 1920px x 1080px / ไม่เกิน
            30 Mb)
          </div>
          <div class="upload-file-wrapper-sub">
            <app-upload-file-flexible
              [type]="!isView ? 'edit' : 'view'"
              [maxImageSize]="5"
              [maxVideoSize]="30"
              [isRequired]="form.controls['file_sub_banner_id'].touched && form.controls['file_sub_banner_id'].invalid"
              [logoDefault]="form.controls['file_sub_banner_url'].value"
              (upload)="form.controls['file_sub_banner_id'].setValue($event)" />
          </div>

          <label class="form-label mt-2" [ngClass]="{ 'form-label-view': isView }"
            >เพิ่มลิงก์</label
          >
          @if (isEdit) {
          <app-select-add-link
            [trigger_name]="form.value.product_name || form.value.product_category_name || form.value.link_path || ''"
            [controlLinkType]="form.controls['cms_link_list_id']"
            [product_id]="form.controls['product_id'].value"
            [product_detail_id]="form.controls['product_detail_id'].value"
            [product_category_id]="form.controls['product_category_id'].value"
            [link_path]="form.controls['link_path'].value"
            (onProductId)="form.controls['product_id'].setValue($event)"
            (onProductDetailId)="form.controls['product_detail_id'].setValue($event)"
            (onCategoryId)="form.controls['product_category_id'].setValue($event)"
            (onLinkPath)="form.controls['link_path'].setValue($event)" />
          } @else {
          <div>
            {{ linkTextView(form.controls['cms_link_list_id'].value, form.value.product_name,
            form.value.product_category_name, form.value.link_path) || '-' }}
          </div>
          }
        </div>
      </div>

      <div class="title-section mt-4 mb-3">ภาพสินค้า</div>
      <div class="section-wrapper">
        <div class="title-section product-list mb-3 pt-2 d-flex justify-content-between">
          <span>
            รายการสินค้า @if (isEdit) {<span class="color--red-600">*</span>}
            <span class="text-sub"> (เพิ่มสินค้าให้ครบ 4 หรือ 8 รายการ)</span>
          </span>
          <!-- ~~ -->
          @if (isEdit && ProductSecondIds.length) {
          <button
            class="btn btn-primary w-fit"
            [disabled]="ProductSecondIds.length > 7"
            (click)="addProductSecond()">
            <span class="icon material-symbols-outlined fill"> add_circle </span>
            เลือกสินค้า
          </button>
          }
        </div>
        <div class="table-responsive-detail">
          <table class="table table-detail table-fixed-column">
            <thead>
              <tr>
                @if (isEdit) {
                <th width="50"></th>
                }
                <th width="90"></th>
                <th width="320">รหัสสินค้า</th>
                <th width="350">ชื่อสินค้า</th>
                <th width="120" class="text-end">ราคา (บาท)</th>
                <th width="120" class="text-center">สต๊อกคงเหลือ</th>
                @if (isEdit) {
                <th width="100" class="fixed box-shadow-left"></th>
                }
              </tr>
            </thead>
            <tbody
              id="all"
              cdkDropList
              (cdkDropListDropped)="isEdit ? dropTableProductSecond($event) : ''"
              class="drag-list mt-3 w-100"
              cdkDropListGroup>
              @for (item of products_second; track item) {
              <tr class="drag-box mb-2" cdkDrag cdkDragHandle>
                @if (isEdit) {
                <td width="50">
                  <div class="pre-box">
                    <button class="btn btn-transparent-outline me-1">
                      <span class="icon icon-dark-grey material-symbols-outlined fill">
                        drag_indicator
                      </span>
                    </button>
                  </div>
                </td>
                }
                <td width="90">
                  <img
                    class="img-table"
                    src="{{ item.image?.file?.url || '/assets/images/icons/img-default-table.svg' }}" />
                </td>
                <td width="320">{{ item.code }}</td>
                <td width="350">
                  <div>
                    <div>{{item['name']}}</div>
                    <div class="product-detail">
                      <div
                        class="tag-color"
                        [ngStyle]="{'background-color': item.product_color?.color_code || '#000000' }"></div>
                      <div>{{ item.color }}, {{ item.size }}</div>
                    </div>
                  </div>
                </td>
                <td width="130" class="text-end">{{ formatPrice(item.selling_price) }}</td>
                <td width="130" class="text-center">{{ item.remain }}</td>
                @if (isEdit) {
                <td width="100" class="fixed box-shadow-left">
                  <div class="w-100 d-flex justify-content-center">
                    <button
                      matRipple
                      class="btn btn-outline-danger"
                      (click)="confirmDeleteProductSecond($index)">
                      <span class="icon material-symbols-outlined fill">delete</span>
                    </button>
                  </div>
                </td>
                }
              </tr>
              }
            </tbody>
          </table>
          @if(!products_second.length) {
          <div class="data-not-found-wrapper flex-column align-items-center">
            <div>ไม่พบสินค้า @if(isEdit) { กรุณาเลือกสินค้า }</div>
            @if (isEdit) {
            <button class="btn btn-primary me-1 mt-4 w-fit" (click)="addProductSecond()">
              <span class="icon material-symbols-outlined fill"> add_circle </span>
              เลือกสินค้า
            </button>
            }
          </div>
          }
        </div>
        @if (form.controls['cms_bhb_main_products_second'].touched &&
        form.controls['cms_bhb_main_products_second'].invalid) {
        <div class="mt-1 font-14 text-danger">กรุณาเพิ่มสินค้าให้ครบ 4 หรือ 8 รายการ</div>
        }
      </div>

      <div class="page-footer-btn">
        @if (isView) { @if (profile.permissions.checkAccess([APP_PERMISSION.CMS],
        [ACTION_ACCESS.UPDATE])) {
        <div class="d-flex flex-wrap justify-content-end">
          <button
            matRipple
            class="btn btn-outline-secondary min-w-165px"
            [routerLink]="['/setting/cms/home/edit']">
            แก้ไข
          </button>
        </div>
        } } @else if(isEdit) {
        <div class="footer-btn d-flex flex-wrap justify-content-between">
          <div class="footer-btn-left d-flex flex-wrap">
            <button matRipple class="btn btn-outline-danger min-w-165px" (click)="onReset()">
              ล้างข้อมูล
            </button>
            <button matRipple class="btn btn-outline-secondary min-w-165px" (click)="onCancel()">
              ยกเลิก
            </button>
          </div>

          <div class="footer-btn-right d-flex flex-wrap">
            <button matRipple class="btn btn-outline-secondary min-w-165px" (click)="openPreview()">
              ดูตัวอย่าง
            </button>
            @if (profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.UPDATE]) ||
            profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.CREATE])) {
            <button matRipple class="btn btn-outline-secondary min-w-165px" (click)="onSave(false)">
              บันทึกร่าง
            </button>
            <button matRipple class="btn btn-primary min-w-165px" (click)="onSave(true)">
              เผยแพร่ทันที
            </button>
            }
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</div>
