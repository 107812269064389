<div class="row">
  <div class="col-12 mb-4">
    <div class="title-section product-list mb-3 pt-2 d-flex justify-content-between">
      <div>
        รายการสินค้า ({{ productIds.length }}/4)
        <span class="text-danger">*</span>
      </div>
      <!-- ~~ -->
      @if (isEdit && productIds.length) {
      <button
        class="btn btn-primary w-fit"
        [disabled]="productIds.length > 3"
        (click)="addProduct()">
        <span class="icon material-symbols-outlined fill"> add_circle </span>
        เลือกสินค้า
      </button>
      }
    </div>

    <div class="table-responsive-detail">
      <table class="table table-fixed-column">
        <thead>
          <tr>
            <th width="50"></th>
            <th width="90"></th>
            <th width="320">รหัสสินค้า</th>
            <th width="350">ชื่อสินค้า</th>
            <th width="120" class="text-end">ราคา (บาท)</th>
            <th width="120" class="text-center">สต๊อกคงเหลือ</th>
            @if (isEdit) {
            <th width="100" class="fixed box-shadow-left"></th>
            }
          </tr>
        </thead>
        <tbody
          id="all"
          cdkDropList
          (cdkDropListDropped)="dropTable($event)"
          [cdkDropListDisabled]="isView"
          class="drag-list mt-3 w-100"
          cdkDropListGroup>
          @for (item of products; track $index) {
          <tr class="drag-box mb-2" cdkDrag cdkDragHandle>
            <td width="50">
              <div class="pre-box">
                <button class="btn btn-transparent-outline me-1">
                  <span class="icon icon-dark-grey material-symbols-outlined fill">
                    drag_indicator
                  </span>
                </button>
              </div>
            </td>
            <td width="90">
              <img
                class="img-table"
                src="{{ item.image?.file?.url || '/assets/images/icons/img-default-table.svg' }}" />
            </td>
            <td width="320">{{ item.code }}</td>
            <td width="350">
              <div>
                <div>{{item['name']}}</div>
                <div class="product-detail">
                  <div
                    class="tag-color"
                    [ngStyle]="{'background-color': item.product_color?.color_code || '#000000' }"></div>
                  <div>{{ item.color }}, {{ item.size }}</div>
                </div>
              </div>
            </td>
            <td width="130" class="text-end">{{ formatPrice(item.selling_price) }}</td>
            <td width="130" class="text-center">{{ item.remain }}</td>
            @if (isEdit) {
            <td width="100" class="fixed box-shadow-left">
              <div class="w-100 d-flex justify-content-center">
                <button matRipple class="btn btn-outline-danger" (click)="confirmDelete($index)">
                  <span class="icon material-symbols-outlined fill">delete</span>
                </button>
              </div>
            </td>
            }
          </tr>
          }
        </tbody>
      </table>

      @if(!products.length) {
      <div class="data-not-found-wrapper flex-column align-items-center">
        <div>ไม่พบสินค้า @if(isEdit) { กรุณาเลือกสินค้า }</div>
        @if (isEdit) {
        <button class="btn btn-primary me-1 mt-4 w-fit" (click)="addProduct()">
          <span class="icon material-symbols-outlined fill"> add_circle </span>
          เลือกสินค้า
        </button>
        }
      </div>
      }
    </div>
    @if (form.controls['cms_pania_campaign_product_lists'].touched &&
    form.controls['cms_pania_campaign_product_lists'].invalid) {
    <div class="mt-1 font-14 text-danger">กรุณาเพิ่มสินค้าให้ครบ 4 รายการ</div>
    }
  </div>

  <div class="col-12 mb-4">
    <div class="title-blue mb-3">ภาพแบนเนอร์ย่อย</div>

    <label class="form-label">ภาพแบนเนอร์</label>

    <div class="section-upload-container">
      @if (isEdit) {
      <div class="remark my-2">(ขนาดรูปภาพ : 1440px x 220px / ไม่เกิน 5 Mb)</div>
      }
      <div class="upload-image-wrapper mb-4">
        <app-upload-file-flexible
          [imgOnly]="true"
          [type]="isEdit ? 'edit' : 'view'"
          [maxImageSize]="5"
          [maxVideoSize]="30"
          [reset]="reset"
          [isRequired]="form.controls['file_sub_banner_two_id'].touched && form.controls['file_sub_banner_two_id'].invalid"
          [logoDefault]="form.controls['file_sub_banner_two_default'].value"
          (upload)="form.controls['file_sub_banner_two_id'].setValue($event)" />
      </div>
    </div>
  </div>

  @if (isEdit) {
  <div class="col-12 mb-4">
    <div class="title-blue mb-3">ภาพสินค้า</div>

    <label class="form-label"> การจัดวางเลย์เอาท์ </label>
    <app-radio-layout
      [control]="form.controls['is_two_image']"
      [oneImage]="false"
      (onChange)="form.controls['is_two_image'].setValue($event);setLayout([])" />
  </div>
  }

  <div class="col-12 mb-4">
    <label class="form-label">
      แนบรูป/วิดีโอ @if (isEdit) { <span class="color--red-600">*</span>}</label
    >

    @if (isEdit) {
    <div class="section-upload-container">
      <div class="remark my-2 text-center">
        (ขนาดรูปภาพ : {{form.controls['is_two_image'].value == 'one' ? '1440px' : '720px'}} x 960px
        / ไม่เกิน 5 Mb) | (ขนาดวีดิโอ : 1920px x 1080px / ไม่เกิน 30 Mb)
      </div>
    </div>
    }
    <div class="box-uploads">
      @for (item of details.controls; track item) {
      <div class="flex-1">
        <div class="upload-file-wrapper">
          <app-upload-file-flexible
            [type]="isEdit ? 'edit' : 'view'"
            [maxImageSize]="5"
            [maxVideoSize]="30"
            [reset]="reset"
            [isRequired]="item.controls['file_id'].touched && item.controls['file_id'].invalid"
            [logoDefault]="item.controls['logoDefault'].value"
            (upload)="item.controls['file_id'].setValue($event)" />
        </div>

        <label class="form-label mt-2" [ngClass]="{ 'form-label-view': isView }">เพิ่มลิงก์</label>
        @if (isEdit) {
        <app-select-add-link
          [trigger_name]="item.value.product_name || item.value.product_category_name || item.value.link_path"
          [controlLinkType]="item.controls['cms_link_list_id']"
          [product_id]="item.controls['product_id'].value"
          [product_detail_id]="item.controls['product_detail_id'].value"
          [product_category_id]="item.controls['product_category_id'].value"
          [link_path]="item.controls['link_path'].value"
          (onProductId)="item.controls['product_id'].setValue($event)"
          (onProductDetailId)="item.controls['product_detail_id'].setValue($event)"
          (onCategoryId)="item.controls['product_category_id'].setValue($event)"
          (onLinkPath)="item.controls['link_path'].setValue($event)" />
        } @else {
        <div>
          {{ linkTextView(item.controls['cms_link_list_id'].value, item.value.product_name,
          item.value.product_category_name, item.value.link_path) || '-' }}
        </div>
        }
      </div>
      }
    </div>
  </div>
</div>
