<div class="page-content pb-0">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">คอลเลกชัน</div>
  </div>

  <div class="title-wrapper">คอลเลกชัน</div>

  <div class="page-content-inner pb-0 d-flex flex-column">
    <div class="tab-box">
      <div class="box-left">
        <div class="box-top d-flex justify-content-between">
          <div class="d-flex align-items-center">คอลเลกชัน</div>
        </div>
        <div class="box-body">
          @for (collect of collectionList; track $index) {
          <div
            matRipple
            class="box d-flex justify-content-between align-items-center manage {{ $index != collectionList.length && 'mb-3' }}"
            [class.active]="collectionId == collect.id"
            (click)="setSelection(collect.id)">
            <div class="box-name d-flex justify-content-between align-items-center">
              <div class="text-truncate">{{collect.name}}</div>
            </div>

            <div class="icon-menu" [matMenuTriggerFor]="menu">
              <img alt="icon" class="mr-2" src="/assets/images/icons/icon-dot.svg" />
            </div>

            <mat-menu #menu="matMenu" xPosition="before">
              <div
                mat-menu-item
                class="item d-flex delete align-items-center text-danger"
                (click)="confirmDelete(collect.id, $index)">
                <img alt="icon" class="me-2" src="/assets/images/icons/icon-delete-red.svg" />
                ลบคอลเลกชัน
              </div>
            </mat-menu>
          </div>
          }
          <button
            class="btn btn-outline-secondary {{ collectionList.length && 'mt-3' }}"
            matRipple
            (click)="addCollection()">
            <img alt="icon" src="/assets/images/icons/icon-add.svg" />
            เพิ่มคอลเลกชัน
          </button>
        </div>
      </div>
      <div class="box-right">
        @for (collect of collectionList; track $index) {
        <!-- ~~ -->
        @if(collectionId == collect.id) {
        <div class="box-body right min-hight">
          @if (isView) {
          <div class="form-label color--neutral-500">
            วันที่อัปเดต: 02-04-2024, 19:00 อัปเดตโดย: กัญญา วงศ์วิไล
          </div>
          <div class="mb-3">
            <app-status [status]="3"> เผยแพร่แล้ว </app-status>
          </div>
          }

          <label
            class="form-label {{ isView && 'color--neutral-500'}}"
            [class.text-danger]="form.controls['content_th'].touched && form.controls['content_th'].invalid">
            หัวข้อคอนเทนต์ (ภาษาไทย)
          </label>

          @if (isEdit) {
          <app-input
            [control]="form.controls['content_th']"
            maxlength="100"
            remainingText="100">
            @if (form.controls['content_th'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            }
          </app-input>
          } @else {
          <div class="label-view mb-3">
            {{ form.value['content_th'] || '-' }}
          </div>
          }

          <label
            class="form-label {{ isView && 'color--neutral-500'}}"
            [class.text-danger]="form.controls['content_en'].touched && form.controls['content_en'].invalid">
            หัวข้อคอนเทนต์ (ภาษาอังกฤษ)
          </label>
          @if (isEdit) {
          <app-input
            [control]="form.controls['content_en']"
            maxlength="100"
            remainingText="100">
            @if (form.controls['content_en'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            }
          </app-input>
          } @else {
          <div class="label-view mb-3">
            {{ form.value['content_en'] || '-' }}
          </div>
          }

          <label
            class="form-label {{ isView && 'color--neutral-500'}}"
            [class.text-danger]="form.controls['description_th'].touched && form.controls['description_th'].invalid">
            คำอธิบายหัวข้อคอนเทนต์ (ภาษาไทย)
          </label>

          @if (isEdit) {
          <app-input
            [control]="form.controls['description_th']"
            maxlength="100"
            remainingText="100">
            @if (form.controls['description_th'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            }
          </app-input>
          } @else {
          <div class="label-view mb-3">
            {{ form.value['description_th'] || '-' }}
          </div>
          }

          <label
            class="form-label {{ isView && 'color--neutral-500'}}"
            [class.text-danger]="form.controls['description_en'].touched && form.controls['description_en'].invalid">
            คำอธิบายหัวข้อคอนเทนต์ (ภาษาอังกฤษ)
          </label>
          @if (isEdit) {
          <app-input
            [control]="form.controls['description_en']"
            maxlength="100"
            remainingText="100">
            @if (form.controls['description_en'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            }
          </app-input>
          } @else {
          <div class="label-view mb-3">
            {{ form.value['description_en'] || '-' }}
          </div>
          }
          <!-- ~~ -->
          @for (item of file_list.controls; track $index) {
          <div class="title-blue mt-2">ภาพที่ {{$index+1}}/9</div>

          <mat-radio-group [formControl]="item.controls['is_vertical']" class="d-flex align-items-center">
            <div class="box-radio me-2">
              <mat-radio-button [value]="true" [disabled]="isView">แนวตั้ง</mat-radio-button>
            </div>

            <div class="box-radio">
              <mat-radio-button [value]="false" [disabled]="isView">แนวนอน</mat-radio-button>
            </div>
          </mat-radio-group>

          @if (isEdit) {
            <div class="text-center font-14 color--neutral-500 mb-1">
              @if(item.controls['is_vertical'].value) { (ขนาดรูปภาพ : 355px x 470px / ไม่เกิน 5
              Mb) | (ขนาดวีดิโอ : 1920px x 1080px / ไม่เกิน 30 Mb) } @else { (ขนาดรูปภาพ : 710px x
              470px / ไม่เกิน 5 Mb) | (ขนาดวีดิโอ : 1920px x 1080px / ไม่เกิน 30 Mb) }
            </div>
          }
          <div class="d-flex justify-content-center">
            <div
              [style]="item.controls['is_vertical'].value ? 'width: 355px; height: 470px' : 'width: 710px; height: 470px'">
              <app-upload-file-flexible
                [type]="isEdit ? 'edit' : 'view'"
                [maxImageSize]="5"
                [maxVideoSize]="30"
                [isRequired]="item.controls['file_id'].touched && item.controls['file_id'].invalid"
                [logoDefault]="item.value.file"
                (upload)="item.controls['file_id'].setValue($event);" />
            </div>
          </div>
          }
          <!-- ~~ -->
          @if (file_list.controls.length <= 8 && isEdit) {
          <button class="btn btn-outline-secondary mt-4" matRipple (click)="addFiles($index)">
            <img alt="icon" src="/assets/images/icons/icon-add.svg" />
            เพิ่ม
          </button>
          }
        </div>
        }
        <!-- ~~ -->
        }
      </div>
    </div>

    <div class="page-footer-btn">
      @if (isView) {
      <div class="d-flex flex-wrap justify-content-end">
        <button
          matRipple
          class="btn btn-outline-secondary min-w-120px"
          [routerLink]="['/setting/cms/collection/edit']"
          [queryParams]="{id: collectionId}">
          แก้ไข
        </button>
      </div>
      } @else if(isEdit) {
      <div class="d-flex flex-wrap justify-content-end gap-2">
        <button matRipple class="btn btn-outline-secondary min-w-135px" (click)="openPreview()">
          ดูตัวอย่าง
        </button>
        <button matRipple class="btn btn-outline-secondary min-w-135px" (click)="onSave('private')">บันทึกร่าง</button>
        <button matRipple class="btn btn-primary min-w-135px" (click)="onSave('public')">เผยแพร่ทันที</button>
      </div>
      }
    </div>
  </div>
</div>
