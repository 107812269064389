<div class="top-content-wrapper">
  <div class="top-content-left">
    <app-search-filter-customer
      placeholder="ค้นหา"
      (onConfirm)="paginationCustomer.reset();getCustomerList();itemChecked.clear()"></app-search-filter-customer>
  </div>
  <div class="top-content-right mb-3">
    @if (profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER], [ACTION_ACCESS.EXPORT])) {
    <button
      matRipple
      class="btn btn-outline-secondary"
      (click)="openModalDownloadFile('ดาวน์โหลดไฟล์รายชื่อ', { message: 'คุณต้องการดาวน์โหลดไฟล์รายชื่อที่เลือกใช่หรือไม่' })">
      <span class="icon material-symbols-outlined fill">download_2</span>
      ส่งออกไฟล์
    </button>
    }
    <!-- ~ -->
    @if (profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER], [ACTION_ACCESS.IMPORT])) {
    <button
      matRipple
      class="btn btn-outline-secondary"
      (click)="openModalUploadFile('เพิ่มข้อมูลลูกค้า')">
      <span class="icon material-symbols-outlined fill">upload_2</span>
      นำเข้าข้อมูล
    </button>
    }
    <!-- ~ -->
    @if (profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER], [ACTION_ACCESS.CREATE])) {
    <button matRipple class="btn btn-primary" [routerLink]="['/customer/create']">
      <span class="icon material-symbols-outlined fill">add_circle</span>
      เพิ่มลูกค้าใหม่
    </button>
    }
  </div>
</div>

<div class="table-responsive table-main-tabs-wrapper">
  <table class="table table-fixed-column">
    <thead>
      <tr>
        @if (profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER], [ACTION_ACCESS.EXPORT])) {
        <th width="70">
          <app-checkbox-all-table
            [checkedValue]="itemChecked"
            [datas]="customerList"
            fieldValue="id"></app-checkbox-all-table>
        </th>
        }
        <th width="200">ชื่อ</th>
        <th width="200">นามสกุล</th>
        <th width="180">ระดับสมาชิก</th>
        <th width="140" class="text-end">เครดิต</th>
        <th width="200">เบอร์โทรศัพท์/โซเชียล</th>
        <th width="160">อีเมล</th>
        <th width="170" class="text-end">ยอดการสั่งซื้อ (บาท)</th>
        <th width="140" class="text-end">ยกเลิก (บาท)</th>
        <th width="150">อัปเดตโดย</th>
        <th width="170">อัปเดตล่าสุด</th>
        <th width="120" class="fixed box-shadow-left text-center">จัดการ</th>
      </tr>
    </thead>

    <tbody>
      @for (item of customerList; track $index) {
      <tr>
        @if (profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER], [ACTION_ACCESS.EXPORT])) {
        <td>
          <app-checkbox-table [checkedValue]="itemChecked" [value]="item.id"></app-checkbox-table>
        </td>
        }
        <td>
          <div>{{ item.name }}</div>
          <div class="cus_code">{{ item.code }}</div>
        </td>
        <td>
          <div>{{ item.last_name }}</div>
        </td>
        <td>
          <div>{{ item.currenTier?.name || '-' }}</div>
          <div>
            กะรัต
            <span class="green">{{ formatPrice(item.carat) }}</span>
          </div>
        </td>
        <td class="text-end">
          <span class="green">{{ formatPrice(item.credit) }}</span>
        </td>
        <td>
          @if (item.telephone) {
          <div>
            <img src="/assets/images/icons/icon-social-tel.svg" class="me-1" />
            {{ item.telephone }}
          </div>
          }
          <!-- ~ -->
          @for (itemS of item.channels; track $index) {
          <div>
            @if (itemS.channel_id) {
            <img
              src="/assets/images/icons/icon-social-{{ channelIcon[itemS.channel_id] }}.svg"
              class="me-1" />
            <span class="name-channel" [innerHTML]="itemS.name"></span>
            }
          </div>
          }
        </td>
        <td class="text-ellipsis">{{ item.email }}</td>
        <td class="text-end">
          <span class="blue">{{ formatPrice(item.total_price) }}</span>
        </td>
        <td class="text-end">
          <span class="red">{{ formatPrice(item.cancel_amount) }}</span>
        </td>
        <td>{{item.updated_by ? item.updated_by.full_name : ''}}</td>
        <td>{{Moment(item.updated_at).format('DD-MM-YYYY, HH:mm')}}</td>
        <td class="fixed box-shadow-left">
          <div class="d-flex justify-content-center align-items-center">
            @if (item.action.view) {
            <button
              matRipple
              matTooltip="ดู"
              matTooltipPosition="above"
              class="btn btn-outline-secondary btn-icon me-2"
              [routerLink]="['/customer/view/' + item.id]">
              <span class="icon material-symbols-outlined fill">visibility</span>
            </button>
            } @if (item.action.edit) {
            <button
              matRipple
              matTooltip="แก้ไข"
              matTooltipPosition="above"
              class="btn btn-outline-secondary btn-icon"
              [routerLink]="['/customer/edit/' + item.id]">
              <span class="icon material-symbols-outlined fill">edit</span>
            </button>
            }
          </div>
        </td>
      </tr>
      }
    </tbody>
  </table>

  @if(!customerList.length) {
  <div class="data-not-found-wrapper">ไม่มีข้อมูลลูกค้า กรุณาเพิ่มลูกค้า</div>
  }
</div>

<!-- Pagination -->
@if (customerList.length) {
<div class="pagination-wrapper">
  <mat-paginator
    [pageIndex]="paginationCustomer.data.pageIndex"
    [length]="paginationCustomer.data.length"
    [pageSize]="paginationCustomer.data.pageSize"
    [pageSizeOptions]="paginationCustomer.data.pageSizeOptions"
    aria-label="Select page"
    (page)="paginationCustomer.setFromPaginator($event);getCustomerList()">
  </mat-paginator>
</div>
}
