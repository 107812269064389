<div class="page-content page-carat">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">เพิ่มการวัดขนาดสินค้า</div>
  </div>

  <div class="title-wrapper">เพิ่มการวัดขนาดสินค้า</div>

  <div class="page-content-inner d-flex flex-column">
    <div class="title-blue mt-3">รายละเอียด</div>
    <div class="row">
      <div class="col-12 col-md-12">
        <label class="form-label mt-3">
          ชื่อการวัดขนาดสินค้า <span class="text-danger">*</span>
        </label>
        <app-input [control]="form.controls['name_measure']" remainingText="100"></app-input>
      </div>
      <div class="col-12 col-md-4">
        <div class="text-center font-14 color--neutral-500 mt-1 mb-3">
          (ขนาดรูปภาพ : 210px x 280px / ไม่เกิน 5Mb)
        </div>
        <div class="d-flex justify-content-center">
          <div [style]="'width: 210px; height: 280px'">
            <app-upload-file-flexible
              [type]="!isView ? 'edit' : 'view'"
              [maxImageSize]="5"
              [maxVideoSize]="30"
              [imgOnly]="true"
              (upload)="form.controls['file'].setValue($event)" />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <label class="form-label"> รายละเอียด (ภาษาไทย) </label>
        <app-text-area [control]="form.controls['description_th']" remainingText="300">
        </app-text-area>

        <label class="form-label"> รายละเอียด (ภาษาอังกฤษ) </label>
        <app-text-area [control]="form.controls['description_en']" remainingText="300">
        </app-text-area>
      </div>

      <div class="col-12 col-md-12">
        <label class="form-label mt-3">
          หัวข้อ (ภาษาไทย)
        </label>
        <app-input [control]="form.controls['title_th']" remainingText="100"></app-input>
      </div>
      <div class="col-12 col-md-12">
        <label class="form-label mt-3">
          หัวข้อ (ภาษาอังกฤษ)
        </label>
        <app-input [control]="form.controls['title_en']" remainingText="100"></app-input>
      </div>
      <div class="col-12 col-md-12">
        <label class="form-label mt-3">
          คำอธิบายหัวข้อ (ภาษาไทย)
        </label>
        <app-input [control]="form.controls['description_title_th']" remainingText="100"></app-input>
      </div>
      <div class="col-12 col-md-12">
        <label class="form-label mt-3">
          คำอธิบายหัวข้อ (ภาษาอังกฤษ)
        </label>
        <app-input [control]="form.controls['description_title_en']" remainingText="100"></app-input>
      </div>
    </div>

    <div class="title-blue mt-3">ตารางข้อมูล</div>
    <label class="form-label mt-3">
      หน่วยที่ใช้
    </label>
    <mat-radio-group [formControl]="form.controls['unit']" class="d-flex align-items-center">
      <div class="me-2">
        <mat-radio-button value="cm" [disabled]="isView">เซนติเมตร (ซม.)</mat-radio-button>
      </div>
      <div class="">
        <mat-radio-button value="inch" [disabled]="isView">นิ้ว</mat-radio-button>
      </div>
    </mat-radio-group>
    
    <app-table-size></app-table-size>

    <div class="title-blue mt-3">
      รายการสินค้า ({{ form.controls['product_list'].value?.length }})
    </div>
    <div class="d-flex justify-content-between flex-wrap gap-2 mt-2">
      <app-search [control]="search" placeholder="ค้นหา" (onEnter)="paginationMeasure.reset()">
      </app-search>
      <button matRipple class="btn btn-outline-danger">
        <span class="icon material-symbols-outlined fill">delete</span>
        ลบข้อมูล (0)
      </button>
    </div>

    <div class="table-responsive table-main-tabs-wrapper mt-3">
      <table class="table table-fixed-column">
        <thead>
          <tr>
            <th width="50">
              <app-checkbox-all-table
                [checkedValue]="itemChecked"
                [datas]="productList"
                fieldValue="id"></app-checkbox-all-table>
            </th>
            <th width="90"></th>
            <th width="320">รหัสสินค้า</th>
            <th width="350">ชื่อสินค้า</th>
            <th width="130" class="text-end">ราคา (บาท)</th>
            @if (!isView) {
            <th width="100" class="fixed box-shadow-left"></th>
            }
          </tr>
        </thead>

        <tbody>
          @for (item of form.controls['product_list'].value; track $index) {
          <tr>
            <td width="50">
              <app-checkbox-table
                [checkedValue]="itemChecked"
                [value]="item?.['id']"></app-checkbox-table>
            </td>
            <td width="90">
              <img [src]="item['img']" />
            </td>
            <td width="320">{{ item['code'] }}</td>
            <td width="350">
              <div>{{item['name']}}</div>
              <div class="product-detail">
                <div class="tag-color" [ngStyle]="{'background-color': item['color']}"></div>
                <div>{{item['color_name']}}, {{item['size']}}</div>
              </div>
            </td>
            <td width="130" class="text-end">{{ item['price'] }}</td>
            @if (!isView) {
            <td width="100" class="fixed box-shadow-left">
              <div class="w-100 d-flex justify-content-center">
                <button
                  matRipple
                  class="btn btn-outline-danger"
                  (click)="deleteProduct(form.controls['product_list'], item['id'])">
                  <span class="icon material-symbols-outlined fill">delete</span>
                </button>
              </div>
            </td>
            }
          </tr>
          }
        </tbody>
      </table>

      @if (!form.controls['product_list'].value?.length) {
      <div class="data-not-found-wrapper flex-column align-items-center">
        <div>ไม่พบสินค้า กรุณาเลือกสินค้า</div>
        <button
          class="btn btn-primary me-1 mt-4 w-fit"
          (click)="addProduct(form.controls['product_list'])">
          <span class="icon material-symbols-outlined fill"> add_circle </span>
          เลือกสินค้า
        </button>
      </div>
      }
    </div>

    <div class="pagination-wrapper d-flex flex-wrap gap-2">
      <mat-paginator
        [pageIndex]="paginationMeasure.data.pageIndex"
        [length]="paginationMeasure.data.length"
        [pageSize]="paginationMeasure.data.pageSize"
        [pageSizeOptions]="paginationMeasure.data.pageSizeOptions"
        (page)="paginationMeasure.setFromPaginator($event);"
        aria-label="Select page">
      </mat-paginator>
      <div class="d-flex align-items-center gap-2 mat-mdc-paginator">
        <span>ไปที่หน้า</span>
        <div class="font-12" [style]="{ 'min-width': '70px', 'max-width': '70px' }">
          @if (!rerender) {
          <app-input-number
            [control]="pageNumber"
            placeholder="0"
            [min]="1"
            [max]="paginationMeasure.totalPage || 1"
            [remove_bottom]="true"
            (onEnter)="onChangePage()"
            (onblur)="onChangePage(true)">
          </app-input-number>
          }
        </div>
        / {{ formatPriceAutoDecimal(paginationMeasure.totalPage) }}
      </div>
    </div>
  </div>
</div>
