import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core'
import { Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { UploadFileFlexibleComponent } from '../../../components/upload-file-flexible/upload-file-flexible'
import { SelectAddLinkComponent } from '../../../components/select-add-link/select-add-link'
import { ModalPreviewComponent } from '../../../components/modal-preview/modal-preview'
import { MatMenuModule } from '@angular/material/menu'
import { StatusComponent } from '../../../components/status/status'
import { DomSanitizer } from '@angular/platform-browser'
import { MatTable, MatTableModule } from '@angular/material/table'

@Component({
  selector: 'app-test',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    UploadFileFlexibleComponent,
    SelectAddLinkComponent,
    MatMenuModule,
    StatusComponent,
    MatTableModule
  ],
  templateUrl: './test.html',
  styleUrls: ['./test.scss'],
})
export class TestComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment

  displayedColumns: string[] = ["x0", "x1"];
  displayedHead: string[] = ["", ""];
  displayedFields: string[] = ["x0", "x1"];
  columnSelect = -1;
  myformArray = new FormArray([
    new FormGroup({
      x0: new FormControl(""),
      x1: new FormControl(""),
    }),
  ]);
  dataSource = this.myformArray.controls;
  // @ViewChildren(ArrowDivDirective) inputs: QueryList<ArrowDivDirective>

  constructor(
    // private keyboardService: KeyBoardService

  ) { }
  ngOnInit() {
    // this.keyboardService.keyBoard.subscribe(res => {
    //   this.move(res)
    // })
  }

  selectColumn(index: number, inputField: any) {
    this.columnSelect = index;
    setTimeout(() => {
      inputField.focus();
    });
  }
  changeColumnName(index: any, columnTitle: any) {
    if (columnTitle) {
      const oldName = this.displayedFields[index];
      const columnName = columnTitle.replace(/ /g, "").toLowerCase();
      if (columnName != oldName) {
        this.myformArray.controls.forEach((group:FormGroup)=>{
            group.addControl(columnName,new FormControl(group.value[oldName]))
            group.removeControl(oldName)
        })
        this.displayedHead.splice(index, 1, columnTitle);
        this.displayedColumns.splice(index, 1, columnName);
        this.displayedFields.splice(index, 1, columnName);
      }
      this.columnSelect = -1;
    }

  }
  deleteRow(index: number) {
    this.myformArray.removeAt(index);
    this.dataSource = [...this.myformArray.controls];
  }
  addRow() {
    const newGroup = new FormGroup({});
    this.displayedFields.forEach(x => {
      newGroup.addControl(x, new FormControl());
    });
    this.myformArray.push(newGroup as FormGroup);

    this.dataSource = [...this.myformArray.controls];
    console.log('Row', this.dataSource);
  }
  addColumn() {
    let newField = "x" + (this.displayedFields.length);

    this.myformArray.controls.forEach((group: FormGroup) => {
      group.addControl(newField, new FormControl());
    });
    this.displayedHead.push("");
    this.dataSource = [...this.myformArray.controls];
    this.displayedFields.push(newField);
    this.displayedColumns = [...this.displayedFields];

    console.log('Column', this.dataSource);
  }

  deleteColumn(i: any) {
    let delField = "x" + i;    
    this.myformArray.controls.forEach((group: FormGroup) => {
      group.removeControl(delField);
    });
    this.displayedHead.splice(i, 1);
    this.dataSource = [...this.myformArray.controls];
    this.displayedFields.splice(i, 1);
    this.displayedColumns = [...this.displayedFields];
  }

  // move(object: any) {
  //   const inputToArray = this.inputs.toArray()
  //   const rows = this.dataSource.length
  //   const cols = this.displayedColumns.length
  //   let index = inputToArray.findIndex(x => x.element === object.element)
  //   switch (object.action) {
  //     case "UP":
  //       index--;
  //       break;
  //     case "DOWN":
  //       index++;
  //       break;
  //     case "LEFT":
  //       if (index - rows >= 0)
  //         index -= rows;
  //       else {
  //         let rowActual = index % rows;
  //         if (rowActual > 0)
  //           index = (rowActual - 1) + (cols - 1) * rows;
  //       }
  //       break;
  //     case "RIGTH":
  //       console.log(index + rows, inputToArray.length)
  //       if (index + rows < inputToArray.length)
  //         index += rows;
  //       else {
  //         let rowActual = index % rows;
  //         if (rowActual < rows - 1)
  //           index = (rowActual + 1);

  //       }
  //       break;
  //   }
  //   if (index >= 0 && index < this.inputs.length) {
  //     inputToArray[index].element.nativeElement.focus();
  //   }
  // }
 
}
