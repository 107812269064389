import { Component, OnInit } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { imports } from '../../../../imports'
import { MatTableModule } from '@angular/material/table'
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-table-size',
  standalone: true,
  imports: [
    ...imports,
    MatTableModule,
  ],
  templateUrl: './table-size.html',
  styleUrls: ['./table-size.scss'],
})
export class TableSizeComponent implements OnInit {

  displayedColumns: string[] = ["x0", "x1"];
  displayedHead: string[] = ["", ""];
  displayedFields: string[] = ["x0", "x1"];
  columnSelect = -1;
  tableArray = new FormArray([
    new FormGroup({
      x0: new FormControl(""),
      x1: new FormControl(""),
    }),
  ]);
  dataSource = this.tableArray.controls;

  constructor() { }

  ngOnInit() { }

  selectColumn(index: number, inputField: any) {
    this.columnSelect = index;
    setTimeout(() => {
      inputField.focus();
    });
  }

  changeColumnName(index: any, columnTitle: any) {
    if (columnTitle) {
      const oldName = this.displayedFields[index];
      const columnName = columnTitle.replace(/ /g, "").toLowerCase();
      if (columnName != oldName) {
        this.tableArray.controls.forEach((group: FormGroup) => {
          group.addControl(columnName, new FormControl(group.value[oldName]))
          group.removeControl(oldName)
        })
        this.displayedHead.splice(index, 1, columnTitle);
        this.displayedColumns.splice(index, 1, columnName);
        this.displayedFields.splice(index, 1, columnName);
      }
      this.columnSelect = -1;
    }

  }
  deleteRow(index: number) {
    this.tableArray.removeAt(index);
    this.dataSource = [...this.tableArray.controls];
  }
  addRow() {
    const newGroup = new FormGroup({});
    this.displayedFields.forEach(x => {
      newGroup.addControl(x, new FormControl());
    });
    this.tableArray.push(newGroup as FormGroup);

    this.dataSource = [...this.tableArray.controls];
    console.log('Row', this.dataSource);
  }
  
  addColumn() {
    let newField = "x" + (this.displayedFields.length);

    this.tableArray.controls.forEach((group: FormGroup) => {
      group.addControl(newField, new FormControl());
    });
    this.displayedHead.push("");
    this.dataSource = [...this.tableArray.controls];
    this.displayedFields.push(newField);
    this.displayedColumns = [...this.displayedFields];

    console.log('Column', this.dataSource);
  }

  deleteColumn(i: any) {
    let delField = "x" + i;
    this.tableArray.controls.forEach((group: FormGroup) => {
      group.removeControl(delField);
    });
    this.displayedHead.splice(i, 1);
    this.dataSource = [...this.tableArray.controls];
    this.displayedFields.splice(i, 1);
    this.displayedColumns = [...this.displayedFields];
  }
}
