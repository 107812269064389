import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { MatTabsModule } from '@angular/material/tabs'

@Component({
  selector: 'app-carats',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    MatTabsModule
  ],
  templateUrl: './cms-product-details.html',
  styleUrls: ['./cms-product-details.scss'],
})
export class CMSProductDetailsComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false
  dataManage: any
  id: any = 1
  tab: number = 0

 
  constructor(
    public profile: Profile,
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public caratService: CaratService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      if (params.tab) {
        this.tab = params.tab
      }
    })
    this.initData()
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
    this.navigateToTab(this.tab)
  }

  navigateToTab(tab: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      skipLocationChange: false,
    })
  }

  initData() {
    this.profile.getProfile() // add for permission
    const apis = [this.caratService.getCarat(this.id)]

    // this.loading.start()
    // forkJoin(apis).subscribe(([resCarat]: any) => {
    //   if (resCarat) {
    //     if (!resCarat.is_error) {
    //       this.dataManage = resCarat.data

    //       this.form.reset({
    //         carat: resCarat.data.carat || 1,
    //         total_order_amount: resCarat.data.total_order_amount,
    //         order_start_date: resCarat.data.order_start_date,
    //         order_end_date: resCarat.data.order_end_date,
    //         value_amount: resCarat.data.value_amount,
    //         // value_start_date: resCarat.data.value_start_date,
    //         // value_end_date: resCarat.data.value_end_date,
    //       })
    //       this.discounts.clear()
    //       const discounts = (this.dataManage.discounts || []).sort((a: any, b: any) =>
    //         a.sort > b.sort ? 1 : -1
    //       )
    //       for (const discount of discounts) {
    //         this.addCaratDiscount(discount)
    //       }
    //     } else {
    //       this.customSnackBar.fail(resCarat.message)
    //     }
    //   }

    //   this.loading.stop()
    // })
  }

  confirmDelete() {
    
  }
}
