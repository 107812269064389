<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">เพิ่มรูปสินค้า</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="d-flex flex-column flex-lg-row gap-4">
      <div class="">
        <label class="form-label">รูปภาพหน้าปก</label>

        <div class="section-upload-container">
          <div class="upload-file-wrapper mb-1">
            <app-upload-file-flexible
              [type]="data.isEdit ? 'edit': 'view'"
              [maxImageSize]="5"
              [imgOnly]="true"
              [logoDefault]="form.controls['cover'].value?.url"
              (uploadData)="form.controls['cover'].setValue($event?.[0])" />
          </div>
        </div>
      </div>
      <div class="flex-1">
        <span class="font-14"
          >รูปภาพเพิ่มเติม
          <span class="gray">(จำนวน 9)</span>
        </span>
        <app-upload-image-more
          [type]="data.isEdit ? 'edit' : 'view'"
          [control]="form.controls.more"
          (upload)="form.controls.more.setValue($event)" />
        <div class="font-14 gray">หมายเหตุ: ขนาดไฟล์รูปไม่เกิน 30 Mb</div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">บันทึก</button>
  </div>
</mat-dialog-actions>
