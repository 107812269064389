import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import { EmailReg, ValidateForm, setErrorForm } from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'

const validateTh = (control: AbstractControl) => {
  if (control.value && /[a-zA-Z]/g.test(control.value)) {
    return {
      patternCha: true,
    }
  }

  return null
}
const validateEn = (control: AbstractControl) => {
  if (control.value && /[ก-ฮ]/g.test(control.value)) {
    return {
      patternCha: true,
    }
  }

  return null
}

@Component({
  selector: 'app-company-manage',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
  ],
  templateUrl: './company-manage.html',
  styleUrls: ['./company-manage.scss'],
})
export class CompanyManagementComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS

  isEdit: boolean = false
  isView: boolean = false
  dataManage: any

  form = new FormGroup({
    code: new FormControl('', [Validators.required, Validators.maxLength(10)]),
    name: new FormControl('', [Validators.required, Validators.maxLength(100), validateTh]),
    name_en: new FormControl('', [Validators.required, Validators.maxLength(100), validateEn]),
    address: new FormControl('', [Validators.required]),
    address_en: new FormControl('', [Validators.required]),
    tax_id: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{13,13}$/)]),
    telephone: new FormControl('', [
      Validators.nullValidator,
      Validators.pattern(/^[0-9]{10,10}$/),
    ]),
    fax: new FormControl('', [Validators.nullValidator, Validators.maxLength(100)]),
    email: new FormControl('', [Validators.pattern(EmailReg), Validators.maxLength(100)]),
    logo_id: new FormControl(null),

    sender_name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    sender_telephone: new FormControl('', [
      Validators.nullValidator,
      Validators.pattern(/^[0-9]{10,10}$/),
    ]),
    sender_address: new FormControl('', [Validators.required]),
  })

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    this.initData()
  }

  initData() {
    const apis = [this.companyProfileService.getCompanyProfile()]

    this.loading.start()
    forkJoin(apis).subscribe(([resCompanyProfile]: any) => {
      if (resCompanyProfile) {
        if (!resCompanyProfile.is_error) {
          this.dataManage = resCompanyProfile.data
          this.form.reset({
            code: resCompanyProfile.data.code,
            name: resCompanyProfile.data.name,
            name_en: resCompanyProfile.data.name_en,
            address: resCompanyProfile.data.address,
            address_en: resCompanyProfile.data.address_en,
            tax_id: resCompanyProfile.data.tax_id,
            telephone: resCompanyProfile.data.telephone,
            fax: resCompanyProfile.data.fax,
            email: resCompanyProfile.data.email,
            logo_id: resCompanyProfile.data.logo_id,
            sender_name: resCompanyProfile.data.sender_name,
            sender_telephone: resCompanyProfile.data.sender_telephone,
            sender_address: resCompanyProfile.data.sender_address,
          })
        } else {
          this.customSnackBar.fail(resCompanyProfile.message)
        }
      }

      this.loading.stop()
    })
  }

  confirmSave(): void {
    ValidateForm(this.form)
    if (!this.form.valid) return

    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.onSave()
      }
    })
  }

  onSave(): void {
    const value = this.form.getRawValue()
    const payload = {
      code: value.code,
      name: value.name,
      name_en: value.name_en,
      address: value.address,
      address_en: value.address_en,
      tax_id: value.tax_id,
      telephone: value.telephone,
      fax: value.fax,
      email: value.email,
      logo_id: value.logo_id,
      sender_name: value.sender_name,
      sender_telephone: value.sender_telephone,
      sender_address: value.sender_address,
    }

    // return console.log(payload)

    this.loading.start()
    this.companyProfileService.updateCompanyProfile(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
          this.isSaved = true
          this.toView()
        } else {
          if (res.errors[0]?.field) {
            setErrorForm(this.form, res.errors)
          } else {
            this.customSnackBar.failSave(res.message)
          }
        }
      }
      this.loading.stop()
    })
  }

  toView() {
    this.router.navigate(['/setting/company-manage/view'])
  }

  isSaved = false
  openModalCancel(resolveFromRouter: (value: boolean) => void): void {
    if (this.isEdit && this.form.dirty && !this.isSaved) {
      const dialogCancel = this.dialog.open(ModalLeaveComponent, {
        data: {},
      })

      dialogCancel.afterClosed().subscribe(result => {
        resolveFromRouter(!!result)
      })
    } else {
      resolveFromRouter(true)
    }
  }
}
