import { Injectable, inject } from '@angular/core'
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { COMPANY_ID, COMPANY_NAME, EnumCompanyId, Logout, getLocalStorage } from '../helpers'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class Service {
  public API_URL: any = environment.apiUrl
  companies: number[] = []
  companyId: EnumCompanyId = COMPANY_ID.BHB

  get companyName() {
    return COMPANY_NAME[this.companyId]
  }

  constructor(private http: HttpClient) {
    this.setCompanyId(getLocalStorage('company-id'))
  }

  setCompanyId(companyId: any) {
    if ([COMPANY_ID.BHB, COMPANY_ID.PANIA].includes(companyId)) {
      this.companyId = companyId
    }
  }

  headers() {
    const headers: any = {
      lang: 'th',
      'company-id': this.companyId,
    }
    const access_token = getLocalStorage('access_token')
    if (access_token) {
      headers.Authorization = 'Bearer ' + access_token
    }
    const lazada_code = getLocalStorage('lazada_code')
    if (lazada_code) {
      headers['Lazada-Code'] = lazada_code
    }
    return headers
  }

  get(path: string, httpParams: any = {}): Observable<any> {
    const url = `${this.API_URL}${path}`
    const httpOptions = {
      params: httpParams,
      headers: {
        ...this.headers(),
      },
    }
    return this.http.get<any>(url, httpOptions).pipe(
      map(res => {
        return this.validateResponse(res, url, 'GET', httpParams)
      }),
      catchError(this.handleError<any>(url))
    )
  }

  getBlob(path: string, httpParams: any = {}): Observable<any> {
    const url = `${this.API_URL}${path}`
    const httpOptions: any = {
      params: httpParams,
      responseType: 'blob',
      observe: 'response',
      headers: {
        ...this.headers(),
      },
    }
    return this.http.get<Blob>(url, httpOptions).pipe(
      map((res: any) => {
        return this.validateResponse(res, url, 'GET', httpParams)
      }),
      catchError(this.handleError<any>(url))
    )
  }

  saveFile(path: string, httpParams: any = {}, fileName?: string) {
    return this.getBlob(path, httpParams).pipe(
      map((res: HttpResponse<any>) => {
        const url = window.URL.createObjectURL(res.body)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = fileName || 'file'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
        return res
      })
    )
  }

  getFileName(headers: HttpHeaders) {
    const contentDisposition = headers.get('Content-Disposition')
    const [, filename] = contentDisposition?.match(/filename=(.+)/) || []
    return filename?.replace(/['"]/g, '') || ''
  }

  post(path: string, data: any): Observable<any> {
    // this.global.setLoading(true);
    const url = `${this.API_URL}${path}`
    const httpOptions = {
      params: data.queryParams,
      headers: {
        ...this.headers(),
      },
    }
    return this.http.post<any>(url, data, httpOptions).pipe(
      map(res => {
        return this.validateResponse(res, url, 'POST', data)
      }),
      catchError(this.handleError<any>(url))
    )
  }

  postBlob(path: string, data: any): Observable<any> {
    const url = `${this.API_URL}${path}`
    const httpOptions: any = {
      responseType: 'blob',
      observe: 'response',
      headers: {
        ...this.headers(),
      },
    }
    return this.http.post<Blob>(url, data, httpOptions).pipe(
      map((res: any) => {
        return this.validateResponse(res, url, 'POST', data)
      }),
      catchError(this.handleError<any>(url))
    )
  }

  uploadFile(path: string, file: any): Observable<any> {
    const url = `${this.API_URL}${path}`
    const httpOptions = {
      headers: {
        ...this.headers(),
      },
    }
    const formData = new FormData()
    formData.append('files', file)
    return this.http.post(url, formData, httpOptions).pipe(
      map(res => {
        return this.validateResponse(res, url, 'POST Upload', formData)
      }),
      catchError(this.handleError<any>(url))
    )
  }

  uploadMultipleFile(path: string, file: any): Observable<any> {
    const url = `${this.API_URL}${path}`
    const httpOptions: any = {
      headers: {
        ...this.headers(),
      },
    }
    const formData = new FormData()
    for (let index = 0; index < file.length; index++) {
      formData.append(`files`, file[index], file[index].name)
    }

    return this.http.post(url, formData, httpOptions).pipe(
      map(res => {
        return this.validateResponse(res, url, 'POST Upload', formData)
      }),
      catchError(this.handleError<any>(url))
    )
  }

  put(path: string, data: any): Observable<any> {
    const url = `${this.API_URL}${path}`
    const httpOptions = {
      params: data.queryParams,
      headers: {
        ...this.headers(),
      },
    }
    return this.http.put<any>(url, data, httpOptions).pipe(
      map(res => {
        return this.validateResponse(res, url, 'PUT', data)
      }),
      catchError(this.handleError<any>(url))
    )
  }

  delete(path: string, httpParams: any = {}): Observable<any> {
    const url = `${this.API_URL}${path}`
    const httpOptions = {
      params: httpParams,
      headers: {
        ...this.headers(),
      },
    }
    return this.http.delete<any>(url, httpOptions).pipe(
      map(res => {
        return this.validateResponse(res, url, 'DELETE', httpParams)
      }),
      catchError(this.handleError<any>(url))
    )
  }

  validateResponse(res: any, url: string, type: string, payload: any): any {
    if (res.status === 'success') {
      return res
    } else if (res.status === 'error') {
      return res
    }
    return res
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error)

      if (error.status === 401) {
        setTimeout(() => {
          // Logout()
          let redirect = '/login'
          if (location.href != '/login') {
            redirect += '?redirect=' + location.href
          }
          location.href = redirect
        }, 1000)
      } else if (error.status === 500) {
        // this.global.setToast('Error', error.error.error_message, 'error');
        // this.global.setLoading(false);
      } else {
        // this.global.setToast(
        //   'Error',
        //   this.translate.instant('error.' + error?.error?.error_message_code),
        //   'error'
        // );
        // this.global.setLoading(false);
      }

      result = {
        is_error: true,
        errors: [],
        ...error.error,
        status: error.status,
      }
      // this.global.setLoading(false);
      return of(result as T)
    }
  }
}
