<div class="page-content pb-0">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">แคมเปญ</div>
  </div>

  <div class="title-wrapper">แคมเปญ</div>

  <div class="page-content-inner d-flex flex-column pb-0">
    <div class="section-container">
      @if (isView && dataManage) {
      <div class="form-label color--neutral-500">
        วันที่อัปเดต: {{ Moment(dataManage?.updated_at).format('DD-MM-YYYY, HH:mm') }} อัปเดตโดย: {{
        dataManage?.updated_by?.full_name || '-' }}
      </div>
      <div class="mb-3">
        <app-status [status]="dataManage?.is_public ? 'public' : 'not_public'">
          {{dataManage?.is_public ? 'เผยแพร่แล้ว' : 'ยังไม่เผยแพร่'}}
        </app-status>
      </div>
      }

      <div class="section-wrapper">
        <div class="title-blue mb-3">ภาพแบนเนอร์หลัก</div>

        <div class="row">
          <div class="col-12 mb-4">
            <label class="form-label">ภาพแบนเนอร์</label>

            <div class="section-upload-container">
              <div class="remark my-2">
                (ขนาดรูปภาพ : 1440px x 1024px / ไม่เกิน 5 Mb) | (ขนาดวีดิโอ : 1920px x 1080px /
                ไม่เกิน 30 Mb)
              </div>
              <div class="upload-file-wrapper mb-4">
                <app-upload-file-flexible
                  [type]="isEdit ? 'edit' : 'view'"
                  [maxImageSize]="5"
                  [maxVideoSize]="30"
                  (upload)="form.controls['banner_file'].setValue($event)" />
              </div>

              <label class="form-label mt-2">เพิ่มลิงก์</label>
              <app-select-add-link [controlLinkType]="form.controls['banner_link_type']" />
            </div>
          </div>

          <div class="col-12 mb-4">
            <div class="title-blue mb-3">ภาพแบนเนอร์ย่อย</div>

            <label class="form-label">ภาพแบนเนอร์</label>

            <div class="section-upload-container">
              <div class="remark my-2">(ขนาดรูปภาพ : 1440px x 220px / ไม่เกิน 5 Mb)</div>
              <div class="upload-image-wrapper mb-4">
                <app-upload-file-flexible
                  [imgOnly]="true"
                  [type]="isEdit ? 'edit' : 'view'"
                  [maxImageSize]="5"
                  [maxVideoSize]="30"
                  (upload)="form.controls['banner_file'].setValue($event)" />
              </div>
            </div>
          </div>

          <div class="col-12 mb-4">
            @for (item of sectionProductArr.controls; track $index) {
            <app-section-product
              [form]="item"
              [sectionIndex]="$index"
              (deleteSection)="deleteSection($event)"
              [showButtonDelete]="sectionProductArr.value.length > 1" />
            }
          </div>
        </div>
      </div>
    </div>

    <div class="page-footer-btn">
      @if (isView) {
      <div class="d-flex flex-wrap justify-content-end">
        <button
          matRipple
          class="btn btn-outline-secondary min-w-120px"
          [routerLink]="['/setting/cms/campaign/edit']">
          แก้ไข
        </button>
      </div>
      } @else if(isEdit) {
      <div class="d-flex flex-wrap justify-content-between">
        <div>
          <button matRipple class="btn btn-outline-danger min-w-135px">ล้างข้อมูล</button>
        </div>

        <div class="d-flex gap-2">
          <button matRipple class="btn btn-outline-secondary min-w-135px" (click)="openPreview()">
            ดูตัวอย่าง
          </button>
          <button
            matRipple
            (click)="onSave('private')"
            class="btn btn-outline-secondary min-w-135px">
            บันทึกร่าง
          </button>
          <button matRipple (click)="onSave('public')" class="btn btn-primary min-w-135px">
            เผยแพร่ทันที
          </button>
        </div>
      </div>
      }
    </div>
  </div>
</div>
