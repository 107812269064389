import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  formatPrice,
} from '../../../../../helpers'
import { UploadImageProfileComponent } from '../../../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../../../services'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../../../components/date-picker/date-picker'
import { UploadFileFlexibleComponent } from '../../../../../components/upload-file-flexible/upload-file-flexible'
import { RadioLayoutComponent } from '../../../../../components/radio-layout/radio-layout'
import { MatRadioModule } from '@angular/material/radio'
import { SelectComponent } from '../../../../../components/select/select'
import { TextareaComponent } from '../../../../../components/text-area/text-area'
import { InputComponent } from '../../../../../components/input/input'
import { imports } from '../../../../../imports'
import { ModalDeleteComponent } from '../../../../../components/modal-delete/modal-delete'
import { ModalSelectProductSingleComponent } from '../../../../../components/modal-select-product-single/modal-select-product-single'
import { SelectAddLinkComponent } from '../../../../../components/select-add-link/select-add-link'
import { ModalSelectProductMultipleComponent } from '../../../../../components/modal-select-product-multiple/modal-select-product-multiple'
import { CMSMenuService } from '../../../../../services/cms-menu.service'
import { linkTextView } from '../../../../../helpers/link'

@Component({
  selector: 'app-section-product',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    UploadFileFlexibleComponent,
    RadioLayoutComponent,
    MatRadioModule,
    SelectComponent,
    CdkDrag,
    CdkDropList,
    SelectAddLinkComponent,
  ],
  templateUrl: './section-product.html',
  styleUrls: ['./section-product.scss'],
})
export class SectionProductComponent implements OnInit {
  @Input() isEdit: boolean = false
  @Input() isView: boolean = false
  @Input() reset: any
  @Input() form!: FormGroup
  @Input() sectionIndex: number = 0
  @Input() showButtonDelete: boolean = false
  @Output() deleteSection = new EventEmitter()

  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly linkTextView = linkTextView
  readonly formatPrice = formatPrice

  list = {
    categories: <any[]>[],
  }

  get details() {
    return this.form.controls['cms_pania_main_content_details'] as FormArray<FormGroup>
  }

  get products() {
    return this.form.controls['cms_pania_main_content_products'].value || []
  }

  get productIds() {
    return this.products.reduce((productIds: any[], item: any) => {
      if (!productIds.includes(item.product_id)) {
        productIds.push(item.product_id)
      }

      return productIds
    }, [])
  }

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public cmsMenuService: CMSMenuService
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    this.initData()

    this.setLayout(
      { value: this.form.controls['is_two_image'].value },
      this.form.controls['value_cms_pania_main_content_details'].value || []
    )
    this.form.controls['value_cms_pania_main_content_details'].valueChanges.subscribe(values => {
      this.setLayout({ value: this.form.controls['is_two_image'].value }, values || [])
    })
  }

  initData() {
    const apis = [this.cmsMenuService.getCategoryList()]
    this.loading.start()
    forkJoin(apis).subscribe(([resCategoryList]) => {
      if (!resCategoryList.is_error) {
        this.list.categories = resCategoryList.data
      } else {
        this.customSnackBar.fail()
      }
      this.loading.stop()
    })
  }

  log(log: any) {
    console.log('log', log)
  }

  setLayout(e: any, values: any[]) {
    this.form.controls['is_two_image'].setValue(e.value)
    this.details.clear()

    let count = this.form.controls['is_two_image'].value == 'one' ? 1 : 2
    for (let index = 0; index < count; index++) {
      const value = values[index]
      this.details.push(
        new FormGroup({
          file_id: new FormControl(value?.file_id || null, [Validators.required]),
          file_url: new FormControl(value?.file?.url || null),
          cms_link_list_id: new FormControl(value?.cms_link_list_id ?? null),
          product_id: new FormControl(value?.product_id || null),
          product_detail_id: new FormControl(value?.product_detail_id || null),
          product_category_id: new FormControl(value?.product_category_id || null),
          link_path: new FormControl(value?.link_path || null),
        })
      )
    }
    // console.log('this.details', this.details)
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      )
    }
    // this.checkValidate()
    this.form.markAllAsTouched()
  }

  dropTable(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.products, event.previousIndex, event.currentIndex)
  }

  category_name(product_category_id: any) {
    return this.list.categories.find(c => c.id == product_category_id)?.name_th
  }

  removeSection(index: number) {
    const dialogRefConfirm = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบคอนเทนต์นี้ใช่หรือไม่`,
      },
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.deleteSection.emit(index)
      }
    })
  }

  addProduct() {
    const dialogRef = this.dialog.open(ModalSelectProductMultipleComponent, {
      data: {
        showStock: true,
        max: 4,
        product_list: this.products,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log('product', result)
        // const products = result.reduce((products: any[], item: any) => {
        //   let product = products.find(p => p.product_id == item.product_id)
        //   if (!product) {
        //     product = {
        //       id: item.product_id,
        //       ...(item.product_detail_id ? {} : item),
        //       product_details: [],
        //     }
        //     products.push(product)
        //   }
        //   if (item.product_detail_id) {
        //     product.product_details.push(item)
        //   }

        //   return products
        // }, [])
        this.form.controls['cms_pania_main_content_products'].setValue(result)
      }
    })
  }

  confirmDelete(index: any) {
    const values = [...this.products]
    values.splice(index, 1)
    this.form.controls['cms_pania_main_content_products'].reset(values)
  }
}
