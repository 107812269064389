import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../../imports'
import { InputComponent } from '../../../../components/input/input'
import { TextareaComponent } from '../../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  Ascending,
  productImage,
  formatPrice,
} from '../../../../helpers'
import { UploadImageProfileComponent } from '../../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../../services'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../../components/date-picker/date-picker'
import { UploadFileFlexibleComponent } from '../../../../components/upload-file-flexible/upload-file-flexible'
import { RadioLayoutComponent } from '../../../../components/radio-layout/radio-layout'
import { MatRadioModule } from '@angular/material/radio'
import { SelectComponent } from '../../../../components/select/select'
import { ModalManageHomeComponent } from './modal-manage-home/modal-manage-home'
import { SelectAddLinkComponent } from '../../../../components/select-add-link/select-add-link'
import { ModalPreviewComponent } from '../../../../components/modal-preview/modal-preview'
import { CMSMainService } from '../../../../services/cms-main.service'
import { LINK_LIST_ID, linkTextView } from '../../../../helpers/link'
import { CMSMenuService } from '../../../../services/cms-menu.service'
import { ModalPreviewMainComponent } from './modal-preview/modal-preview'
import { StatusComponent } from '../../../../components/status/status'
import { ModalSelectProductMultipleComponent } from '../../../../components/modal-select-product-multiple/modal-select-product-multiple'
import { ModalDeleteComponent } from '../../../../components/modal-delete/modal-delete'

@Component({
  selector: 'app-cms-home-bhb',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    UploadFileFlexibleComponent,
    RadioLayoutComponent,
    MatRadioModule,
    SelectComponent,
    CdkDrag,
    CdkDropList,
    SelectAddLinkComponent,
    StatusComponent,
  ],
  templateUrl: './cms-home.html',
  styleUrls: ['./cms-home.scss'],
})
export class CMSHomeBHBComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment
  readonly linkTextView = linkTextView
  readonly formatPrice = formatPrice

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false
  dataManage: any
  reset: any

  form = new FormGroup({
    is_two_image: new FormControl('two'),
    cms_bhb_main_details: new FormArray<FormGroup>([
      new FormGroup({
        file_id: new FormControl(null, [Validators.required]),
        file_url: new FormControl(null),
        cms_link_list_id: new FormControl(null),
        product_id: new FormControl(null),
        product_detail_id: new FormControl(null),
        product_category_id: new FormControl(null),
        link_path: new FormControl(null),
      }),
      new FormGroup({
        file_id: new FormControl(null, [Validators.required]),
        file_url: new FormControl(null),
        cms_link_list_id: new FormControl(null),
        product_id: new FormControl(null),
        product_detail_id: new FormControl(null),
        product_category_id: new FormControl(null),
        link_path: new FormControl(null),
      }),
    ]),

    file_banner_id: new FormControl(null),
    file_banner_url: new FormControl(null),
    cms_bhb_main_products: new FormControl<any[]>([]),

    file_sub_banner_id: new FormControl(null),
    file_sub_banner_url: new FormControl(null),
    cms_link_list_id: new FormControl(null),
    product_id: new FormControl(null),
    product_name: new FormControl(null),
    product_detail_id: new FormControl(null),
    product_category_id: new FormControl(null),
    product_category_name: new FormControl(null),
    link_path: new FormControl(null),

    cms_bhb_main_products_second: new FormControl<any[]>(
      [],
      [
        (control: AbstractControl) => {
          if (this.productCount(control) < 4) {
            return {
              minLength: true,
            }
          }
          if (this.productCount(control) > 4 && this.productCount(control) < 8) {
            return {
              minLength: true,
            }
          }
          return null
        },
      ]
    ),
  })

  get products_first() {
    return this.form.controls['cms_bhb_main_products'].value || []
  }

  get productIds() {
    return this.products_first.reduce((productIds: any[], item: any) => {
      if (!productIds.includes(item.product_id)) {
        productIds.push(item.product_id)
      }
      return productIds
    }, [])
  }

  get products_second() {
    return this.form.controls['cms_bhb_main_products_second'].value || []
  }

  get ProductSecondIds() {
    return this.products_second.reduce((productIds: any[], item: any) => {
      if (!productIds.includes(item.product_id)) {
        productIds.push(item.product_id)
      }
      return productIds
    }, [])
  }

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public cmsMainService: CMSMainService
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    this.initData()
    // this.openModalSelectProductSingle()
  }

  initData() {
    const apis = [this.cmsMainService.getCmsMain()]

    this.loading.start()
    forkJoin(apis).subscribe(([resMain]: any) => {
      if (!resMain.is_error) {
        if (resMain.data.id) {
          let data = resMain.data
          this.dataManage = data

          this.form.reset({
            is_two_image: data?.is_two_image ? 'two' : 'one',
            file_banner_id: data?.file_banner_id,
            file_banner_url: data?.file_main_banner_file?.url,
            file_sub_banner_id: data?.file_sub_banner_id,
            file_sub_banner_url: data?.file_sub_banner_file?.url,
            cms_link_list_id: data.cms_link_list_id,
            product_id: data.product_id,
            product_name: data?.product?.name || null,
            product_detail_id: data.product_detail_id,
            product_category_id: data.product_category_id,
            product_category_name: data?.product_category?.name || null,
          })
          this.setForm(this.dataManage)
        } else if (!resMain.data.id) {
          this.router.navigate(['/setting/cms/home/edit'])
        }
      } else {
        this.customSnackBar.fail()
      }

      this.loading.stop()
    })
  }

  setForm(data: any) {
    if (data.cms_bhb_main_details.length > 0) {
      this.form.controls['cms_bhb_main_details'].clear()
      for (let index = 0; index < data.cms_bhb_main_details.length; index++) {
        const d = data.cms_bhb_main_details[index]
        let newForm = new FormGroup({
          file_id: new FormControl(d?.file_id || null, [Validators.required]),
          file_url: new FormControl(d?.file?.url || null),
          cms_link_list_id: new FormControl(d?.cms_link_list_id || null),
          product_id: new FormControl(d?.product_id || null),
          product_detail_id: new FormControl(d?.product_detail_id || null),
          product_category_id: new FormControl(d?.product_category_id || null),
          link_path: new FormControl(d?.link_path || null),
          product_name: new FormControl(d?.product?.name || null),
          product_category_name: new FormControl(d?.product_category?.name || null),
        })

        this.form.controls['cms_bhb_main_details'].push(newForm)
      }
    }

    let products_first: any[] = []
    let products_second: any[] = []
    if (data.cms_bhb_main_products.length > 0) {
      let products = Ascending(data.cms_bhb_main_products, 'seq').reduce(
        (produtcs: any[], item: any) => {
          produtcs.push({
            ...item,
            ...(item.product_detail_id ? item.product_detail : item.product),
            image: productImage(item.product, item.product_detail),
          })
          return produtcs
        },
        []
      )

      for (let index = 0; index < products.length; index++) {
        const d = products[index]
        if (d.is_first) {
          products_first.push(d)
        }
        if (d.is_second) {
          products_second.push(d)
        }
      }
    }
    this.form.controls['cms_bhb_main_products'].setValue(products_first)
    this.form.controls['cms_bhb_main_products_second'].setValue(products_second)
  }

  dropTableProductFirst(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.products_first, event.previousIndex, event.currentIndex)
  }

  dropTableProductSecond(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.products_second, event.previousIndex, event.currentIndex)
  }

  openModalManageHome() {
    this.dialog.open(ModalManageHomeComponent, {
      data: {},
    })
  }

  setLayout(e: any, values: any[]) {
    this.form.controls['cms_bhb_main_details'].clear()
    this.form.controls['is_two_image'].setValue(e.value)

    let count = this.form.controls['is_two_image'].value == 'one' ? 1 : 2
    for (let index = 0; index < count; index++) {
      const value = values[index]
      this.form.controls['cms_bhb_main_details'].push(
        new FormGroup({
          file_id: new FormControl(value?.file_id || null, [Validators.required]),
          file_url: new FormControl(value?.file?.url || null),
          cms_link_list_id: new FormControl(value?.cms_link_list_id ?? null),
          product_id: new FormControl(value?.product_id || null),
          product_detail_id: new FormControl(value?.product_detail_id || null),
          product_category_id: new FormControl(value?.product_category_id || null),
          link_path: new FormControl(value?.link_path || null),
        })
      )
    }
    console.log('this.details', this.form.controls['cms_bhb_main_details'])
  }

  addProductFirst() {
    const dialogRef = this.dialog.open(ModalSelectProductMultipleComponent, {
      data: {
        showStock: true,
        max: 10,
        product_list: this.products_first,
        filter_product: this.products_second,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let data = result.map((item: any) => {
          return { ...item, is_first: true, is_second: false }
        })
        this.form.controls['cms_bhb_main_products'].setValue(data)
      }
    })
  }

  confirmDeleteFirst(index: any) {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบข้อมูลสินค้านี้ใช่หรือไม่`,
      },
    })
    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        const values = [...this.products_second]
        values.splice(index, 1)
        this.form.controls['cms_bhb_main_products_second'].reset(values)
      }
    })
    return dialogRefDelete
  }

  addProductSecond() {
    const dialogRef = this.dialog.open(ModalSelectProductMultipleComponent, {
      data: {
        showStock: true,
        max: 8,
        product_list: this.products_second,
        filter_product: this.products_first,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let data = result.map((item: any) => {
          return { ...item, is_first: false, is_second: true }
        })
        this.form.controls['cms_bhb_main_products_second'].setValue(data)
      }
    })
  }

  confirmDeleteProductSecond(index: any) {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบข้อมูลสินค้านี้ใช่หรือไม่`,
      },
    })
    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        const values = [...this.products_first]
        values.splice(index, 1)
        this.form.controls['cms_bhb_main_products_second'].reset(values)
      }
    })
    return dialogRefDelete
  }

  productCount(productControl: FormControl | AbstractControl) {
    return (productControl.value || []).reduce((products: any[], value: any) => {
      if (!products.includes(value.product_id)) {
        products.push(value.product_id)
      }
      return products
    }, []).length
  }

  onReset() {
    // this.sectionProductArr.clear()
    this.setDefault()
    this.reset = crypto.randomUUID()
    // this.initData()
  }

  setDefault() {
    this.form.reset({
      is_two_image: 'two',
      file_banner_id: null,
      file_banner_url: null,
      file_sub_banner_id: null,
      file_sub_banner_url: null,
    })
    this.form.controls['cms_bhb_main_details'].clear()
    for (let index = 0; index < 2; index++) {
      this.form.controls['cms_bhb_main_details'].push(
        new FormGroup({
          file_id: new FormControl(null, [Validators.required]),
          file_url: new FormControl(null),
          cms_link_list_id: new FormControl(null),
          product_id: new FormControl(null),
          product_detail_id: new FormControl(null),
          product_category_id: new FormControl(null),
          link_path: new FormControl(null),
        })
      )
    }
  }

  onCancel() {
    const dialogRefConfirm = this.dialog.open(ModalLeaveComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/setting/cms/home/view'])
      }
    })
  }

  openPreview() {
    if (!this.checkValidate()) return

    this.dialog.open(ModalPreviewMainComponent, {
      width: '1200px',
      data: {
        // dataManage: this.dataManage,
        formValue: this.form.getRawValue(),
      },
    })
  }

  checkValidate() {
    ValidateForm(this.form)
    let arr_invalid = false
    ValidateArrayForm(this.form.controls.cms_bhb_main_details)

    if (!this.form.valid) return
    if (arr_invalid) return

    return true
  }

  onSave(is_public: boolean) {
    if (!this.checkValidate()) return

    const value = this.form.getRawValue()
    const products = value.cms_bhb_main_products?.concat(value.cms_bhb_main_products_second)
    console.log('value', value, products)

    const payload = {
      id: this.dataManage?.id || null,
      is_public: is_public,
      is_two_image: value.is_two_image == 'one' ? false : true,
      file_banner_id: value.file_banner_id,
      file_sub_banner_id: value.file_sub_banner_id,
      cms_link_list_id: value.cms_link_list_id ?? null,
      product_id: value.product_id ?? null,
      product_detail_id: value.product_detail_id ?? null,
      product_category_id: value.product_category_id ?? null,
      link_path: value.link_path ?? null,
      is_page_campaign: false,
      is_page_collection: false,
      cms_bhb_main_details: value.cms_bhb_main_details.map((d: any) => {
        return {
          file_id: d.file_id,
          cms_link_list_id: d.cms_link_list_id ?? null,
          product_id: d.product_id || null,
          product_detail_id: d.product_detail_id || null,
          product_category_id: d.product_category_id || null,
          link_path: d.link_path || null,
          is_page_campaign: false,
          is_page_collection: false,
        }
      }),
      cms_bhb_main_products: products?.map((p: any, seq) => {
        return {
          seq: seq + 1,
          product_id: p.product_id,
          product_detail_id: p.product_detail_id,
          is_first: p.is_first,
          is_second: p.is_second,
        }
      }),
    }
    console.log(payload)

    this.loading.start()
    this.cmsMainService.updateCmsMain(payload).subscribe(res => {
      if (!res.is_error) {
        this.customSnackBar.success(is_public ? 'เผยแพร่สำเร็จ' : 'บันทึกร่างสำเร็จ')
        this.router.navigate(['/setting/cms/home/view'])
      } else {
        this.customSnackBar.failSave(is_public ? 'เผยแพร่ไม่สำเร็จ' : 'บันทึกร่างไม่สำเร็จ')
      }
      this.loading.stop()
    })
  }
}
