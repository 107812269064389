<div class="table-responsive mt-3">
  <div class="wrapper">
    <button class="btn-add-column btn btn-transparent-outline" (click)="addColumn()">
      <span class="icon color--neutral-500 material-symbols-outlined fill"> add_circle </span>
    </button>

    <table mat-table [dataSource]="dataSource">
      <ng-container *ngFor="let col of displayedFields;let i=index" [matColumnDef]="col">
        <th mat-header-cell *matHeaderCellDef class="bg-gray">
          <div class="d-flex align-items-center justify-content-between">
            <input
              #columnName
              [ngModel]="displayedHead[i]"
              placeholder="ระบุชื่อ"
              (blur)="changeColumnName(i,columnName.value)" />
  
            <button class="btn btn-transparent-outline" *ngIf="i != 0" (click)="deleteColumn(i)">
              <span class="icon color--neutral-500 material-symbols-outlined fill"> close </span>
            </button>
          </div>
        </th>
        <td mat-cell *matCellDef="let element" [class]="i == 0 ? 'bg-gray' : ''">
          <div class="d-flex align-items-center justify-content-between">
            <input placeholder="ระบุชื่อ" [formControl]="element.get(col)" />
            <button class="btn btn-transparent-outline" *ngIf="i == 0" (click)="deleteRow(i)">
              <span class="icon color--neutral-500 material-symbols-outlined fill"> close </span>
            </button>
          </div>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <button class="btn-add-row btn btn-transparent-outline" (click)="addRow()">
      <span class="icon color--neutral-500 material-symbols-outlined fill"> add_circle </span>
    </button>
  </div>

</div>
