import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../../imports'
import { InputComponent } from '../../../../components/input/input'
import { TextareaComponent } from '../../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  Pagination,
  formatPriceAutoDecimal,
} from '../../../../helpers'
import { UploadImageProfileComponent } from '../../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../../services'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../../components/date-picker/date-picker'
import { ModalSelectProductMultipleComponent } from '../../../../components/modal-select-product-multiple/modal-select-product-multiple'
import { ModalDeleteComponent } from '../../../../components/modal-delete/modal-delete'
import { SearchComponent } from '../../../../components/search/search'
import { MatPaginator } from '@angular/material/paginator'
import { CheckboxAllTableComponent } from '../../../../components/checkbox-table/checkbox-all-table.component'
import { CheckboxTableComponent } from '../../../../components/checkbox-table/checkbox-table.component'
import { MatTooltipModule } from '@angular/material/tooltip'
import { UploadFileFlexibleComponent } from '../../../../components/upload-file-flexible/upload-file-flexible'
import { TableSizeComponent } from '../table-size/table-size'
import { MatRadioButton, MatRadioModule } from '@angular/material/radio'

@Component({
  selector: 'app-carats',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    SearchComponent,
    MatPaginator,
    CheckboxAllTableComponent,
    CheckboxTableComponent,
    MatTooltipModule,
    UploadFileFlexibleComponent,
    TableSizeComponent,
    MatRadioButton,
    MatRadioModule
  ],
  templateUrl: './measure.html',
  styleUrls: ['./measure.scss'],
})
export class SizeGuideMeasureComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal

  search = new FormControl('')
  readonly paginationMeasure = new Pagination({
    sortBy: 'desc',
  })
  productList: any[] = []
  itemChecked = new Set<any>()

  rerender = false
  pageNumber = new FormControl(1)

  isEdit: boolean = false
  isView: boolean = false
  dataManage: any
  id: any = 1


  form = new FormGroup({
    name_measure: new FormControl('', Validators.required),
    file: new FormControl(''),
    description_th: new FormControl(''),
    description_en: new FormControl(''),
    title_th: new FormControl(''),
    title_en: new FormControl(''),
    description_title_th: new FormControl(''),
    description_title_en: new FormControl(''),
    unit:  new FormControl('cm'),

    product_list: new FormControl([]),
  })

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public caratService: CaratService
  ) {}

  ngOnInit(): void {
    this.initData()
  }

  initData() {
    this.profile.getProfile() // add for permission
    const apis = [this.caratService.getCarat(this.id)]

    // this.loading.start()
    // forkJoin(apis).subscribe(([resCarat]: any) => {
    //   if (resCarat) {
    //     if (!resCarat.is_error) {
    //       this.dataManage = resCarat.data

    //       this.form.reset({
    //         carat: resCarat.data.carat || 1,
    //         total_order_amount: resCarat.data.total_order_amount,
    //         order_start_date: resCarat.data.order_start_date,
    //         order_end_date: resCarat.data.order_end_date,
    //         value_amount: resCarat.data.value_amount,
    //         // value_start_date: resCarat.data.value_start_date,
    //         // value_end_date: resCarat.data.value_end_date,
    //       })
    //       this.discounts.clear()
    //       const discounts = (this.dataManage.discounts || []).sort((a: any, b: any) =>
    //         a.sort > b.sort ? 1 : -1
    //       )
    //       for (const discount of discounts) {
    //         this.addCaratDiscount(discount)
    //       }
    //     } else {
    //       this.customSnackBar.fail(resCarat.message)
    //     }
    //   }

    //   this.loading.stop()
    // })
  }


  onChangePage(reset = false) {
    if (this.pageNumber.value) {
      const page = this.pageNumber.value - 1
      if (this.paginationMeasure.data.pageIndex != page) {
        this.paginationMeasure.data.pageIndex = page
        // this.getProductList()
      }
    } else if (reset) {
      this.pageNumber.setValue(this.paginationMeasure.data.pageIndex + 1)
      this.rerender = true
      setTimeout(() => {
        this.rerender = false
      })
    }
  }

  addProduct(controlProduct?: any) {
    const dialogRef = this.dialog.open(ModalSelectProductMultipleComponent, {
      data: {
        product_list: controlProduct.value,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('', result);
        
        controlProduct?.setValue(result)
      }
    })
  }

  deleteProduct(controlProduct: FormControl, id: any) {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบข้อมูลสินค้านี้ใช่หรือไม่`,
      },
    })
    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        let filter = controlProduct.value.filter((e: any) => {
          return e.id != id
        })
        controlProduct?.setValue(filter)
      }
    })
    return dialogRefDelete
  }
}
