import { FormGroup, FormControl, AbstractControl, FormArray } from '@angular/forms'

//a-z 0-9 and spacial at least 6 character
export const PasswordReg = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[#$*&])[a-zA-Z0-9#$*&]{6,}$/
// /^(?=.*[0-9])((?![#$*&]).)*[a-zA-Z0-9#$*&]{6,}$/

export const EmailReg =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s\d@\"]{2,})$/i

export const GetError = (control: FormControl) => {
  if (control.errors && control.touched && !control.valid) {
    return control.errors
  }
  return ''
}

export const validateField = (control: AbstractControl) => {
  control.markAsDirty()
  control.markAsTouched()
}

export const ValidateForm = (controlGroup: FormGroup) => {
  if (controlGroup.disabled) {
    return true
  } else {
    for (const k in controlGroup.controls) {
      const control = controlGroup.controls[k]
      if (control instanceof FormArray) {
        control.markAllAsTouched()
      } else {
        validateField(control)
      }
    }
  }
  return controlGroup.valid
}

export const ValidateArrayForm = (controlArray: FormArray) => {
  controlArray?.controls.forEach((a: any) => {
    for (const k in a?.controls) {
      validateField(a?.controls[k])
    }
  })
}

export const setValueNumber = (num: any) => {
  if (isNaN(num)) return null
  if (num === 0 || num === '0') return 0
  return num
}

export const ValidateNumberNotZero = (control: AbstractControl) => {
  if (!control.value || control.value == '0') {
    return {
      required: true,
    }
  }

  return null
}

export const ValidateEmailTextMultiple = (control: AbstractControl) => {
  const emails = (control.value || '').split(',')
  for (const email of emails) {
    if (!EmailReg.test(email)) {
      return {
        pattern: true,
      }
    }
  }
  return null
}

export const setErrorForm = (controlGroup: FormGroup, errors: any[]) => {
  const controls = controlGroup.controls as any
  for (const err of errors) {
    if (controls[err.field]) {
      controls[err.field].setErrors({
        formApi: err.message,
      })
    }
  }
}

export const setErrorFormArray = (formArr: FormArray<FormGroup>, errors: any[], field: string) => {
  formArr.controls.forEach((form: FormGroup, i: number) => {
    Object.keys(form.controls).forEach(controlName => {
      const err = errors.find(
        (err: any) =>
          err.field == `${field}.${i}.${controlName}` ||
          err.field == `${field}[${i}].${controlName}`
      )
      if (err) {
        form.controls[controlName].setErrors({
          formApi: err.message,
        })
      }
    })
  })
}
