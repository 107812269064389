import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { MatTabsModule } from '@angular/material/tabs'
import { RadioLayoutComponent } from '../../../components/radio-layout/radio-layout'
import { UploadFileFlexibleComponent } from '../../../components/upload-file-flexible/upload-file-flexible'
import { SelectComponent } from '../../../components/select/select'
import { ModalSelectProductSingleComponent } from '../../../components/modal-select-product-single/modal-select-product-single'
import { ModalPreviewComponent } from '../../../components/modal-preview/modal-preview'
import { SelectAddLinkComponent } from '../../../components/select-add-link/select-add-link'
import { ModalDeleteComponent } from '../../../components/modal-delete/modal-delete'
import { ModalSelectProductMultipleComponent } from '../../../components/modal-select-product-multiple/modal-select-product-multiple'
import { StatusComponent } from '../../../components/status/status'
@Component({
  selector: 'app-cms-product-list',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    MatTabsModule,
    RadioLayoutComponent,
    UploadFileFlexibleComponent,
    SelectComponent,
    CdkDrag,
    CdkDropList,
    ModalSelectProductSingleComponent,
    SelectAddLinkComponent,
    StatusComponent,
  ],
  templateUrl: './cms-product-list.html',
  styleUrls: ['./cms-product-list.scss'],
})
export class CMSProductListComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false
  dataManage: any
  id: any = 1

  listAddLink: any[] = [
    {
      id: 1,
      name: 'ไม่ระบุ',
    },
    {
      id: 2,
      name: 'หน้ารายละเอียดสินค้า',
    },
    {
      id: 3,
      name: 'หมวดหมู่สินค้า',
    },
    {
      id: 4,
      name: 'กำหนด URL',
    },
    {
      id: 5,
      name: 'แคมเปญ',
    },
    {
      id: 6,
      name: 'คอลเลคชัน',
    },
  ]

  tab = 0

  tabList = new FormArray([
    new FormGroup({
      name: new FormControl('เสื้อผ้า'),
      content: new FormArray([
        new FormGroup({
          type: new FormControl('left'),
          file: new FormControl(''),
          link: new FormControl(''),
          link_name: new FormControl(''),
          product_list: new FormControl([])
        })
      ])
    }),
    new FormGroup({
      name: new FormControl('กางเกง'),
      content: new FormArray([
        new FormGroup({
          type: new FormControl('left'),
          file: new FormControl(''),
          link: new FormControl(''),
          link_name: new FormControl(''),
          product_list: new FormControl([])
        })
      ])
    }),
  ])


  constructor(
    public profile: Profile,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public route: ActivatedRoute,
    public router: Router,
  ) { }

  ngOnInit(): void {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    this.initData()
  }

  initData() {
    this.profile.getProfile() // add for permission

    // this.loading.start()
    // forkJoin(apis).subscribe(([resCarat]: any) => {
    //   if (resCarat) {
    //     if (!resCarat.is_error) {
    //       this.dataManage = resCarat.data

    //       this.form.reset({
    //         carat: resCarat.data.carat || 1,
    //         total_order_amount: resCarat.data.total_order_amount,
    //         order_start_date: resCarat.data.order_start_date,
    //         order_end_date: resCarat.data.order_end_date,
    //         value_amount: resCarat.data.value_amount,
    //         // value_start_date: resCarat.data.value_start_date,
    //         // value_end_date: resCarat.data.value_end_date,
    //       })
    //       this.discounts.clear()
    //       const discounts = (this.dataManage.discounts || []).sort((a: any, b: any) =>
    //         a.sort > b.sort ? 1 : -1
    //       )
    //       for (const discount of discounts) {
    //         this.addCaratDiscount(discount)
    //       }
    //     } else {
    //       this.customSnackBar.fail(resCarat.message)
    //     }
    //   }

    //   this.loading.stop()
    // })
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
    this.navigateToTab(this.tab)

    // if ($tabIndex == 0) {
    //   this.getPaymentChannelList()
    // } else if ($tabIndex == 1) {
    //   this.getCompanyProfile()
    // }
  }

  navigateToTab(tab: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      skipLocationChange: false,
    })
  }

  dropTable(event: CdkDragDrop<any[]>, lits: any) {
    moveItemInArray(lits, event.previousIndex, event.currentIndex);
  }

  addContent(control: FormArray) {
    control.push(new FormGroup({
      type: new FormControl('left'),
      file: new FormControl(''),
      link: new FormControl(''),
      link_name: new FormControl(''),
      product_list: new FormControl([])
    }))
  }

  openPreview() {
    const dialogRefPreview = this.dialog.open(ModalPreviewComponent, {
      data: {},
    })
    dialogRefPreview.afterClosed().subscribe(result => {
      if (result) {}
    })
    return dialogRefPreview
  }

  deleteProduct(controlProduct: FormControl, id: any) {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบข้อมูลสินค้านี้ใช่หรือไม่`,
      },
    })
    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        let filter = controlProduct.value.filter((e: any) => {
          return e.id != id
        })
        controlProduct?.setValue(filter)
      }
    })
    return dialogRefDelete
  }

  addProduct(controlProduct: any) {
    const dialogRefProduct = this.dialog.open(ModalSelectProductMultipleComponent, {
      data: {
        max: 4,
        product_list: controlProduct.value,
      },
    })
    dialogRefProduct.afterClosed().subscribe(result => {
      if (result) {
        console.log('add product', result);
        controlProduct?.setValue(result)
      }
    })
    return dialogRefProduct
  }

  onSave() {
    this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
    this.router.navigate(['/setting/cms/product-list/view'])
  }

}
