import { Component, Inject, Injectable } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { FormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatTabsModule } from '@angular/material/tabs'
import { MatIconModule } from '@angular/material/icon'
import { MatRipple } from '@angular/material/core'
import { TopMenuComponent } from '../../../../../components/preview/top-menu/top-menu'
import { FileService, ProductService } from '../../../../../services'
import { Loading } from '../../../../../globals'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { CardProductComponent } from '../../../../../components/preview/card-product/card-product'
import { LINK_LIST_ID } from '../../../../../helpers/link'
import { FooterComponent } from '../../../../../components/preview/footer/footer'
import { groupProducts, ProductData } from '../../../../../helpers/preview/product'

@Component({
  selector: 'app-modal-preview',
  templateUrl: './modal-preview.html',
  styleUrls: ['./modal-preview.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatTabsModule,
    MatIconModule,
    MatRipple,
    TopMenuComponent,
    CardProductComponent,
    FooterComponent,
  ],
})
export class ModalPreviewMainComponent {
  readonly LINK_LIST_ID = LINK_LIST_ID

  file_banner: any
  contents: any[] = []

  constructor(
    public dialogRef: MatDialogRef<ModalPreviewMainComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fileService: FileService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public productService: ProductService
  ) {}

  ngOnInit(): void {
    console.log(this.data)

    this.getFile(this.data.formValue.file_banner_id, (res: any) => {
      this.file_banner = res.data
    })

    this.setContents()
  }

  getFile(id: any, call: Function) {
    this.loading.start()
    this.fileService.getFile(id).subscribe(res => {
      if (!res.is_error) {
        call(res)
      } else {
        this.customSnackBar.fail()
      }
      this.loading.stop()
    })
  }

  setContents() {
    this.contents = this.data.formValue.cms_pania_main_contents.map((c: any) => {
      c.cms_pania_main_content_details.forEach((c: any) => {
        c.file = null
        this.getFile(c.file_id, (res: any) => {
          c.file = res.data
        })
      })

      const products = groupProducts(
        (c.cms_pania_main_content_products || []).map((d: any, i: number) => {
          return {
            seq: i + 1,
            ...d,
          }
        })
      )

      c.products = products.map((product: any) => {
        return new ProductData(product)
      })

      return c
    })
    // console.log(this.contents)
  }

  onClose(): void {
    this.dialogRef.close()
  }
}
