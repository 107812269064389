<div class="page-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">ขนาดสินค้า</div>
  </div>

  <div class="title-wrapper">ขนาดสินค้า</div>

  <div class="tabs-wrapper">
    <mat-tab-group
      animationDuration="0ms"
      [disableRipple]="true"
      [selectedIndex]="tab"
      (selectedIndexChange)="changeTab($event)">
      @if (service.companyId == COMPANY_ID.BHB || service.companyId == COMPANY_ID.PANIA) {
      <mat-tab label="คู่มือขนาดสินค้า">
        <div class="tabs-content-wrapper">
          <div class="d-flex justify-content-end">
            @if (profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.CREATE])) {
            <button
              class="btn btn-primary w-fit mb-3"
              [routerLink]="'/setting/cms/product-details/manual/create'"
              [queryParams]="{tab: tab}">
              <span class="icon material-symbols-outlined fill"> add_circle </span>
              เพิ่มคู่มือขนาดสินค้า
            </button>
            }
          </div>
          <div class="table-responsive">
            <table class="table table-fixed-column table-detail">
              <thead>
                <tr>
                  <th width="300">ชื่อคู่มือ</th>
                  <th width="140">จำนวนสินค้า</th>
                  <th width="220">อัปเดตโดย</th>
                  <th width="330">วันที่อัปเดต</th>
                  <th width="120" class="fixed box-shadow-left">จัดการ</th>
                </tr>
              </thead>
              <tbody class="mt-3 w-100">
                @for (item of sizeList; track item; let k = $index;) {
                <tr class="drag-box mb-2">
                  <td width="300">{{ item.name }}</td>
                  <td width="140">
                    {{ formatPriceAutoDecimal((item.product_lists || []).length) }}
                  </td>
                  <td>{{item.updated_by ? item.updated_by.full_name : ''}}</td>
                  <td>{{Moment(item.updated_at).format('DD-MM-YYYY, HH:mm')}}</td>
                  <td class="fixed box-shadow-left">
                    <div class="action-button-wrapper">
                      <!-- @if (item.action.edit) { -->
                      <button
                        matRipple
                        matTooltip="แก้ไข"
                        matTooltipPosition="above"
                        class="btn btn-outline-secondary btn-icon"
                        [routerLink]="'/setting/cms/product-details/manual/edit/' + item.id"
                        [queryParams]="{tab: tab}">
                        <span class="icon material-symbols-outlined fill">edit</span>
                      </button>
                      <!-- } @if (item.action.delete) { -->
                      <button
                        matRipple
                        matTooltip="ลบ"
                        matTooltipPosition="above"
                        class="btn btn-outline-danger btn-icon"
                        (click)="confirmDeleteSize(item)">
                        <span class="icon material-symbols-outlined fill">delete</span>
                      </button>
                      <!-- } -->
                    </div>
                  </td>
                </tr>
                }
              </tbody>
            </table>

            @if(!sizeList.length) {
            <div class="data-not-found-wrapper">ไม่พบข้อมูล กรุณาเพิ่มคู่มือขนาดสินค้า</div>
            }
          </div>

          <!-- Pagination -->
          @if (sizeList.length) {
          <div class="pagination-wrapper">
            <mat-paginator
              [pageIndex]="paginationSize.data.pageIndex"
              [length]="paginationSize.data.length"
              [pageSize]="paginationSize.data.pageSize"
              [pageSizeOptions]="paginationSize.data.pageSizeOptions"
              aria-label="Select page"
              (page)="paginationSize.setFromPaginator($event); getSizeMeasureList()">
            </mat-paginator>
          </div>
          }
        </div>
      </mat-tab>
      }

      @if (service.companyId == COMPANY_ID.PANIA) {
      <mat-tab label="การวัดขนาดสินค้า">
        <div class="tabs-content-wrapper">
          <div class="d-flex justify-content-end">
            @if (profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.CREATE])) {
            <button
              class="btn btn-primary w-fit mb-3"
              [routerLink]="'/setting/cms/product-details/measure/create'"
              [queryParams]="{tab: tab}">
              <span class="icon material-symbols-outlined fill"> add_circle </span>
              เพิ่มการวัดขนาดสินค้า
            </button>
            }
          </div>
          <div class="table-responsive">
            <table class="table table-fixed-column table-detail">
              <thead>
                <tr>
                  <th width="300">ชื่อการวัดขนาดสินค้า</th>
                  <th width="140">จำนวนสินค้า</th>
                  <th width="220">อัปเดตโดย</th>
                  <th width="330">วันที่อัปเดต</th>
                  <th width="120" class="fixed box-shadow-left">จัดการ</th>
                </tr>
              </thead>
              <tbody class="mt-3 w-100">
                @for (item of measureList; track item; let k = $index;) {
                <tr class="drag-box mb-2">
                  <td width="300">{{ item.name }}</td>
                  <td width="140">
                    {{ formatPriceAutoDecimal((item.product_lists || []).length) }}
                  </td>
                  <td>{{item.updated_by ? item.updated_by.full_name : ''}}</td>
                  <td>{{Moment(item.updated_at).format('DD-MM-YYYY, HH:mm')}}</td>
                  <td class="fixed box-shadow-left">
                    <div class="action-button-wrapper">
                      <!-- @if (item.action.edit) { -->
                      <button
                        matRipple
                        matTooltip="แก้ไข"
                        matTooltipPosition="above"
                        class="btn btn-outline-secondary btn-icon"
                        [routerLink]="'/setting/cms/product-details/measure/edit/' + item.id"
                        [queryParams]="{tab: tab}">
                        <span class="icon material-symbols-outlined fill">edit</span>
                      </button>
                      <!-- } @if (item.action.delete) { -->
                      <button
                        matRipple
                        matTooltip="ลบ"
                        matTooltipPosition="above"
                        class="btn btn-outline-danger btn-icon"
                        (click)="confirmDeleteMeasure(item)">
                        <span class="icon material-symbols-outlined fill">delete</span>
                      </button>
                      <!-- } -->
                    </div>
                  </td>
                </tr>
                }
              </tbody>
            </table>

            @if(!measureList.length) {
            <div class="data-not-found-wrapper">ไม่พบข้อมูล กรุณาเพิ่มการวัดขนาดสินค้า</div>
            }
          </div>
          <!-- Pagination -->
          @if (measureList.length) {
          <div class="pagination-wrapper">
            <mat-paginator
              [pageIndex]="paginationMeasure.data.pageIndex"
              [length]="paginationMeasure.data.length"
              [pageSize]="paginationMeasure.data.pageSize"
              [pageSizeOptions]="paginationMeasure.data.pageSizeOptions"
              aria-label="Select page"
              (page)="paginationMeasure.setFromPaginator($event); getSizeMeasureList()">
            </mat-paginator>
          </div>
          }
        </div>
      </mat-tab>
      }
    </mat-tab-group>
  </div>
</div>
