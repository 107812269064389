import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { UploadFileFlexibleComponent } from '../../../components/upload-file-flexible/upload-file-flexible'
import { SelectAddLinkComponent } from '../../../components/select-add-link/select-add-link'
import { ModalPreviewComponent } from '../../../components/modal-preview/modal-preview'
import { SectionProductComponent } from './section-product/section-product'
import { CMSCampaignService } from '../../../services/cms-campaign.service'
import { StatusComponent } from '../../../components/status/status'
@Component({
  selector: 'app-cms-campaign',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    UploadFileFlexibleComponent,
    SelectAddLinkComponent,
    SectionProductComponent,
    StatusComponent,
  ],
  templateUrl: './cms-campaign.html',
  styleUrls: ['./cms-campaign.scss'],
})
export class CMSCampaignComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false
  dataManage: any
  id: any = 1

  menuLink = new FormArray<FormGroup>([
    new FormGroup({
      name: new FormControl('1'),
      menu_link_type: new FormControl(),
      menu_link_name: new FormControl(),
    }),
    new FormGroup({
      name: new FormControl('2'),
      menu_link_type: new FormControl(),
      menu_link_name: new FormControl(),
    }),
    new FormGroup({
      name: new FormControl('3'),
      menu_link_type: new FormControl(),
      menu_link_name: new FormControl(),
    }),
  ])

  form = new FormGroup({
    color_text_content: new FormControl('white'),
    title_content_th: new FormControl(),
    title_content_en: new FormControl(),
    remark_content_th: new FormControl(),
    remark_content_en: new FormControl(),
    banner_file: new FormControl(),
    banner_link_type: new FormControl(),
    banner_link_name: new FormControl(),
    menu_link: this.menuLink,
    category_product: new FormControl(),
  })

  formSectionProduct = new FormGroup({
    type: new FormControl('two'),
    product_image_single: new FormControl(),
    product_image_one: new FormControl(),
    product_image_two: new FormControl(),
    link_product_single_type: new FormControl(),
    link_product_single_name: new FormControl(),
    link_product_one_type: new FormControl(),
    link_product_one_name: new FormControl(),
    link_product_two_type: new FormControl(),
    link_product_two_name: new FormControl(),
    category_product: new FormControl(),
    product_list: new FormControl([]),
  })

  sectionProductArr = new FormArray([this.formSectionProduct])

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public CMSCampaignService: CMSCampaignService
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    this.initData()
  }

  initData() {
    this.profile.getProfile() // add for permission
    const apis = [this.CMSCampaignService.getCmsCampaign()]

    // this.loading.start()
    // forkJoin(apis).subscribe(([resCampaign]: any) => {
    //   if (resCampaign) {
    //     if (!resCampaign.is_error) {
    //       this.dataManage = resCampaign.data

    //       if (!this.dataManage?.id) {
    //         this.router.navigate(['/setting/cms/campaign/edit'])
    //       }
    //       this.form.reset({})
    //     } else {
    //       this.customSnackBar.fail(resCampaign.message)
    //     }
    //   }
    //   this.loading.stop()
    // })
  }

  deleteSection(index: number) {
    this.sectionProductArr.removeAt(index)
  }

  openPreview() {
    const dialogRefPreview = this.dialog.open(ModalPreviewComponent, {
      data: {},
    })
    dialogRefPreview.afterClosed().subscribe(result => {
      if (result) {
      }
    })
    return dialogRefPreview
  }

  onSave(type: any) {
    let payload = {
      id: this.dataManage?.id || undefined,
      bhb_subject_th: '',
      bhb_subject_en: '',
      is_public: type == 'public' ? true : false,
    }
    // return console.log(payload)

    this.loading.start()
    this.CMSCampaignService.addCmsCampaign(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success(type == 'public' ? 'เผยแพร่สำเร็จ' : 'บันทึกร่างสำเร็จ')
          this.router.navigate(['/setting/cms/campaign/view'])
        } else {
          if (res.errors[0]?.field) {
            setErrorForm(this.form, res.errors)
          } else {
            this.customSnackBar.failSave(res.message)
          }
        }
      }
      this.loading.stop()
    })
  }
}
