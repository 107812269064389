<div class="tabs-wrapper">
  <mat-tab-group
    animationDuration="0ms"
    [disableRipple]="true"
    [selectedIndex]="tab"
    (selectedIndexChange)="changeTab($event)">
    <mat-tab label="ข้อมูลทั่วไป">
      <div class="tabs-content-wrapper">
        <div class="top-content-wrapper">
          <div class="top-content-left mb-3">
            <app-search
              [control]="filterTableHistoryProductAddStock.search"
              placeholder="ค้นหา"
              (onEnter)="filterTableHistoryProductAddStock.confirm();paginationHistory.reset();getList();itemChecked.clear()"></app-search>
          </div>
          <div class="top-content-right mb-3">
            <div class="date-range-wrapper">
              <label class="label"> วันที่ทำรายการ </label>

              <app-date-picker-range
                [controlStr]="form.controls['start_date']"
                [controlEnd]="form.controls['end_date']"
                [hideError]="true"
                (onChange)="paginationHistory.reset();getList();itemChecked.clear()"></app-date-picker-range>
            </div>

            @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW])) {
            <button
              matRipple
              class="btn btn-outline-secondary"
              (click)="openModalDownloadFile('ดาวน์โหลดไฟล์การเพิ่มสต๊อกสินค้า', { message: 'คุณต้องการดาวน์โหลดไฟล์การเพิ่มสต๊อกสินค้าที่เลือกใช่หรือไม่' })">
              <span class="icon material-symbols-outlined fill">download_2</span>
              ส่งออกไฟล์
            </button>
            }
          </div>
        </div>

        <div class="table-responsive table-main-tabs-wrapper">
          <table class="table table-fixed-column">
            <thead>
              <tr>
                @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY],
                [ACTION_ACCESS.VIEW])) {
                <th width="70" class="colum-checkbox">
                  <app-checkbox-all-table
                    [checkedValue]="itemChecked"
                    [datas]="historyList"
                    fieldValue="id"></app-checkbox-all-table>
                </th>
                }
                <th width="160">รหัสสินค้า</th>
                <th width="240">ชื่อสินค้า</th>
                <th width="130" class="text-center">จำนวน</th>
                <th width="160" class="text-end">ต้นทุนต่อหน่วย (บาท)</th>
                <th width="200">ผู้ขาย</th>
                <th width="160">ทำรายการโดย</th>
                <th width="160"><app-sort-by key="date">วันที่ทำรายการ</app-sort-by></th>
              </tr>
            </thead>

            <tbody>
              @for (item of historyList; track item) {
              <tr>
                @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY],
                [ACTION_ACCESS.VIEW])) {
                <td class="colum-checkbox">
                  <app-checkbox-table
                    [checkedValue]="itemChecked"
                    [value]="item.id"></app-checkbox-table>
                </td>
                }
                <td>{{ item.item.code }}</td>
                <td>{{ item.item.name }}</td>
                <td class="text-center">{{ formatPriceAutoDecimal(item.stock) }}</td>
                <td class="text-end">{{ formatPrice(item.cost_price) }}</td>
                <td>{{ item.supplier?.name || '-' }}</td>
                <td>{{ item.created_by?.full_name }}</td>
                <td>{{ Moment(item.created_at).format('DD-MM-YYYY HH:mm') }}</td>
              </tr>
              }
            </tbody>
          </table>

          @if(!historyList.length) {
          <div class="data-not-found-wrapper">ไม่พบข้อมูลการเพิ่มสต๊อกสินค้า</div>
          }
        </div>

        <!-- Pagination -->
        @if (historyList.length) {
        <div class="pagination-wrapper">
          <mat-paginator
            [pageIndex]="paginationHistory.data.pageIndex"
            [length]="paginationHistory.data.length"
            [pageSize]="paginationHistory.data.pageSize"
            [pageSizeOptions]="paginationHistory.data.pageSizeOptions"
            aria-label="Select page"
            (page)="paginationHistory.setFromPaginator($event);getList()">
          </mat-paginator>
        </div>
        }
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
