@if (type == 'edit') {
<input
  type="file"
  [id]="uuid"
  [accept]="acceptFileTypes.toString()"
  class="input-upload"
  #fileUpload
  (change)="onChangeUpload(fileUpload)" />

@if (logoUrl) {
<div class="upload-image-box-inner d-flex justify-content-start align-items-center">
  @if (logoUrl) {
  <button class="btn-close-img" matRipple (click)="isDelete()">
    <span class="font-14 icon-white material-symbols-outlined"> close </span>
  </button>
  }

  <label
    class="upload-image-inner {{preview == 'square' && 'square'}}"
    [for]="uuid"
    (dragover)="dragOver($event)"
    (drop)="drop($event)">
    <!-- <img alt="Uploaded Image" class="upload-image" [src]="logoUrl" /> -->

    @if (isImage) {
    <img alt="Uploaded Image" class="upload-image" [src]="logoUrl" />
    }@else {
    <video [src]="logoUrl" [controls]="true"></video>
    }
  </label>
</div>

} @else {
<div class="drop-wrapper d-flex justify-content-start align-items-center">
  <label
    class="upload-file-wrapper d-flex flex-column justify-content-center align-items-center py-4 my-sm-3 mb-2 color--brand-blue--f"
    [class.error]="error"
    [for]="uuid"
    (dragover)="dragOver($event)"
    (drop)="drop($event)">
    <span class="icon-20 icon-blue material-symbols-outlined fill"> add_circle </span>
    เพิ่มรูป @if (!imgOnly) {/วีดิโอ}
  </label>
</div>
} } @else {
<ng-container>
  <!-- Uploaded Image -->
  <div class="upload-image-box-inner d-flex justify-content-start align-items-center">
    <div class="upload-image-inner {{preview == 'square' && 'square'}}">
      @if (isImage) {
      <img alt="Uploaded Image" class="upload-image" [src]="imgUrl" />
      }@else {
      <video [src]="imgUrl"></video>
      }
    </div>
  </div>
</ng-container>
} @if (error) {
<div class="mt-1 text-error">
  @if (is_error) { {{ error_massage }} } 
  @else if (isRequired) { 
    @if (imgOnly) { กรุณาเพิ่มรูปภาพ }
    @else if (!isImage) { กรุณาเพิ่มรูป/วีดีโอ } }
</div>
}
