<div class="card-product">
  <div class="product-image" [class.default]="!productData.colorImage?.url">
    <img
      [src]="productData.colorImage?.url || '/assets/preview/logos/logo-gray.svg'"
      alt="prduct name"
      class="cursor-pointer" />
  </div>

  <div class="product-detail mt-2">
    <div class="d-flex gap-2">
      <span class="text-new"> New </span>
      <!-- <span class="tag-sales"> -40% [[TODO]]</span>
      <img src="/assets/preview/icons/icon-ligtning-light.svg" width="24" /> -->
    </div>
    <!-- ~ -->
    <div class="d-flex align-items-center gap-2 sm:mt-2">
      <span class="product-name flex-1"> {{ productData.color_detail_with_default.name }} </span>

      <div>
        <img
          src="/assets/preview/icons/icon-bag-black.svg"
          alt="product name"
          width="20"
          class="cursor-pointer" />
      </div>
    </div>
    <!-- ~ -->
    <div class="product-price d-flex gap-2 sm:mt-1">
      <span class="text-base-regular">
        ฿ {{ formatPrice(productData.color_default_selling_price) }}
      </span>
      <!-- <span class="price-default"> ฿ 22,500.00 </span>
      <span class="price-sales"> ฿ 16,500.00 </span> -->
    </div>
    <!-- ~ -->
    @if (productData.showDotColor) {
    <div class="d-flex flex-wrap column-gap-2">
      @for (item of productData.colorDetails; track $index) {
      <!-- ~ -->
      @if (item.product_color) {
      <div class="cursor-pointer">
        <app-product-dot-color
          [fill]="item.product_color.color_code"
          [active]="productData.activeColorIndex == $index"
          (click)="productData.activeColor($index)"></app-product-dot-color>
      </div>
      }
      <!-- ~ -->
      }
    </div>
    }
  </div>
</div>
