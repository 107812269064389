<div class="tabs-wrapper">
  <mat-tab-group
    animationDuration="0ms"
    [disableRipple]="true"
    [selectedIndex]="tab"
    (selectedIndexChange)="changeTab($event)">
    <mat-tab label="ข้อมูลทั่วไป">
      <div class="tabs-content-wrapper">
        <div class="top-content-wrapper">
          <div class="top-content-left">
            <app-search-filter-payment
              placeholder="ค้นหา"
              (onConfirm)="paginationHistory.reset();getList();itemChecked.clear()"></app-search-filter-payment>
          </div>
          <div class="top-content-right mb-3">
            <div class="date-range-wrapper">
              <label class="label"> วันที่ทำรายการ </label>

              <app-date-picker-range
                [controlStr]="form.controls['start_date']"
                [controlEnd]="form.controls['end_date']"
                [hideError]="true"
                (onChange)="paginationHistory.reset();getList();itemChecked.clear()"></app-date-picker-range>
            </div>

            @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW])) {
            <button
              matRipple
              class="btn btn-outline-secondary"
              (click)="openModalDownloadFile('ดาวน์โหลดไฟล์การชำระเงิน', { message: 'คุณต้องการดาวน์โหลดไฟล์การชำระเงินที่เลือกใช่หรือไม่' })">
              <span class="icon material-symbols-outlined fill">download_2</span>
              ส่งออกไฟล์
            </button>
            }
          </div>
        </div>

        <div class="table-responsive table-main-tabs-wrapper">
          <table class="table table-fixed-column">
            <thead>
              <tr>
                @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY],
                [ACTION_ACCESS.VIEW])) {
                <th width="70" class="colum-checkbox">
                  <app-checkbox-all-table
                    [checkedValue]="itemChecked"
                    [datas]="historyList"
                    fieldValue="id"></app-checkbox-all-table>
                </th>
                }
                <th width="150">เลขที่ออเดอร์</th>
                <th width="230">ชื่อลูกค้า</th>
                <th width="200">รหัสการทำรายการ</th>
                <th width="200">ช่องทางการชำระเงิน</th>
                <th width="150" class="text-end">ยอดชำระ</th>
                <th width="150">
                  <app-sort-by
                    key="created_at"
                    [pagination]="paginationHistory"
                    (onChange)="getList()">
                    วันที่ทำรายการ
                  </app-sort-by>
                </th>
                <th width="150">สถานะรายการ</th>
              </tr>
            </thead>

            <tbody>
              @for (item of historyList; track item) {
              <tr>
                @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY],
                [ACTION_ACCESS.VIEW])) {
                <td class="colum-checkbox">
                  <app-checkbox-table
                    [checkedValue]="itemChecked"
                    [value]="item.id"></app-checkbox-table>
                </td>
                }
                <td>{{ item.order_no }}</td>
                <td>{{ item.customer_name }}</td>
                <td>{{ item.transaction_no || '-' }}</td>
                <td>{{ item.payment_method }}</td>
                <td class="text-end">{{ formatPrice(item.payment_amount) }}</td>
                <td>{{ Moment(item.created_at).format('DD-MM-YYYY HH:mm') }}</td>
                <td>
                  <app-status [status]="item.is_success ? 3 : 1">
                    {{ item.is_success ? 'สำเร็จ' : 'ไม่สำเร็จ' }}
                  </app-status>
                </td>
              </tr>
              }
            </tbody>
          </table>

          @if(!historyList.length) {
          <div class="data-not-found-wrapper">ไม่พบข้อมูลการชำระเงิน</div>
          }
        </div>

        <!-- Pagination -->
        @if (historyList.length) {
        <div class="pagination-wrapper">
          <mat-paginator
            [pageIndex]="paginationHistory.data.pageIndex"
            [length]="paginationHistory.data.length"
            [pageSize]="paginationHistory.data.pageSize"
            [pageSizeOptions]="paginationHistory.data.pageSizeOptions"
            aria-label="Select page"
            (page)="paginationHistory.setFromPaginator($event);getList()">
          </mat-paginator>
        </div>
        }
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
