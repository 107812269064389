<div class="page-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">ข้อมูลระดับสมาชิก</div>
  </div>

  <div class="title-wrapper">ข้อมูลระดับสมาชิก</div>
  <div class="page-content-inner">
    <div class="top-content-wrapper mb-3">
      <div class="top-content-left">
        <app-search-filter-tier
          placeholder="ค้นหา"
          (onConfirm)="paginationTier.reset();getTierList()"></app-search-filter-tier>
      </div>
      <div class="top-content-right">
        @if (profile.permissions.checkAccess([APP_PERMISSION.TIER], [ACTION_ACCESS.CREATE])) {
        <button
          matRipple
          class="btn btn-primary"
          (click)="openModalTier('เพิ่มระดับสมาชิก', 'add')">
          <span class="icon material-symbols-outlined fill">add_circle</span>
          เพิ่มระดับสมาชิก
        </button>
        }
      </div>
    </div>

    <!-- Table -->
    <div class="table-responsive table-main-wrapper">
      <table class="table table-fixed-column">
        <thead>
          <tr>
            <th width="180">ระดับสมาชิก</th>
            <th width="180">สีระดับสมาชิก</th>
            <th width="180" class="text-end">ยอดสั่งซื้อขั้นต่ำ (บาท)</th>
            <th width="180" class="text-end">ยอดสั่งซื้อสูงสุด (บาท)</th>
            <!-- <th width="200">คำอธิบาย</th> -->
            <th width="120">สถานะ</th>
            <th width="150">อัปเดตโดย</th>
            <th width="170">อัปเดตล่าสุด</th>
            <th width="120" class="fixed box-shadow-left">จัดการ</th>
          </tr>
        </thead>

        <tbody>
          @for (d of tierList; track $index) {
          <tr>
            <td>{{ d.name }}</td>
            <td>
              <app-label-tier-color [color]="d.color"> {{ d.name }} </app-label-tier-color>
            </td>
            <td class="text-end">{{ formatPrice(d.min_amount) }}</td>
            <td class="text-end">{{ formatPrice(d.max_amount) }}</td>
            <!-- <td>
              @if (d.description) {
              <div [innerHtml]="sanitizer.bypassSecurityTrustHtml(d.description)"></div>
              } @else { - }
            </td> -->
            <td>
              <app-status-user [status]="d.is_active ? 'active' : 'inactive'">
                {{ d.is_active ? 'ใช้งาน' : 'ไม่ใช้งาน' }}
              </app-status-user>
            </td>
            <td>{{d.updated_by ? d.updated_by.full_name : ''}}</td>
            <td>{{ d.updated_at ? Moment(d.updated_at).format('DD-MM-YYYY, HH:mm') : '' }}</td>
            <td class="fixed box-shadow-left">
              <div class="d-flex justify-content-center">
                @if (d.action.edit) {
                <button
                  matRipple
                  matTooltip="แก้ไข"
                  matTooltipPosition="above"
                  class="btn btn-outline-secondary btn-icon"
                  (click)="openModalTier('แก้ไขระดับสมาชิก', 'edit', d)">
                  <span class="icon material-symbols-outlined fill">edit</span>
                </button>
                }
              </div>
            </td>
          </tr>
          }
        </tbody>
      </table>

      @if(!tierList.length) {
      <div class="data-not-found-wrapper">ไม่พบข้อมูลระดับสมาชิก กรุณาเพิ่มข้อมูลระดับสมาชิก</div>
      }
    </div>

    <!-- Pagination -->
    @if (tierList.length) {
    <div class="pagination-wrapper">
      <mat-paginator
        [pageIndex]="paginationTier.data.pageIndex"
        [length]="paginationTier.data.length"
        [pageSize]="paginationTier.data.pageSize"
        [pageSizeOptions]="paginationTier.data.pageSizeOptions"
        aria-label="Select page"
        (page)="paginationTier.setFromPaginator($event);getTierList()"
        aria-label="Select page">
      </mat-paginator>
    </div>
    }
  </div>
</div>
