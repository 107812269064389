export const GENDER_ID = {
  NONE: 1,
  MALE: 2,
  FEMALE: 3,
  OTHER: 4,
} as const

export const productImage = (product: any, product_detail?: any) => {
  const files = product_detail?.files || product.files || []
  return files.find((f: any) => f.is_cover) || files[0]
}

export const imgProductDefault = '/assets/images/icons/img-default-table.svg'

export const productKey = (product_id: any, product_detail_id: any) => {
  return `${product_id}-${product_detail_id || ''}`
}

export const getDifferenceProductKey = (array1: any, array2: any) => {
  return array1.filter((object1: any) => {
    return !array2.some((object2: any) => {
      return (
        productKey(object1.product_id, object1.product_detail_id) ===
        productKey(object2.product_id, object2.product_detail_id)
      )
    })
  })
}
