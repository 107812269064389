import { Component } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../../globals'
import { ModalLeaveComponent } from '../../../../../components/modal-leave/modal-leave'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm'
import { ModalDeleteComponent } from '../../../../../components/modal-delete/modal-delete'
import {
  BANK_NONE,
  DownloadFileBlob,
  EmailReg,
  ValidateForm,
  setErrorForm,
} from '../../../../../helpers'
import { InputComponent } from '../../../../../components/input/input'
import { SelectComponent } from '../../../../../components/select/select'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { TextareaComponent } from '../../../../../components/text-area/text-area'
import { DatePickerComponent } from '../../../../../components/date-picker/date-picker'
import { InputNumberComponent } from '../../../../../components/input-number/input-number'
import { MatSlideToggle } from '@angular/material/slide-toggle'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { StatusUserComponent } from '../../../../../components/status-user/status-user'
import { FileService, MasterService, SupplierService } from '../../../../../services'
import { forkJoin } from 'rxjs'
import { imports } from '../../../../../imports'

@Component({
  selector: 'app-supplier-detail',
  standalone: true,
  imports: [
    ...imports,
    MatTabsModule,
    ModalLeaveComponent,
    ModalConfirmComponent,
    ModalDeleteComponent,
    InputComponent,
    SelectComponent,
    ReactiveFormsModule,
    MatCheckboxModule,
    TextareaComponent,
    DatePickerComponent,
    InputNumberComponent,
    MatSlideToggle,
    StatusUserComponent,
  ],
  templateUrl: './supplier-detail.html',
  styleUrls: ['./supplier-detail.scss'],
})
export class SupplierDetailComponent {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS

  isEdit: boolean = false
  isCreate: boolean = false
  isView: boolean = false

  errorUpload = ''
  form = new FormGroup({
    bank_id: new FormControl(''),
    bank_account: new FormControl('', [Validators.pattern(/^[0-9]{10,14}$/)]),
    bank_account_name: new FormControl('', [Validators.maxLength(100)]),
    branch_code: new FormControl('', [Validators.pattern(/^[0-9]+$/)]),
    branch_name: new FormControl('', [Validators.maxLength(100)]),
    code: new FormControl('', [Validators.required, Validators.maxLength(10)]),
    name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    tax_id: new FormControl('', [Validators.pattern(/^[0-9]{13,13}$/)]),
    address: new FormControl(''),
    telephone: new FormControl('', [Validators.pattern(/^[0-9]{10,10}$/)]),
    email: new FormControl('', [Validators.pattern(EmailReg), Validators.maxLength(100)]),
    is_active: new FormControl(true),
    files: new FormControl([]),
  })

  list = {
    bank: [],
  }

  dataManage: any

  get files() {
    return this.form.controls['files'].value || <any[]>[]
  }

  constructor(
    public route: ActivatedRoute,
    public Router: Router,
    public dialog: MatDialog,
    public profile: Profile,
    public router: Router,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public supplierService: SupplierService,
    public masterService: MasterService,
    public fileService: FileService
  ) {}

  ngOnInit() {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    if (this.router.url.includes('create')) {
      this.isCreate = true
    }
    this.route.params.subscribe(params => {
      this.initData(params['id'])
    })
  }

  initData(id: any = null) {
    const apis = [this.masterService.getBanks()]

    if (this.isEdit || this.isView) {
      apis.push(this.supplierService.getSupplier(id))
    }

    this.loading.start()
    forkJoin(apis).subscribe(([resBank, resSupplier]: any) => {
      if (resBank) {
        if (!resBank.is_error) {
          this.list.bank = resBank.data.filter((b: any) => b.code != BANK_NONE.CODE)
        }
      }

      if (resSupplier) {
        if (!resSupplier.is_error) {
          this.dataManage = resSupplier.data
          this.form.reset({
            bank_id: this.dataManage.bank_id,
            bank_account: this.dataManage.bank_account,
            bank_account_name: this.dataManage.bank_account_name,
            branch_code: this.dataManage.branch_code,
            branch_name: this.dataManage.branch_name,
            code: this.dataManage.code,
            name: this.dataManage.name,
            tax_id: this.dataManage.tax_id,
            address: this.dataManage.address,
            telephone: this.dataManage.telephone,
            email: this.dataManage.email,
            is_active: this.dataManage.is_active,
            files: (this.dataManage.files || []).map((d: any) => d.file),
          })
        } else {
          this.customSnackBar.fail(resSupplier.message)
        }
      }

      this.loading.stop()
    })
  }

  uploadFile(e: any) {
    const files = e.target.files
    this.errorUpload = ''
    if (files.length) {
      for (const file of files) {
        if (file.size > 10 * Math.pow(1024, 2)) {
          this.errorUpload = 'ขนาดไฟล์เกิน 10 Mb'
          return
        }
      }

      this.loading.start()
      this.fileService.uploads(files).subscribe((res: any) => {
        if (res) {
          if (!res.is_error) {
            const value = [...res.data, ...this.files].slice(0, 3) as never[]
            this.form.controls['files'].setValue(value)
          } else {
            this.errorUpload = 'เกิดข้อผิดพลาด กรุณาอัพโหลดไฟล์อีกครั้ง'
          }
        }
        this.loading.stop()
      })
    }
    e.target.value = ''
  }

  delFile(i: number) {
    const value = [...this.files] as never[]
    value.splice(i, 1)
    this.form.controls['files'].setValue(value)
  }

  confirmDelete() {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบ “${this.dataManage.code}” ออกจากผู้ขายใช่หรือไม่`,
      },
    })

    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        this.deleteSupplier()
      }
    })

    return dialogRefDelete
  }

  deleteSupplier() {
    this.loading.start()
    this.supplierService.deleteSupplier(this.dataManage.id).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('ลบข้อมูลสำเร็จ')
          this.toList()
        } else {
          this.customSnackBar.fail(res.message || 'ลบข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }

  onConfirm(): void {
    ValidateForm(this.form)
    if (!this.form.valid) return

    if (this.isEdit) {
      this.openModalConfirm()
    } else {
      this.onSave()
    }
  }

  openModalConfirm() {
    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.onSave()
      }
    })

    return dialogRefConfirm
  }

  onSave(): void {
    const value = this.form.getRawValue()
    const payload = {
      bank_id: value.bank_id || null,
      bank_account: value.bank_account,
      bank_account_name: value.bank_account_name,
      branch_code: value.branch_code,
      branch_name: value.branch_name,
      code: value.code,
      name: value.name,
      tax_id: value.tax_id,
      address: value.address,
      telephone: value.telephone,
      email: value.email,
      is_active: value.is_active,
      files: (value.files || []).map((d: any) => ({
        file_id: d.id,
      })),
    }

    // return console.log(payload)

    const api = this.isEdit
      ? this.supplierService.updateSupplier(this.dataManage.id, payload)
      : this.supplierService.addSupplier(payload)

    this.loading.start()
    api.subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
          this.isSaved = true
          this.toView(this.isCreate ? res.data : this.dataManage.id)
        } else {
          if (res.errors[0]?.field) {
            setErrorForm(this.form, res.errors)
          } else {
            this.customSnackBar.failSave(res.message)
          }
        }
      }
      this.loading.stop()
    })
  }

  toList() {
    this.router.navigate(['/customer'], {
      queryParams: {
        tab: 1,
      },
    })
  }

  toView(id = this.dataManage.id) {
    this.router.navigate(['/supplier/view/' + id])
  }

  isSaved = false
  openModalCancel(resolveFromRouter: (value: boolean) => void): void {
    if (this.isEdit && this.form.dirty && !this.isSaved) {
      const dialogCancel = this.dialog.open(ModalLeaveComponent, {
        data: {},
      })

      dialogCancel.afterClosed().subscribe(result => {
        resolveFromRouter(!!result)
      })
    } else {
      resolveFromRouter(true)
    }
  }

  downloadFile(file: any) {
    this.loading.start()
    this.fileService.getBlob(file.id).subscribe((res: any) => {
      if (res) {
        DownloadFileBlob(res.body, file.name)
        this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
      } else {
        this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
      }
      this.loading.stop()
    })
  }
}
