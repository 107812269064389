<div class="page-content pb-0">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">หน้าหลัก</div>
  </div>

  <div class="title-wrapper">หน้าหลัก</div>

  <div class="page-content-inner d-flex flex-column pb-0">
    <!-- @if (isView && dataManage) { -->
    <div class="w-100 d-flex justify-content-between flex-wrap mb-4 mb-sm-0">
      <div class="d-flex flex-column">
        @if (dataManage) {
        <div class="form-label color--neutral-500">
          วันที่อัปเดต: {{ Moment(dataManage?.updated_at).format('DD-MM-YYYY, HH:mm') }}
          <br class="d-flex d-sm-none mt-1 mt-sm-0" />
          อัปเดตโดย: {{ dataManage?.updated_by?.full_name || '-' }}
        </div>
        <div class="mb-3">
          <app-status [status]="dataManage?.is_public ? 'public' : 'not_public'">
            {{dataManage?.is_public ? 'เผยแพร่แล้ว' : 'ยังไม่เผยแพร่'}}
          </app-status>
        </div>
        }
      </div>
      <button matRipple class="btn btn-primary" (click)="openModalManageHome()">
        <span class="icon material-symbols-outlined fill">settings</span>
        <span> จัดการเมนูหน้าหลัก </span>
      </button>
    </div>
    <!-- } @else {
    <div class="w-100 d-flex justify-content-end mb-4 mb-sm-0">
      <button matRipple class="btn btn-primary" (click)="openModalManageHome()">
        <span class="icon material-symbols-outlined fill">settings</span>
        <span> จัดการเมนูหน้าหลัก </span>
      </button>
    </div>
    } -->

    <div class="section-container">
      <div class="title-section mb-3">แบนเนอร์หลัก</div>

      <div class="section-wrapper">
        <div class="row">
          <div class="col-12 mb-4">
            <label class="form-label"> สีตัวอักษรคอนเทนต์ </label>

            <div class="radio-button-wrapper" [style]="{ 'margin-left': '-10px' }">
              <mat-radio-group [formControl]="form.controls.is_content_white">
                @if (isEdit || form.controls.is_content_white.value === true) {
                <mat-radio-button [value]="true" class="me-2">สีขาว</mat-radio-button>
                }
                <!-- ~ -->
                @if (isEdit || form.controls.is_content_white.value === false) {
                <mat-radio-button [value]="false">สีดำ</mat-radio-button>
                }
              </mat-radio-group>
            </div>
          </div>

          <div class="col-12 mb-4">
            <label class="form-label" [ngClass]="{ 'form-label-view': isView }">
              หัวข้อคอนเทนต์ (ภาษาไทย)
            </label>
            @if (isEdit) {
            <app-input [control]="form.controls['content_th']" remainingText="100"> </app-input>
            } @else {
            <div>{{ form.controls['content_th'].value || '-' }}</div>
            }
          </div>

          <div class="col-12 mb-4">
            <label class="form-label" [ngClass]="{ 'form-label-view': isView }">
              หัวข้อคอนเทนต์ (ภาษาอังกฤษ)
            </label>
            @if (isEdit) {
            <app-input [control]="form.controls['content_en']" remainingText="100"> </app-input>
            } @else {
            <div>{{ form.controls['content_en'].value || '-' }}</div>
            }
          </div>

          <div class="col-12 mb-4">
            <label class="form-label" [ngClass]="{ 'form-label-view': isView }">
              คำอธิบายหัวข้อคอนเทนต์ (ภาษาไทย)
            </label>
            @if (isEdit) {
            <app-input [control]="form.controls['content_description_th']" remainingText="100">
            </app-input>
            } @else {
            <div>{{ form.controls['content_description_th'].value || '-' }}</div>
            }
          </div>

          <div class="col-12 mb-4">
            <label class="form-label" [ngClass]="{ 'form-label-view': isView }"
              >คำอธิบายหัวข้อคอนเทนต์ (ภาษาอังกฤษ)</label
            >
            @if (isEdit) {
            <app-input [control]="form.controls['content_description_en']" remainingText="100">
            </app-input>
            } @else {
            <div>{{ form.controls['content_description_en'].value || '-' }}</div>
            }
          </div>

          <div class="col-12 mb-4">
            <label class="form-label"
              >ภาพแบนเนอร์ @if (isEdit) {<span class="color--red-600">*</span>}
            </label>

            <div class="section-upload-container">
              @if (isEdit) {
              <div class="remark mt-0 mb-2 my-sm-2">
                (ขนาดรูปภาพ : 1440px x 1024px / ไม่เกิน 5 Mb) | (ขนาดวีดิโอ : 1920px x 1080px /
                ไม่เกิน 30 Mb)
              </div>
              }
              <div class="upload-file-wrapper">
                <app-upload-file-flexible
                  [type]="isEdit ? 'edit' : 'view'"
                  [maxImageSize]="5"
                  [maxVideoSize]="30"
                  [reset]="reset"
                  [isRequired]="form.controls['file_banner_id'].touched && form.controls['file_banner_id'].invalid"
                  [logoDefault]="dataManage?.file_main_banner_file?.url"
                  (upload)="form.controls['file_banner_id'].setValue($event)" />
              </div>

              <label class="form-label mt-2" [ngClass]="{ 'form-label-view': isView }"
                >เพิ่มลิงก์</label
              >
              @if (isEdit) {
              <app-select-add-link
                [trigger_name]="form.value.product_name || form.value.product_category_name || form.value.link_path"
                [controlLinkType]="form.controls['cms_link_list_id']"
                [product_id]="form.controls['product_id'].value"
                [product_detail_id]="form.controls['product_detail_id'].value"
                [product_category_id]="form.controls['product_category_id'].value"
                [link_path]="form.controls['link_path'].value"
                (onProductId)="form.controls['product_id'].setValue($event)"
                (onProductDetailId)="form.controls['product_detail_id'].setValue($event)"
                (onCategoryId)="form.controls['product_category_id'].setValue($event)"
                (onLinkPath)="form.controls['link_path'].setValue($event)" />
              } @else {
              <div>
                {{ linkTextView(form.controls['cms_link_list_id'].value, form.value.product_name,
                form.value.product_category_name, form.value.link_path) || '-' }}
              </div>
              }
            </div>
          </div>
        </div>

        <div class="title-section mb-3">ลิงก์แถบเมนู</div>

        <div
          id="all"
          cdkDropList
          [cdkDropListData]="cms_pania_link_menus.controls"
          (cdkDropListDropped)="drop($event)"
          [cdkDropListDisabled]="isView"
          class="drag-list mt-3 w-100">
          @for (item of cms_pania_link_menus.controls; track item; let i = $index) {
          <div class="d-flex drag-box mb-0 mb-sm-4">
            @if (isEdit) {
            <div class="pre-box">
              <label class="form-label">&nbsp;</label>

              <button
                class="btn btn-transparent-outline me-1"
                matRipple
                cdkDragHandle
                cdkDrag
                [cdkDragData]="item">
                <span class="icon icon-dark-grey material-symbols-outlined fill">
                  drag_indicator
                </span>
              </button>
            </div>
            }

            <div class="w-100">
              <div class="row">
                <div class="col-12 col-sm-6 mb-4 mb-sm-0">
                  <label class="form-label" [ngClass]="{ 'form-label-view': isView }"
                    >ชื่อลิงก์</label
                  >
                  @if (isEdit) {
                  <app-input [control]="item.controls['link_name']" remainingText="100">
                  </app-input>
                  } @else {
                  <div>{{ item.controls['link_name'].value || '-' }}</div>
                  }
                </div>

                <div class="col-12 col-sm-6 mb-4 mb-sm-0">
                  <label class="form-label" [ngClass]="{ 'form-label-view': isView }"
                    >เพิ่มลิงก์</label
                  >
                  @if (isEdit) {
                  <app-select-add-link
                    [trigger_name]="item.value.product_name || item.value.product_category_name || item.value.link_path"
                    [controlLinkType]="item.controls['cms_link_list_id']"
                    [product_id]="item.controls['product_id'].value"
                    [product_detail_id]="item.controls['product_detail_id'].value"
                    [product_category_id]="item.controls['product_category_id'].value"
                    [link_path]="item.controls['link_path'].value"
                    (onProductId)="item.controls['product_id'].setValue($event)"
                    (onProductDetailId)="item.controls['product_detail_id'].setValue($event)"
                    (onCategoryId)="item.controls['product_category_id'].setValue($event)"
                    (onLinkPath)="item.controls['link_path'].setValue($event)" />
                  } @else {
                  <div>
                    {{ linkTextView(item.controls['cms_link_list_id'].value,
                    item.controls['product_name'].value,
                    item.controls['product_category_name'].value, item.controls['link_path'].value)
                    || '-' }}
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>

      @for (item of sectionProductArr.controls; track item; let i = $index) {
      <app-section-product
        [isEdit]="isEdit"
        [isView]="isView"
        [reset]="reset"
        [form]="item"
        [sectionIndex]="i"
        (deleteSection)="deleteSection($event)"
        [showButtonDelete]="i > 0 && !isView" />
      }
      <!-- ~ -->
      @if (isEdit) {
      <div class="w-100 d-flex justify-content-center">
        <button
          matRipple
          class="btn btn-outline-secondary me-1 mt-4 w-fit"
          (click)="addSection()"
          [disabled]="sectionProductArr.value.length == 3">
          <span class="icon material-symbols-outlined fill"> add_circle </span>
          เพิ่มชุดคอนเทนต์
        </button>
      </div>
      }
    </div>

    <div class="page-footer-btn">
      @if (isView) { @if (profile.permissions.checkAccess([APP_PERMISSION.CMS],
      [ACTION_ACCESS.UPDATE])) {
      <div class="d-flex flex-wrap justify-content-end">
        <button
          matRipple
          class="btn btn-outline-secondary min-w-165px"
          [routerLink]="['/setting/cms/home/edit']">
          แก้ไข
        </button>
      </div>
      } } @else if(isEdit) {
      <div class="footer-btn d-flex flex-wrap justify-content-between">
        <div class="footer-btn-left d-flex flex-wrap">
          <button matRipple class="btn btn-outline-danger min-w-165px" (click)="onReset()">
            ล้างข้อมูล
          </button>
          <button matRipple class="btn btn-outline-secondary min-w-165px" (click)="onCancel()">
            ยกเลิก
          </button>
        </div>

        <div class="footer-btn-right d-flex flex-wrap">
          <button matRipple class="btn btn-outline-secondary min-w-165px" (click)="openPreview()">
            ดูตัวอย่าง
          </button>
          @if (profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.UPDATE]) ||
          profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.CREATE])) {
          <button matRipple class="btn btn-outline-secondary min-w-165px" (click)="onSave(false)">
            บันทึกร่าง
          </button>
          <button matRipple class="btn btn-primary min-w-165px" (click)="onSave(true)">
            เผยแพร่ทันที
          </button>
          }
        </div>
      </div>
      }
    </div>
  </div>
</div>
