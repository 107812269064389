import { Component } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormArray,
} from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { ModalDeleteComponent } from '../../../components/modal-delete/modal-delete'
import {
  Ascending,
  COMPANY_ID,
  ValidateForm,
  formatPrice,
  productImage,
  productKey,
  setErrorForm,
  setErrorFormArray,
} from '../../../helpers'
import { InputComponent } from '../../../components/input/input'
import { SelectComponent } from '../../../components/select/select'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { TextareaComponent } from '../../../components/text-area/text-area'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import { MatSlideToggle } from '@angular/material/slide-toggle'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { StatusUserComponent } from '../../../components/status-user/status-user'
import { ModalAddStockComponent } from './product-stock/modal-add-stock/modal-add-stock'
import { ModalAdjustStockComponent } from './product-stock/modal-adjust-stock/modal-adjust-stock'
import { imports } from '../../../imports'
import { UploadImageTableComponent } from '../../../components/upload-image-table/upload-image-table'
import { ModalDownloadFileComponent } from '../../../components/modal-download-file/modal-download-file'
import { MatPaginator } from '@angular/material/paginator'
import { formatTwoDecimal } from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { UploadImageMoreComponent } from '../../../components/upload-image-more/upload-image-more'
import {
  MasterService,
  ProductBrandService,
  ProductCategoryService,
  ProductService,
  SupplierService,
} from '../../../services'
import { forkJoin } from 'rxjs'
import { InputTagComponent } from '../input-tag/input-tag'
import { ProductStockComponent } from './product-stock/product-stock'
import { ProductStockLogComponent } from './product-stock-log/product-stock-log'
import { ProductSellerListComponent } from './product-seller-list/product-seller-list'
import { ProductReturnLogComponent } from './product-return-log/product-return-log'
import { ModalErrorComponent } from '../../../components/modal-error/modal-error'
import { ModalModelComponent } from '../modal-model/modal-model'
import { CompleteTheLookComponent } from './set-complete-the-look/complete-the-look'
import { YouMayLikeColorComponent } from './set-you-may-like-color/you-may-like-color'
import { YouMayLikeStyleComponent } from './set-you-may-like-style/you-may-like-style'
import { ModalUploadImageComponent } from './modal-upload-image/modal-upload-image'
import { MatTooltip } from '@angular/material/tooltip'
import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop'
import { ModalColorProductComponent } from './modal-color-product/modal-color-product'
import { Service } from '../../../services/service'

@Component({
  selector: 'app-product-detail',
  standalone: true,
  imports: [
    ...imports,
    MatTabsModule,
    ModalLeaveComponent,
    ModalConfirmComponent,
    ModalDeleteComponent,
    InputComponent,
    SelectComponent,
    ReactiveFormsModule,
    MatCheckboxModule,
    TextareaComponent,
    DatePickerComponent,
    InputNumberComponent,
    MatSlideToggle,
    StatusUserComponent,
    ModalAddStockComponent,
    ModalAdjustStockComponent,
    StatusUserComponent,
    UploadImageTableComponent,
    ModalDownloadFileComponent,
    MatPaginator,
    UploadImageProfileComponent,
    UploadImageMoreComponent,
    InputTagComponent,
    ProductStockComponent,
    ProductSellerListComponent,
    ProductReturnLogComponent,
    ProductStockLogComponent,
    CompleteTheLookComponent,
    YouMayLikeColorComponent,
    YouMayLikeStyleComponent,
    MatTooltip,
    CdkDrag,
    CdkDropList,
  ],
  templateUrl: './product-detail.html',
  styleUrls: ['./product-detail.scss'],
})
export class ProductDetailComponent {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly COMPANY_ID = COMPANY_ID
  readonly formatPrice = formatPrice
  readonly formatTwoDecimal = formatTwoDecimal

  isEdit: boolean = false
  isCreate: boolean = false
  isView: boolean = false

  isRelatePage = {
    show: false,
    page: 0,
  }

  product_colors: any[] = []
  product_complete_looks: any[] = []
  product_may_like_colors: any[] = []
  product_may_like_styles: any[] = []

  product_detail_may_like_colors?: AbstractControl<any[]>

  form = new FormGroup({
    files: new FormControl<any[]>([]),
    code: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    real_code: new FormControl(''),
    name: new FormControl('', [Validators.required, Validators.maxLength(250)]),
    product_category_id: new FormControl(''),
    product_sub_category_id: new FormControl(''),
    product_brand_id: new FormControl(''),
    color: new FormControl('', [Validators.maxLength(15)]),
    color_code: new FormControl(''),
    size: new FormControl('', [Validators.maxLength(15)]),
    selling_price: new FormControl(null, [Validators.required, Validators.max(1000000000)]),
    barcode: new FormControl('', Validators.maxLength(250)),
    detail: new FormControl('', Validators.maxLength(250)),
    gender_id: new FormControl<number | null>(null),
    tags: new FormControl([]),
    is_carat: new FormControl(false),
    is_active: new FormControl(true),
    cms_size_guide_name: new FormControl(),
    cms_size_measurement_name: new FormControl(),

    percent_discount: new FormControl(null, [Validators.max(100)]),
    member_price: new FormControl<number | null>(null, [Validators.max(1000000000)]),
    description: new FormControl('', Validators.maxLength(300)),
    description_en: new FormControl('', Validators.maxLength(300)),
    file_model: new FormControl(),
    model_size: new FormControl(''),
    tall: new FormControl(null),
    model_unit: new FormControl(null),
    weight: new FormControl(null),
    shape: new FormControl(''),

    stock: new FormControl(null, [
      (control: AbstractControl) => {
        if (control.value && control.value > 1000000 && this.isCreate) {
          return {
            max: true,
          }
        }
        return null
      },
    ]),
    supplier_id: new FormControl(null),
    cost_price: new FormControl(null, [Validators.max(1000000000)]),
    total_cost_price: new FormControl(null),

    is_sub_product: new FormControl({ disabled: true, value: false }),
    details: new FormArray<FormGroup>([]),
  })

  imgStockDefault = '/assets/images/icons/img-default-table.svg'

  dataManage: any

  list = {
    gategory: <any[]>[],
    brand: <any[]>[],
    gender: <any[]>[],
    supplier: <any[]>[],
  }

  get subCategories() {
    return (
      this.list.gategory.find((d: any) => d.id == this.form.value.product_category_id)
        ?.product_sub_categories || []
    )
  }

  get imageCover() {
    return (this.form.controls.files.value || []).find((d: any) => d.is_cover) as any
  }

  get imageDetails() {
    return (this.form.controls.files.value || []).filter((d: any) => !d.is_cover)
  }

  subProductGroup(index: any) {
    return this.sub_product_list.controls[index] as FormGroup
  }

  get sub_product_list() {
    return this.form.get('details') as FormArray<FormGroup>
  }

  get gender() {
    return this.list.gender.find((d: any) => d.id == this.form.controls.gender_id.value)
  }

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public profile: Profile,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public productService: ProductService,
    public productCategoryService: ProductCategoryService,
    public productBrandService: ProductBrandService,
    public supplierService: SupplierService,
    public service: Service,
    public masterService: MasterService
  ) {}

  ngOnInit() {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    if (this.router.url.includes('create')) {
      this.isCreate = true
      this.form.controls.is_sub_product.enable()
    }
    this.route.params.subscribe(params => {
      this.initData(params['id'])
    })
  }

  initData(id: any) {
    const apis = [
      this.productCategoryService.getProductCategoryList({}),
      this.productBrandService.getProductBrandList({}),
      this.supplierService.getSupplierList({}),
      this.masterService.getGender(),
    ]

    if (this.isEdit || this.isView) {
      apis.push(this.productService.getProduct(id))
    }

    this.loading.start()
    forkJoin(apis).subscribe(([resCategory, resBrand, resSupplier, resGender, resProduct]: any) => {
      if (resCategory) {
        if (!resCategory.is_error) {
          this.list.gategory = (resCategory.data || []).filter(
            (d: any) => d.is_active || d.id == resProduct?.data?.product_category_id
          )
        } else {
          this.customSnackBar.fail(resCategory.message)
        }
      }

      if (resBrand) {
        if (!resBrand.is_error) {
          this.list.brand = (resBrand.data || []).filter(
            (d: any) => d.is_active || d.id == resProduct?.data?.product_brand_id
          )
        } else {
          this.customSnackBar.fail(resBrand.message)
        }
      }

      if (resSupplier) {
        if (!resSupplier.is_error) {
          this.list.supplier = (resSupplier.data || []).filter(
            (d: any) => d.is_active || d.id == resProduct?.data?.supplier_id
          )
        } else {
          this.customSnackBar.fail(resSupplier.message)
        }
      }

      if (resGender) {
        if (!resGender.is_error) {
          this.list.gender = resGender.data || []
        } else {
          this.customSnackBar.fail(resGender.message)
        }
      }

      if (resProduct) {
        if (!resProduct.is_error) {
          this.dataManage = resProduct.data
          Ascending(this.dataManage.details, 'code')

          this.form.reset({
            files: this.dataManage.files.map((d: any) => ({
              ...d.file,
              is_cover: d.is_cover,
            })),
            code: this.dataManage.code,
            real_code: this.dataManage.real_code,
            name: this.dataManage.name,
            detail: this.dataManage.detail,
            color: this.dataManage.color,
            color_code: this.dataManage.color_code,
            size: this.dataManage.size,
            cost_price: this.dataManage.cost_price,
            stock: this.dataManage.stock,
            total_cost_price: this.dataManage.total_cost_price,
            selling_price: this.dataManage.selling_price,
            barcode: this.dataManage.barcode,
            gender_id: this.dataManage.gender_id,
            is_carat: this.dataManage.is_carat,
            is_active: this.dataManage.is_active,
            product_category_id: this.dataManage.product_category_id,
            product_sub_category_id: this.dataManage.product_sub_category_id,
            product_brand_id: this.dataManage.product_brand_id,
            supplier_id: this.dataManage.supplier_id,
            is_sub_product: !!this.dataManage.details.length,

            percent_discount: this.dataManage.percent_discount || 0,
            member_price: this.dataManage.member_price || 0,
            description: this.dataManage.description || '',
            description_en: this.dataManage.description_en || '',
            file_model: this.dataManage.file_model || null,
            model_size: this.dataManage.model_size || '',
            tall: this.dataManage.tall || '',
            model_unit: this.dataManage.model_unit || null,
            weight: this.dataManage.weight || null,
            shape: this.dataManage.shape || '',

            details: [],
            tags: this.dataManage.tags.map((d: any) => d.tag),
            cms_size_guide_name: this.dataManage.cms_size_guide_name || '',
            cms_size_measurement_name: this.dataManage.cms_size_measurement_name || '',
          })

          this.product_colors = this.dataManage.product_colors || []
          this.product_complete_looks = (this.dataManage.product_complete_looks || []).map(
            (p: any) => {
              return this.setProduct(p)
            }
          )
          this.product_may_like_colors = (this.dataManage.product_may_like_colors || []).map(
            (p: any) => {
              return this.setProduct(p)
            }
          )
          this.product_may_like_styles = (this.dataManage.product_may_like_styles || []).map(
            (p: any) => {
              return this.setProduct(p)
            }
          )

          this.sub_product_list.clear()
          const details = Ascending(this.dataManage.details || [], 'seq')
          for (const detail of details) {
            detail.product_may_like_colors = (detail.product_may_like_colors || []).map(
              (p: any) => {
                return this.setProduct(p)
              }
            )
            this.addSubProduct({
              ...detail,
              files: detail.files.map((d: any) => {
                return {
                  ...d.file,
                  is_cover: d.is_cover,
                }
              }),
            })
          }

          if (
            this.dataManage.product_category &&
            !this.list.gategory.some((d: any) => d.id == this.dataManage.product_category_id)
          ) {
            this.list.gategory.push(this.dataManage.product_category)
          }

          if (
            this.dataManage.product_brand &&
            !this.list.brand.some((d: any) => d.id == this.dataManage.product_brand_id)
          ) {
            this.list.brand.push(this.dataManage.product_brand)
          }

          if (
            this.dataManage.supplier &&
            !this.list.supplier.some((d: any) => d.id == this.dataManage.supplier_id)
          ) {
            this.list.supplier.push(this.dataManage.supplier)
          }

          if (this.isEdit && !this.dataManage.details.length) {
            this.form.controls.is_sub_product.enable()
          }
        } else {
          this.customSnackBar.fail(resProduct.message)
        }
      }

      Ascending(this.list.gategory, 'name')
      Ascending(this.list.brand, 'name')
      Ascending(this.list.supplier, 'name')
      Ascending(this.list.gender, 'name')

      setTimeout(() => {
        this.calcMemberPrice()
      })

      this.loading.stop()
    })
  }

  setProduct(item: any) {
    return {
      ...item,
      ...(item.product_detail_id ? item.product_detail : item.product),
      key: productKey(item.product_id, item.product_detail_id),
      image: productImage(item.product_id, item.product_detail_id),
    }
  }

  confirmDelete(dataManage: any) {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบสินค้า “${dataManage.name}” ใช่หรือไม่`,
      },
    })

    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        this.deleteProduct()
      }
    })

    return dialogRefDelete
  }

  selectCategory(e: any) {
    this.form.controls.product_sub_category_id.reset()
  }

  calcMemberPrice() {
    const percent_discount = this.form.controls['percent_discount'].value || 0
    if (percent_discount > 0) {
      const selling_price = this.form.controls['selling_price'].value || 0
      const member_price = selling_price - (percent_discount / 100) * selling_price
      this.form.controls['member_price'].setValue(member_price)
    } else {
      this.form.controls['member_price'].setValue(0)
    }
  }

  deleteProduct() {
    this.loading.start()
    this.productService.deleteProduct(this.dataManage.id).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('ลบข้อมูลสำเร็จ')
          this.toList()
        } else {
          this.dialog.open(ModalErrorComponent, {
            data: {
              title: 'ไม่สามารถลบสินค้าได้',
              detail: res.message,
            },
          })
        }
      }
      this.loading.stop()
    })
  }

  changeIsSubProduct($event: any) {
    this.sub_product_list.clear()
    if ($event.checked) {
      const value = this.form.getRawValue()
      this.addSubProduct({
        name: value.name,
        color: value.color,
        size: value.size,
        selling_price: value.selling_price,
        stock: this.isCreate ? value.stock : 0,
        cost_price: value.cost_price,
        total_cost_price: value.total_cost_price,
        barcode: value.barcode,
        supplier_id: value.supplier_id,
      })
    } else {
      this.product_colors = []
    }
  }

  delSubProduct(item: any, index: number) {
    if (this.dataManage?.is_order && item.value.id) {
      this.dialog.open(ModalErrorComponent, {
        data: {
          title: 'ไม่สามารถลบสินค้าได้',
          detail: 'มีสินค้านี้อยู่ในออเดอร์ที่ยังไม่ปิดรายการ',
        },
      })
      return
    }

    this.sub_product_list.removeAt(index)
    this.validateCustom()
  }

  duplicateSubProduct(f: FormGroup, index: number) {
    const value = f.getRawValue()
    let files = value.files || []
    if (value.cover) {
      files = [
        {
          ...value.cover,
          is_cover: true,
        },
        ...files,
      ]
    }
    this.addSubProduct(
      {
        ...value,
        data: null,
        id: null,
        files,
        product_may_like_colors: (value.product_may_like_colors || []).map((p: any) => {
          return {
            ...p,
            id: null,
          }
        }),
      },
      index + 1
    )
  }

  addSubProduct(data: any = {}, insertIndex?: number) {
    const files = [...(data.files || [])]
    let cover = files.find((f: any) => f.is_cover)
    if (cover) {
      files.splice(files.indexOf(cover), 1)
    }

    const product_may_like_colors = (data.product_may_like_colors || []).map((p: any) => {
      return {
        ...p,
        key: productKey(p.product_id, p.product_detail_id),
        image: productImage(p.product_id, p.product_detail_id),
      }
    })
    let newData = new FormGroup({
      data: new FormControl(data),
      id: new FormControl(data.id || null),
      cover: new FormControl<any[]>(cover || null),
      files: new FormControl<any[]>(files || []),
      code: new FormControl(data.code || '', [Validators.required, Validators.maxLength(15)]),
      real_code: new FormControl(data.real_code || ''),
      name: new FormControl(data.name || '', [Validators.required, Validators.maxLength(250)]),
      product_color_uuid: new FormControl(data.product_color_uuid || null, [Validators.required]),
      color: new FormControl(data.color || '', [Validators.maxLength(50)]),
      size: new FormControl(data.size || '', [Validators.maxLength(15)]),
      selling_price: new FormControl(data.selling_price || '', [
        Validators.required,
        Validators.max(1000000000),
      ]),
      member_price: new FormControl<number | null>(data.member_price || null, [
        Validators.max(1000000000),
      ]),
      stock: new FormControl(data.stock || '', [Validators.max(1000000)]),
      cost_price: new FormControl(data.cost_price || '', [Validators.max(1000000000)]),
      total_cost_price: new FormControl(data.total_cost_price || ''),
      barcode: new FormControl(data.barcode || '', [Validators.maxLength(250)]),
      product_may_like_colors: new FormControl(product_may_like_colors || []),
    })

    if (insertIndex != undefined) {
      this.sub_product_list.insert(insertIndex, newData)
    } else {
      this.sub_product_list.push(newData)
    }
  }

  totalCostPrice() {
    return (this.form.controls['cost_price'].value || 0) * (this.form.controls['stock'].value || 0)
  }

  totalCostPriceDetail(index: number) {
    return (
      (this.subProductGroup(index).controls['cost_price'].value || 0) *
      (this.subProductGroup(index).controls['stock'].value || 0)
    )
  }

  validateCustom() {
    const code = this.form.controls.code.value
    const subListValue = this.sub_product_list.getRawValue()

    let code_duplicate = false
    let valid = true
    let index = 0
    const subList = this.sub_product_list.controls as any
    for (const sub_list of subList) {
      const subCode = sub_list.controls.code as FormControl

      if (
        subCode.value == code ||
        subListValue.some((d: any, i: number) => d.code == subCode.value && index != i)
      ) {
        if (subCode.value == code) {
          this.form.controls.code.setErrors({
            duplicate: true,
          })
          code_duplicate = true
        }

        subCode.setErrors({
          duplicate: true,
        })
        valid = false
      } else {
        subCode.updateValueAndValidity()
      }
      index++
    }

    if (!code_duplicate) {
      this.form.controls.code.updateValueAndValidity()
    }

    return valid
  }

  onConfirm(): void {
    ValidateForm(this.form)
    const isCustomValidate = this.validateCustom()
    if (!this.form.valid || !isCustomValidate) return

    if (this.isEdit) {
      this.openModalConfirm()
    } else {
      this.onSave()
    }
  }

  openModalConfirm() {
    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.onSave()
      }
    })

    return dialogRefConfirm
  }

  onSave(): void {
    const value = this.form.getRawValue()
    const payload = {
      code: value.code,
      real_code: value.real_code,
      name: value.name,
      detail: value.detail,
      color: value.color,
      color_code: value.color_code || '#000000',
      size: value.size,
      cost_price: value.cost_price || 0,
      stock: value.stock || 0,
      total_cost_price: value.cost_price || 0,
      selling_price: value.selling_price || 0,
      barcode: value.barcode,
      gender_id: value.gender_id,
      is_carat: value.is_carat,
      is_active: value.is_active,
      product_category_id: value.product_category_id || null,
      product_brand_id: value.product_brand_id || null,
      product_sub_category_id: value.product_sub_category_id || null,
      supplier_id: value.supplier_id || null,
      percent_discount: value.percent_discount || 0,
      member_price: value.member_price || 0,
      description: value.description || '',
      description_en: value.description_en || '',
      cms_size_guide_id: this.dataManage?.cms_size_guide_id || null,
      cms_size_measurement_id: this.dataManage?.cms_size_measurement_id || null,
      file_model_id: value.file_model?.id || null,
      model_size: value.model_size || '',
      tall: value.tall || null,
      model_unit: value.model_unit || null,
      is_model_centimeter: value.model_unit == 1,
      weight: value.weight || null,
      shape: value.shape || '',
      is_new: this.dataManage?.is_new || false,
      product_colors: this.product_colors.map((v: any) => {
        return {
          id: v.id || null,
          color: v.color || null,
          color_code: v.color_code || '#000000',
          file_id: v.file?.id || null,
          uuid: v.uuid || null,
        }
      }),
      product_complete_looks: this.product_complete_looks.map((v: any) => {
        const oldData = this.dataManage?.product_complete_looks?.find(
          (p: any) =>
            p.product_id == v.product_id &&
            (p.product_detail_id || null) == (v.product_detail_id || null)
        )
        return {
          id: oldData?.id || null,
          product_id: v.product_id,
          product_detail_id: v.product_detail_id || null,
          product_category_id: v.product_category_id || null,
          product_sub_category_id: v.product_sub_category_id || null,
          product_like: 1 /* 1 = complete_looks, 2 = may_like_colors, 3 = may_like_styles*/,
        }
      }),
      product_may_like_colors: this.product_may_like_colors.map((v: any) => {
        const oldData = this.dataManage?.product_may_like_colors?.find(
          (p: any) =>
            p.product_id == v.product_id &&
            (p.product_detail_id || null) == (v.product_detail_id || null)
        )
        return {
          id: oldData?.id || null,
          product_id: v.product_id,
          product_detail_id: v.product_detail_id || null,
          product_category_id: v.product_category_id || null,
          product_sub_category_id: v.product_sub_category_id || null,
          product_like: 2 /* 1 = complete_looks, 2 = may_like_colors, 3 = may_like_styles*/,
        }
      }),
      product_may_like_styles: this.product_may_like_styles.map((v: any) => {
        const oldData = this.dataManage?.product_may_like_styles?.find(
          (p: any) =>
            p.product_id == v.product_id &&
            (p.product_detail_id || null) == (v.product_detail_id || null)
        )
        return {
          id: oldData?.id || null,
          product_id: v.product_id,
          product_detail_id: v.product_detail_id || null,
          product_category_id: v.product_category_id || null,
          product_sub_category_id: v.product_sub_category_id || null,
          product_like: 3 /* 1 = complete_looks, 2 = may_like_colors, 3 = may_like_styles*/,
        }
      }),
      details: (this.sub_product_list.value || []).map((v: any, i) => {
        // console.log('v', v)
        const oldFiles = v.data?.files || []

        let files = (v.files || []).map((d: any) => {
          return {
            id: oldFiles.find((f: any) => f.file_id == d.id)?.id || null,
            file_id: d.id,
          }
        })

        if (v.cover) {
          files = [
            {
              id: oldFiles.find((f: any) => f.file_id == v.cover.id)?.id || null,
              file_id: v.cover.id,
              is_cover: true,
            },
            ...files,
          ]
        }

        const product_color = this.product_colors.find((cl: any) => cl.uuid == v.product_color_uuid)
        return {
          seq: i + 1,
          id: v.id || null,
          code: v.code,
          real_code: v.real_code,
          name: v.name,
          product_color_uuid: v.product_color_uuid,
          color: product_color?.color || v.color,
          size: v.size,
          cost_price: v.cost_price || 0,
          stock: v.stock || 0,
          total_cost_price: v.cost_price || 0,
          selling_price: v.selling_price || 0,
          member_price: v.member_price || 0,
          total_selling_price: v.total_selling_price || 0,
          barcode: v.barcode,
          files,
          product_may_like_colors: (v.product_may_like_colors || []).map((p: any) => {
            const oldData = v.data?.product_may_like_colors?.find(
              (p: any) =>
                p.product_id == v.product_id &&
                (p.product_detail_id || null) == (v.product_detail_id || null)
            )
            return {
              id: oldData?.id || null,
              product_id: p.product_id,
              product_detail_id: p.product_detail_id || null,
              product_category_id: p.product_category_id || null,
              product_sub_category_id: p.product_sub_category_id || null,
              product_like: 2 /* 1 = complete_looks, 2 = may_like_colors, 3 = may_like_styles*/,
            }
          }),
        }
      }),
      tags: (value.tags || []).map((d: any) => ({
        tag_id: d.id,
      })),
      files: (value.files || []).map((d: any) => {
        const fileValue = {
          id: null,
          file_id: d.id,
          is_cover: d.is_cover,
        }

        if (this.dataManage) {
          const oldFile = this.dataManage.files.find((f: any) => f.file_id == fileValue.file_id)
          if (oldFile) {
            fileValue.id = oldFile.id
          }
        }

        return fileValue
      }),
    }

    // return console.log(payload)

    const api = this.isEdit
      ? this.productService.updateProduct(this.dataManage.id, payload)
      : this.productService.addProduct(payload)

    this.loading.start()
    api.subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
          this.isSaved = true
          if (this.isCreate) {
            this.toList()
          } else {
            this.toView(this.dataManage.id)
          }
        } else {
          if (res.errors[0]?.field) {
            setErrorForm(this.form, res.errors)
            setErrorFormArray(this.sub_product_list, res.errors, 'product')
          } else {
            this.customSnackBar.failSave(res.message)
          }
        }
      }
      this.loading.stop()
    })
  }

  toList() {
    this.router.navigate(['/product'], {
      queryParams: {
        tab: 0,
      },
    })
  }

  toView(id = this.dataManage.id) {
    this.router.navigate(['/product/view/' + id])
  }

  toEdit(id = this.dataManage.id) {
    this.router.navigate(['/product/edit/' + id])
  }

  isSaved = false
  openModalCancel(resolveFromRouter: (value: boolean) => void): void {
    if (this.isEdit && this.form.dirty && !this.isSaved) {
      const dialogCancel = this.dialog.open(ModalLeaveComponent, {
        data: {},
      })

      dialogCancel.afterClosed().subscribe(result => {
        resolveFromRouter(!!result)
      })
    } else {
      resolveFromRouter(true)
    }
  }

  uploadImageCover(data: any) {
    const files = this.form.controls.files.value || []
    if (this.imageCover) {
      for (const key in data) {
        this.imageCover[key] = data.id
      }
    } else {
      files.push({
        ...data,
        is_cover: true,
      })
    }
    this.form.controls.files.updateValueAndValidity()
  }

  deleteImageCover() {
    const files = this.form.controls.files.value || []
    files.splice(files.indexOf(this.imageCover), 1)
  }

  openModalAddModel(): void {
    const dialogRef = this.dialog.open(ModalModelComponent, {
      width: '700px',
      data: {
        isEdit: this.isEdit || this.isCreate,
        formValue: this.form.getRawValue(),
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log(result)
        this.form.controls.file_model.setValue(result.file_model)
        this.form.controls.model_size.setValue(result.model_size)
        this.form.controls.model_unit.setValue(result.model_unit)
        this.form.controls.tall.setValue(result.tall)
        this.form.controls.weight.setValue(result.weight)
        this.form.controls.shape.setValue(result.shape)
      }
    })
  }

  openModalUploadImage(f: FormGroup): void {
    // console.log(f)

    const dialogRef = this.dialog.open(ModalUploadImageComponent, {
      data: {
        isEdit: this.isEdit || this.isCreate,
        cover: f.controls['cover'].value,
        files: f.controls['files'].value || [],
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result', result)
        f.controls['cover'].setValue(result.cover || null)
        f.controls['files'].setValue(result.more)
      }
    })
  }

  openModalAddColor(): void {
    const dialogRef = this.dialog.open(ModalColorProductComponent, {
      data: {
        isView: this.isView,
        product_colors: this.product_colors,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result', result)
        this.product_colors = result
        for (const f of this.sub_product_list.controls) {
          if (
            !this.product_colors.some(
              (pc: any) => pc.uuid == f.controls['product_color_uuid'].value
            )
          ) {
            f.controls['product_color_uuid'].reset()
          }
        }
      }
    })
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      )
    }
  }
}
