<div class="tabs-content-wrapper-detail">
  <div class="d-flex justify-content-between">
    <!--  (onEnter)="getProductBrandList()" -->
    <app-search
      [control]="search"
      placeholder="ค้นหา"
      (onEnter)="paginationProduct.reset();getCustomerProductList()"></app-search>

    @if (profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER], [ACTION_ACCESS.VIEW])) {
    <button
      matRipple
      class="btn btn-outline-secondary min-w-100px"
      (click)="openModalDownloadFile('ดาวน์โหลดไฟล์การใช้เครดิต', { message: 'คุณต้องการดาวน์โหลดไฟล์การใช้เครดิตใช่หรือไม่' })">
      <span class="icon material-symbols-outlined fill">download_2</span>
      ส่งออกไฟล์
    </button>
    }
  </div>

  <div class="table-responsive-detail mt-3">
    <table class="table table-fixed-column table-detail">
      <thead>
        <tr>
          <th width="300">ชื่อสินค้า</th>
          <th width="180" class="text-end">จำนวน</th>
          <th width="180">ช่องทางการสั่งซื้อ</th>
          <th width="180" class="text-end">ยอดขาย</th>
          <th width="180">วันที่ซื้อ</th>
          <th width="180">สถานะ</th>
        </tr>
      </thead>

      <tbody>
        @for (item of productList; track $index) {
        <tr>
          <td>{{ item.product_name }}</td>
          <td class="text-end">{{ formatPriceAutoDecimal(item.quantity) }}</td>
          <td>{{ item.channel?.name || '-' }}</td>
          <td class="text-end color--brand-blue--f">{{ formatPrice(item.total_price) }}</td>
          <td>
            {{ item.purchase_date ? Moment(item.purchase_date).format('DD-MM-YYYY , HH:mm') : '-' }}
          </td>
          <td>{{ item.status.name }}</td>
        </tr>
        }
      </tbody>
    </table>

    @if(!productList.length) {
    <div class="data-not-found-wrapper">ไม่พบรายการสินค้า</div>
    }
  </div>

  <!-- Pagination -->
  @if (productList.length) {
  <div class="pagination-wrapper">
    <mat-paginator
      [pageIndex]="paginationProduct.data.pageIndex"
      [length]="paginationProduct.data.length"
      [pageSize]="paginationProduct.data.pageSize"
      [pageSizeOptions]="paginationProduct.data.pageSizeOptions"
      aria-label="Select page"
      (page)="paginationProduct.setFromPaginator($event);getCustomerProductList()">
    </mat-paginator>
  </div>
  }
</div>
