<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    @if(!orderList.length) {
    <div class="detail-list-wrapper">
      <ul class="detail-list">
        <li>
          ดาวน์โหลดตัวอย่างการกรอกข้อมูลออเดอร์ได้ที่นี่
          <span class="template-link" (click)="downloadTemplate()">order_template.xlsx</span>
        </li>
        <li>ระบบยอมรับเฉพาะไฟล์ Excel (.xlsx) เท่านั้น</li>
        <li>จำกัดขนาดไฟล์ไม่เกิน 10 MB</li>
        <li>ตรวจสอบข้อมูลที่เว้นว่าง หรือไม่มีข้อมูลให้ระบุเป็น “ - ”</li>
        <li>ออเดอร์ที่มีสินค้าหลายรายการ ให้กรอกสินค้าแยกแถว และใส่ข้อมูลลูกค้าให้ตรงกัน</li>
      </ul>
    </div>

    <input
      id="upload"
      type="file"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      class="d-none"
      #fileUpload
      (change)="onChangeUpload(fileUpload)" />

    @if(!file) {
    <label
      for="upload"
      class="upload-file-wrapper"
      (dragover)="dragOver($event)"
      (drop)="drop($event)"
      [class.border-danger]="fileErrorMessage">
      <div class="upload-file-text-wrapper h-100">
        <span class="icon material-symbols-outlined icon-upload">drive_folder_upload</span>
        <div class="upload-file-text">ลากวาง หรือ เลือกไฟล์อัปโหลด</div>
        <div class="file-type">.xlsx</div>
      </div>
    </label>
    @if (fileErrorMessage) {
    <div class="mt-1 text-danger text-error">{{ fileErrorMessage }}</div>
    } } @else { @if(uploadError) {
    <div class="upload-file-wrapper upload-error-section">
      <div class="upload-file-text-wrapper">
        <div class="xlsx-icon">
          <img alt="icon" src="/assets/images/icons/icon-excel.svg" />
        </div>
        <div class="file-name-wrapper">
          <div class="file-name">{{ file.name }}</div>
          <button class="btn btn-transparent btn-icon" (click)="resetUpload()">
            <img alt="icon" src="/assets/images/icons/icon-close-circle-red.svg" />
          </button>
        </div>

        <div class="upload-error-text-wrapper">
          <div class="upload-error-text">
            รูปแบบการอัปโหลดไม่ถูกต้อง ตรวจสอบข้อมูลและอัปโหลดใหม่อีกครั้ง
          </div>
          <div class="upload-error-text">Format Error:</div>
          <div class="error-text-row-wrapper">
            @for (err of uploadError; track $index) {
            <div class="error-text-row">
              <!-- <div class="error-row">แถว: 33 คอลัมน์:</div> -->
              <div class="error-info">{{ err.message }}</div>
            </div>
            }
          </div>
        </div>
      </div>
    </div>

    <div class="btn-upload-wrapper">
      <button class="btn btn-outline-secondary btn-upload" (click)="fileUpload.click()">
        อัปโหลดไฟล์ใหม่
      </button>
    </div>
    } @else {
    <div class="upload-file-wrapper file-name-section">
      <div class="upload-file-text-wrapper h-100">
        <div class="xlsx-icon">
          <img alt="icon" src="/assets/images/icons/icon-excel.svg" />
        </div>
        <div class="file-name-wrapper">
          <div class="file-name">{{ file.name }}</div>
          <button class="btn btn-transparent btn-icon" (click)="resetUpload()">
            <span class="icon material-symbols-outlined fill icon-cancel">cancel</span>
          </button>
        </div>
      </div>
    </div>
    } } } @else {
    <div class="table-section-wrapper">
      <div class="table-responsive">
        <table class="table table-fixed-column">
          <colgroup>
            <col width="180" />
            <col width="250" />
            <col width="250" />
            <col width="250" />
            <col width="120" />
            <col width="250" />
            <col width="120" />
            <col width="250" />
            <col width="140" />
            <col width="140" />
            <col width="140" />
            <col width="180" />
            <col width="180" />
            <col width="100" />
            <col width="250" />
            <col width="120" />
            <col width="250" />
            <col width="120" />
            <col width="140" />
            <col width="120" />
            <col width="180" />
          </colgroup>

          <thead>
            <tr class="tr-upload-file">
              <th class="th-blue text-center" colspan="11">ข้อมูลลูกค้า</th>
              <th class="th-red text-center" colspan="6">ข้อมูลสินค้า</th>
              <th class="th-orange text-center" colspan="4">ข้อมูลการจัดส่ง</th>
            </tr>
            <tr class="tr-upload-file">
              <th>รหัสลูกค้า</th>
              <th>ชื่อลูกค้า</th>
              <th>นามสกุล</th>
              <th>อีเมล</th>
              <th>เบอร์โทรศัพท์</th>
              <th>ชื่อผู้รับ</th>
              <th>เบอร์ผู้รับ</th>
              <th>ที่อยู่จัดส่ง</th>
              <th>ช่องทางโซเชียล</th>
              <th>ชื่อโซเชียล</th>
              <th>สินค้าส่งหลายที่อยู่</th>

              <th>รหัสสินค้า</th>
              <th>รหัสสินค้าย่อย</th>
              <th>จำนวน</th>
              <th>ชื่อผู้รับ</th>
              <th>เบอร์ผู้รับ</th>
              <th>ที่อยู่จัดส่ง</th>

              <th>การจัดส่ง</th>
              <th>อื่นๆ</th>
              <th>ค่าจัดส่ง</th>
              <th>หมายเหตุ</th>
            </tr>
          </thead>

          <tbody>
            @for (item of pageList; track $index) {
            <tr class="tr-upload-file">
              <td>{{ item.customer_code || '-' }}</td>
              <td>{{ item.customer_name || '-' }}</td>
              <td>{{ item.customer_last_name || '-' }}</td>
              <td>{{ item.contact_email || '-' }}</td>
              <td>{{ item.telephone || '-' }}</td>
              <td>{{ item.address_name || '-' }}</td>
              <td>{{ item.address_telephone || '-' }}</td>
              <td>{{ item.address_address || '-' }}</td>
              <td>{{ item.channel || '-' }}</td>
              <td>
                <span class="name-channel" [innerHTML]="item.channel_name || '-'"></span>
              </td>
              <td>{{ item.is_multi_delivery || '-' }}</td>

              <td>{{ item.product_code || '-' }}</td>
              <td>{{ item.product_detail_code || '-' }}</td>
              <td>{{ formatPriceAutoDecimal(item.quantity) }}</td>
              <td>{{ item.product_delivery_name || '-' }}</td>
              <td>{{ item.product_delivery_telephone || '-' }}</td>
              <td>{{ item.product_delivery_address || '-' }}</td>

              <td>{{ item.transport || '-' }}</td>
              <td>{{ item.transport_other_name || '-' }}</td>
              <td>{{ formatPrice(item.shipping_cost) }}</td>
              <td>{{ item.remark || '-' }}</td>
            </tr>
            }
          </tbody>
        </table>
      </div>

      <!-- Pagination -->
      @if (orderList.length) {
      <div class="pagination-wrapper">
        <mat-paginator
          [pageIndex]="paginationOrder.data.pageIndex"
          [length]="paginationOrder.data.length"
          [pageSize]="paginationOrder.data.pageSize"
          [pageSizeOptions]="paginationOrder.data.pageSizeOptions"
          (page)="paginationOrder.setFromPaginator($event)"
          aria-label="Select page">
        </mat-paginator>
      </div>
      }
    </div>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    @if (!orderList.length) {
    <button
      matRipple
      class="btn btn-primary btn-md"
      [disabled]="!file || uploadError"
      (click)="checkImport()">
      ตรวจสอบ
    </button>
    } @else {
    <button matRipple class="btn btn-primary btn-md" (click)="onSaveUpload()">บันทึก</button>
    }
  </div>
</mat-dialog-actions>
