<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">หมวดหมู่สินค้า : {{data.data.name}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="detail-wrapper mb-3">
      <div class="modal-title mb-3">หมวดหมู่หลัก</div>

      <div class="container px-0">
        <div class="row gx-3 gy-2">
          <div class="col-12 col-sm-6">
            <div class="d-flex flex-column">
              <div class="label-title">ชื่อหมวดหมู่สินค้าหลัก (ภาษาไทย)</div>
              <div class="name-text">{{data.data.name}}</div>
            </div>
          </div>

          <div class="col-12 col-sm-6">
            <div class="d-flex flex-column">
              <div class="label-title">ชื่อหมวดหมู่สินค้าหลัก (ภาษาอังกฤษ)</div>
              <div class="name-text">{{data.data.name_en}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="line"></div>

    <div class="detail-wrapper mb-3">
      <div class="modal-title mb-3">หมวดหมู่ย่อย</div>

      <div class="container px-0">
        @for (item of data.data.product_sub_categories; track $index) {
        <div class="row gx-3 gy-2">
          <div class="col-12 col-sm-6">
            <div class="d-flex gap-3">
              <div class="subcategory-number">{{$index + 1}}</div>

              <div class="d-flex flex-column">
                <div class="label-title">ชื่อหมวดหมู่สินค้าย่อย (ภาษาไทย)</div>
                <div class="name-text">{{item.name}}</div>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-6">
            <div class="d-flex flex-column">
              <div class="label-title">ชื่อหมวดหมู่สินค้าย่อย (ภาษาอังกฤษ)</div>
              <div class="name-text">{{item.name_en}}</div>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</mat-dialog-content>
