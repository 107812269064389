import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { imports } from '../../../../imports'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../globals'
import {
  DownloadFileBlob,
  Moment,
  Pagination,
  formatPrice,
  formatPriceAutoDecimal,
} from '../../../../helpers'
import { MatDialog } from '@angular/material/dialog'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { ProductStockService } from '../../../../services'
import { ModalAddStockComponent } from './modal-add-stock/modal-add-stock'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { InputNumberComponent } from '../../../../components/input-number/input-number'
import { ModalAdjustStockComponent } from './modal-adjust-stock/modal-adjust-stock'
import { MatPaginator } from '@angular/material/paginator'
import { ModalDownloadFileComponent } from '../../../../components/modal-download-file/modal-download-file'
import { ProductStockListComponent } from './product-stock-list/product-stock-list'
import { DatePickerRangeComponent } from '../../../../components/date-picker-range/date-picker-range'
import { StatusComponent } from '../../../../components/status/status'
import { MatTooltip } from '@angular/material/tooltip'

@Component({
  selector: 'app-product-stock',
  standalone: true,
  imports: [
    ...imports,
    InputNumberComponent,
    ProductStockListComponent,
    MatPaginator,
    DatePickerRangeComponent,
    StatusComponent,
    MatTooltip,
  ],
  templateUrl: './product-stock.html',
  styleUrls: ['./product-stock.scss'],
})
export class ProductStockComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly Moment = Moment

  @Input() product: any
  @Input() imageCover: any

  @Output() done = new EventEmitter()

  imgStockDefault = '/assets/images/default-image-product-more.png'

  total = {
    total_stock: 0,
    total_cost_price: 0,
    total_sell_price: 0,
  }

  resetStockList = 0

  get products() {
    return this.product.details.length ? this.product.details : [this.product]
  }

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public productStockService: ProductStockService
  ) {}

  ngOnInit(): void {
    this.getProductStockTotal()
  }

  reset() {
    this.getProductStockTotal()
    this.done.emit()
  }

  getProductStockTotal() {
    const payload = {
      page: 1,
      page_size: 1,
      filter: {
        product_id: this.product.id,
      },
    }

    // console.log(payload)
    // return

    this.loading.start()
    this.productStockService.getProductStockList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.total.total_stock = res.data.total_stock
          this.total.total_cost_price = res.data.total_cost_price
          this.total.total_sell_price = res.data.total_sell_price
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  openModalAddStock(title: string): void {
    const dialogRef = this.dialog.open(ModalAddStockComponent, {
      data: {
        title,
        product: this.product,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetStockList++
        this.reset()
      }
    })
  }
}
