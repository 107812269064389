import { Component, Input } from '@angular/core'
import { imports } from '../../../imports'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { Loading } from '../../../globals'
import { CompanyProfileService, OrderService, PublicService } from '../../../services'
import { ActivatedRoute } from '@angular/router'
import { decrypt } from '../../../helpers/crypto'
import { forkJoin } from 'rxjs'
import { ORDER_ID, setOrderForPrint } from '../../../helpers'
import { CoverSheetComponent } from '../cover-sheet/cover-sheet'

@Component({
  selector: 'app-cover-sheet-private',
  standalone: true,
  imports: [...imports, CoverSheetComponent],
  templateUrl: './cover-sheet-private.html',
  styleUrls: ['./cover-sheet-private.scss'],
})
export class CoverSheetPrivateComponent {
  @Input() showPrint = true

  companyProfile: any
  orders: any[] = []
  addresss: any[] = []
  receipts: any[] = []

  temps = new Array(30)

  constructor(
    public route: ActivatedRoute,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public orderService: OrderService,
    public companyProfileService: CompanyProfileService,
    public publicService: PublicService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      try {
        const ids = decrypt(params['orders'].replaceAll(' ', '+'))
        // console.log('ids', ids)
        let filter = params['filter'] ? decrypt(params['filter'].replaceAll(' ', '+')) : undefined
        this.initData(ids, filter)
      } catch (e) {}
    })
  }

  async initData(ids: any[], filter: any) {
    const apis = [this.companyProfileService.getCompanyProfile()]
    if (filter) {
      ids = await this.getOrderIdWithFilter(filter)
    }
    for (const id of ids) {
      apis.push(this.orderService.getOrder(id))
    }

    this.loading.start()
    forkJoin(apis).subscribe(async (ress: any) => {
      const [resCompanyProfile, ...resOrders] = ress

      //--------------test
      // const testOrder = {
      //   data: {
      //     ...resOrders[0].data,
      //   },
      // }
      // testOrder.data.order_no = 'AAAA'
      // testOrder.data.delivery_address = [
      //   {
      //     ...testOrder.data.delivery_address[0],
      //     uuid: 'ccccccc',
      //   },
      // ]
      // resOrders.push(testOrder)
      //------------------

      if (!resCompanyProfile.is_error) {
        this.companyProfile = resCompanyProfile.data
      } else {
        this.customSnackBar.fail(resCompanyProfile.message)
      }

      const orders: any[] = []
      const addresss: any[] = []
      const receipts: any[] = []

      for (const res of resOrders) {
        if (!res.is_error) {
          const order = res.data
          const order_id = res.data.id
          await new Promise(rev => {
            this.publicService.getPaymentByOrderId(order_id).subscribe(resPayment => {
              if (!res.is_error) {
                order.paymentData = resPayment.data
              }
              rev(null)
            })
          })

          setOrderForPrint(orders, addresss, receipts, order)
          // console.log('orders', orders)
        } else {
          this.customSnackBar.fail(res.message)
        }
      }

      this.orders = orders
      this.addresss = addresss
      this.receipts = receipts

      // console.log('orders', this.orders)
      // console.log('addresss', this.addresss)

      this.loading.stop()
    })
  }

  async getOrderIdWithFilter(filter: any) {
    const payload = {
      ...filter,
      is_all: true,
    }

    if (payload.filter.status_ids.length) {
      return new Promise<any[]>(rev => {
        this.loading.start()
        this.orderService.getOrderList(payload).subscribe(res => {
          if (!res.is_error) {
            const ids = res.data.records.map((order: any) => order.id)
            rev(ids)
          } else {
            this.customSnackBar.fail()
            rev([])
          }

          this.loading.stop()
        })
      })
    }

    return []
  }
}
