import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { InputComponent } from '../../components/input/input'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { PasswordReg, ValidateForm, validateField } from '../../helpers'
import { InputPasswordComponent } from '../../components/input-password/input-password'
import { AlertMessageComponent } from '../../components/alert-message/alert-message.component'
import { AuthService } from '../../services/auth.service'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { imports } from '../../imports'
import { Service } from '../../services/service'

@Component({
  selector: 'app-reset-pass',
  standalone: true,
  imports: [...imports, InputComponent, InputPasswordComponent, AlertMessageComponent],
  templateUrl: './reset-pass.html',
  styleUrls: ['./reset-pass.scss'],
})
export class ResetPasswordComponent implements OnInit {
  private paramToken: string = ''

  password = new FormControl('', [Validators.required, Validators.pattern(PasswordReg)])
  formChangePassword = new FormGroup({
    password: this.password,
    password_confirm: new FormControl('', [
      Validators.required,
      (control: AbstractControl) => {
        if (control.value != this.password.value) {
          return {
            match: true,
          }
        }

        return null
      },
    ]),
  })

  constructor(
    public snackBar: CustomSnackBar,
    public authServices: AuthService,
    private route: ActivatedRoute,
    public router: Router,
    public service: Service
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.paramToken = params['token'] // Access the 'id' parameter from the URL
    })
  }

  onCheckMatchPassword() {
    const confirmPass = this.formChangePassword.controls['password_confirm']
    if (confirmPass.value) {
      validateField(confirmPass)
    }
  }

  onResetPassword() {
    ValidateForm(this.formChangePassword)
    if (!this.formChangePassword.valid) return

    const value = this.formChangePassword.getRawValue()
    const payload = {
      token: this.paramToken,
      password: value.password,
      confirm_password: value.password_confirm,
    }

    this.authServices.changePassword(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.snackBar.success('รีเซ็ตรหัสผ่านใหม่สำเร็จ')
          this.router.navigate(['/login'])
        } else {
          this.snackBar.failSave('ตั้งรหัสผ่านใหม่ไม่สำเร็จ')
        }
      }
    })
  }
}
