import { getBarcodeBase64 } from './barcode'
import { channelId } from './channel'
import {
  getLocalStorage,
  getSessionStorage,
  setLocalStorage,
  setSessionStorage,
} from './local-storage'
import { PAYMENT_TYPE } from './payment'

export const ORDER_ID = {
  CREATE: 1, //สร้างออเดอร์
  VERIFY: 6, //ตรวจหลักฐานการชำระเงิน
  PAID: 2, //ชำระเงินแล้ว
  PRINTED: 3, //พิมพ์ที่อยู่/ใบเสร็จแล้ว
  SHIPMENT: 4, //ส่งสินค้าแล้ว | ปิดรายการ
  CANCEL: 5, //ยกเลิกออเดอร์
} as const

export const isActionPrint = (status: any) => true
// ![ORDER_ID.CREATE, ORDER_ID.VERIFY].includes(status)

export const isPayment = (status: any) =>
  [ORDER_ID.PAID, ORDER_ID.PRINTED, ORDER_ID.SHIPMENT].includes(status)

export const isPrinted = (status: any) =>
  ![ORDER_ID.CREATE, ORDER_ID.VERIFY, ORDER_ID.PAID].includes(status)

export const isShipment = (status: any) => [ORDER_ID.SHIPMENT].includes(status)

export const KEY_STARAGE_LIST_UPDATE = 'order/list/update'

export const resetOrderList = () => {
  setLocalStorage(KEY_STARAGE_LIST_UPDATE, (getLocalStorage(KEY_STARAGE_LIST_UPDATE) || 0) + 1)
}

export const setOrderForPrint = (orders: any[], addresss: any[], receipts: any[], order: any) => {
  if (!isActionPrint(order.status_id)) return

  const barcode_order_no = getBarcodeBase64(order.order_no)
  const newOrder = {
    ...order,
    products: order.products.filter((p: any) => p.quantity > 0),
    barcode_order_no,
    use_address: order.is_multi_delivery
      ? order.delivery_addresses.filter((address: any) =>
          order.products.some((p: any) => p.delivery_address_uuid == address.uuid)
        )
      : order.delivery_addresses.filter((address: any) => address.is_main),
    paymentText: '-',
    // {
    //   details: [{ payment_type: 1 }],
    // },
  }

  if (order.paymentData) {
    newOrder.paymentText =
      [
        ...new Set(
          order.paymentData.details.map((d: any) => {
            switch (d.payment_type) {
              case PAYMENT_TYPE.CASH:
                return 'เงินสด'
              case PAYMENT_TYPE.CREDIT_DEBIT:
                return 'Credit / Debit Card'
              case PAYMENT_TYPE.SLIP:
                return 'QR code / Prompt Pay'
              default:
                return ''
            }
          })
        ),
      ].join(', ') || '-'
  }

  orders.push(newOrder)

  for (const address of newOrder.use_address) {
    addresss.push({
      order_no: newOrder.order_no,
      transport_name: newOrder.transport.name,
      barcode_order_no: newOrder.barcode_order_no,
      customer_name: newOrder.customer.name,
      channel_name:
        (newOrder.channel?.name || '') +
        (!newOrder.channel_id || newOrder.channel_id == channelId.none
          ? ''
          : `: ${newOrder.channel_name}`),
      created_at: newOrder.created_at,
      address,
      products: newOrder.is_multi_delivery
        ? newOrder.products.filter((p: any) => p.delivery_address_uuid == address.uuid)
        : newOrder.products,
    })

    receipts.push({
      ...newOrder,
      use_address: [address],
    })
  }
}
