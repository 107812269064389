<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">ดูตัวอย่าง</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">
    <div class="page-top-content">
      <div class="box-content">
        <app-top-menu theme="black" [lang]="data.lang"></app-top-menu>
      </div>
    </div>

    <div class="page-body">
      <div class="image-wrapper">
        @if (file_url) {
        <img [src]="file_url" alt="banner" />
        }
      </div>

      <div class="detail-wrapper">
        <div
          [innerHtml]="
            sanitizer.bypassSecurityTrustHtml(data.formValue['detail_' + data.lang] || '')
            "></div>
      </div>
    </div>

    <app-footer [lang]="data.lang"></app-footer>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-primary btn-md" matRipple (click)="onClose()">กลับ</button>
  </div>
</mat-dialog-actions>
