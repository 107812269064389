import { Editor, NgxEditorModule, Toolbar } from 'ngx-editor'
import {
  Component,
  HostListener,
  Input,
  OnInit,
  Output,
  inject,
  SimpleChanges,
} from '@angular/core'
import { MatError, MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { DatePickerComponent } from '../date-picker/date-picker'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { SelectComponent } from '../select/select'
import { MatRipple } from '@angular/material/core'
import { distinctUntilChanged, pairwise, startWith } from 'rxjs'
import { FileService } from '../../services'

@Component({
  selector: 'app-text-editor[control]',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    ReactiveFormsModule,
    FormsModule,
    MatRipple,
    NgxEditorModule,
    MatError,
  ],
  templateUrl: './text-editor.html',
  styleUrls: ['./text-editor.scss'],
})
export class TextEditorComponent implements OnInit {
  @Input() control!: FormControl | AbstractControl
  @Input() placeholder = ''
  @Input() toolbarControl: any = []
  @Input() toolbarImg: boolean = false
  @Input() errorText = ''

  editor: any = Editor
  html = ''
  toolbar: Toolbar = this.toolbarControl

  objDiv: any = HTMLElement

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    const scrollHeight = window.scrollY || document.documentElement.scrollTop
  }

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.control.disable()
      } else {
        this.control.enable()
      }
    }
    if (this.control.value) {
      this.html = this.control.value || ''
    }
  }

  constructor(
    public fileService: FileService
  ) {}

  ngOnInit(): void {
    this.editor = new Editor()
    this.objDiv = document.getElementById('text-editor') as HTMLElement
    this.html = this.control.value || ''
    this.toolbar = this.toolbarControl

    this.formControl.valueChanges
      .pipe(startWith(this.html), distinctUntilChanged(), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (prev != next) {
          this.html = next
        }
      })
  }

  onChangeInsertImage(event:any) {
    const file = event.target.files[0]
    if(file){
       this.fileService.uploadEditor(file).subscribe((res:any) => {
        if(res?.data){
          this.html += `<img src='${res.data.url}' alt='${res.data.name || 'image'}' width='200'/>`;
          setTimeout(() => {
            var rect = this.objDiv.getBoundingClientRect();
            window.scrollTo(0, rect.bottom);
          }, 100);
        }
      })
    }
  }

  ngOnDestroy(): void {
    if (this.editor) {
      this.editor.destroy()
    }
  }

  log(log: any) {
    console.log('log', log)
  }
}
