import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class CMSPolicyService {
  constructor(private service: Service) {}
  private pathCMSPolicy = '/v1/cms/policy'

  addCmsPolicy(payload: any): Observable<any> {
    return this.service.put(`${this.pathCMSPolicy}`, payload)
  }

  getCmsPolicy(): Observable<any> {
    return this.service.get(`${this.pathCMSPolicy}`)
  }
}
