import { Component } from '@angular/core'
import { imports } from '../../../imports'
import { StatusUserComponent } from '../../../components/status-user/status-user'
import { SearchComponent } from '../../../components/search/search'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatDialog } from '@angular/material/dialog'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ModalBankComponent } from './modal-bank/modal-bank'
import { ModalDeleteComponent } from '../../../components/modal-delete/modal-delete'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { MatTabsModule } from '@angular/material/tabs'
import { ActivatedRoute, Router } from '@angular/router'
import { MatSlideToggle } from '@angular/material/slide-toggle'
import { InputComponent } from '../../../components/input/input'
import { Moment, Pagination, ValidateForm, getLocalStorage, setErrorForm } from '../../../helpers'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { CompanyProfileService, PaymentChannelService } from '../../../services'
import { CheckboxTableComponent } from '../../../components/checkbox-table/checkbox-table.component'
import { InputNumberComponent } from '../../../components/input-number/input-number'

@Component({
  selector: 'app-payment-channel',
  standalone: true,
  imports: [
    ...imports,
    StatusUserComponent,
    SearchComponent,
    MatPaginatorModule,
    MatTooltipModule,
    MatTabsModule,
    MatSlideToggle,
    InputComponent,
    InputNumberComponent,
    CheckboxTableComponent,
  ],
  templateUrl: './payment-channel.html',
  styleUrls: ['./payment-channel.scss'],
})
export class PaymentChannelComponent {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment

  tab = 0

  readonly paginationPaymentChannel = new Pagination({})
  paymentChannelList: any[] = []

  itemChecked = new Set<any>()

  companyProfile: any
  form = new FormGroup({
    fee_percent: new FormControl<any>(null, [Validators.required, Validators.max(100)]),
    is_debit_credit: new FormControl(true),
    is_beam_qr_code: new FormControl(true),
  })

  showModal: boolean = false

  isView: boolean = true

  companyId: any

  constructor(
    public profile: Profile,
    public dialog: MatDialog,
    public CustomSnackBar: CustomSnackBar,
    public route: ActivatedRoute,
    public Router: Router,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public paymentChannelService: PaymentChannelService
  ) {}

  ngOnInit(): void {
    this.getPaymentChannelList()
    this.companyId = getLocalStorage('company-id')
  }

  getPaymentChannelList() {
    const pagination = this.paginationPaymentChannel.get()
    const payload = {
      ...pagination,
    }
    // console.log(payload)
    // return

    this.loading.start()
    this.paymentChannelService.getPaymentChannelList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.paymentChannelList = res.data.records
          this.paginationPaymentChannel.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  openModalBank(title: string, type: 'add' | 'edit', dataManage?: any) {
    const dialogRef = this.dialog.open(ModalBankComponent, {
      disableClose: true,
      data: {
        title,
        type,
        dataManage,
        is_main_default: !this.paymentChannelList.length,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPaymentChannelList()
      }
    })

    return dialogRef
  }

  confirmDelete(dataManage: any) {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบบัญชีธนาคารนี้ใช่หรือไม่`,
      },
    })

    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        this.deletePaymentChannel(dataManage)
      }
    })

    return dialogRefDelete
  }

  deletePaymentChannel(dataManage: any) {
    this.loading.start()
    this.paymentChannelService.deletePaymentChannel(dataManage.id).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('ลบข้อมูลสำเร็จ')
          this.getPaymentChannelList()
        } else {
          this.customSnackBar.fail(res.message || 'ลบข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
    this.navigateToTab(this.tab)

    if ($tabIndex == 0) {
      this.getPaymentChannelList()
    } else if ($tabIndex == 1) {
      this.getCompanyProfile()
    }
  }

  getCompanyProfile() {
    this.loading.start()
    this.companyProfileService.getCompanyProfile().subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.companyProfile = res.data
          this.form.reset({
            fee_percent: res.data.fee_percent,
            is_debit_credit: res.data.is_debit_credit,
            is_beam_qr_code: res.data.is_beam_qr_code,
          })
        } else {
          this.customSnackBar.fail()
        }
      }

      this.loading.stop()
    })
  }

  navigateToTab(tab: number) {
    this.Router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      skipLocationChange: false,
    })
  }

  editCreditDebit() {
    this.isView = false
  }

  onConfirm() {
    ValidateForm(this.form)
    if (!this.form.valid) return

    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.onSave()
      }
    })

    return dialogRefConfirm
  }

  onSave() {
    const value = this.form.getRawValue()
    const payload = {
      ...this.companyProfile,
      fee_percent: parseFloat(value.fee_percent || 0),
      is_debit_credit: value.is_debit_credit,
      is_beam_qr_code: value.is_beam_qr_code,
    }

    // return console.log(payload)

    this.loading.start()
    this.companyProfileService.updateCompanyProfile(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
          this.isView = true
        } else {
          if (res.errors[0]?.field) {
            setErrorForm(this.form, res.errors)
          } else {
            this.customSnackBar.failSave(res.message)
          }
        }
      }
      this.loading.stop()
    })
  }

  onClose() {
    this.openModalCancel(bool => {
      if (bool) {
        this.getCompanyProfile()
        this.isView = true
      }
    })
  }

  openModalCancel(resolveFromRouter: (value: boolean) => void): void {
    if (!this.isView && this.form.dirty) {
      const dialogCancel = this.dialog.open(ModalLeaveComponent, {
        data: {},
      })

      dialogCancel.afterClosed().subscribe(result => {
        resolveFromRouter(!!result)
      })
    } else {
      resolveFromRouter(true)
    }
  }
}
