<div class="title-section mb-3 mt-4 pt-2 d-flex justify-content-between">
  รายการสินค้า ({{form.controls['product_list'].value.length}}/4)
  <!-- ~~ -->
  @if (form.controls['product_list'].value.length > 0) {
  <button
    class="btn btn-primary w-fit"
    (click)="addProduct(form.controls['product_list'])"
    [disabled]="form.controls['product_list'].value.length == 4">
    <span class="icon material-symbols-outlined fill"> add_circle </span>
    เลือกสินค้า
  </button>
  }
</div>

<div class="table-responsive-detail">
  <table class="table table-fixed-column">
    <thead>
      <tr>
        <th width="50"></th>
        <th width="90"></th>
        <th width="320">รหัสสินค้า</th>
        <th width="350">ชื่อสินค้า</th>
        <th width="130" class="text-end">ราคา (บาท)</th>
        <th width="130" class="text-center">สต๊อกคงเหลือ</th>
        <th width="100" class="fixed box-shadow-left"></th>
      </tr>
    </thead>
    <tbody
      id="all"
      cdkDropList
      (cdkDropListDropped)="dropTable($event)"
      class="drag-list mt-3 w-100"
      cdkDropListGroup>
      @for (item of form.controls['product_list'].value; track $index) {
      <tr class="drag-box mb-2" cdkDrag cdkDragHandle>
        <td width="50">
          <div class="pre-box">
            <button class="btn btn-transparent-outline me-1">
              <span class="icon icon-dark-grey material-symbols-outlined fill">
                drag_indicator
              </span>
            </button>
          </div>
        </td>
        <td width="90">
          <img src="/assets/images/icons/img-product-test.svg" />
        </td>
        <td width="320">{{ item.code }}</td>
        <td width="350">{{ item.name }}</td>
        <td width="130" class="text-end">{{ item.price }}</td>
        <td width="130" class="text-center">{{ item.qty }}</td>
        <td width="100" class="fixed box-shadow-left">
          <div class="w-100 d-flex justify-content-center">
            <button
              matRipple
              class="btn btn-outline-danger"
              (click)="confirmDelete(form.controls['product_list'], item.id)">
              <span class="icon material-symbols-outlined fill">delete</span>
            </button>
          </div>
        </td>
      </tr>
      }
    </tbody>
  </table>

  @if(!form.controls['product_list'].value.length) {
  <div class="data-not-found-wrapper flex-column align-items-center">
    <div>ไม่พบสินค้า กรุณาเลือกสินค้า</div>
    <button
      class="btn btn-primary me-1 mt-4 w-fit"
      (click)="addProduct(form.controls['product_list'])">
      <span class="icon material-symbols-outlined fill"> add_circle </span>
      เลือกสินค้า
    </button>
  </div>
  }
</div>

<div class="row">
  <div class="col-12 mb-4">
    <div class="title-blue mb-3">ภาพแบนเนอร์ย่อย</div>

    <label class="form-label">ภาพแบนเนอร์</label>

    <div class="section-upload-container">
      <div class="remark my-2">(ขนาดรูปภาพ : 1440px x 220px / ไม่เกิน 5 Mb)</div>
      <div class="upload-image-wrapper mb-4">
        <app-upload-file-flexible
          [imgOnly]="true"
          [type]="isEdit ? 'edit' : 'view'"
          [maxImageSize]="5"
          [maxVideoSize]="30"
          (upload)="form.controls['banner_file'].setValue($event)" />
      </div>
    </div>
  </div>

  <div class="col-12 mb-4">
    <label class="form-label"> การจัดวางเลย์เอาท์ </label>
    <app-radio-layout
      [control]="form.controls['type']"
      [oneImage]="false"
      (onChange)="form.controls['type'].setValue($event)" />
  </div>

  <div class="col-12 mb-4">
    <label class="form-label"> แนบรูป/วิดีโอ </label>

    @if(form.value['type'] == 'one'){
    <div class="section-upload-container">
      <div class="remark my-2">
        (ขนาดรูปภาพ : 1440px x 1024px / ไม่เกิน 5 Mb) | (ขนาดวีดิโอ : 1920px x 1080px / ไม่เกิน 30
        Mb)
      </div>
      <div class="upload-file-wrapper mb-4">
        <app-upload-file-flexible
          [type]="isEdit ? 'edit' : 'view'"
          [maxImageSize]="5"
          [maxVideoSize]="30"
          (upload)="form.controls['product_image_single'].setValue($event)" />
      </div>

      <label class="form-label mt-2">เพิ่มลิงก์</label>
      <app-select-add-link
        [controlLinkType]="form.controls['link_product_single_type']"
        [controlLinkName]="form.controls['link_product_single_name']" />
    </div>
    }@else{

    <div class="section-upload-container">
      <div class="remark my-2">
        (ขนาดรูปภาพ : 720px x 960px / ไม่เกิน 5 Mb) | (ขนาดวีดิโอ : 1920px x 1080px / ไม่เกิน 30 Mb)
      </div>

      <div class="row">
        <div class="col-6">
          <div class="upload-file-wrapper mb-4">
            <app-upload-file-flexible
              [type]="isEdit ? 'edit' : 'view'"
              [maxImageSize]="5"
              [maxVideoSize]="30"
              (upload)="form.controls['product_image_one'].setValue($event)" />
          </div>

          <label class="form-label mt-2">เพิ่มลิงก์</label>
          <app-select-add-link
            [controlLinkType]="form.controls['link_product_one_type']"
            [controlLinkName]="form.controls['link_product_one_name']" />
        </div>
        <div class="col-6">
          <div class="upload-file-wrapper mb-4">
            <app-upload-file-flexible
              [type]="isEdit ? 'edit' : 'view'"
              [maxImageSize]="5"
              [maxVideoSize]="30"
              (upload)="form.controls['product_image_two'].setValue($event)" />
          </div>

          <label class="form-label mt-2">เพิ่มลิงก์</label>

          <app-select-add-link
            [controlLinkType]="form.controls['link_product_two_type']"
            [controlLinkName]="form.controls['link_product_two_name']" />
        </div>
      </div>
    </div>
    }
  </div>
</div>
