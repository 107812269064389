import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  validateField,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { UploadFileFlexibleComponent } from '../../../components/upload-file-flexible/upload-file-flexible'
import { SelectAddLinkComponent } from '../../../components/select-add-link/select-add-link'
import { ModalPreviewComponent } from '../../../components/modal-preview/modal-preview'
import { TextEditorComponent } from '../../../components/text-editor/text-editor'
import { CMSAboutUsService } from '../../../services/cms-about-us.service'
import { DomSanitizer } from '@angular/platform-browser'
import { StatusComponent } from '../../../components/status/status'
import { ModalPreviewAbountUsComponent } from './modal-preview/modal-preview'

@Component({
  selector: 'app-cms-about-us',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    UploadFileFlexibleComponent,
    SelectAddLinkComponent,
    TextEditorComponent,
    StatusComponent,
  ],
  templateUrl: './cms-about-us.html',
  styleUrls: ['./cms-about-us.scss'],
})
export class CMSAboutUsComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment

  isEdit: boolean = false
  isView: boolean = false
  dataManage: any
  tab = 0

  form = new FormGroup({
    file: new FormControl('', [Validators.required]),
    detail_th: new FormControl(''),
    detail_en: new FormControl(''),
  })
  isErrorFile: boolean = false

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public caratService: CaratService,
    public CMSAboutUsService: CMSAboutUsService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    this.initData()
  }

  initData() {
    const apis = [this.CMSAboutUsService.getCmsBrand()]

    this.loading.start()
    forkJoin(apis).subscribe(([resAboutus]: any) => {
      if (resAboutus) {
        if (!resAboutus.is_error) {
          this.dataManage = resAboutus.data

          if (!this.dataManage?.id) {
            this.router.navigate(['/setting/cms/about-us/edit'])
          }
          this.form.reset({
            file: resAboutus.data.file_id,
            detail_th: resAboutus.data.detail_th,
            detail_en: resAboutus.data.detail_en,
          })
        } else {
          this.customSnackBar.fail(resAboutus.message)
        }
      }
      this.loading.stop()
    })
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex

    // if ($tabIndex == 0 && this.profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER])) {
    //   this.tabCustomer?.getCustomerList()
    //   return
    // }
    // this.tabSupplier?.getSupplierList()
  }

  openPreview() {
    ValidateForm(this.form)
    if (!this.form.valid) {
      return
    }

    this.dialog.open(ModalPreviewAbountUsComponent, {
      width: '1200px',
      data: {
        lang: this.tab == 0 ? 'th' : 'en',
        formValue: this.form.getRawValue(),
      },
    })
  }

  onSave(type: any) {
    ValidateForm(this.form)
    if (!this.form.valid) {
      return
    }

    let payload = {
      id: this.dataManage?.id || undefined,
      file_id: this.form.value.file,
      bhb_subject_th: '',
      bhb_subject_en: '',
      detail_th: this.form.value.detail_th,
      detail_en: this.form.value.detail_en,
      is_public: type == 'public' ? true : false,
    }
    // return console.log(payload)

    this.loading.start()
    this.CMSAboutUsService.addCmsBrand(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success(type == 'public' ? 'เผยแพร่สำเร็จ' : 'บันทึกร่างสำเร็จ')
          this.router.navigate(['/setting/cms/about-us/view'])
        } else {
          if (res.errors[0]?.field) {
            setErrorForm(this.form, res.errors)
          } else {
            this.customSnackBar.failSave(
              type == 'public' ? 'เผยแพร่ไม่สำเร็จ' : 'บันทึกร่างไม่สำเร็จ'
            )
          }
        }
      }
      this.loading.stop()
    })
  }

  onCancel() {
    const dialogRefConfirm = this.dialog.open(ModalLeaveComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/setting/cms/about-us/view'])
      }
    })
  }
}
