import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { InputComponent } from '../../components/input/input'
import { TextareaComponent } from '../../components/text-area/text-area'
import { UploadImageProfileComponent } from '../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CheckboxTableComponent } from '../../components/checkbox-table/checkbox-table.component'
import { CheckboxAllTableComponent } from '../../components/checkbox-table/checkbox-all-table.component'
import { StatusUserComponent } from '../../components/status-user/status-user'
import { MatPaginatorModule } from '@angular/material/paginator'
import { ModalDownloadFileComponent } from '../../components/modal-download-file/modal-download-file'
import { ModalAddStockComponent } from './product-detail/product-stock/modal-add-stock/modal-add-stock'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { imports } from '../../imports'
import { SearchComponent } from '../../components/search/search'
import { ACTION_ACCESS, APP_PERMISSION, FilterTableProduct, Loading, Profile } from '../../globals'
import { ModalUploadFileComponent } from './modal-upload-file/modal-upload-file'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatIconModule } from '@angular/material/icon'
import {
  Ascending,
  DownloadFileBlob,
  Moment,
  Pagination,
  channelId,
  formatPrice,
  formatPriceAutoDecimal,
  sortList
} from '../../helpers'
import { ProductService } from '../../services'
import { FormControl } from '@angular/forms'
import { SelectSortComponent } from '../../components/select-sort/select-sort'
import { ModalErrorComponent } from '../../components/modal-error/modal-error'
import { ModalDeleteComponent } from '../../components/modal-delete/modal-delete'
import { InputNumberComponent } from '../../components/input-number/input-number'
import { TabProductsDiscountComponent } from './tab-products-discount/tab-products-discount'

@Component({
  selector: 'app-product',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    CheckboxTableComponent,
    CheckboxAllTableComponent,
    StatusUserComponent,
    MatPaginatorModule,
    ModalDownloadFileComponent,
    ModalAddStockComponent,
    SearchComponent,
    MatTabsModule,
    SelectSortComponent,
    MatTooltipModule,
    MatIconModule,
    InputNumberComponent,
    TabProductsDiscountComponent
  ],
  templateUrl: './product.html',
  styleUrls: ['./product.scss'],
})
export class ProductComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly channelId = channelId

  tab = 0
  sortControl = new FormControl(sortList[0].value)
  readonly paginationProduct = new Pagination({
    sortName: sortList[0].value.sortName,
    sortBy: sortList[0].value.sortBy as 'asc' | 'desc',
  })
  rerender = false
  pageNumber = new FormControl(1)
  productList: any[] = []
  itemChecked = new Set<any>()
  itemCheckedData = new Map()

  //สินค้าส่วนลด
  itemCheckedDiscount = new Set<any>()
  itemCheckedDataDiscount = new Map()

  list = {
    sort: sortList,
  }

  constructor(
    public profile: Profile,
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public productService: ProductService,
    public filterTableProduct: FilterTableProduct
  ) {}

  ngOnInit(): void {
    this.itemChecked.values()
    this.route.queryParams.subscribe((params: any) => {
      if (params.tab) {
        this.tab = params.tab
      }
    })
    this.getProductList()
  }

  onChangePage(reset = false) {
    if (this.pageNumber.value) {
      const page = this.pageNumber.value - 1
      if (this.paginationProduct.data.pageIndex != page) {
        this.paginationProduct.data.pageIndex = page
        this.getProductList()
      }
    } else if (reset) {
      this.pageNumber.setValue(this.paginationProduct.data.pageIndex + 1)
      this.rerender = true
      setTimeout(() => {
        this.rerender = false
      })
    }
  }

  getProductList() {
    const pagination = this.paginationProduct.get()
    const dataFilter = this.filterTableProduct.getData()
    const payload = {
      ...pagination,
      ...dataFilter,
    }

    // console.log(payload)
    // return

    this.loading.start()
    this.productService.getProductList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.productList = res.data.records.map((product: any) => {
            Ascending(product.details, 'code')

            return {
              ...product,
              isShowStock: !!(
                !product.details.length ||
                this.getStock(product, channelId.online)?.book ||
                this.getStock(product, channelId.shopee)?.book ||
                this.getStock(product, channelId.lazada)?.book
              ),
            }
          })
          this.paginationProduct.setFromResponse(res.data)
          this.pageNumber.setValue(res.data.page)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  setItemCheckedData(checked: boolean, datas: any[]) {
    datas.forEach(item => {
      if (checked) {
        this.itemCheckedData.set(item.id, {
          id: item.id,
          is_order: item.is_order,
        })
      } else {
        this.itemCheckedData.delete(item.id)
      }
    })
  }

  confirmDelete() {
    let itemSize = this.tab == 0 ? this.itemCheckedData.size : this.tab == 1 ? this.itemCheckedDataDiscount.size : ''
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบสินค้าทั้งหมดที่เลือก ${this.itemCheckedData.size} รายการใช่หรือไม่`,
      },
    })

    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        this.deleteProduct()
      }
    })

    return dialogRefDelete
  }

  deleteProduct() {
    const payload = {
      ids: [...this.itemCheckedData.values()].map(item => item.id),
    }

    this.loading.start()
    this.productService.deleteProductBulk(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('ลบข้อมูลสำเร็จ')
          this.itemChecked.clear()
          this.itemCheckedData.clear()
          this.getProductList()
        } else {
          this.dialog.open(ModalErrorComponent, {
            data: {
              title: 'ไม่สามารถลบสินค้าได้',
              detail: res.message,
            },
          })
        }
      }
      this.loading.stop()
    })
  }

  getStock(item: any, channelId: any) {
    return item.channels.find((d: any) => d.id == channelId)
  }

  onSort() {
    this.paginationProduct.setFromPaginator({
      pageIndex: 0,
      ...(this.sortControl.value || {}),
    })
    this.getProductList()
  }

  openModalAddStock(title: string, product: any): void {
    const dialogRef = this.dialog.open(ModalAddStockComponent, {
      data: {
        title,
        product,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getProductList()
      }
    })
  }

  openModalCancel(): void {
    const dialogCancel = this.dialog.open(ModalLeaveComponent, {
      data: {
        detail: `คุณยังไม่ได้บันทึกข้อมูลต้องการออกจากหน้านี้โดยไม่บันทึกข้อมูลใช่หรือไม่`,
      },
    })

    dialogCancel.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/setting/promotion/view'])
      }
    })
  }

  openModalDownloadFile(title: string, detail: any) {
    const dialogRef = this.dialog.open(ModalDownloadFileComponent, {
      data: {
        title,
        detail,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.downloadFile()
      }
    })

    return dialogRef
  }

  downloadFile() {
    const pagination = this.paginationProduct.get()
    const dataFilter = this.filterTableProduct.getData()
    dataFilter.filter.ids = this.itemChecked.size > 0 ? [...this.itemChecked.values()] : undefined

    const payload = {
      sort_by: pagination.sort_by,
      sort_name: pagination.sort_name,
      ...dataFilter,
    }

    this.loading.start()
    this.productService.exportProduct(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `products.xlsx`)
          this.itemChecked.clear()
          this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
        } else {
          this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }

  openModalUploadFile(title: string): void {
    const dialogRef = this.dialog.open(ModalUploadFileComponent, {
      disableClose: true,
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: {
        title,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getProductList()
      }
    })
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
    this.navigateToTab(this.tab)
  }

  getCover(item: any) {
    return item.files.find((d: any) => d.is_cover)
  }

  navigateToTab(tab: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      skipLocationChange: false,
    })
  }

  // tab discount product
  setItemCheckedDiscount(data: any) {
    if (data.type == 'set') {
      this.itemCheckedDataDiscount.set(data.item.id, {
        id: data.item.id,
        is_order: data.item.is_order,
      })
    } else {
      this.itemCheckedDataDiscount.delete(data.item.id)
    }
  }
}
