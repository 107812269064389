import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class ShippingService {
  constructor(private service: Service) { }
  private path = '/v1/shipping-settings'

  createShipping(payload: any): Observable<any> {
    return this.service.post(`${this.path}`, payload)
  }

  updateShipping(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.path}/${id}`, payload)
  }

  getShipping(id: any): Observable<any> {
    return this.service.get(`${this.path}/${id}`)
  }

  deleteShipping(id: any): Observable<any> {
    return this.service.delete(`${this.path}/${id}`)
  }
}
