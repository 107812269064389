import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { RoleComponent } from './checkbox-access-role/checkbox-access-role'
import { StatusUserComponent } from '../../../../../components/status-user/status-user'
import { MatDialog } from '@angular/material/dialog'
import { ModalRoleComponent } from './modal-role/modal-role'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm'
import { ModalDeleteComponent } from '../../../../../components/modal-delete/modal-delete'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../../globals'
import { RoleService, UserService } from '../../../../../services'
import { forkJoin } from 'rxjs'
import { MatMenuModule } from '@angular/material/menu'
import { Moment } from '../../../../../helpers'
import { ModalLeaveComponent } from '../../../../../components/modal-leave/modal-leave'
import { imports } from '../../../../../imports'

@Component({
  selector: 'app-tab-role-manage',
  standalone: true,
  imports: [
    ...imports,
    MatTabsModule,
    MatCheckboxModule,
    RoleComponent,
    StatusUserComponent,
    ModalRoleComponent,
    ModalConfirmComponent,
    ModalDeleteComponent,
    MatMenuModule,
  ],
  templateUrl: './tab-role-manage.html',
  styleUrls: ['./tab-role-manage.scss'],
})
export class TabRoleManageComponent implements OnInit {
  @Input() isEditAccess: boolean = false
  @Output() isEditAccessChange = new EventEmitter<boolean>()

  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment

  roleList: any[] = []
  applicationList: any[] = []
  userList: any[] = []

  isDisplayAccess: boolean = false
  isCreateAccess: boolean = false
  selectionRole: any
  tab = 0

  get isEdit() {
    return !(this.isDisplayAccess && this.isEditAccess)
  }
  dirty = false

  constructor(
    public profile: Profile,
    public dialog: MatDialog,
    public roleService: RoleService,
    public userService: UserService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar
  ) {}

  ngOnInit(): void {
    this.getRoleList(null)
  }

  toggleEditAccess(bool: boolean) {
    this.isEditAccess = bool
    this.isEditAccessChange.emit(bool)
    this.dirty = false
  }

  getRoleList(roleSelectId: any, isEdit = false) {
    this.loading.start()
    this.roleService.getRoleList({}).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.roleList = res.data
          if (roleSelectId) {
            this.roleList.forEach((role: any, i: number) => {
              if (roleSelectId == role.id) {
                // this.setEditAccess(role, i)
                this.setSelectedRole(role, i, isEdit)
              }
            })
          } else {
            this.cancelRole()
          }
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
  }

  setSelectedRole(role: any, i: number, isEdit = false) {
    const payloadUserList = {
      page: 1,
      page_size: 9999,
      filter: {
        role_ids: [role.id],
      },
    }

    const apis = [
      this.roleService.getApplications({}),
      this.roleService.getRole(role.id),
      this.userService.getUserList(payloadUserList),
    ]

    this.loading.start()
    forkJoin(apis).subscribe(([resApplication, roleManage, resUser]) => {
      let applicationList = []
      if (resApplication) {
        if (!resApplication.is_error) {
          applicationList = resApplication.data
        } else {
          this.customSnackBar.fail(resApplication.message)
        }
      }

      if (roleManage) {
        if (!roleManage.is_error) {
          this.applicationList = applicationList.map((app: any) => {
            if (!app.application_permissions) {
              app.application_permissions = []
            }
            app.application_permissions.map((access: any) => {
              access.permission.is_selected = this.checkAccessSelected(
                app.id,
                access.id,
                roleManage.data.applications
              )
              return access
            })
            return app
          })

          this.isDisplayAccess = true
          this.toggleEditAccess(isEdit)

          this.selectionRole = role
        } else {
          this.customSnackBar.fail(roleManage.message)
        }
      }

      if (resUser) {
        if (!resUser.is_error) {
          this.userList = resUser.data.records
        } else {
          this.customSnackBar.fail(resUser.message)
        }
      }
      this.loading.stop()
    })
  }

  checkAccessSelected(appId: any, accessId: any, applications: any[]) {
    const app = applications.find((d: any) => d.id == appId)
    if (app) {
      return app.application_permissions.some(
        (ac: any) => ac.id == accessId && ac.permission.is_selected
      )
    }

    return false
  }

  renameRole(role: any): void {
    this.openModalRole('แก้ไขสิทธิ์ผู้ใช้งาน', 'edit', role)
  }

  setEditAccess(role: any, index: number) {
    this.setSelectedRole(role, index, true)
  }

  deleteRole(role: any, i: number): void {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบ “${role.name}” ออกจากสิทธิ์ผู้ใช้งานใช่หรือไม่`,
      },
    })

    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        this.onDeleteRole(role)
      }
    })
  }

  onDeleteRole(role: any) {
    this.loading.start()
    this.roleService.deleteRole(role.id).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('ลบข้อมูลสำเร็จ')
          this.getRoleList(null)
        } else {
          this.customSnackBar.fail(res.message || 'ลบข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }

  cancelRole() {
    this.selectionRole = null
    this.isDisplayAccess = false
    this.toggleEditAccess(false)
  }

  openModalRole(title: string, type: 'create' | 'edit', role: any = null): void {
    const dialogRef = this.dialog.open(ModalRoleComponent, {
      disableClose: true,
      data: {
        title,
        type,
        dataManage: role,
      },
    })

    dialogRef.afterClosed().subscribe(roleUpdate => {
      if (roleUpdate) {
        // console.log('roleUpdate', roleUpdate)
        if (type == 'create') {
          this.getRoleList(roleUpdate.id, true)
        } else {
          this.getRoleList(role.id, false)
        }
      }
    })
  }

  openModalConfirm(): void {
    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.onSave()
      }
    })
  }

  onSave() {
    const application_permission_ids = this.applicationList.reduce((ids: any[], app: any) => {
      for (const application_permission of app.application_permissions) {
        if (application_permission.permission.is_selected) {
          ids.push(application_permission.id)
        }
      }
      return ids
    }, [])

    const payload = {
      name: this.selectionRole.name,
      application_permission_ids,
    }

    this.loading.start()
    this.roleService.updateRole(this.selectionRole.id, payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')

          this.isDisplayAccess = true
          this.toggleEditAccess(false)
        } else {
          this.customSnackBar.failSave(res.message)
        }
      }
      this.loading.stop()
    })
  }

  onClose() {
    this.openModalCancel(bool => {
      if (bool) {
        this.cancelRole()
      }
    })
  }

  openModalCancel(resolveFromRouter: (value: boolean) => void): void {
    if (this.dirty) {
      const dialogCancel = this.dialog.open(ModalLeaveComponent, {
        data: {},
      })

      dialogCancel.afterClosed().subscribe(result => {
        resolveFromRouter(!!result)
      })
    } else {
      resolveFromRouter(true)
    }
  }
}
