import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  formatPrice,
  Pagination,
  formatPriceAutoDecimal,
} from '../../helpers'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import {
  CompanyProfileService,
  CaratService,
  ProductService,
  MasterService,
  ProductCategoryService,
} from '../../services'
import { CustomSnackBar } from '../snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../globals'
import { forkJoin } from 'rxjs'
import { MatRadioModule } from '@angular/material/radio'
import { MatRipple } from '@angular/material/core'
import { MatIconModule } from '@angular/material/icon'
import { MatTabsModule } from '@angular/material/tabs'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { imports } from '../../imports'
import { InputComponent } from '../input/input'
import { TextareaComponent } from '../text-area/text-area'
import { SelectComponent } from '../select/select'
import { InputSearchComponent } from '../input-search/input-search'
import { MatPaginator } from '@angular/material/paginator'
import { InputNumberComponent } from '../input-number/input-number'
import { CheckboxTableComponent } from '../checkbox-table/checkbox-table.component'
import { MatCheckbox } from '@angular/material/checkbox'

@Component({
  selector: 'app-modal-select-product-multiple',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    MatRadioModule,
    SelectComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatIconModule,
    MatRipple,
    InputSearchComponent,
    InputNumberComponent,
    MatPaginator,
    CheckboxTableComponent,
    MatCheckbox,
  ],
  templateUrl: './modal-select-product-multiple.html',
  styleUrls: ['./modal-select-product-multiple.scss'],
})
export class ModalSelectProductMultipleComponent implements OnInit {
  // @Input() form: any = new FormGroup('')
  // @Input() sectionIndex: number = 0
  // @Input() showButtonDelete: boolean = false
  // @Output() deleteSection = new EventEmitter()
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly pagination = new Pagination({
    sortName: 'code',
  })

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false
  rerender = false
  pageNumber = new FormControl(1)

  formSearch = new FormGroup({
    search_name: new FormControl(),
    category: new FormControl(),
    sub_category: new FormControl(),
  })
  form = new FormGroup({
    product_id: new FormControl(),
    product_name: new FormControl(),
  })

  productSelect: any[] = []
  listProduct: any[] = []

  list = {
    category: <any[]>[],
    sub_category: <any[]>[],
  }

  get productIds() {
    return this.productSelect.reduce((productIds: any[], item: any) => {
      if (!productIds.includes(item.product_id)) {
        productIds.push(item.product_id)
      }

      return productIds
    }, [])
  }

  constructor(
    public dialogRef: MatDialogRef<ModalSelectProductMultipleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public productCategoryService: ProductCategoryService,
    public productService: ProductService
  ) {}

  ngOnInit(): void {
    this.initData()
    this.getProductList()
  }

  initData() {
    const apis = [
      this.productCategoryService.getProductCategoryList({
        is_all: true,
      }),
    ]
    this.loading.start()
    forkJoin(apis).subscribe(([resProductCategoryList]) => {
      if (!resProductCategoryList.is_error) {
        this.list.category = resProductCategoryList.data
      } else {
        this.customSnackBar.fail()
      }
      this.loading.stop()
    })
  }

  clear() {
    this.productSelect = []
  }

  changeCatecory() {
    this.clear()
    this.getProductList()
  }

  getProductList() {
    const pagination = this.pagination.get()
    const payload: any = {
      ...pagination,
      search: this.formSearch.value.search_name || undefined,
      filter: {
        is_actives: [true],
        is_stock: true,
      },
    }

    if (this.formSearch.value.category?.length) {
      payload.filter.category_ids = this.formSearch.value.category
    }

    if (this.formSearch.value.category?.length) {
      payload.filter.sub_category_ids = this.formSearch.value.sub_category
    }

    // console.log(payload)
    // return

    this.loading.start()
    this.productService.getProductForOrderList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.listProduct = res.data.records.map((product: any) => {
            const key = `${product.id}-${product.product_detail_id || ''}`

            const newProduct = {
              key,
              product_id: product.id,
              ...product,
            }

            return newProduct
          })
          this.pagination.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  checkItem(key: any) {
    return this.productSelect.find((d: any) => d.key == key)
  }

  onCheck(item: any) {
    const data = this.checkItem(item.key)
    if (data) {
      this.productSelect.splice(this.productSelect.indexOf(data), 1)
    } else {
      this.productSelect.push(item)
    }
  }

  isProductSelect(item: any) {
    return this.productIds.includes(item.product_id)
  }

  log(log: any) {
    console.log('log', log)
  }

  onClose(): void {
    this.dialogRef.close(false)
  }

  onConfirm(): void {
    const datas: any[] = [...this.productSelect]
    this.dialogRef.close(datas)
  }

  onChangePage(reset = false) {
    if (this.pageNumber.value) {
      const page = this.pageNumber.value - 1
      if (this.pagination.data.pageIndex != page) {
        this.pagination.data.pageIndex = page
        this.getProductList()
      }
    } else if (reset) {
      this.pageNumber.setValue(this.pagination.data.pageIndex + 1)
      this.rerender = true
      setTimeout(() => {
        this.rerender = false
      })
    }
  }
}
