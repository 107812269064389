<div class="page-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">ข้อมูลค่าขนส่ง</div>
    @if (isEdit) {
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">แก้ไข</div>
    }
  </div>

  <div class="title-wrapper">ข้อมูลค่าขนส่ง</div>

  <div class="page-content-inner d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center">
      <div class="title-blue">กำหนดค่าจัดส่งปกติ</div>
      @if (isView) {
        <button
          matRipple
          class="btn btn-primary btn-md"
          [routerLink]="'/setting/shipping-manage/edit'">
          แก้ไขข้อมูล
        </button>
      }
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <label class="form-label mt-2">ค่าจัดส่งปกติ <span class="text-danger">*</span></label>
        <app-input-number
          [disabled]="isView"
          [control]="form.controls['shipping_normal']"
          unit="บาท"
          placeholder="0.00">
          @if (form.controls['shipping_normal'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @if (form.controls['shipping_normal'].hasError('max')) {
          <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
          }
        </app-input-number>
      </div>
    </div>
    <div class="title-blue mt-3">กำหนดเงื่อนไข</div>
    <mat-radio-group [formControl]="form.controls['conditions']" (change)="resetValidate()">
      <div class="list-wrapper d-flex flex-wrap gap-3">
        @for (item of conditionsList; track $index) {
        <mat-radio-button [value]="item.id" [disabled]="isView">
          {{item.name}}
        </mat-radio-button>
        }
      </div>
    </mat-radio-group>
    @if (form.value['conditions'] != 1) {
    <div class="row mt-3">
      @if (form.value['conditions'] == 2 || form.value['conditions'] == 4) {
      <div class="col-12 col-md-4">
        <label class="form-label"> ยอดคำสั่งซื้อขั้นต่ำ <span class="text-danger">*</span> </label>
        <app-input-number
          [disabled]="isView"
          [control]="form.controls['min_order']"
          placeholder="0.00">
          @if (form.controls['min_order'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @if (form.controls['min_order'].hasError('max')) {
          <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
          }
        </app-input-number>
      </div>
      } @if (form.value['conditions'] == 3 || form.value['conditions'] == 4) {
      <div class="col-12 col-md-4">
        <label class="form-label">
          จำนวนสินค้าที่ซื้อขั้นต่ำ <span class="text-danger">*</span>
        </label>
        <app-input-number
          [disabled]="isView"
          [control]="form.controls['min_purchase']"
          placeholder="0.00">
          @if (form.controls['min_purchase'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @if (form.controls['min_purchase'].hasError('max')) {
          <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
          }
        </app-input-number>
      </div>
      }
      <div class="col-12 col-md-4">
        <label class="form-label"> ค่าส่ง <span class="text-danger">*</span> </label>
        <app-input-number
          [disabled]="isView"
          [control]="form.controls['shipping_cost']"
          placeholder="0.00">
          @if (form.controls['shipping_cost'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @if (form.controls['shipping_cost'].hasError('max')) {
          <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
          }
        </app-input-number>
      </div>
    </div>
    } @if(isEdit) {
    <div class="page-footer-btn pb-0">
      <div class="d-flex flex-wrap justify-content-end gap-2">
        <button
          matRipple
          class="btn btn-outline-secondary min-w-135px"
          [routerLink]="'/setting/shipping-manage/view'">
          ยกเลิก
        </button>
        <button matRipple class="btn btn-primary min-w-135px" (click)="onSave()">บันทึก</button>
      </div>
    </div>
    }
  </div>
</div>
