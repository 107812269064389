import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { UploadFileFlexibleComponent } from '../../../components/upload-file-flexible/upload-file-flexible'
import { SelectAddLinkComponent } from '../../../components/select-add-link/select-add-link'
import { ModalPreviewComponent } from '../../../components/modal-preview/modal-preview'
import { MatMenuModule } from '@angular/material/menu'
import { StatusComponent } from '../../../components/status/status'
import { DomSanitizer } from '@angular/platform-browser'
import { CMSPolicyService } from '../../../services/cms-policy.service'
import { ModalPreviewPolicyComponent } from './modal-preview/modal-preview'

@Component({
  selector: 'app-cms-policy',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    UploadFileFlexibleComponent,
    SelectAddLinkComponent,
    MatMenuModule,
    StatusComponent,
  ],
  templateUrl: './cms-policy.html',
  styleUrls: ['./cms-policy.scss'],
})
export class CMSPolicyComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment

  isEdit: boolean = false
  isView: boolean = false
  dataManage: any
  selectionId: number = 0
  tab = 0
  policyType: any = 'policy'
  is_public: boolean = false

  policyList = new FormArray([
    new FormGroup({
      type: new FormControl('policy'),
      policy_id: new FormControl(null),
      name: new FormControl('นโยบายความเป็นส่วนตัว'),
      active: new FormControl(true),
      detail_th: new FormControl(''),
      detail_en: new FormControl(''),
      is_privacy_policy: new FormControl(true),
    }),
    new FormGroup({
      type: new FormControl('notpolicy'),
      policy_id: new FormControl(null),
      name: new FormControl('ข้อกำหนดและเงื่อนไข'),
      active: new FormControl(false),
      detail_th: new FormControl(''),
      detail_en: new FormControl(''),
      is_privacy_policy: new FormControl(false),
    }),
  ])

  constructor(
    public profile: Profile,
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public CMSPolicyService: CMSPolicyService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.policyType = params.id || 'policy'

      if (this.router.url.includes('view')) {
        this.isView = true
      }
      if (this.router.url.includes('edit')) {
        this.isEdit = true
      }

      this.initData()
    })
  }

  initData() {
    const apis = [this.CMSPolicyService.getCmsPolicy()]

    this.loading.start()
    forkJoin(apis).subscribe(([resAboutus]: any) => {
      if (resAboutus) {
        if (!resAboutus.is_error) {
          this.dataManage = resAboutus.data

          if (!this.dataManage) {
            this.router.navigate(['/setting/cms/policy/edit'])
          }
          if (this.dataManage?.length > 0) {
            this.dataManage.map((item: any) => {
              if (item.is_privacy_policy) {
                this.policyList.controls[0].patchValue({
                  policy_id: item.id,
                  detail_th: item.detail_th,
                  detail_en: item.detail_en,
                  active: true,
                })
              } else {
                this.policyList.controls[1].patchValue({
                  policy_id: item.id,
                  detail_th: item.detail_th,
                  detail_en: item.detail_en,
                  active: true,
                })
              }
            })
            this.policyList.updateValueAndValidity()
          }
        } else {
          this.customSnackBar.fail(resAboutus.message)
        }
      }
      this.loading.stop()
    })
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
  }

  setSelection(type: any) {
    this.policyType = type
    this.router.navigate([`/setting/cms/policy/${this.isView ? 'view' : 'edit'}`], {
      queryParams: { id: this.policyType },
    })
  }

  openPreview() {
    this.dialog.open(ModalPreviewPolicyComponent, {
      width: '1200px',
      data: {
        lang: this.tab == 0 ? 'th' : 'en',
        policies: this.policyList.getRawValue()[this.policyType == 'policy' ? 0 : 1],
      },
    })
  }

  onSave(type: any) {
    let payload = this.policyList.controls.map((item: any, i: number) => {
      return {
        id: item.value.policy_id || undefined,
        is_privacy_policy: item.value.is_privacy_policy,
        detail_th: item.value.detail_th,
        detail_en: item.value.detail_en,
        is_public: type == 'public' ? true : false,
      }
    })
    let payloadArr = this.policyType == 'policy' ? [payload[0]] : [payload[1]]
    // return console.log(payloadArr)

    this.loading.start()
    this.CMSPolicyService.addCmsPolicy(payloadArr).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success(type == 'public' ? 'เผยแพร่สำเร็จ' : 'บันทึกร่างสำเร็จ')
          this.router.navigate(['/setting/cms/policy/view'], {
            queryParams: { id: this.policyType },
          })
        } else {
          this.customSnackBar.failSave(
            type == 'public' ? 'เผยแพร่ไม่สำเร็จ' : 'บันทึกร่างไม่สำเร็จ'
          )
        }
      }
      this.loading.stop()
    })
  }

  onCancel() {
    const dialogRefConfirm = this.dialog.open(ModalLeaveComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/setting/cms/policy/view'], { queryParams: { id: this.policyType } })
      }
    })
  }
}
