import { Component } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'

import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { SearchComponent } from '../../../components/search/search'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { imports } from '../../../imports'
import { ModalDownloadFileComponent } from '../../../components/modal-download-file/modal-download-file'
import {
  Pagination,
  DownloadFileBlob,
  Moment,
  formatPrice,
  convertDateToApi,
  formatNoDecimal,
} from '../../../helpers'
import { ExpenseService, FileService, ProductStockService } from '../../../services'
import { DatePickerRangeComponent } from '../../../components/date-picker-range/date-picker-range'
import { FormControl, FormGroup } from '@angular/forms'
import { CheckboxAllTableComponent } from '../../../components/checkbox-table/checkbox-all-table.component'
import { CheckboxTableComponent } from '../../../components/checkbox-table/checkbox-table.component'
import { SearchFilterComponent } from './search-filter/search-filter'
import { SortByComponent } from '../../../components/sort-by/sort-by'
import { FilterTableHistoryExpense } from '../../../globals/search-and-filter/history-expense'

@Component({
  selector: 'app-expense-item',
  standalone: true,
  imports: [
    ...imports,
    MatTabsModule,
    SearchComponent,
    MatPaginatorModule,
    MatTooltipModule,
    DatePickerRangeComponent,
    CheckboxAllTableComponent,
    CheckboxTableComponent,
    SearchFilterComponent,
    SortByComponent,
  ],
  templateUrl: './expense-item.html',
  styleUrls: ['./expense-item.scss'],
})
export class ExpenseItemComponent {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment
  readonly formatPrice = formatPrice
  readonly formatNoDecimal = formatNoDecimal

  readonly paginationExpenseItem = new Pagination({
    sortName: 'id',
  })
  readonly paginationExpenseDefective = new Pagination({
    sortName: 'id',
  })

  tab = 0

  itemChecked1 = new Set<any>()
  itemChecked2 = new Set<any>()

  formExpenseDefective = new FormGroup({
    search: new FormControl(''),
    start_date: new FormControl(''),
    end_date: new FormControl(''),
  })

  expenseItemList: any = []
  expenseDefectiveList: any = []

  constructor(
    public route: ActivatedRoute,
    public Router: Router,
    public dialog: MatDialog,
    public profile: Profile,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public expenseService: ExpenseService,
    public fileService: FileService,
    public filterTableHistoryExpense: FilterTableHistoryExpense
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      if (params.tab) {
        this.tab = params.tab
      }
      if (this.tab.toString() == '0') {
        this.getExpenseItemList()
      } else {
        this.getExpenseDefectiveList()
      }
    })
  }

  getFilterExpenseItem() {
    const dataFilter = this.filterTableHistoryExpense.getData()

    if (!dataFilter.filter.expense_category_ids) {
      dataFilter.filter.expense_category_ids = [1, 2, 4]
    }

    const value = this.filterTableHistoryExpense.form.getRawValue()

    if (value.start_date) {
      dataFilter.filter.start_date = convertDateToApi(value.start_date)
    }
    if (value.end_date) {
      dataFilter.filter.end_date = convertDateToApi(value.end_date, 'day')
    }

    return dataFilter
  }

  getExpenseItemList() {
    const pagination = this.paginationExpenseItem.get()
    const dataFilter = this.getFilterExpenseItem()

    const payload = {
      ...pagination,
      ...dataFilter,
    }

    this.loading.start()
    this.expenseService.getExpenseList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.expenseItemList = res.data.records
          this.paginationExpenseItem.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  getFilterExpenseDefective() {
    const dataFilter: any = {
      filter: {
        expense_category_ids: [3],
      },
    }

    const value = this.formExpenseDefective.value
    if (value.start_date) {
      dataFilter.filter.start_date = convertDateToApi(value.start_date)
    }
    if (value.end_date) {
      dataFilter.filter.end_date = convertDateToApi(value.end_date, 'day')
    }

    if (value.search) {
      dataFilter.search = value.search
    }

    return dataFilter
  }

  getExpenseDefectiveList() {
    const pagination = this.paginationExpenseDefective.get()
    const dataFilter = this.getFilterExpenseDefective()

    const payload: any = {
      ...pagination,
      ...dataFilter,
    }

    this.loading.start()
    this.expenseService.getExpenseList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.expenseDefectiveList = res.data.records
          this.paginationExpenseDefective.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  changeTab($tabIndex: any) {
    this.filterTableHistoryExpense.clear()
    this.tab = $tabIndex
    this.navigateToTab(this.tab)
  }

  navigateToTab(tab: number) {
    this.Router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      skipLocationChange: false,
    })
  }

  openModalDownloadFile(title: string, detail: any) {
    const dialogRef = this.dialog.open(ModalDownloadFileComponent, {
      data: {
        title,
        detail,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.tab == 0) {
          this.downloadFileExpenseItem()
        } else {
          this.downloadFileExpenseDefective()
        }
      }
    })

    return dialogRef
  }

  downloadFileExpenseItem() {
    const pagination = this.paginationExpenseItem.get()
    const dataFilter = this.getFilterExpenseItem()
    dataFilter.filter.ids = this.itemChecked1.size > 0 ? [...this.itemChecked1.values()] : undefined
    // dataFilter.filter.expense_category_ids = [1, 2, 4]

    const payload = {
      sort_by: pagination.sort_by,
      sort_name: pagination.sort_name,
      ...dataFilter,
    }
    this.loading.start()
    this.expenseService.exportExpenseList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `expense.xlsx`)
          this.itemChecked1.clear()
          this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
        } else {
          this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }

  downloadFileExpenseDefective() {
    const pagination = this.paginationExpenseDefective.get()
    const dataFilter = this.getFilterExpenseDefective()
    dataFilter.filter.ids = this.itemChecked2.size > 0 ? [...this.itemChecked2.values()] : undefined
    // dataFilter.filter.expense_category_ids = [3]

    const payload = {
      sort_by: pagination.sort_by,
      sort_name: pagination.sort_name,
      ...dataFilter,
    }
    this.loading.start()
    this.expenseService.exportExpenseList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `expense-defective.xlsx`)
          this.itemChecked2.clear()
          this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
        } else {
          this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }

  downloadFiles(file: any) {
    this.loading.start()
    this.fileService.getBlob(file.id).subscribe((res: any) => {
      if (res) {
        DownloadFileBlob(res.body, file.name)
        this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
      } else {
        this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
      }
      this.loading.stop()
    })
  }
}
