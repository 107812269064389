export const LINK_LIST_ID = {
  none: '',
  PRODUCT_DETAIL: 1,
  CATEGORY: 2,
  URL: 3,
  CAMPAIGN: 4,
  COLLECTION: 5,
} as const

export type EnumLinkListId = (typeof LINK_LIST_ID)[keyof typeof LINK_LIST_ID]

export const linkTextView = (
  cms_link_list_id: any,
  product_name?: any,
  product_category_name?: any,
  link_path?: any
) => {
  switch (cms_link_list_id) {
    case LINK_LIST_ID.none:
      return 'ไม่ระบุ'
    case LINK_LIST_ID.PRODUCT_DETAIL:
      return product_name || 'หน้ารายละเอียดสินค้า'
    case LINK_LIST_ID.CATEGORY:
      return product_category_name || 'หมวดหมู่สินค้า'
    case LINK_LIST_ID.URL:
      return link_path || 'กำหนด URL'
    case LINK_LIST_ID.CAMPAIGN:
      return 'แคมเปญ'
    case LINK_LIST_ID.COLLECTION:
      return 'คอลเลคชัน'
    default:
      break
  }
}
