import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  Pagination,
  sortList
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { SelectSortComponent } from '../../../components/select-sort/select-sort'
import { CheckboxAllTableComponent } from '../../../components/checkbox-table/checkbox-all-table.component'
import { CheckboxTableComponent } from '../../../components/checkbox-table/checkbox-table.component'

@Component({
  selector: 'app-tab-products-discount',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    SelectSortComponent,
    CheckboxAllTableComponent,
    CheckboxTableComponent
  ],
  templateUrl: './tab-products-discount.html',
  styleUrls: ['./tab-products-discount.scss'],
})
export class TabProductsDiscountComponent implements OnInit {
  @Output() setItemCheckedDiscount = new EventEmitter()

  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment

  isEdit: boolean = false
  isView: boolean = false

  sortControl = new FormControl(sortList[0].value)
  readonly paginationProduct = new Pagination({
    sortName: sortList[0].value.sortName,
    sortBy: sortList[0].value.sortBy as 'asc' | 'desc',
  })
  rerender = false
  pageNumber = new FormControl(1)
  productList: any[] = [
    {id: 1}
  ]
  itemChecked = new Set<any>()
  itemCheckedData = new Map()

  list = {
    sort: sortList,
  }

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
  ) {}

  ngOnInit(): void {
    this.getDiscountProductList()
  }

  getDiscountProductList() {

  }

  onSort() {
    this.paginationProduct.setFromPaginator({
      pageIndex: 0,
      ...(this.sortControl.value || {}),
    })
    this.getDiscountProductList()
  }


  setItemCheckedData(checked: boolean, datas: any[]) {
    datas.forEach(item => {
      if (checked) {
        this.itemCheckedData.set(item.id, {
          id: item.id,
          is_order: item.is_order,
        })
        this.setItemCheckedDiscount.emit({item, type: 'set'})
      } else {
        this.itemCheckedData.delete(item.id)
        this.setItemCheckedDiscount.emit({item, type: 'delete'})
      }
    })
  }

}
