export interface PaginationInf {
  sortName?: string
  sortBy?: 'asc' | 'desc'
  pageIndex?: number
  pageSize?: number
  length?: number
  pageSizeOptions?: number[]
}

export class Pagination {
  readonly defaultSort = {
    sortName: 'id',
    sortBy: 'asc',
  }

  readonly data = {
    sortName: this.defaultSort.sortName,
    sortBy: this.defaultSort.sortBy,
    pageIndex: 0,
    pageSize: 10,
    length: 0,
    pageSizeOptions: [5, 10, 25, 100],
  }

  get default_order_reverse() {
    return this.defaultSort.sortBy == 'desc'
  }

  get is_order_reverse() {
    return this.data.sortBy == 'desc'
  }

  get indexData() {
    const min = this.data.pageIndex * this.data.pageSize
    const index = {
      min,
      max: min + (this.data.pageSize - 1),
    }
    return index
  }

  get page() {
    return this.data.pageIndex + 1
  }

  get totalPage() {
    return Math.ceil(this.data.length ? this.data.length / this.data.pageSize : 0)
  }

  constructor(data: PaginationInf) {
    this.defaultSort.sortBy = data.sortBy || this.defaultSort.sortBy
    this.defaultSort.sortName = data.sortName || this.defaultSort.sortName
    this.setFromPaginator(data)
  }

  setFromResponse(data: any, autoResetPage = false) {
    let pageIndex = data.page - 1
    if (autoResetPage) {
      const totalPage = Math.ceil(data.total_record ? data.total_record / this.data.pageSize : 0)
      if (data.page > totalPage) {
        pageIndex = 1
      }
    }
    this.data.pageIndex = pageIndex
    this.data.length = data.total_record
  }

  setFromPaginator(value: any) {
    const data = this.data as any
    for (const key in value) {
      if (key in this.data) {
        data[key] = value[key]
      }
    }
  }

  get() {
    return {
      sort_name: this.data.sortName,
      sort_by: this.data.sortBy,
      page: this.data.pageIndex + 1,
      page_size: this.data.pageSize,
    }
  }

  reset() {
    this.data.pageIndex = 0
  }

  resetSort() {
    this.data.sortBy = this.defaultSort.sortName
    this.data.sortName = this.defaultSort.sortName
  }

  onSort(key: string, reverse: boolean) {
    let is_order_reverse = !this.is_order_reverse
    if (this.data.sortName == key) {
      is_order_reverse = reverse ? !is_order_reverse : is_order_reverse
      if (is_order_reverse == this.default_order_reverse) {
        key = this.defaultSort.sortName
        is_order_reverse = this.default_order_reverse
      }
    } else {
      is_order_reverse = this.default_order_reverse
      if (reverse) {
        is_order_reverse = !is_order_reverse
      }
    }

    this.data.sortName = key
    this.data.sortBy = is_order_reverse ? 'desc' : 'asc'
    this.data.pageIndex = 0

    return this.get()
  }
}
