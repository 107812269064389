<div class="tab-role-box">
  <div class="box-left">
    <div class="box-head d-flex justify-content-between">
      <div class="d-flex align-items-center">สิทธิ์ผู้ใช้งาน</div>
      @if (!isEditAccess && profile.permissions.checkAccess([APP_PERMISSION.ROLE_AND_PERMISSION],
      [ACTION_ACCESS.CREATE])) {
      <button
        class="btn btn-outline-secondary"
        (click)="openModalRole('เพิ่มสิทธิ์ผู้ใช้งาน', 'create')"
        matRipple>
        <span class="icon material-symbols-outlined fill">add_circle</span>
        เพิ่มสิทธิ์
      </button>
      }
    </div>
    <div class="box-body">
      @if (isDisplayAccess && isEditAccess && selectionRole) {
      <div
        class="box-role d-flex justify-content-between align-items-center role-manage {{ !isCreateAccess && 'active'}}">
        <div class="box-role-name-select d-flex justify-content-between align-items-center">
          <div class="text-truncate">{{selectionRole.name}}</div>
        </div>
      </div>
      } @else { @for (role of roleList; track $index) {
      <div
        matRipple
        class="box-role d-flex justify-content-between align-items-center role-manage {{ $index+1 != roleList.length && 'mb-3' }}"
        [class.active]="selectionRole == role">
        <div
          class="box-role-name d-flex justify-content-between align-items-center"
          (click)="setSelectedRole(role, $index)">
          <div class="text-truncate">{{role.name}}</div>
        </div>
        @if (!isEditAccess && (role.action.edit || role.action.delete)) {
        <div class="role-icon-menu" [matMenuTriggerFor]="menu">
          <img alt="icon" class="mr-2" src="/assets/images/icons/icon-dot.svg" />
        </div>
        } @else {
        <ng-template #emptyIcon></ng-template>
        }
        <mat-menu #menu="matMenu" xPosition="before">
          @if (role.action.edit) {
          <div mat-menu-item class="role-item d-flex align-items-center" (click)="renameRole(role)">
            <img alt="icon" class="me-2" src="/assets/images/icons/icon-edit.svg" />
            แก้ไขชื่อสิทธิ์
          </div>
          <div
            mat-menu-item
            class="role-item d-flex align-items-center"
            (click)="setEditAccess(role, $index)">
            <img alt="icon" class="me-2" src="/assets/images/icons/icon-edit-access.svg" />
            แก้ไขการเข้าถึง
          </div>
          } @if (role.action.delete) {
          <div
            mat-menu-item
            class="role-item d-flex delete-role align-items-center text-danger"
            (click)="deleteRole(role, $index)">
            <img alt="icon" class="me-2" src="/assets/images/icons/icon-delete-red.svg" />
            ลบสิทธิ์
          </div>
          }
        </mat-menu>
      </div>
      } } @if (!roleList.length) {
      <div class="data-not-found">ไม่พบสิทธิ์ผู้ใช้งาน กรุณาเพิ่มสิทธิ์ผู้ใช้งาน</div>
      }
    </div>
  </div>
  <div class="box-right">
    <div class="box-head py-4">จัดการสิทธิ์และการเข้าถึง</div>
    <div class="box-body min-hight">
      @if(!isEditAccess && !isDisplayAccess) {
      <div class="data-not-found">กรุณาเลือกสิทธิ์ผู้ใช้งาน ข้อมูลการเข้าถึงจะแสดงที่นี่</div>
      }
      <!--  -->
      @if(isDisplayAccess) {
      <!--  -->
      @if (isEditAccess && selectionRole) {
      <app-checkbox-access-role
        [isEditAccess]="isEditAccess"
        [dataCheckbox]="applicationList"
        (onChange)="dirty = true"></app-checkbox-access-role>
      } @else {
      <div class="tabs-sub-wrapper">
        <mat-tab-group
          animationDuration="0ms"
          [disableRipple]="true"
          [selectedIndex]="tab"
          (selectedIndexChange)="changeTab($event)">
          <mat-tab label="การเข้าถึง">
            <app-checkbox-access-role
              [isEditAccess]="isEditAccess"
              [dataCheckbox]="applicationList"></app-checkbox-access-role>
          </mat-tab>

          <mat-tab label="รายชื่อผู้ใช้งาน ({{ userList.length }})">
            <div class="tabs-content-wrapper px-0">
              <div class="table-responsive">
                <table class="table table-fixed-column">
                  <thead>
                    <tr class="role">
                      <th width="90">รหัสผู้ใช้</th>
                      <th width="140">ชื่อ</th>
                      <th width="140">นามสกุล</th>
                      <th width="145">ตำแหน่ง</th>
                      <th width="175">อีเมล</th>
                      <th width="150">เบอร์โทรศัพท์</th>
                      <th width="120">สิทธิ์ผู้ใช้งาน</th>
                      <th width="112">สถานะ</th>
                      <th width="150">อัปเดตโดย</th>
                      <th width="170">อัปเดตล่าสุด</th>
                    </tr>
                  </thead>

                  <tbody>
                    @for (user of userList; track $index) {
                    <tr>
                      <td>{{ user.code }}</td>
                      <td>{{ user.first_name }}</td>
                      <td>{{ user.last_name }}</td>
                      <td>{{ user.position }}</td>
                      <td>{{ user.email }}</td>
                      <td>{{ user.tel || '-' }}</td>
                      <td>{{ user.role.name }}</td>
                      <td>
                        <app-status-user [status]="user.is_active ? 'active' : 'inactive'">
                          {{ user.is_active ? 'ใช้งาน' : 'ไม่ใช้งาน' }}
                        </app-status-user>
                      </td>
                      <td>{{user.updated_by ? user.updated_by.full_name : ''}}</td>
                      <td>
                        {{ user.updated_at ? Moment(user.updated_at).format('DD-MM-YYYY, HH:mm') :
                        '' }}
                      </td>
                    </tr>
                    }
                  </tbody>
                </table>
                @if (!userList.length) {
                <div class="data-not-found data-not-found-fix">ไม่พบรายชื่อผู้ใช้งาน</div>
                }
              </div>

              <!-- Pagination -->
              @if (userList.length) {
              <div class="pagination-wrapper">
                <mat-paginator
                  [pageIndex]="paginationUser.data.pageIndex"
                  [length]="paginationUser.data.length"
                  [pageSize]="paginationUser.data.pageSize"
                  [pageSizeOptions]="paginationUser.data.pageSizeOptions"
                  aria-label="Select page"
                  (page)="paginationUser.setFromPaginator($event);getUserList()">
                </mat-paginator>
              </div>
              }
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
      } }
    </div>
  </div>
</div>

@if (isDisplayAccess && isEditAccess) {
<div class="d-flex justify-content-end mt-3">
  <button
    matRipple
    type="button"
    class="btn btn-outline-primary min-w-100px me-2"
    (click)="onClose()">
    ยกเลิก
  </button>
  <button matRipple type="button" class="btn btn-primary min-w-100px" (click)="openModalConfirm()">
    ยืนยัน
  </button>
</div>
}
