<div class="page-content pb-0">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">คอลเลกชัน</div>
  </div>

  <div class="title-wrapper">คอลเลกชัน</div>

  <div class="page-content-inner pb-0 d-flex flex-column">
    <div class="tab-box">
      <div class="box-left">
        <div class="box-top d-flex justify-content-between">
          <div class="d-flex align-items-center">คอลเลกชัน</div>
        </div>
        <div class="box-body">
          @for (collect of collectionList; track $index) {
          <div
            matRipple
            [matRippleDisabled]="collectionId == 'create'"
            class="box d-flex justify-content-between align-items-center manage {{ $index != collectionList.length && 'mb-3' }}"
            [class.active]="collectionId == collect.id"
            [class.disable-pointer-event]="!isView"
            (click)="collectionId == 'create' ? null : setSelection(collect.id)">
            <div class="box-name d-flex justify-content-between align-items-center">
              <div class="text-truncate">{{collect.name}}</div>
            </div>

            <div class="icon-menu" [matMenuTriggerFor]="menu">
              <img alt="icon" class="mr-2" src="/assets/images/icons/icon-dot.svg" />
            </div>

            <mat-menu #menu="matMenu" xPosition="before">
              <div
                mat-menu-item
                class="item d-flex delete align-items-center text-danger"
                (click)="confirmDelete(collect.id, $index)">
                <img alt="icon" class="me-2" src="/assets/images/icons/icon-delete-red.svg" />
                ลบคอลเลกชัน
              </div>
            </mat-menu>
          </div>
          }
          <!-- ~ -->
          @if (isView) {
          <button
            class="btn btn-outline-secondary {{ collectionList.length && 'mt-3' }}"
            matRipple
            (click)="addCollection()">
            <span class="icon material-symbols-outlined fill">add_circle</span>
            เพิ่มคอลเลกชัน
          </button>
          }
        </div>
      </div>
      <div class="box-right">
        @for (collect of collectionList; track $index) {
        <!-- ~~ -->
        @if(collectionId == collect.id) {
        <div class="box-body right min-hight">
          @if (dataManage?.[$index]) {
          <div class="form-label color--neutral-500">
            วันที่อัปเดต: {{ Moment(dataManage?.[$index]?.updated_at).format('DD-MM-YYYY, HH:mm') }}
            อัปเดตโดย: {{ dataManage?.[$index]?.updated_by?.full_name || '-' }}
          </div>
          <div class="mb-3">
            <app-status [status]="dataManage?.[$index].is_public ? 'public' : 'not_public'">
              {{dataManage?.[$index].is_public ? 'เผยแพร่แล้ว' : 'ยังไม่เผยแพร่'}}
            </app-status>
          </div>
          }

          <label
            class="form-label {{ isView && 'color--neutral-500'}}"
            [class.text-danger]="form.controls['content_name_th'].touched && form.controls['content_name_th'].invalid">
            หัวข้อคอนเทนต์ (ภาษาไทย)
          </label>

          @if (isEdit) {
          <app-input
            [control]="form.controls['content_name_th']"
            maxlength="100"
            remainingText="100">
            @if (form.controls['content_name_th'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            }
          </app-input>
          } @else {
          <div class="label-view mb-3">{{ form.value['content_name_th'] || '-' }}</div>
          }

          <label
            class="form-label {{ isView && 'color--neutral-500'}}"
            [class.text-danger]="form.controls['content_name_en'].touched && form.controls['content_name_en'].invalid">
            หัวข้อคอนเทนต์ (ภาษาอังกฤษ)
          </label>
          @if (isEdit) {
          <app-input
            [control]="form.controls['content_name_en']"
            maxlength="100"
            remainingText="100">
            @if (form.controls['content_name_en'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            }
          </app-input>
          } @else {
          <div class="label-view mb-3">{{ form.value['content_name_en'] || '-' }}</div>
          }

          <label
            class="form-label {{ isView && 'color--neutral-500'}}"
            [class.text-danger]="form.controls['content_detail_th'].touched && form.controls['content_detail_th'].invalid">
            คำอธิบายหัวข้อคอนเทนต์ (ภาษาไทย)
          </label>

          @if (isEdit) {
          <app-input
            [control]="form.controls['content_detail_th']"
            maxlength="100"
            remainingText="100">
            @if (form.controls['content_detail_th'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            }
          </app-input>
          } @else {
          <div class="label-view mb-3">{{ form.value['content_detail_th'] || '-' }}</div>
          }

          <label
            class="form-label {{ isView && 'color--neutral-500'}}"
            [class.text-danger]="form.controls['content_detail_en'].touched && form.controls['content_detail_en'].invalid">
            คำอธิบายหัวข้อคอนเทนต์ (ภาษาอังกฤษ)
          </label>
          @if (isEdit) {
          <app-input
            [control]="form.controls['content_detail_en']"
            maxlength="100"
            remainingText="100">
            @if (form.controls['content_detail_en'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            }
          </app-input>
          } @else {
          <div class="label-view mb-3">{{ form.value['content_detail_en'] || '-' }}</div>
          }
          <!-- ~~ -->
          @for (item of file_list.controls; track item; let i = $index) {
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="title-blue mt-2">
                ภาพที่ {{i+1}}/9 @if(isEdit){ <span class="color--red-600">*</span> }
              </div>

              <mat-radio-group
                [formControl]="item.controls['is_vertical']"
                class="d-flex align-items-center">
                @if (!isView || item.controls['is_vertical'].value == true) {
                <div class="radio-button-wrapper box-radio me-2">
                  <mat-radio-button [value]="true">แนวตั้ง</mat-radio-button>
                </div>
                }
                <!-- ~ -->
                @if (!isView || item.controls['is_vertical'].value == false) {
                <div class="radio-button-wrapper box-radio">
                  <mat-radio-button [value]="false">แนวนอน</mat-radio-button>
                </div>
                }
              </mat-radio-group>
            </div>

            @if (i > 0 && !isView) {
            <button matRipple class="btn btn-outline-danger" (click)="file_list.removeAt(i)">
              <span class="icon material-symbols-outlined fill">delete</span>
            </button>
            }
          </div>

          @if (isEdit) {
          <div class="remark mt-0 mb-2 my-sm-2">
            @if(item.controls['is_vertical'].value) { (ขนาดรูปภาพ : 355px x 470px / ไม่เกิน 5 Mb) |
            (ขนาดวีดิโอ : 1920px x 1080px / ไม่เกิน 30 Mb) } @else { (ขนาดรูปภาพ : 710px x 470px /
            ไม่เกิน 5 Mb) | (ขนาดวีดิโอ : 1920px x 1080px / ไม่เกิน 30 Mb) }
          </div>
          }
          <div class="d-flex justify-content-center">
            <div
              class="upload-file-wrapper"
              [ngClass]="item.controls['is_vertical'].value ? 'upload-vertical' : 'upload-horizontal'">
              <app-upload-file-flexible
                [type]="isEdit ? 'edit' : 'view'"
                [maxImageSize]="5"
                [maxVideoSize]="30"
                [isRequired]="item.controls['file_id'].touched && item.controls['file_id'].invalid"
                [logoDefault]="item.value.file"
                (upload)="item.controls['file_id'].setValue($event);" />
            </div>
          </div>
          }
          <!-- ~~ -->
          @if (file_list.controls.length <= 8 && isEdit) {
          <button class="btn btn-outline-secondary mt-4" matRipple (click)="addFiles($index)">
            <span class="icon material-symbols-outlined fill">add_circle</span>
            เพิ่ม
          </button>
          }
        </div>
        }
        <!-- ~~ -->
        }
      </div>
    </div>

    <div class="page-footer-btn">
      @if (isView) { @if (profile.permissions.checkAccess([APP_PERMISSION.CMS],
      [ACTION_ACCESS.UPDATE])) {
      <div class="d-flex flex-wrap justify-content-end">
        <button
          matRipple
          class="btn btn-outline-secondary min-w-165px"
          [routerLink]="['/setting/cms/collection/edit']"
          [queryParams]="{id: collectionId}">
          แก้ไข
        </button>
      </div>
      } } @else if(isEdit) {
      <div class="footer-btn d-flex flex-wrap justify-content-between">
        <div class="footer-btn-left d-flex flex-wrap">
          <button matRipple class="btn btn-outline-secondary min-w-165px" (click)="onCancel()">
            ยกเลิก
          </button>
        </div>

        <div class="footer-btn-right d-flex flex-wrap">
          <button matRipple class="btn btn-outline-secondary min-w-165px" (click)="openPreview()">
            ดูตัวอย่าง
          </button>
          @if (profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.UPDATE]) ||
          profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.CREATE])) {
          <button
            matRipple
            class="btn btn-outline-secondary min-w-165px"
            (click)="onSave('private')">
            บันทึกร่าง
          </button>
          <button matRipple class="btn btn-primary min-w-165px" (click)="onSave('public')">
            เผยแพร่ทันที
          </button>
          }
        </div>
      </div>
      }
    </div>
  </div>
</div>
