import { Component, OnInit } from '@angular/core'
import { imports } from '../../imports'
import { InputComponent } from '../../components/input/input'
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatCheckbox } from '@angular/material/checkbox'
import { TextareaComponent } from '../../components/text-area/text-area'
import { Loading } from '../../globals'
import { SelectComponent } from '../../components/select/select'
import { MatDialog } from '@angular/material/dialog'
import { ModalProductAddressComponent } from './modal-product-address/modal-product-address'
import { ModalDiscountCodeComponent } from './modal-discount-code/modal-discount-code'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatRadioModule } from '@angular/material/radio'
import { UploadImageProfileComponent } from '../../components/upload-image-profile/upload-image-profile'
import { InputNumberComponent } from '../../components/input-number/input-number'
import { DatePickerComponent } from '../../components/date-picker/date-picker'
import { TimePickerComponent } from '../../components/time-picker/time-picker'
import { PaymentStatuslogComponent } from './payment-status-log/payment-status-log'
import { LabelTierColorComponent } from '../../components/label-tier-color/label-tier-color'
import { ModalTierComponent } from './modal-tier/modal-tier'
import { ModalMyTierComponent } from './modal-my-tier/modal-my-tier'
import { ModalDiscountCaratComponent } from './modal-discount-carat/modal-discount-carat'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { FileService, PublicService } from '../../services'
import { ActivatedRoute, Router } from '@angular/router'
import { forkJoin } from 'rxjs'
import {
  BANK_NONE,
  COMPANY_ID,
  DownloadFileBlob,
  Moment,
  ORDER_ID,
  PAYMENT_TYPE,
  TRANSPORT_ID,
  ValidateArrayForm,
  ValidateForm,
  checkUseCarat,
  convertDateToApi,
  currenTier,
  formatPrice,
  formatPriceAutoDecimal,
  mergeDateAndTime,
} from '../../helpers'
import { InputChipsComponent } from '../../components/input-chips/input-chips'
import { Service } from '../../services/service'

const order_status_wait_paid = ORDER_ID.CREATE + 0.1

@Component({
  selector: 'app-order-payment',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    MatCheckbox,
    SelectComponent,
    ModalProductAddressComponent,
    ModalDiscountCodeComponent,
    MatProgressBarModule,
    MatRadioModule,
    UploadImageProfileComponent,
    InputNumberComponent,
    DatePickerComponent,
    TimePickerComponent,
    PaymentStatuslogComponent,
    LabelTierColorComponent,
    ModalTierComponent,
    ModalMyTierComponent,
    ModalDiscountCaratComponent,
    InputChipsComponent,
  ],
  templateUrl: './order-payment.html',
  styleUrls: ['./order-payment.scss'],
})
export class OrderPaymentComponent implements OnInit {
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly Moment = Moment
  readonly TRANSPORT_ID = TRANSPORT_ID
  readonly ORDER_ID = ORDER_ID
  readonly currenTier = currenTier
  readonly PAYMENT_TYPE = PAYMENT_TYPE
  readonly BANK_NONE = BANK_NONE
  readonly COMPANY_ID = COMPANY_ID

  tierList: any[] = []

  link_code: any
  order_not_found = false
  order: any
  customer: any
  companyProfile = {
    is_debit_credit: false,
    fee_percent: 0,
  }
  carat = {
    carat: 0,
    total_order_amount: 0,
    value_amount: 0,
    discounts: [],
    isUseCarat: false,
  }
  payment: any
  readonly statuss = [
    0,
    ORDER_ID.CREATE,
    ORDER_ID.VERIFY,
    ORDER_ID.PAID,
    ORDER_ID.PRINTED,
    ORDER_ID.SHIPMENT,
  ] as const
  status: any = -1
  isEdit = true
  isEditCustomer = false
  creditAndCaratError = false

  get isCreate() {
    return [ORDER_ID.CREATE].includes(this.status) && !this.order.is_confirm_address
  }

  get isWaitPaid() {
    return [ORDER_ID.CREATE].includes(this.status) && this.order.is_confirm_address
  }

  get isVerify() {
    return [ORDER_ID.VERIFY].includes(this.status)
  }

  get isPreparing() {
    return [ORDER_ID.PAID, ORDER_ID.PRINTED].includes(this.status)
  }

  get isShipped() {
    return [ORDER_ID.SHIPMENT].includes(this.status)
  }

  get stepIndex() {
    return this.statuss.indexOf(this.status)
  }

  get isNotFound() {
    return this.stepIndex < 0
  }

  getStepIndex(status: any) {
    return this.statuss.indexOf(status)
  }

  get stepCompleted() {
    return {
      isCreate: this.stepIndex >= this.getStepIndex(ORDER_ID.CREATE),
      isWaitPaid: this.stepIndex > this.getStepIndex(ORDER_ID.CREATE) || this.isWaitPaid,
      isVerify: this.stepIndex >= this.getStepIndex(ORDER_ID.VERIFY),
      isPreparing:
        this.stepIndex >= this.getStepIndex(ORDER_ID.PAID) ||
        this.stepIndex >= this.getStepIndex(ORDER_ID.PRINTED),
      isShipped: this.stepIndex >= this.getStepIndex(ORDER_ID.SHIPMENT),
    }
  }

  form = new FormGroup({
    name: new FormControl(''),
    last_name: new FormControl(''),
    telephone: new FormControl(''),
    is_policy: new FormControl(false),
    discount_code: new FormControl<any[]>(
      [],
      [
        (control: AbstractControl) => {
          // if (control.value && control.value.length && this.discountCodeList) {
          // const isPromo = this.discountCodeList.some((d: any) => {
          //   return control.value.includes(d.code)
          // })
          // if (!isPromo) {
          //   return {
          //     incorrect: true,
          //   }
          // }
          // }

          if (control.errors?.['incorrect']) {
            return {
              incorrect: true,
            }
          }
          return null
        },
      ]
    ),
  })

  addresss = new FormArray<FormGroup>([])
  productArrs = new FormArray<FormControl>([])
  formStatusCreate = new FormGroup({
    is_multi_delivery: new FormControl(false),
    addresss: this.addresss,
    productArrs: this.productArrs,
  })

  formPayment = new FormGroup({
    credit: new FormControl(0),
    carat: new FormControl(0),
  })
  arrayPayments = new FormArray<FormGroup<{ [k: string]: FormControl }>>([])

  formTax = new FormGroup({
    is_tax: new FormControl(false),
    tax_name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    tax_id: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{13,13}$/)]),
    tax_address: new FormControl('', [Validators.required]),
    tax_telephone: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{10,10}$/)]),
    is_tax_same_address: new FormControl(false),
  })

  products: any[] = []
  bankAccountList: any[] = []
  bankList: any[] = []
  discountCodeList: any[] = []

  bank_account_main_id: any

  get isMultiDelivery() {
    return this.formStatusCreate.controls['is_multi_delivery'].value
  }

  get promotionCodes() {
    return this.discountCodeList.filter((d: any) =>
      this.form.controls.discount_code.value?.includes(d.code)
    )
  }

  get discountCodeValue() {
    return this.form.controls.discount_code.value || []
  }

  get discountCodes() {
    return this.discountCodeList.filter((d: any) => this.discountCodeValue.includes(d.code))
  }

  get useCredit() {
    return this.formPayment.controls.credit.value || 0
  }

  get maxCredit() {
    return this.customer?.credit || 0
  }

  get useCarat() {
    return this.formPayment.controls.carat.value || 0
  }

  get maxCarat() {
    return this.carat.discounts.reduce((maxC: any, c: any) => (c.carat > maxC.carat ? c : maxC), {
      carat: 0,
      discount_price: 0,
    })
  }

  get maxCaratCustomer() {
    return (
      (this.customer.carat < this.maxCarat.carat ? this.customer.carat : this.maxCarat.carat) || 0
    )
  }

  get nowCarat() {
    const dataCarat = this.carat.discounts.reduce(
      (nowC: any, c: any) => {
        return c.carat <= this.useCarat && c.carat > nowC.carat ? c : nowC
      },
      { carat: 0, discount_price: 0 }
    )

    let diff = (this.formPayment.controls['carat'].value || 0) - dataCarat.carat
    diff *= this.carat.value_amount

    return {
      dataCarat,
      discount: dataCarat.discount_price + diff,
    }
  }

  get nextCarat() {
    return this.carat.discounts.reduce((nextC: any, c: any) => {
      return this.useCarat < c.carat && c.carat < nextC.carat ? c : nextC
    }, this.maxCarat)
  }

  get discount() {
    return this.order.discount || 0
  }

  totalProductPrice(onlyIsCarat: boolean): number {
    return this.order.products.reduce((total: number, d: any) => {
      if (onlyIsCarat && !d.is_carat) {
        return total
      }
      return (total += d.total_price || 0)
    }, 0)
  }

  get shippingCost() {
    return this.order.shipping_cost || 0
  }

  get discountCodePrice() {
    return (
      (this.isWaitPaid
        ? this.discountCodes.reduce((total: number, promotion: any) => {
            if (promotion.is_percent) {
              this.products.forEach((product: any) => {
                if (
                  promotion.products.some(
                    (p: any) =>
                      p.product_id == product.product_id &&
                      p.product_detail_id == product.product_detail_id
                  )
                ) {
                  total += (promotion.value * product.total_price) / 100
                }
              })
            } else {
              total += promotion.value
            }
            return total
          }, 0)
        : this.payment.discount_code_price) || 0
    )
  }

  get creditCodePrice() {
    return (this.isWaitPaid ? this.useCredit : this.payment.credit_code_price) || 0
  }

  get caratCodePrice() {
    return (this.isWaitPaid ? this.nowCarat.discount : this.payment.carat_code_price) || 0
  }

  get sumTotalOther() {
    return (
      this.shippingCost -
      (this.discount + this.discountCodePrice + this.creditCodePrice + this.caratCodePrice)
    )
  }

  get sumTotal() {
    if (this.isWaitPaid) {
      return this.totalProductPrice(false) + this.sumTotalOther
    } else {
      return this.order.total
    }
  }

  get caratTotal() {
    if (this.carat.isUseCarat) {
      const sumTotalOnlyIsCarat = this.totalProductPrice(true)

      let total =
        this.carat.total_order_amount && sumTotalOnlyIsCarat
          ? Math.floor(sumTotalOnlyIsCarat / this.carat.total_order_amount)
          : 0
      if (total < 1) {
        total = 0
      }

      const excess = sumTotalOnlyIsCarat - total * this.carat.total_order_amount
      const remain = this.carat.total_order_amount - excess
      const percent = (excess * 100) / this.carat.total_order_amount

      return {
        total,
        excess,
        remain,
        percent,
      }
    } else {
      return {
        total: 0,
        excess: 0,
        remain: 0,
        percent: 0,
      }
    }
  }

  get receivedCarat() {
    return this.stepIndex < 3 ? this.caratTotal.total : this.payment.received_carat
  }

  get detailCreditDebit() {
    if (this.payment) {
      return this.payment.details.find((p: any) => p.payment_type == PAYMENT_TYPE.CREDIT_DEBIT)
    }
  }

  get paymentCreditDebit() {
    return this.arrayPayments.controls.find(
      (form: FormGroup) => form.controls['payment'].value == PAYMENT_TYPE.CREDIT_DEBIT
    )
  }

  get disableCreditDebit() {
    return !!this.paymentCreditDebit || !!this.detailCreditDebit
  }

  get totalRemainAmount() {
    if (this.payment) {
      const remain = this.sumTotal - (this.payment?.payment_amount || 0)
      return remain < 0 ? 0 : remain
    }
    return this.sumTotal
  }

  get paymentRemainAmount() {
    return this.arrayPayments.controls.reduce<number>((total, form: FormGroup) => {
      if (form.controls['payment'].value == PAYMENT_TYPE.SLIP) {
        total -= form.controls['payment_amount'].value || 0
      }
      if (total < 0) {
        return 0
      }
      return total
    }, this.sumTotal - (this.payment?.payment_amount || 0))
  }

  get totalPaymentAmount() {
    return this.arrayPayments.controls.reduce((total, form: FormGroup) => {
      if (form.controls['payment'].value == PAYMENT_TYPE.SLIP) {
        total += form.controls['payment_amount'].value || 0
      } else {
        if (form.controls['is_full_amount'].value == 1) {
          total += this.paymentRemainAmount
        } else {
          total += form.controls['payment_amount'].value || 0
        }
      }
      return total
    }, 0)
  }

  get totalPaymentAmountDetail() {
    return this.payment
      ? this.payment.details.reduce((total: number, p: any) => {
          return total + p.payment_amount
        }, 0)
      : 0
  }

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public loading: Loading,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public publicService: PublicService,
    public fileService: FileService,
    public service: Service
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.initData(params['link_code'])
    })

    this.form.controls.discount_code.valueChanges.subscribe(value => {
      if (value && value.length && this.discountCodeList) {
        const isPromo = this.discountCodeList.some((d: any) => {
          return value.includes(d.code)
        })
        if (isPromo) {
          this.form.controls.discount_code.setErrors({
            incorrect: false,
          })
          this.form.controls.discount_code.updateValueAndValidity()
        } else {
          this.form.controls.discount_code.reset([])
          setTimeout(() => {
            this.form.controls.discount_code.setErrors({
              incorrect: true,
            })
          })
        }
      }
    })
  }

  initData(link_code: string) {
    if (!link_code) return
    this.link_code = link_code

    const apis = [
      this.publicService.getOrderByLinkCode(link_code),
      this.publicService.getCarats(),
      this.publicService.getBankAccounts(),
      this.publicService.getBanks(),
      this.publicService.getCompanyProfile(),
      this.publicService.getTiers(),
    ]

    this.loading.start()
    forkJoin(apis).subscribe(
      ([resOrder, resCarat, resBankAccount, resBank, resCompanyProfile, resTier]: any) => {
        if (!resOrder.is_error) {
          this.publicService
            .getCustomerById(resOrder.data.customer_id)
            .subscribe(async resCustomer => {
              if (!resCustomer.is_error) {
                const order = resOrder.data

                if (order.status_id != ORDER_ID.CREATE) {
                  await this.getPayment(order.id)
                  if (!this.payment) return
                }

                if (order.status_id == ORDER_ID.CREATE) {
                  this.getPromotions(order.id)
                }

                this.tierList = resTier.data.filter((d: any) => d.is_active)

                const tier = currenTier(this.tierList, resCustomer.data.total_price)
                const tier_id = tier?.id || null

                this.order = order
                order.customer.tier = tier
                order.customer.tier_id = tier_id

                this.customer = {
                  ...resCustomer.data,
                  tier,
                  tier_id,
                }

                this.status = this.order.status_id

                //test
                // this.order.is_confirm_address = false
                // this.status = ORDER_ID.CREATE
                // this.status = ORDER_ID.VERIFY
                // this.status = ORDER_ID.PAID
                // this.status = ORDER_ID.PRINTED
                // this.status = ORDER_ID.SHIPMENT

                this.form.controls.name.setValue(order.customer_name)
                this.form.controls.last_name.setValue(order.customer_last_name)
                this.form.controls.telephone.setValue(order.telephone)

                this.formStatusCreate.controls.is_multi_delivery.setValue(
                  this.order.is_multi_delivery
                )

                this.formTax.reset({
                  is_tax: this.order.is_tax,
                  tax_name: this.order.tax_name,
                  tax_id: this.order.tax_id,
                  tax_address: this.order.tax_address,
                  tax_telephone: this.order.tax_telephone,
                  is_tax_same_address: this.order.is_tax_same_address,
                })

                for (const adr of this.order.delivery_addresses) {
                  this.addAddress(adr, this.order.delivery_addresses.length)
                }

                this.productArrs.clear()
                this.products = this.order.products
                  .filter((p: any) => p.quantity > 0)
                  .map((p: any) => {
                    const addressControl = new FormControl(p.delivery_address_uuid || null, [
                      (control: AbstractControl) => {
                        if (this.isMultiDelivery && !control.value) {
                          return {
                            required: true,
                          }
                        }
                        return null
                      },
                    ])

                    this.productArrs.push(addressControl)

                    return {
                      ...p,
                      key: `${p.product_id}-${p.product_detail_id}`,
                      item: p.product_detail || p.product,
                      addressControl,
                    }
                  })

                if (this.products.length < 2) {
                  this.formStatusCreate.controls.is_multi_delivery.disable()
                }

                if (!resCompanyProfile.is_error) {
                  this.companyProfile = resCompanyProfile.data
                } else {
                  this.customSnackBar.fail()
                }

                if (!resCarat.is_error) {
                  this.carat = {
                    ...resCarat.data,
                    isUseCarat: checkUseCarat(resCarat.data),
                  }
                } else {
                  this.customSnackBar.fail()
                }

                if (!resBankAccount.is_error) {
                  this.bankAccountList = resBankAccount.data

                  this.bank_account_main_id = null
                  const banckAccountMain = this.bankAccountList.find((d: any) => d.is_primary)
                  if (banckAccountMain) {
                    this.bank_account_main_id = banckAccountMain.id
                  } else if (this.bankAccountList[0]) {
                    this.bank_account_main_id = this.bankAccountList[0].id
                  }

                  this.formPayment.reset({
                    credit: 0,
                    carat: 0,
                  })

                  if (this.isWaitPaid) {
                    this.addPayment()
                  }
                } else {
                  this.customSnackBar.fail()
                }

                if (!resBank.is_error) {
                  this.bankList = [
                    resBank.data.find((b: any) => b.code == BANK_NONE.CODE),
                    ...resBank.data.filter((b: any) => b.code != BANK_NONE.CODE),
                  ]
                } else {
                  this.customSnackBar.fail()
                }
              }
              this.loading.stop()
            })
        } else {
          if (resOrder.message == 'record not found') {
            this.order_not_found = true
          } else {
            this.customSnackBar.fail()
          }
        }
        this.loading.stop()
      }
    )
  }

  getPromotions(orderId: any) {
    this.publicService.getPromotionByOrderId(orderId).subscribe((res: any) => {
      if (!res.is_error) {
        this.discountCodeList = res.data
      } else {
        this.customSnackBar.fail()
      }
    })
  }

  addPayment() {
    const now = Moment()
    const formPayment = new FormGroup<{ [k: string]: FormControl }>({
      payment: new FormControl(1),
      is_full_amount: new FormControl(1),
      bank_account_id: new FormControl(this.bank_account_main_id || null, [
        (control: AbstractControl) => {
          const parent = control.parent as FormGroup
          if (parent && parent.controls['payment'].value == PAYMENT_TYPE.SLIP && !control.value) {
            return {
              required: true,
            }
          }
          return null
        },
      ]),
      file_id: new FormControl(null, [
        (control: AbstractControl) => {
          const parent = control.parent as FormGroup
          if (parent && parent.controls['payment'].value == PAYMENT_TYPE.SLIP && !control.value) {
            return {
              required: true,
            }
          }
          return null
        },
      ]),
      bank_id: new FormControl(null, [
        (control: AbstractControl) => {
          const parent = control.parent as FormGroup
          if (parent && parent.controls['payment'].value == PAYMENT_TYPE.SLIP && !control.value) {
            return {
              required: true,
            }
          }
          return null
        },
      ]),
      payment_amount: new FormControl(null, [
        (control: AbstractControl) => {
          const parent = control.parent as FormGroup
          if (parent) {
            const isQrcode = parent.controls['payment'].value == PAYMENT_TYPE.SLIP
            const isCreditDebit =
              parent.controls['payment'].value == PAYMENT_TYPE.CREDIT_DEBIT &&
              parent.controls['is_full_amount'].value == 2
            if ((isQrcode || isCreditDebit) && !control.value) {
              return {
                required: true,
              }
            }
          }
          return null
        },
      ]),
      date: new FormControl(now.toISOString(), [
        (control: AbstractControl) => {
          const parent = control.parent as FormGroup
          if (parent && parent.controls['payment'].value == PAYMENT_TYPE.SLIP && !control.value) {
            return {
              required: true,
            }
          }
          return null
        },
      ]),
      time: new FormControl<string>(now.format('HH:mm:ss'), [
        (control: AbstractControl) => {
          const parent = control.parent as FormGroup
          if (parent && parent.controls['payment'].value == PAYMENT_TYPE.SLIP && !control.value) {
            return {
              required: true,
            }
          }
          return null
        },
      ]),
    })

    this.arrayPayments.push(formPayment)
  }

  delPayment(index: number) {
    this.arrayPayments.removeAt(index)
  }

  getPayment(orderId: any) {
    return new Promise(rev => {
      this.publicService.getPaymentByOrderId(orderId).subscribe((res: any) => {
        if (!res.is_error) {
          this.payment = res.data
        } else {
          this.customSnackBar.fail()
        }
        rev(null)
      })
    })
  }

  useDiscountCode(call?: Function) {
    const promotion = this.promotionCodes[0]
    if (promotion) {
      this.loading.start()
      this.publicService.validatePromotion(this.order.id, promotion.id).subscribe((res: any) => {
        if (!res.is_error) {
          if (call) call()
        } else {
          this.form.controls.discount_code.setErrors({
            formApi: res.message,
          })
        }
        this.loading.stop()
      })
    } else {
      if (call) call()
    }
  }

  onCheckTax() {
    const is_tax = this.formTax.controls.is_tax.value
    if (is_tax) {
      this.formTax.reset({
        is_tax,
        tax_name: this.customer.tax_name,
        tax_id: this.customer.tax_id,
        tax_address: this.customer.tax_address,
        tax_telephone: this.customer.tax_telephone,
        is_tax_same_address: this.customer.is_tax_same_address,
      })
    } else {
      this.formTax.reset({
        is_tax,
      })
    }
  }

  formatTotalDiscount(discount: number) {
    return discount > 0 ? -discount : discount
  }

  setUseCredit(num: 1 | -1) {
    if (num == 1 ? this.useCredit < this.maxCredit : this.useCredit > 0) {
      this.formPayment.controls['credit'].setValue(this.useCredit + num)
    } else if (this.formPayment.controls.credit.value === null) {
      this.formPayment.controls['credit'].setValue(0)
    }
  }

  setUseCarat(num: 1 | -1) {
    if (!this.carat.isUseCarat) return

    if (num == 1 ? this.useCarat < this.maxCaratCustomer : this.useCarat > 0) {
      this.formPayment.controls['carat'].setValue(this.useCarat + num)
    } else if (this.formPayment.controls.carat.value === null) {
      this.formPayment.controls['carat'].setValue(0)
    }
  }

  addAddress(data?: any, length = this.addresss.controls.length) {
    // const productKeys = data
    //   ? this.products.reduce((keys: any[], p: any) => {
    //       if (p.delivery_address_uuid == data.uuid) {
    //         keys.push(p.key)
    //       }
    //       return keys
    //     }, [])
    //   : []
    const is_main = data?.is_main || false
    let newA = new FormGroup({
      data: new FormControl(data),
      name: new FormControl(data?.name || '', [Validators.required, Validators.maxLength(100)]),
      telephone: new FormControl(data?.telephone || '', [
        Validators.required,
        Validators.pattern(/^[0-9]{10,10}$/),
      ]),
      address: new FormControl(data?.address || '', [Validators.required]),
      // product: new FormControl<any[]>(productKeys, [Validators.required]),
      is_main: new FormControl(is_main),
      uuid: new FormControl(data?.uuid || crypto.randomUUID()),
    })

    if (is_main || (length < 1 && !is_main)) {
      newA.controls.is_main.disable()
    }
    this.addresss.push(newA)
  }

  delAddress(address: FormGroup, index: number) {
    this.addresss.removeAt(index)

    for (const product of this.products) {
      if (product.addressControl.value == address.controls['uuid'].value) {
        product.addressControl.reset()
      }
    }
  }

  addressProductList(i: number) {
    const values = this.addresss.value.filter((v, idx) => {
      return i != idx
    })
    return this.products.filter((p: any) => {
      return !values.some(v => {
        return (v['product'] || []).includes(p.key)
      })
    })
  }

  setDefaultAddress(item: any) {
    item.controls['is_main'].disable()
    this.addresss.controls.forEach(f => {
      if (f.controls['is_main'] !== item.controls['is_main']) {
        f.controls['is_main'].setValue(false)
        f.controls['is_main'].enable()
      }
    })
  }

  resetProductAddress() {
    for (const item of this.products) {
      item.addressControl.reset()
    }
  }

  // resetAddress() {
  //   this.addresss.controls.forEach(f => {
  //     f.controls['product'].reset([])
  //   })
  // }

  onUpdateOrder() {
    ValidateForm(this.formStatusCreate)
    if (!this.formStatusCreate.valid) return

    // const dialogRefProductAddress = this.dialog.open(ModalProductAddressComponent, {
    //   data: {},
    // })

    // dialogRefProductAddress.afterClosed().subscribe(result => {
    // if (result) {
    const value = this.formStatusCreate.getRawValue()
    const valueTax = this.formTax.getRawValue()

    if (value.addresss.length < 1) {
      this.customSnackBar.fail('ไม่พบที่อยู่')
      return
    }

    const payload = {
      ...this.order,
      is_tax: valueTax.is_tax, // ออกใบกำกับภาษี
      tax_name: valueTax.tax_name,
      tax_id: valueTax.tax_id,
      tax_address: valueTax.tax_address,
      tax_telephone: valueTax.tax_telephone,
      is_tax_same_address: valueTax.is_tax_same_address,
      is_multi_delivery: value.is_multi_delivery,
      is_confirm_address: true,
      delivery_addresses: value.addresss.map((d: any) => ({
        id: d.data?.id || null,
        name: d.name,
        telephone: d.telephone,
        address: d.address,
        is_main: d.is_main,
        uuid: d.uuid,
      })),
      products: this.products.map((p: any) => {
        // console.log('p', p)
        // p.delivery_address_uuid = p.addressControl.value || null
        // delete p.addressControl

        const product = {
          ...p,
          delivery_address_uuid: p.addressControl.value || null,
        }
        delete product.addressControl

        return product
      }),
    }

    // return console.log(payload)

    this.loading.start()
    this.publicService.updateOrder(this.order.id, payload).subscribe((res: any) => {
      if (!res.is_error) {
        // this.resetOrder()
        location.reload()
      } else {
        this.customSnackBar.failSave(res.message)
      }
      this.loading.stop()
    })
    // }
    // })
  }

  resetOrder() {
    this.isEditCustomer = false
    this.loading.start()
    this.publicService.getOrderByLinkCode(this.link_code).subscribe((resOrder: any) => {
      if (!resOrder.is_error) {
        this.order = resOrder.data
        this.status = this.order.status_id
      } else {
        this.customSnackBar.failSave(resOrder.message)
      }
      this.loading.stop()
    })
  }

  discountCode() {
    const dialogRefDiscountCode = this.dialog.open(ModalDiscountCodeComponent, {
      data: {
        products: this.products,
        discountCodeList: this.discountCodeList,
        promotionCode: this.form.controls.discount_code.value?.[0],
      },
    })

    dialogRefDiscountCode.afterClosed().subscribe(result => {
      if (result) {
        this.form.controls.discount_code.setValue([result])
      }
    })
  }

  bankAccount(paymentGroup: FormGroup) {
    return this.bankAccountList.find(
      (d: any) => d.id == paymentGroup.controls['bank_account_id'].value
    )
  }

  downloadQr(paymentGroup: FormGroup) {
    const banckAccount = this.bankAccountList.find(
      (d: any) => d.id == paymentGroup.controls['bank_account_id'].value
    )

    if (banckAccount) {
      this.loading.start()
      this.publicService.getBlob(banckAccount.qr.id).subscribe(res => {
        if (res) {
          DownloadFileBlob(res.body, 'qr bhb payment')
          this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
        } else {
          this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
        }
        this.loading.stop()
      })
    }
  }

  changePriceSlip() {
    if (this.paymentCreditDebit && this.paymentCreditDebit.value) {
      this.paymentCreditDebit.controls['payment_amount'].setValue(this.paymentRemainAmount)
    }
  }

  resetPayment(paymentGroup: FormGroup) {
    const value = paymentGroup.getRawValue()
    const now = Moment()
    paymentGroup.reset({
      // credit: value.credit,
      // carat: value.carat,
      payment: value.payment,
      is_full_amount: value.is_full_amount,
      bank_account_id: this.bank_account_main_id || null,
      date: now.toISOString(),
      time: now.format('HH:mm:ss'),
    })
  }

  validateCreditAndCarat() {
    this.creditAndCaratError = false
    this.formPayment.reset({
      ...this.formPayment.getRawValue(),
    })

    const sumTotalWithoutCaratAndCredit =
      this.sumTotal + (this.creditCodePrice + this.caratCodePrice)
    const sumCaratAndCredit = this.creditCodePrice + this.caratCodePrice
    if (sumCaratAndCredit > sumTotalWithoutCaratAndCredit) {
      this.creditAndCaratError = true
    } else if (sumCaratAndCredit < sumTotalWithoutCaratAndCredit) {
      return true
    }

    return false
  }

  onPayment() {
    const isCheckValidateForm = this.validateCreditAndCarat()
    if (this.creditAndCaratError) {
      return
    }

    if (isCheckValidateForm) {
      ValidateForm(this.formPayment)
      if (!this.formPayment.valid) return

      ValidateArrayForm(this.arrayPayments)
      if (!this.arrayPayments.valid) return
    }

    if (this.useCredit > this.maxCredit) {
      this.formPayment.controls['credit'].setErrors({
        max: true,
      })
      return
    }

    if (this.useCarat > this.maxCaratCustomer) {
      this.formPayment.controls['carat'].setErrors({
        max: true,
      })
      return
    }

    this.useDiscountCode(() => {
      // const value = this.formPayment.getRawValue()
      const valueTax = this.formTax.getRawValue()
      const valuePayments = this.arrayPayments.getRawValue()

      const payload: any = {
        order_id: this.order.id,
        is_used_promotion: false,
        promotion_id: this.promotionCodes[0]?.id,
        used_credit: this.isWaitPaid ? this.useCredit : 0, // จำนวนเครดิตที่จะใช้
        used_carat: this.isWaitPaid ? this.useCarat : 0, // จำนวนกะรัตที่จะใช้
        is_qrcode: true,
        file_id: null, // แนบสลิป
        bank_account_id: null,
        bank_id: null,
        payment_date: convertDateToApi(Moment()),
        payment_amount: this.totalPaymentAmount + this.totalPaymentAmountDetail,
        discount_code_price: this.discountCodePrice,
        credit_code_price: this.creditCodePrice,
        carat_code_price: this.caratCodePrice,
        grand_total_price: this.sumTotal,
        received_carat: this.caratTotal.total, // ยอดกะรัตที่ได้จากการเปิดออเดอร์
        is_consent: true,
        is_tax: valueTax.is_tax, // ออกใบกำกับภาษี
        tax_name: valueTax.tax_name,
        tax_id: valueTax.tax_id,
        tax_address: valueTax.tax_address,
        tax_telephone: valueTax.tax_telephone,
        is_tax_same_address: valueTax.is_tax_same_address,
        files: [],
        details: [],
      }

      if (payload.grand_total_price > 0) {
        payload.details = [
          ...(this.payment ? this.payment.details : []),
          ...valuePayments.map(value => {
            const detail: any = {
              id: null,
              payment_type: value['payment'], // 1:QrCode 2:เงินสด 3:Credit
              bank_account_id: null,
              bank_id: null,
              payment_date: null,
              payment_amount: 0,
              fee_amount: 0,
              file_id: null,
            }

            const payment_amount = value['payment_amount'] || 0

            if (value['payment'] == 1) {
              detail.file_id = value['file_id'] || null
              detail.bank_id = value['bank_id'] || null
              detail.payment_amount = payment_amount
              detail.bank_account_id = value['bank_account_id'] || null
              if (value['date']) {
                detail.payment_date = convertDateToApi(
                  mergeDateAndTime(value['date'], value['time'])
                )
              }
            } else {
              if (value['is_full_amount'] == 1) {
                detail.payment_amount = this.paymentRemainAmount
              } else {
                detail.payment_amount = payment_amount
              }

              detail.payment_date = convertDateToApi(Moment())
              detail.fee_amount = (this.companyProfile.fee_percent / 100) * detail.payment_amount
            }
            return detail
          }),
        ]
      }

      // console.log(payload)
      // return

      this.loading.start()
      this.publicService.savePayment(payload).subscribe((res: any) => {
        if (!res.is_error) {
          if (!this.detailCreditDebit && res.data.payment_link) {
            location.href = res.data.payment_link
          } else {
            location.reload()
          }
        } else {
          this.customSnackBar.failSave(res.message)
        }
        this.loading.stop()
      })
    })
  }

  openMyTier() {
    this.dialog.open(ModalMyTierComponent, {
      data: {
        order: this.order,
        customer: this.customer,
      },
    })
  }

  openDiscountCarat() {
    this.dialog.open(ModalDiscountCaratComponent, {
      data: {
        carat: this.carat,
      },
    })
  }
}
