<div class="login-body-wrapper">
  <div class="login-layout">
    <div class="login-center">
      <div class="login-box">
        <div>
          <div class="login-logo">
            <img alt="Logo" src="/assets/images/logos/logo-web-1.svg" width="140" />
          </div>
          <div class="brand-title">ระบบจัดการหลังร้าน Brandname Hunter</div>
        </div>

        <!-- login -->
        @if (form == 'login') {
        <form autocomplete="off">
          <div class="title mb-2">
            <span class="title">เข้าสู่ระบบ</span>
          </div>

          @if (failLogin) {
          <app-alert-message
            type="error"
            [message]="failLogin "
            (onClose)="failLogin = ''"></app-alert-message>
          }

          <div class="row gx-0 w-100 mt-3">
            <div class="col-12">
              <label
                class="form-label"
                [class.text-danger]="formLogin.controls['email'].touched && formLogin.controls['email'].invalid">
                อีเมล
              </label>
              <app-input [control]="formLogin.controls['email']" (onEnter)="onLogin()">
                @if (formLogin.controls['email'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @if (formLogin.controls['email'].hasError('pattern')) {
                <ng-container error>รูปแบบอีเมลไม่ถูกต้อง</ng-container>
                }
              </app-input>
            </div>

            <div class="col-12 mt-1">
              <label
                class="form-label"
                [class.text-danger]="formLogin.controls['password'].touched && formLogin.controls['password'].invalid">
                รหัสผ่าน
              </label>
              <app-input-password [control]="formLogin.controls['password']" (onEnter)="onLogin()">
                @if (formLogin.controls['password'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                }
              </app-input-password>
            </div>

            <div class="col-12 my-2" (click)="forgot()">
              <span class="forgot-button"> ลืมรหัสผ่าน </span>
            </div>

            <div class="col-12 mt-3">
              <button type="button" class="btn btn-primary w-100" (click)="onLogin()">
                เข้าสู่ระบบ
              </button>
            </div>
          </div>
        </form>
        }

        <!-- forgot -->
        @if (form == 'forgot') {
        <form autocomplete="off">
          <div class="title mb-2">
            <span class="title">ลืมรหัสผ่าน</span>
          </div>

          @if (successForgot) {
          <app-alert-message
            type="success"
            [message]="successForgot"
            (onClose)="successForgot = ''"></app-alert-message>
          }

          <div class="row gx-0 w-100 mt-3">
            <div class="col-12">
              <label
                class="form-label"
                [class.text-danger]="forgotForm.controls['email'].touched && forgotForm.controls['email'].invalid">
                อีเมลผู้ใช้งาน
              </label>
              <app-input [control]="forgotForm.controls['email']" (onEnter)="onForgot()">
                @if (forgotForm.controls['email'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (forgotForm.controls['email'].hasError('pattern')) {
                <ng-container error>รูปแบบอีเมลไม่ถูกต้อง</ng-container>
                } @else if (forgotForm.controls['email'].hasError('fail')) {
                <ng-container error>อีเมลไม่ถูกต้อง</ng-container>
                }
              </app-input>
            </div>

            <div class="col-12 mt-3">
              <button type="button" class="btn btn-primary w-100" (click)="onForgot()">
                ยืนยัน
              </button>
            </div>

            <div class="col-12 mt-3">
              <button type="button" class="btn btn-outline-primary w-100" (click)="onCancel()">
                ยกเลิก
              </button>
            </div>
          </div>
        </form>
        }
      </div>
    </div>
  </div>
</div>
