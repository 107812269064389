import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  Pagination,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { UploadFileFlexibleComponent } from '../../../components/upload-file-flexible/upload-file-flexible'
import { SelectAddLinkComponent } from '../../../components/select-add-link/select-add-link'
import { ModalPreviewComponent } from '../../../components/modal-preview/modal-preview'
import { ModalDeleteComponent } from '../../../components/modal-delete/modal-delete'
import { CMSFaqsService } from '../../../services/cms-faqs.service'
import { MatPaginatorModule } from '@angular/material/paginator'
import { StatusComponent } from '../../../components/status/status'

@Component({
  selector: 'app-cms-faqs',
  standalone: true,
  imports: [
    ...imports,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    UploadFileFlexibleComponent,
    SelectAddLinkComponent,
    MatPaginatorModule,
    StatusComponent
  ],
  templateUrl: './cms-faqs.html',
  styleUrls: ['./cms-faqs.scss'],
})
export class CMSFAQSComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment

  readonly paginationFaqs = new Pagination({})
  faqsList: any[] = []

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public CMSFaqsService: CMSFaqsService
  ) {}

  ngOnInit(): void {
    this.getCmsFaqsList()
  }

  getCmsFaqsList() {
    this.profile.getProfile() // add for permission

    const pagination = this.paginationFaqs.get()
    const payload = {
      ...pagination,
    }
    this.loading.start()
    this.CMSFaqsService.getCmsFaqsQuery(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.faqsList = res.data.records
          this.paginationFaqs.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  confirmDelete(id: any) {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบหมวดหมู่คำถามนี้ใช่หรือไม่`,
      },
    })

    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        this.deleteFaqs(id)
      }
    })
    return dialogRefDelete
  }

  deleteFaqs(id: any) {
    this.loading.start()
    this.CMSFaqsService.deleteCmsFaqsById(id).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('ลบข้อมูลสำเร็จ')
          this.getCmsFaqsList()
        } else {
          this.customSnackBar.fail(res.message || 'ลบข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }
}
