export const Ascending = (datas: any, key: string, reverse: boolean = false) =>
  datas.sort((a: any, b: any) => ((reverse ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1))

export const AscendingByLang = (datas: any, key: string, firstLang: string) =>
  datas.sort((a: any, b: any) =>
    a[key].localeCompare(b[key], firstLang, { ignorePunctuation: true })
  )

  export const sortList = [
    {
      value: {
        sortName: 'created_at',
        sortBy: 'desc',
      },
      text: 'เรียงตามวันที่สร้างใหม่-เก่า',
    },
    {
      value: {
        sortName: 'code',
        sortBy: 'asc',
      },
      text: 'เรียงตามรหัสสินค้า A-Z',
    },
    {
      value: {
        sortName: 'code',
        sortBy: 'desc',
      },
      text: 'เรียงตามรหัสสินค้า Z-A',
    },
    {
      value: {
        sortName: 'name',
        sortBy: 'asc',
      },
      text: 'เรียงตามชื่อสินค้า A-Z',
    },
    {
      value: {
        sortName: 'selling_price',
        sortBy: 'asc',
      },
      text: 'เรียงตามราคาขายต่ำ-สูง',
    },
    {
      value: {
        sortName: 'selling_price',
        sortBy: 'desc',
      },
      text: 'เรียงตามราคาขายสูง-ต่ำ',
    },
    {
      value: {
        sortName: 'stock',
        sortBy: 'desc',
      },
      text: 'เรียงตามจำนวนสต๊อกมาก-น้อย',
    },
  ]