<mat-form-field
  appearance="outline"
  class="select-custom-arrow {{ type == 'select-bank' ? 'select-bank' : 'select' }} {{ remove_bottom && 'remove-bottom' }}">
  <mat-select
    #select
    [formControl]="formControl"
    [placeholder]="placeholder"
    [multiple]="multiple"
    [hideSingleSelectionIndicator]="true"
    (selectionChange)="selectionChange.emit($event)"
    [placeholder]="placeholder"
    (opened)="inputSearch.focus()"
    (closed)="searchText.set('')">
    @if(showTrigger){
      <mat-select-trigger>
      <div class="d-flex" [ngStyle]="{ 'margin-top': '1px' }">
        <div>{{showTrigger}}</div>
      </div>
    </mat-select-trigger>
    }@else{
      <mat-select-trigger>
      <div class="d-flex" [ngStyle]="{ 'margin-top': '1px' }">
        @for (textHtml of tiggerInnerHtml; track $index) { @if ($index){ ,&nbsp; }
        <div [innerHTML]="textHtml | sanitizeHtml"></div>
        }
      </div>
    </mat-select-trigger>
    }

    <div class="search-select" [class.d-none]="!search.length">
      <input
        type="text"
        [(ngModel)]="searchText"
        class="form-control input w-100"
        placeholder="ค้นหา"
        (keydown)="$event.stopPropagation()"
        #inputSearch />
    </div>

    @if (datas.length) {
    <!-- <ng-content select="[beforeOption]"></ng-content> -->
    <!-- ~ -->
    @for (option of datas; track option) {
    <mat-option
      [value]="option[fieldValue]"
      [disabled]="disabledNotAllOption && option[fieldValue] != 'all'"
      [class.d-none]="isNoSearch(option)"
      (onSelectionChange)="onSelectionChange.emit($event)">
      <ng-container *ngTemplateOutlet="optionRef; context: { $implicit: option }"></ng-container>
    </mat-option>
    }
    <!-- ~ -->
    } @else {
    <mat-option [disabled]="true" class="option-customer-item text-center select-not-found">
      {{ empty }}
    </mat-option>
    }
  </mat-select>

  @if (control.invalid) {
  <mat-error>
    <ng-content select="[error]"></ng-content>
  </mat-error>
  }

  <div matSuffix class="select-suffix d-flex align-items-center">
    <img src="/assets/images/icons/icon-select.svg" />
  </div>
</mat-form-field>
