<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">จัดการเมนูหน้าหลัก</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">
    <div class="row">
      <div class="col-6">ภาษาไทย</div>
      <div class="col-6">ภาษาอังกฤษ</div>
    </div>
    <div
      id="all"
      cdkDropList
      [cdkDropListData]="menu.controls"
      (cdkDropListDropped)="drop($event)"
      class="drag-list mt-3 w-100">
      @for (item of menu.controls; track item; let i = $index) {
      <div class="d-flex drag-box mb-2">
        <div class="pre-box">
          <label class="form-label">&nbsp;</label>

          <button
            class="btn btn-transparent-outline me-1"
            matRipple
            cdkDragHandle
            cdkDrag
            [cdkDragData]="item">
            <span class="icon icon-dark-grey material-symbols-outlined fill"> drag_indicator </span>
          </button>
        </div>

        <div class="w-100">
          <div class="row">
            <div class="col-6">
              <label class="form-label">เมนู</label>

              @if (item.controls['is_defalut'].value) {
              <app-input [control]="item.controls['name_th']" [disabled]="true" />
              }@else {
              <app-select
                [control]="item.controls['product_category_id']"
                [datas]="categoryList(categories, item.controls['product_category_id'].value)"
                fieldValue="id"
                placeholder="เลือก"
                [search]="['name_th']"
                (selectionChange)="chnageSelect(item)">
                <ng-template #optionRef let-option> {{ option.name_th }} </ng-template>
              </app-select>
              }
            </div>

            <div class="col-6">
              <label class="form-label">เมนู</label>
              <app-input [control]="item.controls['name_en']" [disabled]="true" />
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-outline-secondary btn-md" matRipple (click)="onClose()">ยกเลิก</button>
    <button class="btn btn-primary btn-md" matRipple (click)="onConfirm()">บันทึก</button>
  </div>
</mat-dialog-actions>
