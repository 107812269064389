<div class="page-content-inner mb-3">
  <div class="top-content-wrapper align-items-center flex-wrap mb-3">
    <div class="top-content-left"><div class="title-blue">ข้อมูลสต๊อกสินค้า</div></div>
    <div class="top-content-right">
      @if (product.action_stock.add) {
      <button matRipple (click)="openModalAddStock('เพิ่มสต๊อก')" class="btn btn-primary">
        <span class="icon material-symbols-outlined fill">add_circle</span>
        เพิ่มสต๊อก
      </button>
      }
    </div>
  </div>

  <div class="d-flex flex-wrap align-items-center">
    <div class="me-4">
      สต๊อกคงเหลือปัจจุบัน:
      <span class="title-blue ms-1">{{ formatPriceAutoDecimal(total.total_stock) }}</span>
    </div>
    <div class="me-4">
      ต้นทุนสต๊อก:
      <span class="title-blue ms-1">{{ formatPriceAutoDecimal(total.total_cost_price) }} บาท</span>
    </div>
    <div class="">
      มูลค่าสต๊อก:
      <span class="title-blue ms-1">{{ formatPriceAutoDecimal(total.total_sell_price) }} บาท</span>
    </div>
    <div class="me-4">
      <span
        class="icon material-symbols-outlined fill color--neutral-400 cursor-pointer mt-2 ms-1"
        matTooltip="มูลค่าสต๊อก = สต๊อกคงเหลือปัจจุบัน x ราคาขาย"
        matTooltipPosition="above"
        matTooltipClass="custom-tooltip"
        >info</span
      >
    </div>
    <div>
      สต๊อกเว็บรวม:
      <span class="title-blue ms-1">-</span>
    </div>
  </div>
  <div class="line-detail"></div>

  @for (product_detail of products; track $index) {
  <div class="banner-stock d-flex align-items-center mt-4">
    <img
      class="img-stock me-3"
      [src]="(!product_detail.details ? product_detail.files[0]?.file?.url : imageCover?.url) || imgStockDefault" />
    <div>
      <div class="font-14">{{product_detail.name}}</div>
      <div class="detail-stock">{{product_detail.color}}, {{product_detail.size}}</div>
    </div>
  </div>

  <app-product-stock-list
    [product]="product"
    [product_detail]="product_detail"
    [resetStockList]="resetStockList"
    (onResetList)="reset()"></app-product-stock-list>
  }
</div>
