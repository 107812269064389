import { Component, ViewChild, ViewChildren } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { setLocalStorage } from '../../helpers'
import { imports } from '../../imports'
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatTable } from '@angular/material/table'
// declare const chrome: any
// console.log('chrome', chrome)

// window.addEventListener('storage', e => {
//   console.log('eeeeeeeeeeeeeeeeeeee', e)
//   if (location.pathname != '/temp') {
//     if (e.key == 'access_token') {
//       console.log('eeeeeeeeeeeeeeeeeeee1', e)
//       // location.href = '/'
//     }
//   }
// })

// window.addEventListener(
//   'message',
//   e => {
//     console.log('message', e)
//   },
//   false
// )

export interface User {
  isSelected: boolean;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  birthDate: string;
  isEdit: boolean;
}

export const UserColumns = [
  {
    key: 'isSelected',
    type: 'isSelected',
    label: '',
  },
  {
    key: 'firstName',
    type: 'text',
    label: 'First Name',
    required: true,
  },
  {
    key: 'lastName',
    type: 'text',
    label: 'Last Name',
  },
  {
    key: 'email',
    type: 'email',
    label: 'Email',
    required: true,
    pattern: '.+@.+',
  },
  {
    key: 'birthDate',
    type: 'date',
    label: 'Date of Birth',
  },
  {
    key: 'isEdit',
    type: 'isEdit',
    label: '',
  },
];

@Component({
  selector: 'app-temp',
  standalone: true,
  templateUrl: './temp.html',
  imports: [
    ...imports,
  ]
})
export class TempComponent {
  constructor(private route: ActivatedRoute) {
    route.queryParams.subscribe(queryParams => {
      if (queryParams['access_token'] && queryParams['refresh_token']) {
        localStorage.clear()
        sessionStorage.clear()

        setLocalStorage('access_token', queryParams['access_token'])
        setLocalStorage('refresh_token', queryParams['refresh_token'])

        // const win = window.top as any
        window.postMessage('ttttttttttttttttt')
      }
    })
  }

  onPost() {
    window.postMessage('sssssssssssssssssssss')
  }

  onPost2() {
    window.postMessage('sssssssssssssssssssss', 'https://bnh.poc.socket9.com')
  }

}
