import { Component, EventEmitter, Injectable, Input, OnInit, Output, SimpleChanges, inject } from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { DatePickerComponent } from '../date-picker/date-picker'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { SelectComponent } from '../select/select'
import { MatRipple } from '@angular/material/core'
import { CustomSnackBar } from '../snackbar/snackbar'
import { Loading } from '../../globals'
import { FileService } from '../../services'

@Component({
  selector: 'app-upload-file-flexible',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    DatePickerComponent,
    SelectComponent,
    ReactiveFormsModule,
    FormsModule,
    MatRipple,
  ],
  templateUrl: './upload-file-flexible.html',
  styleUrls: ['./upload-file-flexible.scss'],
})
export class UploadFileFlexibleComponent implements OnInit {
  @Input() type: string = 'view'
  @Input() logoDefault: any
  @Output() showChange = new EventEmitter<boolean>()
  @Output() upload = new EventEmitter<any>()
  @Output() onOpen = new EventEmitter()
  @Output() onClose = new EventEmitter()

  @Input() preview: string = 'square'
  @Input() imgOnly: boolean = false
  @Input() maxVideoSize: number = 30
  @Input() maxImageSize: number = 5
  @Input() isRequired: boolean = false

  readonly acceptFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'video/mp4']
  readonly acceptFileImgTypes = ['image/png', 'image/jpeg', 'image/jpg']

  // errorType: boolean = false
  // errorSize: boolean = false
  is_error: boolean = false
  error_massage: string = ''

  uuid = crypto.randomUUID()
  imageSrc: any
  isImage: any

  get logoUrl() {
    return this.imageSrc || this.logoDefault
  }

  get imgUrl() {
    return this.logoUrl || '/assets/images/default-image-product-more.png'
  }

  get error() {
    return this.is_error || this.isRequired
  }

  constructor(
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public fileService: FileService
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.logoDefault) {
      this.checkImage(this.logoDefault)
    }
  }

  // ------------upload file-------------------

  onChangeUpload(fileUpload: any) {
    const file = fileUpload.files[0]
    this.isImage = file.type.includes('image')
    fileUpload.value = ''

    if (file) {
      //check file
      if (this.imgOnly && !this.isImage) {
        this.is_error = true
        this.error_massage = 'ไฟล์ต้องเป็นนามสกุล png, jpeg, jpg'
        this.customSnackBar.fail('ไฟล์ต้องเป็นนามสกุล png, jpeg, jpg')
        return
      }
      if (!this.isValidType(file.type)) {
        this.is_error = true
        this.error_massage = 'ไฟล์ต้องเป็นนามสกุล png, jpeg, jpg, mp4'
        this.customSnackBar.fail('ไฟล์ต้องเป็นนามสกุล png, jpeg, jpg, mp4')
        return
      }
      if (this.isImage) {
        if (!this.isValidSize(file.size)) {
          this.is_error = true
          this.error_massage = `ไฟล์รูปภาพต้องไม่เกิน ${this.maxImageSize}mb`
          this.customSnackBar.fail(`ไฟล์รูปภาพต้องไม่เกิน ${this.maxImageSize}mb`)
          return
        }
      } else {
        if (!this.isValidSize(file.size)) {
          this.is_error = true
          this.error_massage = `ไฟล์วีดีโอต้องไม่เกิน ${this.maxVideoSize}mb`
          this.customSnackBar.fail(`ไฟล์วีดีโอต้องไม่เกิน ${this.maxVideoSize}mb`)
          return
        }
      }
      this.is_error = false

      var reader = new FileReader();
      reader.readAsDataURL(file);
      // reader.onload = (event: any) => {
      //   this.imageSrc = event.target.result;
      // }

      if (!this.is_error) {
        this.loading.start()
        this.fileService.upload(file).subscribe((res: any) => {
          if (res) {
            if (!res.is_error) {
              this.upload.emit(res.data[0].id)
              this.imageSrc = res.data[0].url
            } else {
              this.customSnackBar.fail()
            }
          }
          this.loading.stop()
        })
      }
    }
  }

  isValidType(fileType: any) {
    let acceptFile = this.imgOnly ? this.acceptFileImgTypes : this.acceptFileTypes
    return acceptFile.some((type: any) => {
      return type === fileType
    })
  }

  isValidSize(fileSize: number) {
    return fileSize < (this.isImage ? this.maxImageSize : this.maxVideoSize) * Math.pow(1024, 2)
  }

  isDelete() {
    this.upload.emit([])
    if (this.imageSrc) {
      this.imageSrc = ''
    } else {
      this.logoDefault = ''
    }
  }

  dragOver(event: Event) {
    event.preventDefault()
  }

  drop(event: any) {
    event.preventDefault()
    this.onChangeUpload(event.dataTransfer)
  }

  checkImage(file: any) {
    let acceptFile = ['jpg', 'jpeg', 'png']
    const check = acceptFile.some(element => {
      if (file.includes(element)) {
        return true;
      }
      return false;
    });
    this.isImage = check
  }
}
