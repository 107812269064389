import { Component, Input, Output } from '@angular/core'
import { MatRipple } from '@angular/material/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { Router, RouterModule } from '@angular/router'
import { ACTION_ACCESS, APP_PERMISSION, Profile } from '../../globals'
import { Service } from '../../services/service'
import { COMPANY_ID } from '../../helpers'

@Component({
  selector: 'app-setting-menu',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    MatRipple,
    RouterModule,
  ],
  templateUrl: './setting-menu.html',
  styleUrls: ['./setting-menu.scss'],
})
export class SettingMenuComponent {
  @Input() name = ''
  @Output() ddd = ''

  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly COMPANY_ID = COMPANY_ID

  submenu = [false]

  constructor(public router: Router, public profile: Profile, public service: Service) {}

  toggleSubMenu(i: any) {
    this.submenu[i] = !this.submenu[i]
  }

  checkActive(menu: any) {
    return this.router.url.includes(menu)
  }

  checkIsSetting() {
    return this.router.url.includes('/setting')
  }
}
