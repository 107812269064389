<mat-radio-group [formControl]="formControl" (change)="onChange.emit($event.value)">
  <div class="list-wrapper d-flex flex-wrap gap-4">
    @for (item of list; track $index) {
    <!-- ~ -->
    @if(item.show){
    <div class="d-flex flex-column">
      <img [src]="item.img" (click)="selectOption(item.value)" />
      <mat-radio-button [value]="item.value">{{item.text}}</mat-radio-button>
    </div>
    } }
  </div>
</mat-radio-group>
